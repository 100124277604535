import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { FormatterProps } from "react-data-grid";
import { TFunction, useTranslation } from "react-i18next";
import { AdapterUiService } from "./adapter-ui.service";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../context";
import { ReactElement, useContext } from "react";
import { fetchHelpers, uiConstants } from "../helpers";
import { useEntity } from "../hooks";

interface IUseExternalResourceProps {
  onExternalResourceClick: (formatterProps: FormatterProps<any>) => void;
}

export interface IExternalResource {
  // Other ERP specific attributes
  [s: string]: any;

  Agreement: string;
}

export interface IExternalResourceDialogProps {
  open: boolean;
  resource: IExternalResource | null;
  onClose: (success: boolean) => void;
}

export interface IUseExternalResourcesFormProps {
  resource: IExternalResource | null;
  onCreate?: (resource: IExternalResource) => void;
  onUpdate?: () => void;
}

export abstract class ExternalResourcesUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  abstract readonly craftsArrayKey: string;

  useExternalResources = (
    props: IUseExternalResourceProps
  ): EntityTableProvider => {
    const { onExternalResourceClick } = props;

    return () => {
      const site: IUserContextSite =
        useContext(AdapterUserContext)?.userProperties[
          AdapterUserContextProp.SITE
        ];
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const externalResourcesUiService: ExternalResourcesUiService =
        this.adapterUiService.getExternalResourcesUiService();
      const { entityName, columns } = externalResourcesUiService.getTableParams(
        onExternalResourceClick,
        t
      );

      const fetchExternalResources = async (
        fetchProps: ITableFetchFunctionProps
      ) =>
        await this.adapterUiService
          .getAdapterService()
          .fetchExternalResources(fetchHelpers.getTableQuery({ fetchProps }));

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          saveKey: "external_resources",
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchExternalResources,
          fetchOn: true,
          dependencies: [!!site],
          fetchTriggers: [site],
          rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
          useAdvancedFilters: false,
          infiniteScrolling: true,
        }),
      };
    };
  };

  abstract renderExternalResourceDialog(
    props: IExternalResourceDialogProps
  ): ReactElement;

  getI18nNamespaces(): string[] {
    return [
      "module.external_resource",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.column_selector",
      "shared.grid_header",
      "shared.advanced_filters",
      "shared.lookup",
      "shared.crafts",
      "shared.river_data_grid",
      "common.button",
      "common.label",
      "entity.workcenter",
      "entity.external_resource",
      "entity.workcenter",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  canManageExternalResources(): boolean {
    return true;
  }

  //-----------------------
  // ERP-specific
  //-----------------------

  abstract getTableParams(
    onExternalResourceClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams;

  abstract getErpSpecificI18nNamespaces(): string[];
}
