import React, { ReactElement, useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { TableContext } from "../../../../context";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { useGridActionHelpers } from "../../../../helpers";
import { gridHelpers, IUseTable } from "../../river-data-grid";
import { GenericAction } from "./generic-action";

interface IUseClearFilterActionProps {
  onClear?: () => void;
}

export const useClearFilterAction = (props?: IUseClearFilterActionProps) => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("shared.grid_header:label.clear_filters");

  const disabled: boolean =
    !gridHelpers.hasFiltersApplied(table) && !table.columnFilters.length;

  const onClick = async (): Promise<void> => {
    if (disabled) return;

    if (
      tableContext?.table.columnFilters.length !== 0 ||
      Object.keys(table.query).length !== 0 ||
      tableContext?.table.lastFetchColumnFilters.length !== 0
    ) {
      await tableContext?.table.clearFilters();
      props?.onClear?.();
    }
  };

  const renderClearFilterAction = (props?: any): ReactElement => (
    <GenericAction
      icon={FilterAltOffOutlinedIcon}
      enableOn={!disabled}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderClearFilterAction,
    clearFilterAction: {
      icon: withSanitizedProps(renderClearFilterAction),
      title,
      onClick,
      disabled,
    },
  };
};

export type IUseClearFilterAction = ReturnType<typeof useClearFilterAction>;
