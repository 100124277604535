import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { LookupType, RiverLookup } from "../../../../components/shared";
import {
  useJdeWoMassUpdateForm,
  IJdeWorkOrderMassUpdate,
} from "./use-jde-wo-mass-update-form";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { IWoMassUpdateDialogProps } from "../../../shared";
import { TableUiService } from "../../../table-ui.service";
import { uiConstants } from "../../../../helpers";
import { QueryDto } from "@river/interfaces";

export const JdeWoMassUpdateDialog: React.FC<IWoMassUpdateDialogProps> = (
  props
) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const [commonStatuesValues, setCommonStatuesValues] = useState<string[]>([]);

  const form: RiverFormInstance = useJdeWoMassUpdateForm({
    submit: async () => {
      const updatedRows = await submitUseMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.workOrder),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const {
    setStandaloneFields,
    submit,
    isProcessing,
    standalone,
    forceProcessingState,
  } = form;

  const massUpdateFields: IJdeWorkOrderMassUpdate =
    form.standalone as IJdeWorkOrderMassUpdate;

  const resetDialogState = (): void => {
    form.resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const getCommonStatusValues = async (): Promise<void> => {
    const selectedRows = tableContext?.table.getSelectedRows() ?? [];
    if (selectedRows.length === 0) return;

    const queryWorkOrderStatusRules = async (
      workOrder: any
    ): Promise<string[]> => {
      const query: QueryDto = {
        query: {
          $and: [
            {
              attribute_name: "F4826_DCTO",
              attribute_value: {
                operator: "$eq",
                value: workOrder.F4801_DCTO,
              },
            },
            {
              attribute_name: "F4826_TYPS",
              attribute_value: {
                operator: "$eq",
                value: workOrder.F4801_TYPS,
              },
            },
            {
              attribute_name: "F4826_SRST",
              attribute_value: {
                operator: "$eq",
                value: workOrder.F4801_SRST,
              },
            },
          ],
        },
      };

      try {
        forceProcessingState(true);
        const result = await adapterContext!.service
          .getAdapterService()
          .searchEntityData("wo_status_rule", query);
        if (result.length > 0) {
          const {
            F4826_NTST,
            F4826_A1ST,
            F4826_A2ST,
            F4826_A3ST,
            F4826_A4ST,
            F4826_A5ST,
          } = result[0];
          return [
            F4826_NTST,
            F4826_A1ST,
            F4826_A2ST,
            F4826_A3ST,
            F4826_A4ST,
            F4826_A5ST,
          ].filter(Boolean);
        }
        return [];
      } catch (message) {
        notify.error({ message });
        throw message;
      } finally {
        forceProcessingState(false);
      }
    };

    const promises = selectedRows.map((workOrder) =>
      queryWorkOrderStatusRules(workOrder)
    );

    try {
      const results = await Promise.all(promises);

      // Filter out strings that are empty or contain only spaces
      const filteredResults = results.map((result) =>
        result.filter((item) => item.trim() !== "")
      );
      const intersection = filteredResults.reduce(
        (acc, curr) => acc.filter((value) => curr.includes(value)),
        filteredResults[0] || []
      );
      setCommonStatuesValues(intersection);
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    if (
      props.open &&
      Array.from(tableContext?.table.selectedRowIds!).length > 0
    ) {
      getCommonStatusValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const submitUseMassUpdate = async (): Promise<any[]> => {
    const updateObject: { [key: string]: any } = {};
    const omitFields: string[] = ["WBSElementExternalID"];

    Object.keys(massUpdateFields).forEach((prop) => {
      // @ts-ignore
      const val: any = massUpdateFields[prop];
      if (val !== "" && !omitFields.includes(prop)) {
        updateObject[prop] = val;
      }
    });

    const errors: any[] = [];

    const processUpdate = async (id: string) => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .updateEntityData("workorder", id, updateObject);
      } catch (err) {
        errors.push(err);
      }
    };

    if (props.workorderIds) {
      for (const id of props.workorderIds) {
        await processUpdate(id);
      }
    } else {
      const selectedRowIds: string[] = Array.from(
        tableContext?.table.selectedRowIds ?? []
      );
      for (const id of selectedRowIds) {
        await processUpdate(id);
      }
    }

    if (errors.length) {
      notify.error({
        title: t(
          "shared.wo_mass_update_dialog:notification.mass_update_failed"
        ),
        message: errors[0].message,
      });
    } else {
      const success = true;
      closeDialog(success);
    }

    return Promise.resolve([]);
  };

  const renderStatusField = (): ReactElement => {
    const buildQuery = (statuses: string[]): any => ({
      $or: statuses.length
        ? statuses.map((status) => ({
            $and: [
              {
                attribute_name: "F0005_SY",
                attribute_value: { operator: "$eq", value: "00" },
              },
              {
                attribute_name: "F0005_RT",
                attribute_value: { operator: "$eq", value: "SS" },
              },
              {
                attribute_name: "F0005_KY",
                attribute_value: { operator: "$regex", value: status },
              },
            ],
          }))
        : [
            {
              $and: [
                {
                  attribute_name: "F0005_SY",
                  attribute_value: { operator: "$eq", value: "00" },
                },
                {
                  attribute_name: "F0005_RT",
                  attribute_value: { operator: "$eq", value: "SS" },
                },
                {
                  attribute_name: "F0005_KY",
                  attribute_value: { operator: "$in", value: [] },
                },
              ],
            },
          ],
    });

    const customQuery = buildQuery(commonStatuesValues);

    return (
      <RiverLookup
        id={"F4801_SRST"}
        fullWidth
        lookup={{
          type: LookupType.JDE_USER_DEFINED_CODE,
          customQuery,
        }}
        onSelect={(selectedPriority) => {
          const value = (selectedPriority?.F0005_KY ?? "") as string;
          setStandaloneFields({
            ...(standalone as IJdeWorkOrderMassUpdate),
            F4801_SRST: value.trim(),
          });
        }}
      />
    );
  };

  const renderStatusCommentField = (): ReactElement => (
    <RiverTextInput id={"F4801_STCM"} fullWidth />
  );

  const renderPriorityField = (): ReactElement => (
    <RiverLookup
      id={"F4801_PRTS"}
      fullWidth
      lookup={{
        type: LookupType.JDE_USER_DEFINED_CODE,
        customFilters: [
          {
            attribute_name: "F0005_SY",
            attribute_value: {
              operator: "$eq",
              value: "00",
            },
          },
          {
            attribute_name: "F0005_RT",
            attribute_value: {
              operator: "$eq",
              value: "PR",
            },
          },
        ],
      }}
      onSelect={(selectedPriority) => {
        const value = (selectedPriority?.F0005_KY ?? "") as string;
        setStandaloneFields({
          ...(standalone as IJdeWorkOrderMassUpdate),
          F4801_PRTS: value.trim(),
        });
      }}
    />
  );

  const renderFlashMessageToField = (): ReactElement => (
    <RiverLookup
      id={"F4801_SPRT"}
      fullWidth
      lookup={{
        type: LookupType.JDE_USER_DEFINED_CODE,
        customFilters: [
          {
            attribute_name: "F0005_SY",
            attribute_value: {
              operator: "$eq",
              value: "00",
            },
          },
          {
            attribute_name: "F0005_RT",
            attribute_value: {
              operator: "$eq",
              value: "WM",
            },
          },
        ],
      }}
      onSelect={(selectedPriority) => {
        const value = (selectedPriority?.F0005_KY ?? "") as string;
        setStandaloneFields({
          ...(standalone as IJdeWorkOrderMassUpdate),
          F4801_SPRT: value.trim(),
        });
      }}
    />
  );

  const renderAssignedToField = (): ReactElement => (
    <RiverLookup
      id={"F4801_ANP"}
      fullWidth
      lookup={{ type: LookupType.JDE_ADDRESS_BOOK }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IJdeWorkOrderMassUpdate),
          F4801_ANP: Number(selectedPriority.F0101_AN8),
        });
      }}
    />
  );

  return (
    <RiverDialog
      title={t("shared.wo_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderStatusField()}
      {renderStatusCommentField()}
      {renderPriorityField()}
      {renderFlashMessageToField()}
      {renderAssignedToField()}
    </RiverDialog>
  );
};
