import {
  PrimaryButton,
  RiverCheckbox,
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverFormSelect,
  RiverSpinner,
  RiverTextInput,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import {
  AdapterRestrictionDto,
  IAdapterRestriction,
  IAdapterRole,
  IEntity,
  QueryDto,
} from "@river/interfaces";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverFormInstance } from "../../../hooks";
import { useRestrictionForm } from "./use-restriction-form";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import styles from "./restrictions-dialog.module.scss";

interface RestrictionsDialogProps {
  open: boolean;
  restriction: IAdapterRestriction | null;
  onClose: (opts?: { refresh?: boolean }) => void;
}

export const RestrictionsDialog: React.FC<RestrictionsDialogProps> = (
  props
): ReactElement => {
  const form: RiverFormInstance = useRestrictionForm({
    restriction: props.restriction,
    onCreate: () => closeDialog({ refresh: true }),
    onUpdate: () => closeDialog({ refresh: true }),
  });

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const notify = useNotification();
  const { t } = useTranslation();

  const { submit, resetForm, isCreate, isProcessing, setDto } = form;
  const dto: AdapterRestrictionDto = form.dto as AdapterRestrictionDto;
  const { entity_name, expression, validated } = dto;

  const [roles, setRoles] = useState<IAdapterRole[]>([]);
  const [entities, setEntities] = useState<IEntity[]>([]);

  const getRoles = async () => {
    try {
      const result = await adapterContext?.service
        .getAdapterService()
        .fetchRoles();
      if (result) {
        setRoles(result);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const getEntities = async () => {
    try {
      const result = await adapterContext?.service
        .getAdapterService()
        .getEntityDefinitions();
      if (result) {
        setEntities(result);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const closeDialog = (opts?: { refresh?: boolean }): void => {
    resetForm();
    props.onClose({ refresh: !!opts?.refresh });
  };

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.restrictions:dialog.create_restriction")
      : t("module.restrictions:dialog.update_restriction");

  const getActionButtonText = (): string =>
    isCreate ? t("common.button:create") : t("common.button:save");

  useEffect(() => {
    getRoles();
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidateExpression = async () => {
    try {
      const query: QueryDto = {
        query: {
          $and: [JSON.parse(expression)],
        },
        $limit: 50,
        $skip: 0,
        sort: [],
      };
      await adapterContext?.service
        .getAdapterService()
        .searchEntityData(entity_name, query);
      setDto({
        ...dto,
        validated: true,
      });
    } catch (message) {
      setDto({
        ...dto,
        validated: false,
      });
      notify.error({ message });
    }
  };

  const renderActionsContent = (): ReactElement => (
    <>
      <RiverDialogButton
        onClick={() => closeDialog()}
        text={t("common.button:cancel")}
      />
      <RiverDialogActionButton
        disabled={!validated}
        onClick={async () => {
          console.log("onSave");
          await submit();
        }}
        text={t("common.button:save")}
      />
    </>
  );

  const renderRoleField = (): ReactElement => (
    <RiverFormSelect
      id={"role"}
      items={roles.map((item) => {
        return {
          text: `${item.role}`,
          value: item.role,
        };
      })}
      fullWidth
      disabled={!!props.restriction}
    />
  );

  const renderEntityField = (): ReactElement => (
    <RiverFormSelect
      id={"entity_name"}
      items={entities.map((item) => {
        return {
          text: `${item.entity_name}`,
          value: item.entity_name,
        };
      })}
      fullWidth
      disabled={!!props.restriction}
    />
  );

  const renderExpressionField = (): ReactElement => (
    <RiverTextInput
      id={"expression"}
      fullWidth
      onChangeEvent={(event: React.ChangeEvent<HTMLInputElement>) => {
        setDto({
          ...dto,
          expression: event.target.value,
          active: false,
          validated: false,
        });
      }}
      multiline
      inputProps={{
        minRows: 6,
        maxRows: 12,
      }}
    />
  );

  const renderValidateButton = (): ReactElement => (
    <PrimaryButton
      disabled={!entity_name || !expression}
      variant="outlined"
      className={styles.validateButton}
      onClick={onValidateExpression}
      text={t("module.restrictions:dialog.validate_btn")}
    />
  );

  const renderActiveField = (): ReactElement => (
    <RiverCheckbox id={"active"} disabled={!validated} />
  );

  const renderValidatedField = (): ReactElement => (
    <RiverCheckbox id={"validated"} disabled />
  );

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={closeDialog}
      actionButtonText={getActionButtonText()}
      form={form}
      fullWidth
      classes={{ content: styles.dialogContent }}
      actionsContent={renderActionsContent()}
    >
      <RiverSpinner show={isProcessing} />
      {renderRoleField()}
      {renderEntityField()}
      {renderExpressionField()}
      <div className={styles.expressionControls}>
        {renderValidateButton()}
        {renderActiveField()}
        {renderValidatedField()}
      </div>
    </RiverDialog>
  );
};
