import React, {
  RefObject,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  IAdapterFolder,
  IEntityObject,
  IAdapterRule,
  IAdapterBaseline,
} from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  SetTabFn,
} from "../../context";
import { ScheduleHeader } from "./schedule-header";
import { ScheduleStatusHeader } from "./schedule-status-header";
import { ScheduleTasks } from "./schedule-tasks";
import { ScheduleUtilization } from "./schedule-utilization";
import { useParams } from "react-router";
import { IScheduleGanttTimeOption } from "./schedule-tasks/schedule-gantt-header-options";
import { IUseTable, useAsyncSchedule } from "../shared";
import { IUseScheduleGantt } from "./schedule-gantt/use-schedule-gantt";
import { useEntityHelpers, uiConstants } from "../../helpers";
import { addMilliseconds } from "date-fns";
import { useNotification } from "@river/common-ui";
import { IUseScheduleOperationsOverlay } from "./schedule-gantt";
import {
  TaskHelpersUiService,
  ObjectType,
  TableUiService,
  userPreferencesService,
} from "../../services";
import { usePageCleanup } from "../../hooks";
import SplitterLayout from "react-splitter-layout";
import { DataGridHandle } from "react-data-grid";
import styles from "./schedule.module.scss";
import clsx from "clsx";

export const DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE = 40;

const DEFAULT_TASK_PANE_HEIGHT_PERCENTAGE = 60;
const INITIAL_SCHEDULE_TASKS_EXPANDED: boolean = false;

export const Schedule: React.FC = () => {
  usePageCleanup();
  const [splitterHeight, setSplitterHeight] = useState<number>(
    DEFAULT_TASK_PANE_HEIGHT_PERCENTAGE
  );
  const splitterContainerRef = useRef<HTMLDivElement>(null);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();
  const getRowsFromObjectIds = tableUiService.getRowsFromObjectIds();
  const [splitterTransitionActive, setSplitterTransitionActive] =
    useState<boolean>(true);

  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const [currentSchedule, setCurrentSchedule] = useState<IAdapterFolder>();
  const [selectedGanttDate, setSelectedGanttDate] = useState<Date>();
  const [ganttTimeOption, setGanttTimeOption] =
    useState<IScheduleGanttTimeOption>();
  const [entityTableRef, setEntityTableRef] =
    useState<RefObject<DataGridHandle>>();
  const [ganttSplitterRef, setGanttSplitterRef] =
    useState<RefObject<HTMLDivElement>>();
  const [utilizationSplitterRef, setUtilizationSplitterRef] =
    useState<RefObject<HTMLDivElement>>();
  const [ganttScrollAreaRef, setGanttScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [ganttOverlayScrollAreaRef, setGanttOverlayScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [utilizationScrollAreaRef, setUtilizationScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [
    scheduleUtilizationCurrentTableState,
    setScheduleUtilizationCurrentTableState,
  ] = useState<IUseTable>();
  const [selectedValidationRule, setSelectedValidationRule] =
    useState<IAdapterRule | null>(null);
  const [selectedBaseline, setSelectedBaseline] = useState<
    IAdapterBaseline | undefined
  >();
  const currentTasksTableRef = useRef<IUseTable>();
  const setCurrentScheduleTasksTabFnRef = useRef<SetTabFn>();
  const ganttRef = useRef<IUseScheduleGantt>();
  const utilizationGanttRef = useRef<IUseScheduleGantt>();
  const operationsOverlayRef = useRef<IUseScheduleOperationsOverlay>();
  const notify = useNotification();

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const entityHelpers = useEntityHelpers();
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getTaskFolder, getStartDateFieldName, getEndDateFieldName } =
    taskHelpers.getHelpers()();
  const [scheduleTasksExpanded, setScheduleTasksExpanded] = useState<boolean>(
    INITIAL_SCHEDULE_TASKS_EXPANDED
  );

  const asyncSchedule = useAsyncSchedule({
    onComplete: () => {
      loadCurrentSchedule();
      scheduleUtilizationCurrentTableState!.fetch();
    },
  });
  const { scheduleProgress, doAsyncSchedule } = asyncSchedule;

  const loadCurrentSchedule = async (props?: { updateGanttDate?: boolean }) => {
    try {
      const schedule = await adapterContext!.service
        .getAdapterService()
        .getFolderById(scheduleId);

      if (props?.updateGanttDate) {
        setSelectedGanttDate(new Date(schedule.start_date));
      }

      setCurrentSchedule(schedule);
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    const setInitialSplitterHeight = async () => {
      try {
        const preferenceHeight: number | null =
          await userPreferencesService.getHorizontalScheduleSplitterPosition(
            adapterContext!.service.getAdapterService()
          );
        const initialHeight = preferenceHeight ?? splitterHeight;
        setSplitterHeight(initialHeight);
      } catch (message) {
        notify.error({ message });
      }
    };

    const setInitialScheduleTaskExpanded = async () => {
      try {
        const value = await userPreferencesService.getScheduleTaskExpanded(
          adapterContext!.service.getAdapterService()
        );
        setScheduleTasksExpanded(value ?? scheduleTasksExpanded);
      } catch (message) {
        notify.error({ message });
      }
    };

    setInitialScheduleTaskExpanded();
    setInitialSplitterHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCurrentSchedule({ updateGanttDate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);

  const doSyncSchedule = async (date: Date, ids?: string[]): Promise<void> => {
    const table = currentTasksTableRef.current;

    if (!ids) {
      ids = getSelectedRowsObjectIds(table!);
    }
    if (!ids.length) return;

    const objs: IEntityObject[] = getRowsFromObjectIds(table!, ids);
    const rowType = objs[0][uiConstants.fields.rowType] as ObjectType;
    const startDateField: string = getStartDateFieldName(objs[0]);
    const endDateField: string = getEndDateFieldName(objs[0]);

    objs.forEach((obj) => {
      const oldStart = new Date(
        Date.parse(
          entityHelpers.getAttributeValue(obj, startDateField) as string
        )
      );
      const oldEnd = new Date(
        Date.parse(entityHelpers.getAttributeValue(obj, endDateField) as string)
      );

      const diff = oldEnd.getTime() - oldStart.getTime();
      const newEnd = addMilliseconds(date, diff);

      table!.updateRow({
        rowId: getObjectId(obj),
        updatedRow: {
          __folder: Object.assign(getTaskFolder(obj) as any, {
            start_date: date,
            end_date: newEnd,
          }),
        },
      });
    });

    try {
      table!.forceLoadingState(true);
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .schedule({
          folder_id: scheduleId || "",
          entity_name: rowType,
          entity_ids: ids,
          start_date: date,
        });
      table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, rowType),
          updatedRow,
        })),
      });
      loadCurrentSchedule();
    } catch (message) {
      notify.error({ message });
    } finally {
      table!.forceLoadingState(false);
    }
  };

  const scheduleFn = async (date: Date, ids?: string[]): Promise<void> => {
    const table = currentTasksTableRef.current!;
    const isSelectAll: boolean =
      table.selectedRowIds.size === table.entities.length;
    if (isSelectAll) {
      return doAsyncSchedule({ schedule: currentSchedule!, table, date });
    } else {
      return doSyncSchedule(date, ids);
    }
  };

  const calculateSplitterHeightPercentage = (): number => {
    const containerEl = splitterContainerRef.current!;
    const paneEl = containerEl.querySelectorAll(
      ".layout-pane"
    )[0] as HTMLDivElement;
    const containerHeight: number = containerEl.getBoundingClientRect().height;
    return (paneEl.offsetHeight * 100) / (containerHeight || 1);
  };

  const saveSplitterHeight = async () => {
    try {
      const splitterHeightPercentage: number =
        calculateSplitterHeightPercentage();
      await userPreferencesService.setHorizontalScheduleSplitterPosition(
        adapterContext!.service.getAdapterService(),
        splitterHeightPercentage
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const handleSplitterOnDragEnd = () => {
    setSplitterTransitionActive(true);
    saveSplitterHeight();
  };

  return (
    <>
      {!!currentSchedule && (
        <ScheduleContext.Provider
          value={{
            currentSchedule,
            refreshCurrentSchedule: loadCurrentSchedule,
            selectedGanttDate,
            setSelectedGanttDate,
            entityTableRef,
            setEntityTableRef,
            currentTasksTableRef,
            ganttSplitterRef,
            setGanttSplitterRef,
            utilizationSplitterRef,
            setUtilizationSplitterRef,
            ganttScrollAreaRef,
            setGanttScrollAreaRef,
            ganttOverlayScrollAreaRef,
            setGanttOverlayScrollAreaRef,
            utilizationScrollAreaRef,
            setUtilizationScrollAreaRef,
            ganttTimeOption,
            setGanttTimeOption,
            forceScheduleRender: forceUpdate,
            scheduleUtilizationCurrentTableState,
            setScheduleUtilizationCurrentTableState,
            selectedValidationRule,
            setSelectedValidationRule,
            scheduleFn,
            ganttRef,
            utilizationGanttRef,
            operationsOverlayRef,
            selectedBaseline,
            setSelectedBaseline,
            scheduleTasksExpanded,
            setScheduleTasksExpanded,
            setCurrentScheduleTasksTabFnRef,
          }}
        >
          <ScheduleHeader />
          <div className={clsx([styles.root])} ref={splitterContainerRef}>
            <SplitterLayout
              percentage={true}
              primaryIndex={1}
              vertical={true}
              secondaryInitialSize={splitterHeight!}
              customClassName={clsx([
                styles.splitterLayout,
                {
                  [styles.splitterTransitionActive]: splitterTransitionActive,
                  [styles.scheduleTasksExpanded]: scheduleTasksExpanded,
                },
              ])}
              onDragStart={() => {
                setSplitterTransitionActive(false);
              }}
              onDragEnd={handleSplitterOnDragEnd}
            >
              <div className={styles.topContainer}>
                <ScheduleStatusHeader />
                <ScheduleTasks />
              </div>
              <ScheduleUtilization />
            </SplitterLayout>
          </div>
          {scheduleProgress.renderDialog()}
        </ScheduleContext.Provider>
      )}
    </>
  );
};
