import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IBacklogContext,
  IUserContextSite,
} from "../../context";
import { useContext, DragEvent } from "react";
import { BacklogTabId, BacklogUiService } from "./backlog-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { IAdapterFolder, IEntityObject, QueryDto } from "@river/interfaces";
import { getFolderQueryGroup } from "@river/util";
import { AdapterService } from "../adapter.service";
import { DndUtils } from "./dnd-utils";

interface IUseBacklogOperationsProps {
  backlogContext: IBacklogContext;
}

export const useBacklogOperations = (
  props: IUseBacklogOperationsProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const operationsTabId: BacklogTabId = BacklogTabId.OPERATIONS;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const backlogContext: IBacklogContext = props.backlogContext;
  const currentSchedule: IAdapterFolder = backlogContext.currentSchedule!;
  const entityHelpers = useEntityHelpers();
  const currentTab: BacklogTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const openEntityInErp = async (record: IEntityObject) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, "workorder");
  };

  const columns: IColumn[] = uiService.getDefaultOperationColumns({
    onOperationClick: openEntityInErp,
  })();

  // Temporarily commented loading KPI data
  /*const [availabilityUtilization, setAvailabilityUtilization] =
    useState<IAvailabilityUtilizationResult | null>();*/

  const fetchFunction = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = fetchHelpers.getTableQuery({
      fetchProps,
      ...(currentSchedule && {
        initialQueryAttributeGroup: getFolderQueryGroup(currentSchedule, ""),
      }),
    });

    table.setLastRanQueryDto(query);

    const operations = await adapterService.fetchOperations(query);
    const operationRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.operation
    );

    //const workCenters = new Set<string>();

    operations.forEach((op) => {
      try {
        entityHelpers.setAttributeValue(
          op,
          uiConstants.fields.rowType,
          uiConstants.rowType.operation
        );
        entityHelpers.setAttributeValue(
          op,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(op, operationRowIdAttribute)
        );

        // workCenters.add(
        //   entityHelpers.getAttributeValue(op, "WorkCntr") as string
        // );
      } catch (e) {
        console.error(`Error processing WorkOrder Operation _id: ${op._id}`);
        console.error(e);
      }
    });

    // Temporarily commented loading KPI data
    /*const newAvailUtilization =
      await availabilityHelpers.fetchAvailabilityAndUtilization(
        adapterContext!.service.getAdapterService(),
        {
          type: "WorkCenter",
          keyField: "WorkCenter",
          startDate: startDate,
          endDate: endDate,
          resources: Array.from(workCenters).map((key) => ({
            key: key,
            name: key,
          })),
        }
      );
    setAvailabilityUtilization(newAvailUtilization);*/

    return operations;
  };

  const onDrag = (event: DragEvent, row: IEntityObject) => {
    const rowId = row[uiConstants.fields.rowId] as string;
    const dragOperations = table.selectedRowIds.has(rowId)
      ? Array.from(table.selectedRowIds).map((id) => {
          const operation = table.entities.find(
            (entity) => entity[uiConstants.fields.rowId] === id
          );
          if (!operation) {
            throw new Error(`Could not find WO ${id}`);
          }
          return operation;
        })
      : [row];

    const operationIds = dragOperations.map((o) => o["_id"]);
    DndUtils.dragWithCustomImage(event, `${dragOperations.length} operations`);
    event.dataTransfer.setData(
      "text",
      JSON.stringify({ workOrderIds: operationIds })
    );
    event.dataTransfer.dropEffect = "move";
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `backlog.operations`,
    columns,
    fetchFunction,
    dependencies: [!!site],
    fetchOn: currentTab === operationsTabId,
    initialSimpleFilters: uiService.getInitialTabSimpleFilters(operationsTabId),
    initialQuery: uiService.getInitialTabQuery(operationsTabId),
    keepSelection: true,
    fetchTriggers: [currentSchedule?._id, site],
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    infiniteScrolling: true,
    onClearFilters: () => {
      uiService.initialFiltersOverrides[operationsTabId] = null;
      uiService.initialQueryOverrides[operationsTabId] = null;
    },
    onDrag,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
