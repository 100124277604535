import { ReactElement } from "react";
import { IEntityObject } from "@river/interfaces";
import { TableContext } from "../../../../context";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FormatterProps } from "react-data-grid";
import { ColumnFormatter } from "../../../../services";
import { IUseTableCell } from "../../../../hooks";
import dataGridStyles from "../../river-data-grid/river-data-grid.module.scss";
import styles from "./work-order-id-formatter.module.scss";
import clsx from "clsx";

const DEFAULT_DRAGGABLE: boolean = true;

export interface IWorkOrderIdFormatterProps {
  onWorkOrderClick?: (workOrder: IEntityObject) => void;
  getTaskColor: (row: IEntityObject) => string;
  draggable?: boolean;
  tableCellRenderers: IUseTableCell;
}

export const getWorkOrderIdFormatter = (
  props: IWorkOrderIdFormatterProps
): ColumnFormatter => {
  const draggable: boolean = props.draggable ?? DEFAULT_DRAGGABLE;
  return (formatterProps: FormatterProps<any>): ReactElement => (
    <TableContext.Consumer>
      {(tableContext) => {
        const workOrder: IEntityObject = formatterProps.row;
        const columnKey: string = formatterProps.column.key;
        const orderId: string = workOrder[columnKey] as string;
        const taskColor: string = props.getTaskColor(workOrder);
        const { renderCell, renderCellText } = props.tableCellRenderers!;
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={clsx([styles.workOrderNumberCell])}>
                  {draggable && (
                    <div
                      className={styles.dragHandle}
                      draggable="true"
                      onDragStart={(e) => {
                        tableContext?.table.onDrag!(e, workOrder);
                      }}
                      onDragEnd={(e) => {}}
                    >
                      <DragIndicatorIcon classes={{ root: styles.dragIcon }} />
                    </div>
                  )}
                  <div
                    className={styles.colorMarker}
                    style={{
                      background: taskColor,
                    }}
                  />
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => props.onWorkOrderClick?.(workOrder)}
                  >
                    {renderCellText({ formatterProps, value: orderId })}
                  </div>
                </div>
              ),
            })}
          </>
        );
      }}
    </TableContext.Consumer>
  );
};
