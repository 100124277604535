import React, { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { LookupType, RiverLookup } from "../../../../components/shared";
import {
  useOracleCloudWoMassUpdateForm,
  IOracleCloudWorkOrderMassUpdate,
} from "./use-oracle-cloud-wo-mass-update-form";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { IWoMassUpdateDialogProps } from "../../../shared";
import { uiConstants } from "../../../../helpers";
import { TableUiService } from "../../../table-ui.service";

export const OracleCloudWoMassUpdateDialog: React.FC<
  IWoMassUpdateDialogProps
> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useOracleCloudWoMassUpdateForm({
    submit: async () => {
      const updatedRows = await submitUseMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.workOrder),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const { onStandaloneLookupSelect, submit, isProcessing } = form;
  const massUpdateFields: IOracleCloudWorkOrderMassUpdate =
    form.standalone as IOracleCloudWorkOrderMassUpdate;

  const resetDialogState = (): void => {
    form.resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const submitUseMassUpdate = async (): Promise<any[]> => {
    const updateObject: { [key: string]: any } = {};
    const omitFields: string[] = [];
    Object.keys(massUpdateFields).forEach((prop) => {
      // @ts-ignore
      const val: any = massUpdateFields[prop];
      if (val !== "" && !omitFields.includes(prop)) {
        updateObject[prop] = val;
      }
    });

    const errors: any[] = [];

    const processUpdate = async (id: string) => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .updateEntityData("workorder", id, updateObject);
      } catch (err) {
        errors.push(err);
      }
    };

    if (props.workorderIds) {
      for (const id of props.workorderIds) {
        await processUpdate(id);
      }
    } else {
      const selectedRowIds: string[] = Array.from(
        tableContext?.table.selectedRowIds ?? []
      );
      for (const id of selectedRowIds) {
        await processUpdate(id);
      }
    }

    if (errors.length) {
      notify.error({
        title: t(
          "shared.wo_mass_update_dialog:notification.mass_update_failed"
        ),
        message: errors[0],
      });
    } else {
      const success = true;
      closeDialog(success);
    }

    return Promise.resolve([]);
  };

  const renderWorkOrderStatusCodeField = (): ReactElement => (
    <RiverLookup
      id={"WorkOrderStatusCode"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_CLOUD_WO_STATUS }}
      onSelect={onStandaloneLookupSelect("WoStatusCode", "WorkOrderStatusCode")}
    />
  );

  const renderWorkOrderTypeCodeField = (): ReactElement => (
    <RiverLookup
      id={"WorkOrderTypeCode"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_CLOUD_WO_TYPE }}
      onSelect={onStandaloneLookupSelect("LookupCode", "WorkOrderTypeCode")}
    />
  );

  const renderWorkOrderSubTypeCodeField = (): ReactElement => (
    <RiverLookup
      id={"WorkOrderSubTypeCode"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_CLOUD_WO_SUBTYPE }}
      onSelect={onStandaloneLookupSelect("LookupCode", "WorkOrderSubTypeCode")}
    />
  );

  return (
    <RiverDialog
      title={t("shared.wo_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderWorkOrderStatusCodeField()}
      {renderWorkOrderTypeCodeField()}
      {renderWorkOrderSubTypeCodeField()}
      <RiverTextInput
        id={"WorkOrderPriority"}
        fullWidth
        inputProps={{
          type: "number",
        }}
      />
    </RiverDialog>
  );
};
