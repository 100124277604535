import {
  SupervisorScheduleUtilizationTabId,
  SupervisorScheduleUtilizationUiService,
  IGetDefaultSupervisorScheduleBacklogColumnsProps,
  IGetSupervisorProgressFiltersProps,
  SupervisorProgressSummaryFilterID,
  IGetSupervisorComplianceFiltersProps,
  SupervisorComplianceSummaryFilterID,
} from "../../supervisor-schedule-utilization-ui-service";
import {
  ColumnsProvider,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../../ui-service.types";
import { TabProps } from "@mui/material/Tab";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useTranslation } from "@river/common-ui";
import { Avatar } from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../../components/schedule/schedule-utilization/utilization-helpers";
import {
  GenericAction,
  RefreshAction,
  getWorkOrderIdFormatter,
  useRiverSelectColumn,
} from "../../../components/shared";
import { TextEditor } from "react-data-grid";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTableCellRenderers } from "../../../hooks";
import React, { useContext } from "react";
import styles from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/defaultColumns.module.scss";
import clsx from "clsx";
import { QueryAttributeDto } from "@river/interfaces";
import {
  IOracleEbsSupervisorScheduleProgressFiltersDto,
  OracleEbsSupervisorScheduleProgressFilters,
} from "./oracle-ebs-supervisor-schedule-progress-filters";
import {
  IOracleEbsSupervisorScheduleComplianceFiltersDto,
  OracleEbsSupervisorSchedulingComplianceFilters,
} from "./oracle-ebs-supervisor-schedule-compliance-filters";
import TuneIcon from "@mui/icons-material/Tune";
import { SupervisorScheduleUtilizationContext } from "../../../context/supervisor-schedule.utilization.context";

const DEFAULT_ORACLE_EBS_SUPERVISOR_SCHEDULE_UTILIZATION_TAB: SupervisorScheduleUtilizationTabId =
  SupervisorScheduleUtilizationTabId.METRICS;

export class OracleEbsSupervisorScheduleUtilizationUiService extends SupervisorScheduleUtilizationUiService {
  getTabs(): TabsProvider {
    return (): TabProps[] => {
      const { t } = useTranslation();
      return [
        {
          label: t("module.supervisor_schedule:tab.metrics.title"),
          icon: <LeaderboardIcon />,
          value: SupervisorScheduleUtilizationTabId.METRICS,
        },
        {
          label: t("module.supervisor_schedule:tab.backlog.title"),
          icon: <DynamicFeedIcon />,
          value: SupervisorScheduleUtilizationTabId.BACKLOG,
        },
        {
          label: t("module.supervisor_schedule:tab.craft.title"),
          icon: <CenterFocusWeakIcon />,
          value: SupervisorScheduleUtilizationTabId.CRAFTS,
        },
        {
          label: t("module.supervisor_schedule:tab.people.title"),
          icon: <PersonIcon />,
          value: SupervisorScheduleUtilizationTabId.PERSONS,
        },
        {
          label: t("module.supervisor_schedule:tab.crews.title"),
          icon: <GroupsIcon />,
          value: SupervisorScheduleUtilizationTabId.CREWS,
        },
        {
          label: t("module.supervisor_schedule:tab.validation_rules.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.VALIDATION_RULES,
        },
        {
          label: t("module.supervisor_schedule:tab.progress.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.PROGRESS,
        },
        {
          label: t("module.supervisor_schedule:tab.compliance.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.COMPLIANCE,
        },
      ];
    };
  }

  getDefaultTab(): SupervisorScheduleUtilizationTabId {
    return DEFAULT_ORACLE_EBS_SUPERVISOR_SCHEDULE_UTILIZATION_TAB;
  }

  getDefaultBacklogColumns(
    props: IGetDefaultSupervisorScheduleBacklogColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WIP_ENTITY_NAME",
          name: t("entity.workorder:workorder.WIP_ENTITY_NAME"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.workorder:workorder.DESCRIPTION"),
          editor: TextEditor,
        },
        {
          key: "OWNING_DEPARTMENT_CODE",
          name: t("entity.workorder:workorder.OWNING_DEPARTMENT_CODE"),
        },
        {
          key: "INSTANCE_NUMBER",
          name: t("entity.workorder:workorder.INSTANCE_NUMBER"),
        },
        {
          key: "SHUTDOWN_TYPE_DISP",
          name: t("entity.workorder:workorder.SHUTDOWN_TYPE_DISP"),
        },
        {
          key: "WORK_ORDER_STATUS",
          name: t("entity.workorder:workorder.WORK_ORDER_STATUS"),
        },
        {
          key: "PRIORITY_DISP",
          name: t("entity.workorder:workorder.PRIORITY_DISP"),
        },
        {
          key: "WORK_ORDER_TYPE_DISP",
          name: t("entity.workorder:workorder.WORK_ORDER_TYPE_DISP"),
        },
        {
          key: "ACTIVITY_TYPE_DISP",
          name: t("entity.workorder:workorder.ACTIVITY_TYPE_DISP"),
        },
        {
          key: "ACTIVITY_CAUSE_DISP",
          name: t("entity.workorder:workorder.ACTIVITY_CAUSE_DISP"),
        },
        {
          key: "ACTIVITY_SOURCE_MEANING",
          name: t("entity.workorder:workorder.ACTIVITY_SOURCE_MEANING"),
        },
        {
          key: "SCHEDULED_START_DATE",
          name: t("entity.workorder:workorder.SCHEDULED_START_DATE"),
        },
        {
          key: "SCHEDULED_COMPLETION_DATE",
          name: t("entity.workorder:workorder.SCHEDULED_COMPLETION_DATE"),
        },
      ];
    };
  }

  getDefaultCraftUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "RESOURCE_CODE",
          name: t(
            "entity.department_resource:department_resource.RESOURCE_CODE"
          ),
        },
        {
          key: "DEPARTMENT_CODE",
          name: t(
            "entity.department_resource:department_resource.DEPARTMENT_CODE"
          ),
        },
      ];
    };
  }

  getCraftUtilizationEntityName(): string {
    return "resource_utilization";
  }

  getDefaultPersonUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "INSTANCE_NAME",
          name: t("entity.person_utilization:person_utilization.INSTANCE_NAME"),
          width: 165,
          formatter: (formatterProps) => {
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <div className={clsx([styles.person])}>
                      <Avatar
                        url={utilizationHelpers.getRandomGanttAvatarURL(
                          Number(formatterProps.row.Resources)
                        )}
                      />
                      <div>
                        {formatterProps.row[
                          formatterProps.column.key
                        ]?.toString()}
                      </div>
                    </div>
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "Resources.RESOURCE_CODE",
          name: t(
            "entity.department_resource:department_resource.RESOURCE_CODE"
          ),
        },
        {
          key: "Resources.DEPARTMENT_CODE",
          name: t(
            "entity.department_resource:department_resource.DEPARTMENT_CODE"
          ),
        },
      ];
    };
  }
  getCraftUtilizationKeyColumn(): string {
    return "RESOURCE_ID";
  }

  getCraftUtilizationStatLabelColumn(): string {
    return "RESOURCE_CODE";
  }

  getProgressGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const supervisorScheduleUtilizationContext = useContext(
        SupervisorScheduleUtilizationContext
      );
      const { progressFiltersOpened, setProgressFiltersOpened } =
        supervisorScheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.supervisor_schedule:utilization_filters.progress_filters_toggle"
            )}
            onClick={() => {
              setProgressFiltersOpened(!progressFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  getComplianceGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const supervisorScheduleUtilizationContext = useContext(
        SupervisorScheduleUtilizationContext
      );
      const { complianceFiltersOpened, setComplianceFiltersOpened } =
        supervisorScheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.supervisor_schedule:utilization_filters.compliance_filters_toggle"
            )}
            onClick={() => {
              setComplianceFiltersOpened(!complianceFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <OracleEbsSupervisorScheduleProgressFilters />;
  }

  renderComplianceFilters(): ReactElementProvider {
    return () => <OracleEbsSupervisorSchedulingComplianceFilters />;
  }

  getComplianceFilters(
    props: IGetSupervisorComplianceFiltersProps
  ): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      let woTypeFilters: any[] =
        (
          props.complianceFilters as IOracleEbsSupervisorScheduleComplianceFiltersDto
        )[SupervisorComplianceSummaryFilterID.WO_TYPES] || [];

      if (!!woTypeFilters.length) {
        queryAttributes.push({
          attribute_name: "WORK_ORDER_TYPE",
          attribute_value: {
            operator: "$in",
            value: woTypeFilters.map((wo_type) => wo_type.LOOKUP_CODE),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getProgressFilters(props: IGetSupervisorProgressFiltersProps): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      let woTypeFilters: any[] =
        (
          props.progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
        )[SupervisorProgressSummaryFilterID.WO_TYPES] || [];
      let departmentFilters: any[] =
        (
          props.progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
        )[SupervisorProgressSummaryFilterID.DEPARTMENTS] || [];
      let resourceFilters: any[] =
        (
          props.progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
        )[SupervisorProgressSummaryFilterID.RESOURCES] || [];
      if (!!woTypeFilters.length) {
        queryAttributes.push({
          attribute_name: "WORK_ORDER_TYPE",
          attribute_value: {
            operator: "$in",
            value: woTypeFilters.map((wo_type) => wo_type.LOOKUP_CODE),
          },
        });
      }

      if (!!departmentFilters.length) {
        queryAttributes.push({
          attribute_name: "DEPARTMENT_ID",
          attribute_value: {
            operator: "$in",
            value: departmentFilters.map(
              (department) => department.DEPARTMENT_ID
            ),
          },
        });
      }

      if (!!resourceFilters.length) {
        queryAttributes.push({
          attribute_name: "RESOURCE_ID",
          attribute_value: {
            operator: "$in",
            value: resourceFilters.map((resource) => resource.RESOURCE_ID),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getBlankProgressFilters(): IOracleEbsSupervisorScheduleProgressFiltersDto {
    return { wo_types: [], departments: [], resources: [] };
  }
  getBlankComplianceFilters(): IOracleEbsSupervisorScheduleComplianceFiltersDto {
    return { wo_types: [] };
  }
}
