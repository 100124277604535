import {
  IGetDefaultScheduleBacklogColumnsProps,
  IGetProgressFiltersProps,
  ProgressSummaryFilterID,
  ScheduleUtilizationTabId,
  ScheduleUtilizationUiService,
} from "../../schedule-utilization-ui-service";
import {
  ColumnsProvider,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../../ui-service.types";
import { TabProps } from "@mui/material/Tab";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTranslation } from "@river/common-ui";
import { Avatar } from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../../components/schedule/schedule-utilization/utilization-helpers";
import {
  GenericAction,
  RefreshAction,
  getWorkOrderIdFormatter,
  useRiverSelectColumn,
} from "../../../components/shared";
import { TextEditor } from "react-data-grid";
import { useTableCellRenderers } from "../../../hooks";
import styles from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/defaultColumns.module.scss";
import clsx from "clsx";
import { ScheduleUtilizationContext } from "../../../context";
import { useContext } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import {
  IJdeScheduleProgressFiltersDto,
  JdeScheduleProgressFilters,
} from "./jde-schedule-progress-filters";
import { IJdeScheduleComplianceFiltersDto } from "./jde-schedule-compliance-filters";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";

const DEFAULT_JDE_SCHEDULE_UTILIZATION_TAB: ScheduleUtilizationTabId =
  ScheduleUtilizationTabId.BACKLOG;

export class JdeScheduleUtilizationUiService extends ScheduleUtilizationUiService {
  getTabs(): TabsProvider {
    return (): TabProps[] => {
      const { t } = useTranslation();
      return [
        {
          label: t("module.schedule:tab.backlog.title"),
          icon: <DynamicFeedIcon />,
          value: ScheduleUtilizationTabId.BACKLOG,
        },
        {
          label: t("module.schedule:tab.craft.title"),
          icon: <CenterFocusWeakIcon />,
          value: ScheduleUtilizationTabId.CRAFTS,
        },
        {
          label: t("module.schedule:tab.people.title"),
          icon: <PersonIcon />,
          value: ScheduleUtilizationTabId.PERSONS,
        },
        {
          label: t("module.schedule:tab.crews.title"),
          icon: <GroupsIcon />,
          value: ScheduleUtilizationTabId.CREWS,
        },
        {
          label: t("module.schedule:tab.validation_rules.title"),
          icon: <PlaylistAddCheckIcon />,
          value: ScheduleUtilizationTabId.VALIDATION_RULES,
        },
        {
          label: t("module.schedule:tab.progress.title"),
          icon: <PlaylistAddCheckIcon />,
          value: ScheduleUtilizationTabId.PROGRESS,
        },
        {
          label: t("module.schedule:tab.compliance.title"),
          icon: <PlaylistAddCheckIcon />,
          value: ScheduleUtilizationTabId.COMPLIANCE,
        },
      ];
    };
  }

  getDefaultTab(): ScheduleUtilizationTabId {
    return DEFAULT_JDE_SCHEDULE_UTILIZATION_TAB;
  }

  getDefaultBacklogColumns(
    props: IGetDefaultScheduleBacklogColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const tableCellRenderers = useTableCellRenderers();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "F4801_DOCO",
          name: t("entity.workorder:workorder.F4801_DOCO"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "F4801_DL01",
          name: t("entity.workorder:workorder.F4801_DL01"),
          editor: TextEditor,
        },
        {
          key: "F4801_DCTO",
          name: t("entity.workorder:workorder.F4801_DCTO"),
        },
        {
          key: "F4801_MMCU",
          name: t("entity.workorder:workorder.F4801_MMCU"),
        },
        {
          key: "F4801_NUMB",
          name: t("entity.workorder:workorder.F4801_NUMB"),
        },
        {
          key: "F4801_NUMB_desc",
          name: t("entity.workorder:workorder.F4801_NUMB_desc"),
        },
        {
          key: "F4801_HRSO",
          name: t("entity.workorder:workorder.F4801_HRSO"),
        },
        {
          key: "F4801_PRTS",
          name: t("entity.workorder:workorder.F4801_PRTS"),
          editor: TextEditor,
        },
        {
          key: "F4801_SRST",
          name: t("entity.workorder:workorder.F4801_SRST"),
        },
        {
          key: "__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultCraftUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "F30006_MCU",
          name: t(
            "entity.workcenter_utilization:workcenter_utilization.F30006_MCU"
          ),
          width: 140,
        },
        {
          key: "F30006_MCUW",
          name: t(
            "entity.workcenter_utilization:workcenter_utilization.F30006_MCUW"
          ),
        },
        {
          key: "F30006_MMCU",
          name: t(
            "entity.workcenter_utilization:workcenter_utilization.F30006_MMCU"
          ),
        },
      ];
    };
  }

  getCraftUtilizationEntityName(): string {
    return "workcenter_utilization";
  }

  getDefaultPersonUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "F0101_AN8",
          name: t("entity.person_utilization:person_utilization.F0101_AN8"),
          width: 165,
          formatter: (formatterProps) => {
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <div className={clsx([styles.person])}>
                      <Avatar
                        url={utilizationHelpers.getRandomGanttAvatarURL(
                          Number(formatterProps.row.PersonNumber)
                        )}
                      />
                      <div>
                        {formatterProps.row[
                          formatterProps.column.key
                        ]?.toString()}
                      </div>
                    </div>
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "F0101_ALPH",
          name: t("entity.person_utilization:person_utilization.F0101_ALPH"),
          width: 100,
        },
        {
          key: "WorkCenters.F30006_MCU",
          name: t("entity.person_utilization:person_utilization.WorkCenters"),
        },
      ];
    };
  }

  getCraftUtilizationKeyColumn(): string {
    return "F30006_MCU";
  }

  getCraftUtilizationStatLabelColumn(): string {
    return "F30006_MCU";
  }

  getProgressGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const scheduleUtilizationContext = useContext(ScheduleUtilizationContext);
      const { progressFiltersOpened, setProgressFiltersOpened } =
        scheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.schedule:utilization_filters.progress_filters_toggle"
            )}
            onClick={() => {
              setProgressFiltersOpened(!progressFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <JdeScheduleProgressFilters />;
  }

  getComplianceGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const scheduleUtilizationContext = useContext(ScheduleUtilizationContext);
      const { complianceFiltersOpened, setComplianceFiltersOpened } =
        scheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.schedule:utilization_filters.compliance_filters_toggle"
            )}
            onClick={() => {
              setComplianceFiltersOpened(!complianceFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  renderComplianceFilters(): ReactElementProvider {
    //return () => <JdeSchedulingComplianceFilters />;
    return () => <></>;
  }

  getComplianceFilters(): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getProgressFilters(props: IGetProgressFiltersProps): QueryProvider {
    return async () => {
      let workcenterFilters: any[] =
        (props.progressSummaryFilters as IJdeScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.WORKCENTER
        ] || [];

      const createWorkcenterQuery = (
        workcenterFilter: any
      ): QueryAttributeGroupDto => {
        return {
          $and: [
            {
              attribute_name: "F30006_MCU",
              attribute_value: {
                operator: "$eq",
                value: workcenterFilter.F30006_MCU as string,
              },
            },
            {
              attribute_name: "F30006_MMCU",
              attribute_value: {
                operator: "$eq",
                value: workcenterFilter.F30006_MMCU as string,
              },
            },
          ],
        };
      };

      const workcenterQuery: QueryAttributeGroupDto = {
        $or: workcenterFilters.map((workcenterFilter) =>
          createWorkcenterQuery(workcenterFilter)
        ),
      };
      return {
        query: workcenterQuery,
      };
    };
  }

  getBlankProgressFilters(): IJdeScheduleProgressFiltersDto {
    return { workcenter: [] };
  }
  getBlankComplianceFilters(): IJdeScheduleComplianceFiltersDto {
    return { pmacttype: [] };
  }
}
