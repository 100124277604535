import { useContext } from "react";
import { ShiftsUiService } from "../../services";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../../context";

export class SapShiftsUiService extends ShiftsUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.planning_plant"];
  }

  canManageShifts = (): boolean => {
    const site: IUserContextSite =
      useContext(AdapterUserContext)?.userProperties[
        AdapterUserContextProp.SITE
      ];

    return site?.site_id !== "*";
  };
}
