import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  ISupervisorScheduleContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "./supervisor-schedule-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { IAdapterFolder, IEntityObject, QueryDto } from "@river/interfaces";
import { getFolderQueryGroup } from "@river/util";
import { AdapterService } from "../adapter.service";

interface IUseSupervisorScheduleOperationsProps {
  scheduleContext: ISupervisorScheduleContext;
}

export const useSupervisorScheduleOperations = (
  props: IUseSupervisorScheduleOperationsProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const operationsTabId: SupervisorScheduleTasksTabId =
    SupervisorScheduleTasksTabId.OPERATIONS;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const scheduleContext: ISupervisorScheduleContext = props.scheduleContext;
  const currentSchedule: IAdapterFolder = scheduleContext.currentSchedule!;
  const entityHelpers = useEntityHelpers();
  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const openEntityInErp = async (record: IEntityObject) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, "workorder");
  };

  const columns: IColumn[] = uiService.getDefaultOperationColumns({
    onOperationClick: openEntityInErp,
  })();

  const fetchFunction = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        ...(currentSchedule && {
          initialQueryAttributeGroup: getFolderQueryGroup(currentSchedule, ""),
        }),
      }),
      $unwind: ["__folder"],
    };

    const operations = await adapterService.fetchOperations(query);
    const operationRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.operation
    );

    table.setLastRanQueryDto(query);

    const workCenters = new Set<string>();

    operations.forEach((op) => {
      try {
        entityHelpers.setAttributeValue(
          op,
          uiConstants.fields.rowType,
          uiConstants.rowType.operation
        );
        entityHelpers.setAttributeValue(
          op,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(op, operationRowIdAttribute)
        );
        workCenters.add(entityHelpers.getAttributeValue(op, "WorkCntr"));
      } catch (e) {
        console.error(`Error processing Operation _id: ${op._id}`);
        console.error(e);
      }
    });

    return operations;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `supervisor.schedule.operations`,
    columns,
    fetchFunction,
    dependencies: [!!site, currentTab === operationsTabId],
    fetchOn: currentTab === operationsTabId,
    initialSimpleFilters: uiService.getInitialTabSimpleFilters(operationsTabId),
    initialQuery: uiService.getInitialTabQuery(operationsTabId),
    keepSelection: true,
    fetchTriggers: [currentSchedule._id, site],
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    infiniteScrolling: true,
    onClearFilters: () => {
      uiService.taskInitialFiltersOverrides[operationsTabId] = null;
      uiService.taskInitialQueryOverrides[operationsTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
