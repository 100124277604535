import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverDropdownActions } from "../river-dropdown-actions";
import { useCurrentModule } from "../../../hooks";
import { fetchHelpers } from "../../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TabContext,
  TableContext,
} from "../../../context";
import { IUseTable, gridHelpers } from "../river-data-grid";
import {
  AdapterReportColumnDto,
  AdapterReportCreateDto,
  IAdapterFolder,
  IAdapterReport,
  IAdapterReportConfig,
} from "@river/interfaces";
import { ExcelIcon } from "../../../icons";
import { Constants } from "@river/constants";
import { ModuleKey } from "../../sidebar-menu";
import { useReportAction } from "./actions";

export const ReportsDropdown: React.FC = (): ReactElement => {
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const entityName: string =
    tableContext?.entity.entityDefinition?.entity.entity_name ||
    tableContext?.entityName!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tabContext = useContext(TabContext);
  const currentTab = tabContext?.selectedTab;
  const scheduleContext = useContext(ScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;

  const { currentModuleKey } = useCurrentModule();
  const [availableReportConfigs, setAvailableReportConfigs] = useState<
    IAdapterReportConfig[]
  >([]);
  const [activeReportConfig, setActiveReportConfig] =
    useState<IAdapterReportConfig | null>(null);

  const { reportAction } = useReportAction({
    generateReport: async (): Promise<IAdapterReport> => {
      let createReportDto: AdapterReportCreateDto = {
        report_type:
          activeReportConfig?.report_type || Constants.report_type.excel_basic,
        entity_name: entityName,
        module: currentModuleKey,
        query: fetchHelpers.getQueryWithoutPagination(table.lastRanQueryDto!),
      };

      if (ModuleKey.SCHEDULES === currentModuleKey) {
        createReportDto["folder_id"] = currentSchedule._id!;
      }

      if (!activeReportConfig?.is_custom) {
        createReportDto["columns"] = getReportColumns();
      }

      return await adapterContext!.service
        .getAdapterService()
        .generateReport(createReportDto);
    },
  });

  const getReportColumns = (): AdapterReportColumnDto[] => {
    return gridHelpers.getNonUtilityColumns(table.columns).map((c) => ({
      attribute: c.key,
      label: c.name as string,
    }));
  };

  const getAvailableReportConfigs = async () => {
    const availableReportsResponse = await adapterContext!.service
      .getAdapterService()
      .getReportConfigsForEntityName(
        entityName,
        currentModuleKey.toLowerCase()
      );
    setAvailableReportConfigs(availableReportsResponse);
  };

  useEffect(() => {
    getAvailableReportConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <>
      <RiverDropdownActions
        module={currentModuleKey}
        items={availableReportConfigs.map((reportConfig) => ({
          title: reportConfig.description!,
          startIcon: ExcelIcon,
          onClick: () => {
            setActiveReportConfig(reportConfig);
            reportAction.onClick();
          },
        }))}
      />
      {reportAction.renderDownloadDialog()}
    </>
  );
};
