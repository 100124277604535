import React, { ReactElement, useContext } from "react";
import { GenericAction, IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  TableContext,
} from "../../../context";
import { useNotification } from "@river/common-ui";
import { uiConstants, useGridActionHelpers } from "../../../helpers";
import { LinkMultipleIcon } from "../../../icons";
import { TableUiService } from "../../table-ui.service";

export const useCreateDependenciesAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const scheduleContext = useContext(SupervisorScheduleContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t(
    "module.supervisor_schedule:action_label.create_dependencies"
  );

  const onClick = async (): Promise<void> => {
    let selected: string[];
    if (tableContext?.table.selectedRowIdsBySelectionOrder?.length! > 0) {
      selected = tableContext?.table.selectedRowIdsBySelectionOrder!;
    } else {
      selected = Array.from(tableContext?.table.selectedRowIds!);
    }
    tableContext?.table.forceLoadingState(true);

    const errors: any[] = [];
    try {
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .autoDependencies({
          folder_id: scheduleContext?.currentSchedule?._id,
          operation_ids: selected,
        });
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
      });
    } catch (error) {
      errors.push(error);
    }

    const table: IUseTable = tableContext?.table!;
    table.forceLoadingState(false);

    if (errors.length) {
      notify.error({
        title: t(
          "module.supervisor_schedule:notification.dependency_creation_failed"
        ),
        message: errors[0],
      });
    } else {
      notify.success(
        t("module.supervisor_schedule:notification.dependency_creation_success")
      );
    }
  };

  const renderCreateDependenciesAction = (props?: any): ReactElement => (
    <GenericAction
      icon={LinkMultipleIcon}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderCreateDependenciesAction,
    createDependenciesAction: {
      icon: withSanitizedProps(renderCreateDependenciesAction),
      title,
      onClick,
    },
  };
};

export type IUseCreateDependenciesAction = ReturnType<
  typeof useCreateDependenciesAction
>;
