import React, { ReactElement, useContext } from "react";
import { GenericAction, IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useNotification } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import { TableUiService } from "../../table-ui.service";

export const useJobPriorityScoreAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();

  const title: string = t(
    "module.supervisor_schedule:action_label.job_priority_score"
  );

  const onClick = (): void => {
    adapterContext!.service
      .getAdapterService()
      .jobPriorityScore({
        entity_name: tableContext?.entityName!,
        entity_ids: getSelectedRowsObjectIds(table),
      })
      .then((result) => {
        tableContext?.table.refresh();
        notify.success(
          t(
            "module.supervisor_schedule:notification.success_job_priority_score"
          )
        );
      })
      .catch((error) => {
        notify.error(error.response.data.message);
      });
  };

  const renderJobPriorityScoreAction = (props?: any): ReactElement => (
    <GenericAction
      icon={FunctionsIcon}
      enableOnSelection={true}
      title={t("module.supervisor_schedule:action_label.job_priority_score")}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderJobPriorityScoreAction,
    jobPriorityScoreAction: {
      icon: withSanitizedProps(renderJobPriorityScoreAction),
      title,
      onClick,
    },
  };
};

export type IUseJobPriorityScoreAction = ReturnType<
  typeof useJobPriorityScoreAction
>;
