import { FC, ReactElement, useContext, useEffect, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { uiConstants } from "../../../../helpers";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { TableUiService } from "../../../../services";
import { IEntityObject } from "@river/interfaces";
import { useSapOperationUpdateForm } from "./use-sap-op-mass-update-form";
import { LookupType, RiverLookup } from "../../../../components/shared";

export interface IOpMassUpdateDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

export const SapOpMassUpdateDialog: FC<IOpMassUpdateDialogProps> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useSapOperationUpdateForm({
    submit: async () => {
      const updatedRows = await submitMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const { onStandaloneLookupSelect, submit, isProcessing, resetForm } = form;
  const [networkWOsSelected, setNetworkWOsSelected] = useState<boolean>(false);

  const hasNetworkWOsSelected = (): boolean =>
    !!tableContext?.table?.getSelectedRows()?.some((row) => {
      const workOrder = row.to_WorkOrder as IEntityObject | undefined;
      return workOrder?.OrderCategory === "20";
    });

  useEffect(() => {
    if (props.open) {
      setNetworkWOsSelected(hasNetworkWOsSelected());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const massUpdateFields: any = form.standalone;

  const resetDialogState = (): void => {
    resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const submitMassUpdate = async (): Promise<any[]> => {
    const updateObject: { [key: string]: any } = {};
    Object.keys(massUpdateFields).forEach((prop) => {
      // @ts-ignore
      const val: any = massUpdateFields[prop];
      if (val !== "") {
        updateObject[prop] = val;
      }
    });

    const errors: any[] = [];

    const processUpdate = async (operationId: string) => {
      const entity = tableContext?.table.entities.find(
        (row) => row[uiConstants.fields.rowId] === operationId
      );
      if (entity) {
        const workOrderId: string = entity[uiConstants.fields._id] as string;
        try {
          await adapterContext!.service
            .getAdapterService()
            .updateEntityData("operation", workOrderId, {
              _id: operationId,
              ...updateObject,
            });
        } catch (err) {
          errors.push(err);
        }
      }
    };

    const selectedRowIds: string[] = Array.from(
      tableContext?.table.selectedRowIds ?? []
    );
    for (const operationId of selectedRowIds) {
      await processUpdate(operationId);
    }

    if (errors.length) {
      notify.error({
        title: t(
          "shared.op_mass_update_dialog:notification.mass_update_failed"
        ),
        message: errors[0],
      });
    } else {
      const success = true;
      closeDialog(success);
    }

    return Promise.resolve([]);
  };

  const renderWorkCenterField = (): ReactElement => (
    <RiverLookup
      id="WorkCntr"
      fullWidth
      lookup={{ type: LookupType.CRAFTS }}
      onSelect={onStandaloneLookupSelect("WorkCenter", "WorkCntr")}
    />
  );

  const renderSystemConditionField = (): ReactElement => (
    <RiverLookup
      id="Systcond"
      fullWidth
      lookup={{ type: LookupType.SYSTEM_CONDITIONS }}
      onSelect={onStandaloneLookupSelect("Systcond")}
      disabled={networkWOsSelected}
    />
  );

  const renderNumberOfCapacitiesField = (): ReactElement => (
    <RiverTextInput
      id="NumberOfCapacities"
      fullWidth={true}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderResponsiblePersonField = (): ReactElement => (
    <RiverLookup
      id="PersNo"
      fullWidth
      lookup={{ type: LookupType.PERSONS }}
      onSelect={onStandaloneLookupSelect("PersonNumber", "PersNo")}
      disabled={networkWOsSelected}
    />
  );

  const renderWorkField = (): ReactElement => (
    <RiverTextInput
      id="WorkActivity"
      fullWidth={true}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderDurationField = (): ReactElement => (
    <RiverTextInput
      id="DurationNormal"
      fullWidth={true}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  return (
    <RiverDialog
      title={t("shared.op_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        closeDialog(false);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderWorkCenterField()}
      {renderResponsiblePersonField()}
      {renderWorkField()}
      {renderDurationField()}
      {renderNumberOfCapacitiesField()}
      {renderSystemConditionField()}
    </RiverDialog>
  );
};
