import React, { useContext } from "react";
import {
  SupervisorComplianceSummaryFilterID,
  IGetSupervisorComplianceFiltersProps,
  IGetDefaultSupervisorScheduleBacklogColumnsProps,
  IGetSupervisorProgressFiltersProps,
  SupervisorProgressSummaryFilterID,
  SupervisorScheduleUtilizationTabId,
  SupervisorScheduleUtilizationUiService,
} from "../../supervisor-schedule-utilization-ui-service";
import {
  ColumnsProvider,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../../ui-service.types";
import { TabProps } from "@mui/material/Tab";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useTranslation } from "@river/common-ui";
import { Avatar } from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../../components/schedule/schedule-utilization/utilization-helpers";
import {
  GenericAction,
  RefreshAction,
  getWorkOrderIdFormatter,
  useRiverSelectColumn,
} from "../../../components/shared";
import { TextEditor } from "react-data-grid";
import { useTableCellRenderers } from "../../../hooks";
import styles from "../../../components/schedule/schedule-utilization/schedule-utilization-tables/defaultColumns.module.scss";
import clsx from "clsx";
import { SupervisorScheduleUtilizationContext } from "../../../context/supervisor-schedule.utilization.context";
import TuneIcon from "@mui/icons-material/Tune";
import { QueryAttributeDto } from "@river/interfaces";
import {
  ISapSupervisorScheduleProgressFiltersDto,
  SapSupervisorScheduleProgressFilters,
} from "./sap-supervisor-schedule-progress-filters";
import {
  ISapSupervisorScheduleComplianceFiltersDto,
  SapSupervisorSchedulingComplianceFilters,
} from "./sap-supervisor-schedule-compliance-filters";

const DEFAULT_SAP_SUPERVISOR_SCHEDULE_UTILIZATION_TAB: SupervisorScheduleUtilizationTabId =
  SupervisorScheduleUtilizationTabId.METRICS;

export class SapSupervisorScheduleUtilizationUiService extends SupervisorScheduleUtilizationUiService {
  getTabs(): TabsProvider {
    return (): TabProps[] => {
      const { t } = useTranslation();
      return [
        {
          label: t("module.supervisor_schedule:tab.metrics.title"),
          icon: <LeaderboardIcon />,
          value: SupervisorScheduleUtilizationTabId.METRICS,
        },
        {
          label: t("module.supervisor_schedule:tab.backlog.title"),
          icon: <DynamicFeedIcon />,
          value: SupervisorScheduleUtilizationTabId.BACKLOG,
        },
        {
          label: t("module.supervisor_schedule:tab.craft.title"),
          icon: <CenterFocusWeakIcon />,
          value: SupervisorScheduleUtilizationTabId.CRAFTS,
        },
        {
          label: t("module.supervisor_schedule:tab.people.title"),
          icon: <PersonIcon />,
          value: SupervisorScheduleUtilizationTabId.PERSONS,
        },
        {
          label: t("module.supervisor_schedule:tab.crews.title"),
          icon: <GroupsIcon />,
          value: SupervisorScheduleUtilizationTabId.CREWS,
        },
        {
          label: t("module.supervisor_schedule:tab.validation_rules.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.VALIDATION_RULES,
        },
        {
          label: t("module.supervisor_schedule:tab.progress.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.PROGRESS,
        },
        {
          label: t("module.supervisor_schedule:tab.compliance.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.COMPLIANCE,
        },
      ];
    };
  }

  getDefaultTab(): SupervisorScheduleUtilizationTabId {
    return DEFAULT_SAP_SUPERVISOR_SCHEDULE_UTILIZATION_TAB;
  }

  getDefaultBacklogColumns(
    props: IGetDefaultSupervisorScheduleBacklogColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const tableCellRenderers = useTableCellRenderers();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "Orderid",
          name: t("entity.workorder:workorder.Orderid"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "ShortText",
          name: t("entity.workorder:workorder.ShortText"),
          editor: TextEditor,
        },
        {
          key: "OrderType",
          name: t("entity.workorder:workorder.OrderType"),
        },
        {
          key: "Plant",
          name: t("entity.workorder:workorder.Plant"),
        },
        {
          key: "Equipment",
          name: t("entity.workorder:workorder.Equipment"),
        },
        {
          key: "FunctLoc",
          name: t("entity.workorder:workorder.FunctLoc"),
        },
        {
          key: "MnWkCtr",
          name: t("entity.workorder:workorder.MnWkCtr"),
        },
        {
          key: "Priority",
          name: t("entity.workorder:workorder.Priority"),
          editor: TextEditor,
        },
        {
          key: "Userstatus",
          name: t("entity.workorder:workorder.Userstatus"),
        },
        {
          key: "ProductionStartDate",
          name: t("entity.workorder:workorder.ProductionStartDate"),
          width: 160,
        },
        {
          key: "ProductionFinishDate",
          name: t("entity.workorder:workorder.ProductionFinishDate"),
          width: 160,
        },
        {
          key: "__folder.folder",
          name: t("entity.folder:folder.folder"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultCraftUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "WorkCenter",
          name: t(
            "entity.workcenter_utilization:workcenter_utilization.WorkCenter"
          ),
          width: 140,
        },
        {
          key: "Plant",
          name: t("entity.workcenter_utilization:workcenter_utilization.Plant"),
        },
      ];
    };
  }

  getCraftUtilizationEntityName(): string {
    return "workcenter_utilization";
  }

  getDefaultPersonUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "PersonName",
          name: t("entity.person_utilization:person_utilization.PersonName"),
          width: 165,
          formatter: (formatterProps) => {
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <div className={clsx([styles.person])}>
                      <Avatar
                        url={utilizationHelpers.getRandomGanttAvatarURL(
                          Number(formatterProps.row.PersonNumber)
                        )}
                      />
                      <div>
                        {formatterProps.row[
                          formatterProps.column.key
                        ]?.toString()}
                      </div>
                    </div>
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "PersonNumber",
          name: t("entity.person_utilization:person_utilization.PersonNumber"),
          width: 100,
        },
        {
          key: "WorkCenters.WorkCenter",
          name: t("entity.person_utilization:person_utilization.WorkCenters"),
          width: 140,
        },
      ];
    };
  }

  getCraftUtilizationKeyColumn(): string {
    return "WorkCenterId";
  }

  getCraftUtilizationStatLabelColumn(): string {
    return "WorkCenter";
  }

  getProgressGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const supervisorScheduleUtilizationContext = useContext(
        SupervisorScheduleUtilizationContext
      );
      const { progressFiltersOpened, setProgressFiltersOpened } =
        supervisorScheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.supervisor_schedule:utilization_filters.progress_filters_toggle"
            )}
            onClick={() => {
              setProgressFiltersOpened(!progressFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <SapSupervisorScheduleProgressFilters />;
  }

  getComplianceGridActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const supervisorScheduleUtilizationContext = useContext(
        SupervisorScheduleUtilizationContext
      );
      const { complianceFiltersOpened, setComplianceFiltersOpened } =
        supervisorScheduleUtilizationContext!;
      return () => (
        <>
          <GenericAction
            icon={TuneIcon}
            title={t(
              "module.supervisor_schedule:utilization_filters.compliance_filters_toggle"
            )}
            onClick={() => {
              setComplianceFiltersOpened(!complianceFiltersOpened);
            }}
          />
          <RefreshAction />
        </>
      );
    };
  }

  renderComplianceFilters(): ReactElementProvider {
    return () => <SapSupervisorSchedulingComplianceFilters />;
  }

  getComplianceFilters(
    props: IGetSupervisorComplianceFiltersProps
  ): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      let pmacttypeFilters: any[] =
        (props.complianceFilters as ISapSupervisorScheduleProgressFiltersDto)[
          SupervisorComplianceSummaryFilterID.PMACTTYPE
        ] || [];

      if (!!pmacttypeFilters.length) {
        queryAttributes.push({
          attribute_name: "Pmacttype",
          attribute_value: {
            operator: "$in",
            value: pmacttypeFilters.map((pmacttype) => pmacttype.Pmacttype),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getProgressFilters(props: IGetSupervisorProgressFiltersProps): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      let pmacttypeFilters: any[] =
        (
          props.progressSummaryFilters as ISapSupervisorScheduleProgressFiltersDto
        )[SupervisorProgressSummaryFilterID.PMACTTYPE] || [];
      let workcenterFilters: any[] =
        (
          props.progressSummaryFilters as ISapSupervisorScheduleProgressFiltersDto
        )[SupervisorProgressSummaryFilterID.WORKCENTER] || [];
      if (!!pmacttypeFilters.length) {
        queryAttributes.push({
          attribute_name: "Pmacttype",
          attribute_value: {
            operator: "$in",
            value: pmacttypeFilters.map((pmacttype) => pmacttype.Pmacttype),
          },
        });
      }
      if (!!workcenterFilters.length) {
        queryAttributes.push({
          attribute_name: "WorkCenterId",
          attribute_value: {
            operator: "$in",
            value: workcenterFilters.map(
              (workcenter) => workcenter.WorkCenterId
            ),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getBlankProgressFilters(): ISapSupervisorScheduleProgressFiltersDto {
    return { pmacttype: [], workcenter: [] };
  }
  getBlankComplianceFilters(): ISapSupervisorScheduleComplianceFiltersDto {
    return { pmacttype: [] };
  }
}
