import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleUtilizationContext,
} from "../../../../context";
import {
  ISupervisorScheduleProgressFilters,
  SupervisorProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters/chart-filters-container/chart-filters-container";
import { JdeSupervisorScheduleProgressWorkcenterFilters } from "./filters";
export interface IJdeSupervisorScheduleProgressFiltersDto
  extends ISupervisorScheduleProgressFilters {
  [SupervisorProgressSummaryFilterID.WORKCENTER]: any[];
}

export const JdeSupervisorScheduleProgressFilters: React.FC =
  (): ReactElement => {
    const adapterContext: IAdapterUiContextState | null =
      useContext(AdapterUiContext);
    const [loading, setLoading] = useState<boolean>(false);
    const notify = useNotification();
    const supervisorScheduleUtilizationContext = useContext(
      SupervisorScheduleUtilizationContext
    );
    const {
      progressFiltersOpened,
      progressSummaryFilters,
      setProgressSummaryFilters,
    } = supervisorScheduleUtilizationContext!;

    const fetchProgressSummaryFilters = async () => {
      try {
        setLoading(true);
        const result: IJdeSupervisorScheduleProgressFiltersDto =
          await userPreferencesService.getScheduleProgressFilters(
            adapterContext!.service.getAdapterService()
          );
        if (result) {
          setProgressSummaryFilters(result);
        }
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    const onDeleteProgressSummaryFilter = async (
      selectedObject: any,
      filterId: SupervisorProgressSummaryFilterID
    ) => {
      let columnId: string = "F30006_MCU";

      const newDto: IJdeSupervisorScheduleProgressFiltersDto = {
        ...(progressSummaryFilters as IJdeSupervisorScheduleProgressFiltersDto),
        [filterId]: (progressSummaryFilters as any)[filterId]?.filter(
          (selected: any) => selected[columnId] !== selectedObject[columnId]
        ),
      };

      try {
        setLoading(true);
        await userPreferencesService.setScheduleProgressFilters(
          adapterContext!.service.getAdapterService(),
          newDto
        );
        setProgressSummaryFilters(newDto);
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    const onSelectProgressSummaryFilters = async (
      selectedObjects: any[],
      filterId: SupervisorProgressSummaryFilterID
    ) => {
      try {
        const payload: IJdeSupervisorScheduleProgressFiltersDto = {
          ...(progressSummaryFilters as IJdeSupervisorScheduleProgressFiltersDto),
          [filterId]: [
            ...(progressSummaryFilters as any)[filterId],
            ...selectedObjects,
          ],
        };
        setLoading(true);
        await userPreferencesService.setScheduleProgressFilters(
          adapterContext!.service.getAdapterService(),
          payload
        );
        setProgressSummaryFilters(payload);
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchProgressSummaryFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderWorkcenterFilters = (): ReactElement => (
      <JdeSupervisorScheduleProgressWorkcenterFilters
        rows={
          (progressSummaryFilters as IJdeSupervisorScheduleProgressFiltersDto)[
            SupervisorProgressSummaryFilterID.WORKCENTER
          ]
        }
        onSelect={onSelectProgressSummaryFilters}
        onDelete={onDeleteProgressSummaryFilter}
      />
    );

    return (
      <Collapse
        in={progressFiltersOpened}
        orientation={"horizontal"}
        timeout={300}
      >
        <ChartFiltersContainer>
          {renderWorkcenterFilters()}
          <RiverSpinner show={loading} />
        </ChartFiltersContainer>
      </Collapse>
    );
  };
