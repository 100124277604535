import { FormatterProps } from "react-data-grid";
import { useEntity, useTableCellRenderers } from "../hooks";
import { AdapterUiService } from "./adapter-ui.service";
import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";
import { useTranslation } from "react-i18next";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../context";
import { useContext } from "react";
import { TFunction } from "i18next";
import dataGridStyles from "../components/shared/river-data-grid/river-data-grid.module.scss";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { fetchHelpers, uiConstants } from "../helpers";
import { IEntityObject } from "@river/interfaces";

interface IUseRestrictionsProps {
  onRestrictionClick: (formatterProps: FormatterProps<any>) => void;
}

export abstract class RestrictionsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useRestrictions = (props: IUseRestrictionsProps): EntityTableProvider => {
    const { onRestrictionClick } = props;
    return () => {
      const { t } = useTranslation();
      const site: IUserContextSite =
        useContext(AdapterUserContext)?.userProperties[
          AdapterUserContextProp.SITE
        ];
      const { entityName, columns } = this.getRestrictionsTableParams(
        onRestrictionClick,
        t
      );

      const fetchRestrictions = async (fetchProps: ITableFetchFunctionProps) =>
        (await this.adapterUiService
          .getAdapterService()
          .fetchRestrictions(
            fetchHelpers.getTableQuery({ fetchProps })
          )) as unknown as IEntityObject[];

      const { RiverSelectColumn } = useRiverSelectColumn();
      return {
        entity: useEntity({ entityName }),
        table: useTable({
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchRestrictions,
          fetchOn: true,
          dependencies: [!!site],
          fetchTriggers: [site],
          rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
        }),
      };
    };
  };

  protected getRestrictionsTableParams = (
    onRestrictionClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return {
      entityName: "restriction",
      columns: [
        {
          key: "entity_name",
          name: t("entity.restriction:restriction.entity_name"),
          formatter: (formatterProps) => (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => onRestrictionClick(formatterProps)}
                  >
                    {renderCellText({ formatterProps })}
                  </div>
                ),
              })}
            </>
          ),
        },
        {
          key: "role",
          name: t("entity.restriction:restriction.role"),
        },
        {
          key: "created_by",
          name: t("entity.restriction:restriction.created_by"),
        },
        {
          key: "updated_at",
          name: t("entity.restriction:restriction.updated_at"),
          formatter: (formatterProps) => {
            return <div>{renderCellText({ formatterProps })}</div>;
          },
        },
      ],
    };
  };

  getI18nNamespaces(): string[] {
    return [
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.grid_header",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.error_messages",
      "module.restrictions",
      "common.button",
      "common.label",
      "entity.restriction",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  abstract getErpSpecificI18nNamespaces(): string[];
}
