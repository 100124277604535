import React, { useContext, useState } from "react";
import { PrimaryButton, useTranslation } from "@river/common-ui";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { RestrictionsAction } from "../restrictions";
import { RestrictionsDialog } from "../restrictions-dialog";
import { TableContext } from "../../../context";

export const RestrictionsSubHeader: React.FC = (props) => {
  const [restrictionDialogOpened, setRestrictionDialogOpened] =
    useState<boolean>(false);
  const openDialog = (): void => setRestrictionDialogOpened(true);
  const closeDialog = (): void => setRestrictionDialogOpened(false);
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.RESTRICTIONS}
          action={RestrictionsAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.restrictions:button.add_restriction")}
          />
        </ProtectedAction>
      </div>
      <RestrictionsDialog
        open={restrictionDialogOpened}
        restriction={null}
        onClose={(opts) => {
          closeDialog();
          if (opts?.refresh) tableContext?.table.fetch();
        }}
      />
    </>
  );
};
