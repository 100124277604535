import jsonata from "jsonata";
import { set } from "lodash";
import { ITableContext, TableContext } from "../context";
import { IAttribute } from "@river/interfaces";
import { Constants } from "@river/constants";
import { parseSapDateTime } from "@river/util";
import { useContext } from "react";

export function useEntityHelpers() {
  const tableContext = useContext(TableContext);

  const processDoubleValues = (values: any[]) =>
    values
      .flat()
      .map((v) =>
        v?.toString().split(".")[1]?.length > 2 ? (+v).toFixed(2) : v
      )
      .join(", ");

  const formatDateStringAsUTC = (date: string): string => {
    let formatted: string = "";
    try {
      formatted = date
        ? new Intl.DateTimeFormat(navigator.language, {
            timeZone: "UTC",
            dateStyle: "short",
            timeStyle: "short",
          }).format(new Date(date))
        : "";
    } catch (e) {
      console.error(e);
    }
    return formatted;
  };
  const getAttributeValue = (payload: any, key: string): any => {
    return jsonata(key).evaluate(payload);
  };

  const setAttributeValue = (payload: any, key: string, value: any): any => {
    return set(payload, key, value);
  };

  const getFormattedEntityAttribute = (
    entityObject: any,
    attributeName: string,
    tableContextProp?: ITableContext
  ): string => {
    const localTableContext: ITableContext = tableContextProp || tableContext!;
    let value: string = "";
    const attr: IAttribute | undefined =
      localTableContext?.entity.attributesByName.get(attributeName);
    value = getAttributeValue(entityObject, attributeName);

    // if it is undefined or empty array => nothing to do here
    if (
      typeof value === "undefined" ||
      value === null ||
      (Array.isArray(value) && !value.length)
    ) {
      return value;
    }

    if (attr) {
      const dataType: string = attr.data_type;
      switch (dataType) {
        case Constants.data_type.date:
        case Constants.data_type.datetime:
          const dateTimeValues = Array.isArray(value) ? value : [value];
          return dateTimeValues.map((v) => formatDateStringAsUTC(v)).join(", ");
        case Constants.data_type.sap_date:
          let timeValue: any;
          if (attr.time_attribute_name) {
            // need to "inherit" the prefix from the original attribute (attr)
            const prefix = attributeName.replace(attr.attribute_name, "");
            const timeAttrKey = `${prefix}${attr.time_attribute_name}`;
            const timeAttr: IAttribute | undefined =
              localTableContext?.entity.attributesByName.get(timeAttrKey);
            timeValue = timeAttr
              ? getAttributeValue(entityObject, timeAttrKey)
              : null;
          }
          const dateValues = Array.isArray(value) ? value : [value];
          let timeValues: any = null;
          if (timeValue) {
            timeValues =
              Array.isArray(timeValue) && timeValue.length
                ? timeValue
                : [timeValue];
          }
          return dateValues
            .map((v, index, array) => {
              let tv = null;
              if (timeValues && timeValues.length > index) {
                tv = timeValues[index];
              }
              const dateValue = parseSapDateTime(v, tv);
              return formatDateStringAsUTC(dateValue);
            })
            .join(", ");
        case Constants.data_type.boolean:
          const booleanValues = Array.isArray(value) ? value : [value];
          return booleanValues.map((v) => (v ?? "").toString()).join(", ");
        case Constants.data_type.double:
          return processDoubleValues([value]);
      }
    }

    return (Array.isArray(value) ? value : [value]).join(", ");
  };

  return {
    formatDateStringAsUTC,
    getAttributeValue,
    setAttributeValue,
    getFormattedEntityAttribute,
  };
}

export type IUseEntityHelpers = ReturnType<typeof useEntityHelpers>;
