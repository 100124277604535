import { FC, ReactElement, useContext, useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { Constants } from "@river/constants";
import { AdapterUiContext, ScheduleContext } from "../../../context";
import SearchIcon from "@mui/icons-material/Search";
import { LookupDialog, LookupType, useAsyncPost } from "../../shared";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  RiverCheckbox,
  RiverDialog,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { ScheduleAction, userPreferencesService } from "../../../services";
import { useScheduleActions } from "../../../services/schedule-ui-service/schedule-actions";
import { AdapterFolderStatusDto, StatusDto } from "@river/interfaces";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import styles from "./schedule-status-header.module.scss";
import clsx from "clsx";

const statusMessages = {
  [Constants.schedule_statuses.draft]: {
    buttonText: "module.schedule:action_label.commit_schedule",
    message: "module.schedule:folder_status.draft_message",
    statusText: "shared.schedule_status:draft",
    confirmationDialogMessage:
      "module.schedule:dialog.change_status_from_draft_to_commited_confirmation.message",
    colorClass: styles.draft,
  },
  [Constants.schedule_statuses.committed]: {
    buttonText: "common.button:start_now",
    message: "module.schedule:folder_status.commited_message",
    statusText: "shared.schedule_status:committed",
    confirmationDialogMessage:
      "module.schedule:dialog.change_status_from_commited_to_in_progress_confirmation.message",
    colorClass: styles.committed,
  },
  [Constants.schedule_statuses.in_progress]: {
    buttonText: "common.button:complete",
    message: "module.schedule:folder_status.in_progress_message",
    statusText: "shared.schedule_status:in_progress",
    confirmationDialogMessage:
      "module.schedule:dialog.change_status_from_in_progress_to_completed_confirmation.message",
    colorClass: styles.inProgress,
  },
  [Constants.schedule_statuses.complete]: {
    buttonText: "module.schedule:action_label.close_schedule",
    message: "module.schedule:folder_status.complete_message",
    statusText: "shared.schedule_status:complete",
    confirmationDialogMessage:
      "module.schedule:dialog.change_status_from_completed_to_closed_confirmation.message",
    colorClass: styles.complete,
  },
  [Constants.schedule_statuses.closed]: {
    buttonText: "",
    message: "module.schedule:folder_status.closed_message",
    statusText: "shared.schedule_status:closed",
    confirmationDialogMessage: "",
    colorClass: styles.closed,
  },
};

export const ScheduleStatusHeader: FC = (): ReactElement => {
  const { t } = useTranslation();
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext = useContext(AdapterUiContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showChangeScheduleDialog, setShowChangeDialog] =
    useState<boolean>(false);
  const { renderAutoScheduleAction } = useScheduleActions();
  const notify = useNotification();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showConfrimationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<string | undefined>(
    scheduleContext?.currentSchedule?.status.code
  );

  const [statusInfo, setStatusInfo] = useState(() => {
    return statusMessages[statusCode || Constants.schedule_statuses.draft];
  });

  const asyncPost = useAsyncPost();
  const { asyncPostProgress, doAsyncPost } = asyncPost;

  useEffect(() => {
    setStatusCode(scheduleContext?.currentSchedule?.status.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleContext?.currentSchedule]);

  useEffect(() => {
    setStatusInfo(
      statusMessages[statusCode || Constants.schedule_statuses.draft]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  useEffect(() => {
    loadUserPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const formatDate = (date: Date): string => {
    try {
      const utcDate = dayjs.utc(date);
      const formattedDate = utcDate.format(
        "dddd MMMM DD, YYYY [at] h:mma [EST]"
      );
      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const renderStatusConfirmationMessage = (): ReactElement => (
    <div
      dangerouslySetInnerHTML={{
        __html: t(statusInfo.confirmationDialogMessage, {
          scheduleName: scheduleContext?.currentSchedule?.description,
        }),
      }}
    />
  );

  const renderScheduleName = (): ReactElement => (
    <div className={styles.scheduleName}>
      <div className={styles.label}>
        {scheduleContext?.currentSchedule!.folder}
      </div>
      {renderChangeScheduleDialog()}
    </div>
  );

  const renderScheduleStatus = (): ReactElement => (
    <div className={clsx(styles.status, statusInfo.colorClass)}>
      {t(statusInfo.statusText)}
    </div>
  );

  const renderScheduleStatusMessage = (): ReactElement | null => {
    let renderMessage: string = t(statusInfo.message, {
      time: formatDate(scheduleContext?.currentSchedule?.start_date!),
    });
    if (
      scheduleContext?.currentSchedule?.has_uncommitted_changes &&
      statusCode !== Constants.schedule_statuses.draft
    ) {
      renderMessage = t(
        "module.schedule:folder_has_uncommitted_changes_message"
      );
    }
    return (
      <div className={clsx(styles.statusMessage, statusInfo.colorClass)}>
        {renderMessage}
      </div>
    );
  };

  const handleChangeConfirmationAction = (): void => {
    if (statusCode === Constants.schedule_statuses.draft) {
      if (!showConfrimationDialog) {
        setOpenDialog(true);
      } else {
        handleSubmitConfirmationDialog();
      }
    } else {
      if (
        !scheduleContext?.currentSchedule?.has_uncommitted_changes &&
        !showConfrimationDialog
      ) {
        setOpenDialog(true);
      } else {
        handleSubmitConfirmationDialog();
      }
    }
  };

  const handleCloseConfirmationDialog = (): void => {
    setOpenDialog(false);
  };
  const handleSubmitConfirmationDialog = async () => {
    setOpenDialog(false);
    const table = scheduleContext?.currentTasksTableRef.current;
    let newStatusCode: string = "";

    switch (statusCode) {
      case Constants.schedule_statuses.draft:
        newStatusCode = Constants.schedule_statuses.committed;
        break;
      case Constants.schedule_statuses.committed:
        newStatusCode = Constants.schedule_statuses.in_progress;
        break;
      case Constants.schedule_statuses.in_progress:
        newStatusCode = Constants.schedule_statuses.complete;
        break;
      case Constants.schedule_statuses.complete:
        newStatusCode = Constants.schedule_statuses.closed;
        break;
    }
    try {
      scheduleContext?.currentTasksTableRef.current?.forceLoadingState(true);
      const folderId: string | undefined =
        scheduleContext?.currentSchedule?._id;

      if (scheduleContext?.currentSchedule?.has_uncommitted_changes) {
        doAsyncPost();
      } else {
        table?.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .updateFolderStatus(folderId!, {
            status: { code: newStatusCode } as StatusDto,
          } as AdapterFolderStatusDto);
        await table?.refresh();
        await table?.clearFilters();
      }

      scheduleContext?.refreshCurrentSchedule();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };

  const loadUserPreferences = async (): Promise<void> => {
    try {
      const state: boolean =
        await userPreferencesService.getShowScheduleStatusChangeConfirmation(
          adapterContext!.service.getAdapterService()
        );
      setShowConfirmationDialog(state);
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderStatusActionButton = (): ReactElement | null => {
    if (statusInfo.buttonText) {
      return (
        <Button
          variant="outlined"
          className={clsx(styles.statusActionButton, statusInfo.colorClass)}
          onClick={handleChangeConfirmationAction}
        >
          {scheduleContext?.currentSchedule?.has_uncommitted_changes &&
          statusCode !== Constants.schedule_statuses.draft
            ? t("common.button:save")
            : t(statusInfo.buttonText)}
        </Button>
      );
    }
    return null;
  };

  const renderChangeScheduleDialog = (): ReactElement => (
    <>
      <IconButton
        onClick={() => {
          setShowChangeDialog(true);
        }}
      >
        <SearchIcon className={styles.lookupIcon} />
      </IconButton>
      <LookupDialog
        lookup={{
          type: LookupType.SCHEDULES,
          selectedRowIds: [scheduleContext!.currentSchedule!._id],
        }}
        singleSelect={true}
        open={showChangeScheduleDialog}
        onClose={() => setShowChangeDialog(false)}
        onSubmit={(schedules) => {
          if (schedules.length) {
            const schedule = schedules[0];
            if (schedule._id) {
              navigate(
                `/schedules/${
                  schedule._id as string
                }?${searchParams.toString()}`
              );
              scheduleContext?.setSelectedBaseline(undefined);
            }
          }
          setShowChangeDialog(false);
        }}
      />
    </>
  );

  const renderScheduleInfo = (): ReactElement => (
    <div className={styles.scheduleInfo}>
      {renderScheduleName()}
      {renderScheduleStatus()}
      <ProtectedAction
        module={ModuleKey.SCHEDULES}
        action={ScheduleAction.WO_AUTO_SCHEDULE}
      >
        {renderAutoScheduleAction()}
      </ProtectedAction>
    </div>
  );

  const renderConfirmationDialog = (): ReactElement => (
    <RiverDialog
      title={t("module.schedule:dialog.schedule_status_change.title")}
      open={openDialog}
      onClose={handleCloseConfirmationDialog}
      onSubmit={handleSubmitConfirmationDialog}
      actionButtonText={t("common.button:confirm")}
    >
      <div>
        <span>
          {renderStatusConfirmationMessage()}
          <div>
            <br />
            <RiverCheckbox
              label={t("module.schedule:dialog.dont_show_this_again.button")}
              id={"dont_show_this_again"}
              checked={showConfrimationDialog}
              onChangeEvent={async () => {
                try {
                  await userPreferencesService.setShowScheduleStatusChangeConfirmation(
                    adapterContext!.service.getAdapterService(),
                    !showConfrimationDialog
                  );
                  setShowConfirmationDialog(!showConfrimationDialog);
                } catch (message) {
                  notify.error({ message });
                }
              }}
            />
          </div>
        </span>
      </div>
    </RiverDialog>
  );

  return (
    <div className={styles.root}>
      {renderScheduleInfo()}
      {renderConfirmationDialog()}
      {
        <div
          className={clsx(
            styles.statusMessagesAndActions,
            statusInfo.colorClass
          )}
        >
          {renderScheduleStatusMessage()}
          {renderStatusActionButton()}
        </div>
      }
      {asyncPostProgress.renderDialog()}
    </div>
  );
};
