import React, { useContext, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../context";
import { RestrictionsUiService } from "../../services/restrictions-ui.service";
import { IAdapterRestriction } from "@river/interfaces";
import { FormatterProps } from "react-data-grid";
import { RestrictionsHeader } from "./restrictions-header";
import { RestrictionsSubHeader } from "./restrictions-subheader";
import { RestrictionsGridHeader } from "./restrictions-grid-header";
import { RiverSpinner } from "@river/common-ui";
import { RiverDataGrid } from "../shared";
import { RestrictionsDialog } from "./restrictions-dialog";

export enum RestrictionsAction {
  CREATE = "CREATE_RESTRICTION",
  UPDATE = "UPDATE_RESTRICTION",
  DELETE = "DELETE_RESTRICTION",
}

export const Restrictions: React.FC = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: RestrictionsUiService =
    adapterContext?.service.getRestrictionsUiService()!;
  const [selectedRestriction, setSelectedRestriction] =
    useState<IAdapterRestriction | null>(null);

  const [restrictionsDialogOpened, setRestrictionsDialogOpened] =
    useState<boolean>(false);

  const onRestrictionClick = (formatterProps: FormatterProps<any>): void => {
    table.setSelectedRowIds(new Set());
    setSelectedRestriction(formatterProps.row as IAdapterRestriction);
    setRestrictionsDialogOpened(true);
  };
  const { entity, table } = uiService.useRestrictions({
    onRestrictionClick,
  })();

  return (
    <TableContext.Provider value={{ table, entity }}>
      <RestrictionsHeader />
      <RestrictionsSubHeader />
      <RestrictionsGridHeader />
      <RiverSpinner show={table.isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={table.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />

      <RestrictionsDialog
        open={restrictionsDialogOpened}
        restriction={selectedRestriction}
        onClose={(opts) => {
          setRestrictionsDialogOpened(false);
          if (opts?.refresh) table.refresh();
          setSelectedRestriction(null);
        }}
      />
    </TableContext.Provider>
  );
};
