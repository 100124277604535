import { Column, SortColumn } from "react-data-grid";

import {
  IEntityObject,
  IQueryAttributeGroup,
  TQueryOperator,
} from "@river/interfaces";

export enum AttributeDataType {
  STRING = "string",
  BOOLEAN = "boolean",
  DATE = "date",
  DOUBLE = "double",
  DATETIME = "datetime",
}

export interface IFilterDefinition {
  label: string;
  field: string;
  operators: TQueryOperator[];
  defaultValue?: string;
  defaultOperator?: TQueryOperator;
  dataType?: AttributeDataType;
}

export interface IColumn extends Column<IEntityObject> {}

export interface IColumnLayout {
  name: string;
  columns: Column<any>[];
}

export interface IColumnFilter {
  field: string;
  operator: TQueryOperator;
  value: any;
}

export interface ITableFilter {
  name: string;
  query: IQueryAttributeGroup;
  sort?: SortColumn[];
  simpleFilters?: IColumnFilter[];
}

export interface ITablePreferences {
  filters?: ITableFilter[];
  defaultFilterName?: string;
  layouts?: IColumnLayout[];
  currentLayoutName?: string;
  columns: Column<any>[];
}
