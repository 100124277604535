import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const ProgressIcon: React.FC<RiverSVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...sanitizeSvgProps(props)}
    >
      <title>{props.title}</title>
      <g id="wi_export_icon 1">
        <path
          id="Vector"
          d="M22.4982 15.2513C21.8766 15.2513 21.3732 15.7547 21.3732 16.3763V20.3906H2.62695V16.3763C2.62695 15.7547 2.12352 15.2513 1.50195 15.2513C0.880391 15.2513 0.376953 15.7547 0.376953 16.3763V21.5156C0.376953 22.1372 0.880391 22.6406 1.50195 22.6406H22.4991C23.1207 22.6406 23.6241 22.1372 23.6241 21.5156V16.3763C23.6241 15.7547 23.1207 15.2513 22.4991 15.2513H22.4982Z"
          fill="#1A1A1A"
        />
        <path
          id="Vector_2"
          d="M12.2504 1.47561C12.1511 1.32093 11.9251 1.31999 11.8248 1.47374L7.26293 8.43468C7.1523 8.60343 7.27324 8.82749 7.4748 8.82749H10.217C10.3567 8.82749 10.4711 8.94093 10.4711 9.08155V16.9022C10.4711 17.0428 10.5845 17.1562 10.7251 17.1562H13.3529C13.4936 17.1562 13.607 17.0428 13.607 16.9022V9.08155C13.607 8.94093 13.7204 8.82749 13.8611 8.82749H16.5245C16.7251 8.82749 16.847 8.6053 16.7382 8.43655L12.2504 1.47561Z"
          fill="#1A1A1A"
        />
      </g>
    </svg>
  );
};
