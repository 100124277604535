import React, { ReactElement, useContext, useEffect } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  SetTabFn,
  TableContext,
} from "../../../../context";
import { IUseTable } from "../../../shared";
import { IAdapterFolder, IAdapterRule, IEntityObject } from "@river/interfaces";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { RuleTile } from "../../../rule-tile";
import { uiConstants } from "../../../../helpers";
import { IColumnFilter } from "../../../../interfaces";
import { ScheduleTasksTabId, ScheduleUiService } from "../../../../services";
import styles from "./utilization-validation-rule-tiles.module.scss";

const RULE_ENTITY_NAME_TO_TASKS_TAB_MAP: {
  [entityName: string]: ScheduleTasksTabId;
} = {
  workorder: ScheduleTasksTabId.WORKORDERS,
  operation: ScheduleTasksTabId.OPERATIONS,
};

export const UtilizationValidationRuleTiles: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const scheduleContext = useContext(ScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const scheduleUiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const currentScheduleTasksTab: ScheduleTasksTabId =
    scheduleUiService.getCurrentTab()();
  const notify = useNotification();

  useEffect(() => {
    return () => {
      table.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Switch to corresponding Schedule Tasks tab based on rule.entity_name
   * and filter the table to display the rows matching the rule expression
   *
   * @param rule
   */
  const onRuleTileClick = async (rule: IAdapterRule): Promise<void> => {
    try {
      table.setSelectedRowIds(new Set([rule._id]));
      const entities: IEntityObject[] = await adapterContext!.service
        .getAdapterService()
        .jobValidationFilter(rule._id, currentSchedule._id);
      const entityIds: string[] = entities.map(
        (obj) => obj[uiConstants.fields._id] as string
      );

      const entityTab: ScheduleTasksTabId =
        RULE_ENTITY_NAME_TO_TASKS_TAB_MAP[rule.entity_name];
      const filterToApply: IColumnFilter[] = [
        {
          field: "_id",
          operator: "$in",
          value: entityIds,
        },
      ];
      const tabTable: IUseTable = scheduleUiService.taskTableRefs[entityTab]!;
      scheduleContext?.setSelectedValidationRule(rule);

      if (currentScheduleTasksTab === entityTab) {
        await tabTable.fetch({ newColumnFilters: filterToApply, newQuery: {} });
      } else {
        scheduleUiService.taskInitialFiltersOverrides[entityTab] =
          filterToApply;
        scheduleUiService.taskInitialQueryOverrides[entityTab] = {};
        const setCurrentTasksTab: SetTabFn =
          scheduleContext?.setCurrentScheduleTasksTabFnRef.current!;
        setCurrentTasksTab(entityTab);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const isLoading = table?.isLoading;
  return (
    <>
      <RiverSpinner show={isLoading} />
      {!isLoading && (
        <div className={styles.root}>
          {(table.entities as IAdapterRule[]).map((rule, index) => (
            <RuleTile
              key={index}
              rule={rule}
              selected={table.selectedRowIds.has(rule._id)}
              entityCount={rule.count}
              onClick={() => onRuleTileClick(rule)}
            />
          ))}
        </div>
      )}
    </>
  );
};
