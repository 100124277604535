import { ReactElement, useState, useMemo, useEffect } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useRiverCustomPopup } from "../river-custom-popup";
import { Button } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs, { Dayjs } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import { useTranslation } from "@river/common-ui";
import styles from "./river-date-range-picker.module.scss";
import clsx from "clsx";

export interface IRiverDateRange {
  startDate: Date;
  endDate: Date;
}

interface IRiverDateRangePickerProps {
  range?: IRiverDateRange;
  onOpen?: () => void;
  onClose?: () => void;
  onSelect: (range: IRiverDateRange) => void;
  onClear?: () => void;
}

export const RiverDateRangePicker = (
  props: IRiverDateRangePickerProps
): ReactElement => {
  const { t } = useTranslation();

  dayjs.extend(localizedFormat);
  dayjs.extend(localeData);

  const rangePlaceholder: string = useMemo(() => {
    dayjs.locale(navigator.language.toLowerCase());
    const dateFormat = dayjs().localeData().longDateFormat("L");
    return `${dateFormat} - ${dateFormat}`;
  }, []);

  const [startDate, setStartDate] = useState<Date | null>(
    props.range?.startDate || null
  );

  const subtractDay = (date?: Date): Date | null =>
    date ? dayjs(date).subtract(1, "day").toDate() : null;

  const [endDate, setEndDate] = useState<Date | null>(
    subtractDay(props.range?.endDate)
  );

  const [rangeStr, setRangeStr] = useState<string>(
    props.range ? getRangeStr(props.range) : rangePlaceholder
  );

  useEffect(() => {
    setStartDate(props.range?.startDate || null);
    setEndDate(subtractDay(props.range?.endDate));
    setRangeStr(props.range ? getRangeStr(props.range) : rangePlaceholder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.range]);

  function getRangeStr(range: IRiverDateRange): string {
    const { startDate, endDate } = range;
    dayjs.locale(navigator.language.toLowerCase());
    const dateFormat = dayjs().localeData().longDateFormat("L");

    if (startDate && endDate) {
      const startDateDayjs: Dayjs = dayjs(startDate).utc();
      const startDateStr: string = startDateDayjs.format(`${dateFormat}`);

      let endDateDayjs: Dayjs = dayjs(endDate).utc();
      endDateDayjs = endDateDayjs.subtract(1, "day");
      const endDateStr: string = endDateDayjs.format(`${dateFormat}`);

      if (startDateDayjs.isSame(endDateDayjs)) {
        return `${startDateStr}`;
      } else {
        return `${startDateStr} - ${endDateStr}`;
      }
    } else {
      return `${dateFormat} - ${dateFormat}`;
    }
  }

  const isInnerDay = (day: Dayjs): boolean => {
    return dayjs(startDate).isBefore(day) && dayjs(endDate).isAfter(day);
  };

  const CustomDay: React.FC<PickersDayProps<Dayjs>> = (props) => {
    const { day, selected, ...other } = props;
    const isSelected =
      startDate && endDate
        ? dayjs(day).isSame(dayjs(startDate), "day") ||
          dayjs(day).isSame(dayjs(endDate), "day") ||
          (dayjs(day).isAfter(dayjs(startDate), "day") &&
            dayjs(day).isBefore(dayjs(endDate), "day"))
        : selected;

    return (
      <PickersDay
        classes={{
          root: clsx([{ [styles.innerDay]: isInnerDay(day) }]),
        }}
        selected={isSelected}
        day={day}
        {...other}
      />
    );
  };

  const CustomActionbar = () => <></>;

  const onStartDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue ? newValue.toDate() : null);
  };

  const onEndDateChange = (newValue: Dayjs | null) => {
    setEndDate(newValue ? newValue.toDate() : null);
  };

  const onClear = () => {
    setStartDate(null);
    setEndDate(null);
    setRangeStr(rangePlaceholder);
    closePopup();
    props.onClear?.();
  };

  const onApply = () => {
    if (startDate && endDate) {
      setRangeStr(getRangeStr({ startDate, endDate }));
      props.onSelect({
        startDate,
        endDate,
      });
    }
    closePopup();
  };

  const renderContent = (): ReactElement => {
    return (
      <>
        <div className={styles.dateTimePicker}>
          <StaticDatePicker
            slots={{
              toolbar: () => <></>,
              actionBar: CustomActionbar,
              day: CustomDay,
            }}
            onChange={onStartDateChange}
            value={dayjs(startDate)}
            maxDate={dayjs(endDate)}
            timezone={"UTC"}
          />
          <StaticDatePicker
            slots={{
              toolbar: () => <></>,
              actionBar: CustomActionbar,
              day: CustomDay,
            }}
            onChange={onEndDateChange}
            value={dayjs(endDate)}
            minDate={dayjs(startDate)}
            timezone={"UTC"}
          />
        </div>
        <div className={styles.actionButtons}>
          <Button
            variant="outlined"
            className={clsx([styles.actionButton, styles.clear])}
            onClick={onClear}
          >
            {t("common.button:clear")}
          </Button>
          <Button
            variant="contained"
            className={clsx([styles.actionButton, styles.apply])}
            disabled={[startDate, endDate].some((item) => !item)}
            onClick={onApply}
          >
            {t("common.button:apply")}
          </Button>
        </div>
      </>
    );
  };

  const renderToggle = (): ReactElement => (
    <div
      className={clsx([
        styles.toggle,
        { [styles.rangeSet]: rangeStr !== rangePlaceholder },
      ])}
    >
      <CalendarTodayOutlinedIcon className={styles.icon} />
      <span className={styles.text}>{rangeStr}</span>
    </div>
  );

  const riverCustomPopup = useRiverCustomPopup({
    content: renderContent(),
    toggle: renderToggle,
    popoverProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: -5,
        horizontal: "left",
      },
    },
    onOpen: () => props.onOpen?.(),
    onClose: () => props.onClose?.(),
  });

  const { closePopup } = riverCustomPopup;
  return <>{riverCustomPopup.render()}</>;
};
