import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ProgressSummaryFilterID } from "../../../../schedule-utilization-ui-service";

interface IJdeScheduleProgressWorkcenterFiltersProps {
  rows: any[];
  onSelect: (selectedObjects: any[], filterId: ProgressSummaryFilterID) => void;
  onDelete: (selectedObject: any, filterId: ProgressSummaryFilterID) => void;
}

export const JdeScheduleProgressWorkcenterFilters: React.FC<
  IJdeScheduleProgressWorkcenterFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const workcenterLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((workcenter) => workcenter["F30006_MCU"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "F30006_MCU",
          name: t("entity.workcenter:workcenter.F30006_MCU"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 120,
        },
        {
          key: "F30006_MMCU",
          name: t("entity.workcenter:workcenter.F30006_MMCU"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 80,
        },
      ]}
      lookup={{
        type: LookupType.CRAFTS,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "F30006_MCU",
            attribute_value: {
              operator: "$nin",
              value: workcenterLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selected) =>
        props.onDelete(selected, ProgressSummaryFilterID.WORKCENTER)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ProgressSummaryFilterID.WORKCENTER);
      }}
      addFilterLabel={t(
        "module.schedule:utilization_filters.add_workcenter_filter"
      )}
    />
  );
};
