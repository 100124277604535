import { FC, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import { AdapterUiContext, ScheduleReportContext } from "../../../../context";
import {
  IScheduleProgressFilters,
  ProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters";
import { JdeScheduleProgressWorkcenterFilters } from "./filters";

export interface IJdeScheduleReportProgressFiltersDto
  extends IScheduleProgressFilters {
  [ProgressSummaryFilterID.WORKCENTER]: any[];
}

export const JdeScheduleReportProgressFilters: FC = () => {
  const adapterContext = useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();
  const scheduleReportContext = useContext(ScheduleReportContext);
  const {
    progressFiltersOpened,
    currentSchedule,
    progressSummaryFilters,
    setProgressSummaryFilters,
  } = scheduleReportContext!;

  const fetchProgressSummaryFilters = async () => {
    try {
      setLoading(true);
      const result: IJdeScheduleReportProgressFiltersDto =
        await userPreferencesService.getScheduleProgressFilters(
          adapterContext!.service.getAdapterService()
        );

      if (result) {
        setProgressSummaryFilters(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteProgressSummaryFilter = async (
    selectedObject: any,
    filterId: ProgressSummaryFilterID
  ) => {
    let columnId: string = "F30006_MCU";

    const newDto: IJdeScheduleReportProgressFiltersDto = {
      ...(progressSummaryFilters as IJdeScheduleReportProgressFiltersDto),
      [filterId]: (progressSummaryFilters as any)?.[filterId]?.filter(
        (selected: any) => selected[columnId] !== selectedObject[columnId]
      ),
    };

    try {
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        newDto
      );

      setProgressSummaryFilters(newDto);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onSelectProgressSummaryFilters = async (
    selectedObjects: any[],
    filterId: ProgressSummaryFilterID
  ) => {
    try {
      const payload: IJdeScheduleReportProgressFiltersDto = {
        ...(progressSummaryFilters as IJdeScheduleReportProgressFiltersDto),
        [filterId]: [
          ...((progressSummaryFilters as any)?.[filterId] ?? []),
          ...selectedObjects,
        ],
      };

      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        payload
      );
      setProgressSummaryFilters(payload);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!currentSchedule) {
      fetchProgressSummaryFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule]);

  const renderWorkcenterFilters = () => (
    <JdeScheduleProgressWorkcenterFilters
      rows={
        (progressSummaryFilters as IJdeScheduleReportProgressFiltersDto)?.[
          ProgressSummaryFilterID.WORKCENTER
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  return (
    <Collapse in={progressFiltersOpened} orientation="horizontal" timeout={300}>
      <ChartFiltersContainer>
        {renderWorkcenterFilters()}
        <RiverSpinner show={loading} />
      </ChartFiltersContainer>
    </Collapse>
  );
};
