import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { ExternalResourceAction } from "../external-resources";
import { useTranslation } from "@river/common-ui";
import { ExternalResourcesUiService } from "../../../services";

export const ExternalResourcesSubHeader: React.FC = (props) => {
  const [resourceDialogOpened, setResourceDialogOpened] =
    useState<boolean>(false);
  const openDialog = (): void => {
    setResourceDialogOpened(true);
  };
  const closeDialog = (): void => setResourceDialogOpened(false);
  const tableContext = useContext(TableContext);
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.EXTERNAL_RESOURCES}
          action={ExternalResourceAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            disabled={!uiService.canManageExternalResources()}
            text={t("module.external_resource:button.add_resource")}
          />
        </ProtectedAction>
      </div>
      {uiService.renderExternalResourceDialog({
        open: resourceDialogOpened,
        resource: null,
        onClose: (success) => {
          closeDialog();
          if (success) tableContext?.table.fetch();
        },
      })}
    </>
  );
};
