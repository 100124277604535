import { AdapterUiService } from "./adapter-ui.service";
import {
  ColumnsProvider,
  FetchFunctionProvider,
  SelectorTableColumnsProvider,
} from "./ui-service.types";
import { IColumn } from "../interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../context";
import { useContext } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "@river/common-ui";

export enum CrewsAction {
  CREATE = "CREATE_CREW",
  UPDATE = "UPDATE_CREW",
  DELETE = "DELETE_CREW",
}

export abstract class CrewsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.grid_header",
      "shared.river_table_selector",
      "module.crews",
      "module.calendars",
      "common.button",
      "common.label",
      "shared.crew_resource_selector",
      "shared.river_custom_selector",
      "entity.availability_header",
      "entity.workcenter",
      "entity.resource",
      "entity.crew_workcenter",
      "entity.calendar",
      "entity.crew_resource",
      "entity.crew",
      "entity.crew_person",
      "entity.crew_supervisor_ref",
      "shared.river_data_grid",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  getCrewResourceSelectorColumns = (): SelectorTableColumnsProvider => {
    const availableTableColumns: IColumn[] =
      this.getAvailableCrewResourcesColumns();
    const assignedTableColumns: IColumn[] =
      this.getAssignedCrewResourcesColumns();
    return () => ({
      availableTableColumns,
      assignedTableColumns,
    });
  };

  getCrewCraftSelectorColumns = (): SelectorTableColumnsProvider => {
    const availableTableColumns: IColumn[] =
      this.getAvailableCrewCraftsColumns();
    const assignedTableColumns: IColumn[] = this.getAssignedCrewCraftsColumns();
    return () => ({
      availableTableColumns,
      assignedTableColumns,
    });
  };

  getUnassignCrewCraftsFn = () => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return (selectedIds: Set<string>): Promise<any> => {
        const promises: Promise<void>[] = [];
        selectedIds.forEach((id) => {
          promises.push(
            adapterContext!.service.getAdapterService().deleteCrewCraft(id)
          );
        });
        return Promise.all(promises);
      };
    };
  };

  getSupervisorTableColumns = (): (() => Column<any>[]) => {
    const { t } = useTranslation();

    const columns: IColumn[] = [
      {
        key: "display_name",
        name: t("entity.crew_supervisor_ref:crew_supervisor_ref.display_name"),
      },
      {
        key: "user_name",
        name: t("entity.crew_supervisor_ref:crew_supervisor_ref.user_name"),
      },
    ];
    return () => columns;
  };

  // ERP-specific
  abstract getAvailableCrewResourcesColumns: ColumnsProvider;
  abstract getAssignedCrewResourcesColumns: ColumnsProvider;

  abstract resourceNumberField: string;
  abstract availableCrewCraftsEntityName: string;
  abstract availableCrewCraftsRowKeyGetter(row: any): string;
  abstract assignedCrewCraftsEntityName: string;

  abstract getAvailableCrewCraftsColumns: ColumnsProvider;
  abstract getAssignedCrewCraftsColumns: ColumnsProvider;

  abstract getAvailableCrewCraftsFetchFn: (
    crew: string
  ) => FetchFunctionProvider;
  abstract getAssignedCrewCraftsFetchFn: (
    crew: string
  ) => FetchFunctionProvider;

  abstract getAssignCrewCraftsFn: (
    crew: string
  ) => () => (selectedIds: Set<string>) => Promise<any>;

  abstract getErpSpecificI18nNamespaces(): string[];

  canManageCrews(): boolean {
    return true;
  }
}
