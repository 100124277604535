import { AdapterUiService } from "../adapter-ui.service";
import { AdapterService } from "../adapter.service";
import { OracleEbsAdapterService } from "./oracle-ebs-adapter.service";
import { OracleEbsExternalResourcesUiService } from "./oracle-ebs-external-resources-ui-service";
import { OracleEbsSitesUiService } from "./oracle-ebs-sites-ui.service";
import { OracleEbsAvailabilityUiService } from "./oracle-ebs-availability-ui.service";
import { OracleEbsPersonUiService } from "./oracle-ebs-person-ui.service";
import { OracleEbsMaterialRequirementsUiService } from "./oracle-ebs-material-requirements-ui.service";
import { OracleEbsBacklogUiService } from "./oracle-ebs-backlog-ui.service";
import { OracleEbsUsersUiService } from "./oracle-ebs-users-ui.service";
import { OracleEbsScheduleUiService } from "./oracle-ebs-schedule-ui.service";
import { OracleEbsSchedulesUiService } from "./oracle-ebs-schedules-ui.service";
import { OracleEbsScheduleUtilizationUiService } from "./oracle-ebs-schedule-utilization-ui-service";
import { OracleEbsTaskHelpersUiService } from "./oracle-ebs-task-helpers-ui.service";
import { OracleEbsCraftsUiService } from "./oracle-ebs-crafts-ui.service";
import { OracleEbsOperationsUiService } from "./oracle-ebs-operations-ui-service";
import { OracleEbsExecutionUiService } from "./oracle-ebs-execution-ui-service";
import { OracleEbsShiftsUiService } from "./oracle-ebs-shifts-ui.service";
import { OracleEbsCalendarsUiService } from "./oracle-ebs-calendars-ui.service";
import { OracleEbsValidationDashboardUiService } from "./oracle-ebs-validation-dashboard-ui.service";
import { OracleEbsTimecardUiService } from "./oracle-ebs-timecard-ui.service";
import { OracleEbsCrewsUiService } from "./oracle-ebs-crews-ui.service";
import { OracleEbsTableUiService } from "./oracle-ebs-table-ui.service";
import { OracleEbsUserRolesUiService } from "./oracle-ebs-user-roles-ui-service";
import { OracleEbsRestrictionsUiService } from "./oracle-ebs-restrictions-ui.service";
import { OracleEbsSupervisorScheduleUiService } from "./oracle-ebs-supervisor-schedule-ui.service";
import { OracleEbsSupervisorScheduleUtilizationUiService } from "./oracle-ebs-supervisor-schedule-utilization-ui-service/oracle-ebs-supervisor-schedule-utilization-ui.service";
import { OracleEbsSupervisorSchedulesUiService } from "./oracle-ebs-supervisor-schedules-ui-service";
import { OracleEbsSupervisorTimeCardUiService } from "./oracle-ebs-supervisor-timecard-ui-service";
import { OracleEbsScheduleReportUiService } from "./oracle-ebs-schedule-report-ui-service";

export class OracleEbsAdapterUiService extends AdapterUiService {
  private supervisorScheduleUiService: OracleEbsSupervisorScheduleUiService | null =
    null;
  private supervisorTimeCardUiService: OracleEbsSupervisorTimeCardUiService | null =
    null;
  private supervisorSchedulesUiService: OracleEbsSupervisorSchedulesUiService | null =
    null;
  private supervisorScheduleUtilizationUiService: OracleEbsSupervisorScheduleUtilizationUiService | null =
    null;
  private sitesUiService: OracleEbsSitesUiService | null = null;
  private backlogUiService: OracleEbsBacklogUiService | null = null;
  private schedulesUiService: OracleEbsSchedulesUiService | null = null;
  private scheduleUiService: OracleEbsScheduleUiService | null = null;
  private scheduleUtilizationUiService: OracleEbsScheduleUtilizationUiService | null =
    null;
  private usersUiService: OracleEbsUsersUiService | null = null;
  private availabilityUiService: OracleEbsAvailabilityUiService | null = null;
  private externalResourcesUiService: OracleEbsExternalResourcesUiService | null =
    null;
  private materialRequirementsUiService: OracleEbsMaterialRequirementsUiService | null =
    null;
  private personUiService: OracleEbsPersonUiService | null = null;
  private taskHelpersUiService: OracleEbsTaskHelpersUiService | null = null;
  private craftsUiService: OracleEbsCraftsUiService | null = null;
  private operationsUiService: OracleEbsOperationsUiService | null = null;
  private executionUiService: OracleEbsExecutionUiService | null = null;
  private shiftsUiService: OracleEbsShiftsUiService | null = null;
  private calendarsUiService: OracleEbsCalendarsUiService | null = null;
  private validationDashboardUiService: OracleEbsValidationDashboardUiService | null =
    null;
  private timecardUiService: OracleEbsTimecardUiService | null = null;
  private crewsUiService: OracleEbsCrewsUiService | null = null;
  private tableUiService: OracleEbsTableUiService | null = null;
  private userRolesUiService: OracleEbsUserRolesUiService | null = null;
  private restrictionsUiService: OracleEbsRestrictionsUiService | null = null;
  private scheduleReportUiService: OracleEbsScheduleReportUiService | null =
    null;

  protected createAdapterService(): AdapterService {
    return new OracleEbsAdapterService(this.customerInfo);
  }

  getSitesUiService(): OracleEbsSitesUiService {
    if (!this.sitesUiService) {
      this.sitesUiService = new OracleEbsSitesUiService(this);
    }
    return this.sitesUiService;
  }

  getBacklogUiService(): OracleEbsBacklogUiService {
    if (!this.backlogUiService) {
      this.backlogUiService = new OracleEbsBacklogUiService(this);
    }
    return this.backlogUiService;
  }

  getScheduleUiService(): OracleEbsScheduleUiService {
    if (!this.scheduleUiService) {
      this.scheduleUiService = new OracleEbsScheduleUiService(this);
    }
    return this.scheduleUiService;
  }

  getSchedulesUiService(): OracleEbsSchedulesUiService {
    if (!this.schedulesUiService) {
      this.schedulesUiService = new OracleEbsSchedulesUiService(this);
    }
    return this.schedulesUiService;
  }

  getScheduleUtilizationUiService(): OracleEbsScheduleUtilizationUiService {
    if (!this.scheduleUtilizationUiService) {
      this.scheduleUtilizationUiService =
        new OracleEbsScheduleUtilizationUiService(this);
    }
    return this.scheduleUtilizationUiService;
  }

  getUsersUiService(): OracleEbsUsersUiService {
    if (!this.usersUiService) {
      this.usersUiService = new OracleEbsUsersUiService(this);
    }
    return this.usersUiService;
  }

  getAvailabilityUiService(): OracleEbsAvailabilityUiService {
    if (!this.availabilityUiService) {
      this.availabilityUiService = new OracleEbsAvailabilityUiService(this);
    }
    return this.availabilityUiService;
  }

  getExternalResourcesUiService(): OracleEbsExternalResourcesUiService {
    if (!this.externalResourcesUiService) {
      this.externalResourcesUiService = new OracleEbsExternalResourcesUiService(
        this
      );
    }
    return this.externalResourcesUiService;
  }

  getMaterialRequirementsUiService(): OracleEbsMaterialRequirementsUiService {
    if (!this.materialRequirementsUiService) {
      this.materialRequirementsUiService =
        new OracleEbsMaterialRequirementsUiService(this);
    }
    return this.materialRequirementsUiService;
  }

  getPersonUiService(): OracleEbsPersonUiService {
    if (!this.personUiService) {
      this.personUiService = new OracleEbsPersonUiService(this);
    }
    return this.personUiService;
  }

  getTaskHelpersUiService(): OracleEbsTaskHelpersUiService {
    if (!this.taskHelpersUiService) {
      this.taskHelpersUiService = new OracleEbsTaskHelpersUiService(this);
    }
    return this.taskHelpersUiService;
  }

  getCraftsUiService(): OracleEbsCraftsUiService {
    if (!this.craftsUiService) {
      this.craftsUiService = new OracleEbsCraftsUiService(this);
    }
    return this.craftsUiService;
  }

  getOperationsUiService(): OracleEbsOperationsUiService {
    if (!this.operationsUiService) {
      this.operationsUiService = new OracleEbsOperationsUiService(this);
    }
    return this.operationsUiService;
  }

  getExecutionUiService(): OracleEbsExecutionUiService {
    if (!this.executionUiService) {
      this.executionUiService = new OracleEbsExecutionUiService(this);
    }
    return this.executionUiService;
  }

  getShiftsUiService(): OracleEbsShiftsUiService {
    if (!this.shiftsUiService) {
      this.shiftsUiService = new OracleEbsShiftsUiService(this);
    }
    return this.shiftsUiService;
  }

  getCalendarsUiService(): OracleEbsCalendarsUiService {
    if (!this.calendarsUiService) {
      this.calendarsUiService = new OracleEbsCalendarsUiService(this);
    }
    return this.calendarsUiService;
  }

  getValidationDashboardUiService(): OracleEbsValidationDashboardUiService {
    if (!this.validationDashboardUiService) {
      this.validationDashboardUiService =
        new OracleEbsValidationDashboardUiService(this);
    }
    return this.validationDashboardUiService;
  }

  getTimeCardUiService(): OracleEbsTimecardUiService {
    if (!this.timecardUiService) {
      this.timecardUiService = new OracleEbsTimecardUiService(this);
    }
    return this.timecardUiService;
  }

  getCrewsUiService(): OracleEbsCrewsUiService {
    if (!this.crewsUiService) {
      this.crewsUiService = new OracleEbsCrewsUiService(this);
    }
    return this.crewsUiService;
  }

  getTableUiService(): OracleEbsTableUiService {
    if (!this.tableUiService) {
      this.tableUiService = new OracleEbsTableUiService(this);
    }
    return this.tableUiService;
  }

  getUserRolesUiService(): OracleEbsUserRolesUiService {
    if (!this.userRolesUiService) {
      this.userRolesUiService = new OracleEbsUserRolesUiService(this);
    }
    return this.userRolesUiService;
  }

  getRestrictionsUiService(): OracleEbsRestrictionsUiService {
    if (!this.restrictionsUiService) {
      this.restrictionsUiService = new OracleEbsRestrictionsUiService(this);
    }
    return this.restrictionsUiService;
  }

  getSupervisorScheduleUiService(): OracleEbsSupervisorScheduleUiService {
    if (!this.supervisorScheduleUiService) {
      this.supervisorScheduleUiService =
        new OracleEbsSupervisorScheduleUiService(this);
    }
    return this.supervisorScheduleUiService;
  }

  getSupervisorSchedulesUiService(): OracleEbsSupervisorSchedulesUiService {
    if (!this.supervisorSchedulesUiService) {
      this.supervisorSchedulesUiService =
        new OracleEbsSupervisorSchedulesUiService(this);
    }
    return this.supervisorSchedulesUiService;
  }

  getSupervisorScheduleUtilizationUiService(): OracleEbsSupervisorScheduleUtilizationUiService {
    if (!this.supervisorScheduleUtilizationUiService) {
      this.supervisorScheduleUtilizationUiService =
        new OracleEbsSupervisorScheduleUtilizationUiService(this);
    }
    return this.supervisorScheduleUtilizationUiService;
  }

  getSupervisorTimeCardUiService(): OracleEbsSupervisorTimeCardUiService {
    if (!this.supervisorTimeCardUiService) {
      this.supervisorTimeCardUiService =
        new OracleEbsSupervisorTimeCardUiService(this);
    }
    return this.supervisorTimeCardUiService;
  }

  getScheduleReportUiService(): OracleEbsScheduleReportUiService {
    if (!this.scheduleReportUiService) {
      this.scheduleReportUiService = new OracleEbsScheduleReportUiService(this);
    }
    return this.scheduleReportUiService;
  }
}
