import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { ShiftsAction } from "../shifts";
import { ShiftDialog } from "../shift-dialog";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { ShiftsUiService } from "../../../services";

export const ShiftsSubHeader: React.FC = (props) => {
  const [shiftDialogOpened, setShiftDialogOpened] = useState<boolean>(false);

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ShiftsUiService =
    adapterContext?.service!.getShiftsUiService()!;

  const openDialog = (): void => {
    setShiftDialogOpened(true);
  };
  const closeDialog = (): void => setShiftDialogOpened(false);
  const tableContext = useContext(TableContext);

  const { t } = useTranslation();

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction module={ModuleKey.SHIFTS} action={ShiftsAction.CREATE}>
          <PrimaryButton
            onClick={openDialog}
            disabled={!uiService.canManageShifts()}
            text={t("module.shifts:button.add_shift")}
          />
        </ProtectedAction>
      </div>
      <ShiftDialog
        open={shiftDialogOpened}
        shift={null}
        onClose={(success) => {
          closeDialog();
          if (success) tableContext?.table.fetch();
        }}
      />
    </>
  );
};
