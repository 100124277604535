import { AdapterRestrictionDto, IAdapterRestriction } from "@river/interfaces";
import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useAllowedAction } from "../../protected-action";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useContext } from "react";
import { ModuleKey } from "../../sidebar-menu";
import { RestrictionsAction } from "../restrictions";

export const useRestrictionForm = (props: {
  restriction: IAdapterRestriction | null;
  onCreate: (restriction: IAdapterRestriction) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  return useRiverForm<AdapterRestrictionDto, IAdapterRestriction, Object>({
    initialDto: Object.assign(new AdapterRestrictionDto(), {
      role: "",
      entity_name: "",
      expression: "",
      active: false,
      validated: false,
    }),
    entityName: "restriction",
    instanceToEdit: props.restriction,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (
      dto: AdapterRestrictionDto
    ): Promise<IAdapterRestriction> => {
      return await adapterContext!.service
        .getAdapterService()
        .createRestriction(dto);
    },
    update: async (dto: AdapterRestrictionDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateRestriction(props.restriction!._id, dto);
    },
    labels: {
      role: t("entity.restriction:restriction.role"),
      entity_name: t("entity.restriction:restriction.entity_name"),
      expression: t("entity.restriction:restriction.expression"),
      active: t("entity.restriction:restriction.active"),
      validated: t("entity.restriction:restriction.validated"),
    },
    readOnly: !isActionAllowed(
      ModuleKey.RESTRICTIONS,
      RestrictionsAction.UPDATE
    ),
  });
};
