import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";
import { AdapterUiService } from "./adapter-ui.service";
import { TFunction, useTranslation } from "react-i18next";
import dataGridStyles from "../components/shared/river-data-grid/river-data-grid.module.scss";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { IAdapterCalendar } from "@river/interfaces";
import { fetchHelpers, uiConstants } from "../helpers";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../context";
import { useContext } from "react";
import { useEntity, useTableCellRenderers } from "../hooks";
import { FormatterProps } from "react-data-grid";

interface IUseCalendarsProps {
  onCalendarClick: (formatterProps: FormatterProps<any>) => void;
}

export abstract class CalendarsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useCalendars = (props: IUseCalendarsProps): EntityTableProvider => {
    const { onCalendarClick } = props;
    return () => {
      const site: IUserContextSite =
        useContext(AdapterUserContext)?.userProperties[
          AdapterUserContextProp.SITE
        ];

      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const { entityName, columns } = this.getCalendarEntityTableParams(
        onCalendarClick,
        t
      );

      const fetchCalendars = async (fetchProps: ITableFetchFunctionProps) =>
        await this.adapterUiService
          .getAdapterService()
          .fetchCalendars(fetchHelpers.getTableQuery({ fetchProps }));

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchCalendars,
          fetchOn: true,
          dependencies: [!!site],
          fetchTriggers: [site],
          rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
          useAdvancedFilters: false,
          infiniteScrolling: true,
        }),
      };
    };
  };

  protected getCalendarEntityTableParams = (
    onCalendarClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams => {
    // const dayNames = [
    //   t("common.label:sunday"),
    //   t("common.label:monday"),
    //   t("common.label:tuesday"),
    //   t("common.label:wednesday"),
    //   t("common.label:thursday"),
    //   t("common.label:friday"),
    //   t("common.label:saturday"),
    // ];
    const { renderTextCell, renderCell, renderCellText } =
      useTableCellRenderers();
    return {
      entityName: "calendar",
      columns: [
        {
          key: "calendar",
          name: t("entity.calendar:calendar.calendar"),
          formatter: (formatterProps) => (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => onCalendarClick(formatterProps)}
                  >
                    {renderCellText({ formatterProps })}
                  </div>
                ),
              })}
            </>
          ),
        },
        { key: "description", name: t("entity.calendar:calendar.description") },
        {
          key: "reference_date",
          name: t("entity.calendar:calendar.reference_date"),
        },
        {
          key: "num_of_days",
          name: t("module.calendars:label.number_of_days"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: (formatterProps.row as IAdapterCalendar).template
                  ?.length,
              })}
            </>
          ),
        },
      ],
    };
  };

  getI18nNamespaces(): string[] {
    return [
      "module.calendars",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.grid_header",
      "shared.column_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.error_messages",
      "common.button",
      "common.label",
      "entity.calendar",
      "entity.shift",
      "entity.availability_header",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getErpSpecificI18nNamespaces(): string[];

  canManageCalendars(): boolean {
    return true;
  }
}
