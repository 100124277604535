import {
  RiverDialog,
  RiverRadioGroup,
  RiverSpinner,
  useNotification,
} from "@river/common-ui";
import React, { useContext, useState } from "react";
import { RiverFormInstance } from "../../../hooks";
import { LookupType, RiverLookup } from "..";
import {
  WoScheduleAction,
  IStandaloneValidator,
  useMoveRecordsFromScheduleForm,
} from "./use-move-records-from-schedule-form";

import styles from "./move-records-from-schedule-dialog.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TableContext,
} from "../../../context";
import { useParams } from "react-router";
import { IEntityObject, QueryAttributeDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";

interface KEY_MAP {
  [entityName: string]: {
    moved: { title: string; message: string };
    removed: { title: string; message: string };
  };
}

const TRANSLATION_KEY_MAP: KEY_MAP = {
  workorder: {
    moved: {
      title:
        "shared.move_records_from_schedule_dialog:notification.work_order_moved.title",
      message:
        "shared.move_records_from_schedule_dialog:notification.work_order_moved.message",
    },
    removed: {
      title:
        "shared.move_records_from_schedule_dialog:notification.work_order_removed.title",
      message:
        "shared.move_records_from_schedule_dialog:notification.work_order_removed.message",
    },
  },
  operation: {
    moved: {
      title:
        "shared.move_records_from_schedule_dialog:notification.operation_moved.title",
      message:
        "shared.move_records_from_schedule_dialog:notification.operation_moved.message",
    },
    removed: {
      title:
        "shared.move_records_from_schedule_dialog:notification.operation_removed.title",
      message:
        "shared.move_records_from_schedule_dialog:notification.operation_removed.message",
    },
  },
};

export interface IMoveRecordsFromScheduleDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MoveRecordsFromScheduleDialog: React.FC<
  IMoveRecordsFromScheduleDialogProps
> = (props) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const scheduleContext = useContext(ScheduleContext);
  const form: RiverFormInstance = useMoveRecordsFromScheduleForm();
  const notify = useNotification();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    resetForm,
    setStandaloneFields,
    validateStandaloneField,
    validationErrors,
  } = form;

  const standalone: IStandaloneValidator =
    form.standalone as IStandaloneValidator;

  const submit = async () => {
    if (standalone.action === WoScheduleAction.REMOVE_FROM_SCHEDULE) {
      removeRecordsFromFolder();
    } else if (
      standalone.action === WoScheduleAction.MOVE_TO_ANOTHER_SCHEDULE
    ) {
      await validateStandaloneField("scheduleId");
      if (validationErrors.list.length > 0) {
        return;
      }
      moveRecordsToAnotherFolder();
    }
  };

  const moveRecordsToAnotherFolder = async (): Promise<void> => {
    try {
      setIsProcessing(true);
      const entityName: string = tableContext?.entityName as string;
      await adapterContext!.service.getAdapterService().schedule({
        folder_id: standalone.scheduleId,
        entity_name: entityName,
        entity_ids: Array.from(table!.selectedRowIds),
      });
      props.onClose();
      notify.info({
        title: t(TRANSLATION_KEY_MAP[entityName].moved.title),
        message: t(TRANSLATION_KEY_MAP[entityName].moved.message, {
          folder: standalone.scheduleName,
        }),
      });
      table?.refresh();
      resetForm();
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsProcessing(false);
    }
  };

  const removeRecordsFromFolder = async (): Promise<void> => {
    try {
      setIsProcessing(true);
      const entityName: string = tableContext?.entityName as string;
      await adapterContext!.service
        .getAdapterService()
        .removeFromFolder(
          scheduleId,
          entityName,
          Array.from(table!.selectedRowIds)
        );
      props.onClose();
      notify.info({
        title: t(TRANSLATION_KEY_MAP[entityName].removed.title),
        message: t(TRANSLATION_KEY_MAP[entityName].removed.message, {
          folder: scheduleContext?.currentSchedule?.folder,
        }),
      });
      table?.refresh();
      resetForm();
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsProcessing(false);
    }
  };

  const getScheduleQueryAttributes = (): QueryAttributeDto[] => {
    if (!scheduleId) {
      return [];
    } else {
      return [
        {
          attribute_name: "_id",
          attribute_value: {
            operator: "$ne",
            value: scheduleId,
          },
        },
      ];
    }
  };

  return (
    <RiverDialog
      title={t("shared.move_records_from_schedule_dialog:title")}
      open={props.open}
      onClose={() => {
        props.onClose();
        resetForm();
      }}
      actionButtonText={t("common.button:ok")}
      form={form}
      classes={{
        paper: styles.paper,
      }}
      onSubmit={submit}
    >
      <RiverSpinner show={isProcessing} />
      <RiverRadioGroup
        id={"action"}
        options={Object.values(WoScheduleAction)}
      />
      <RiverLookup
        id={"scheduleId"}
        fullWidth
        lookup={{
          type: LookupType.SCHEDULES,
          customFilters: getScheduleQueryAttributes(),
        }}
        onSelect={(selected: IEntityObject) => {
          if (selected) {
            setStandaloneFields({
              ...standalone,
              scheduleName: selected.folder,
              scheduleId: selected._id,
            });
          }

          validateStandaloneField("scheduleId", selected._id);
        }}
        inputProps={{
          disabled: true,
        }}
        disabled={
          standalone.action !== WoScheduleAction.MOVE_TO_ANOTHER_SCHEDULE
        }
        value={standalone.scheduleName}
      />
    </RiverDialog>
  );
};
