import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleUtilizationContext,
} from "../../../../context";
import {
  IScheduleProgressFilters,
  ProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters/chart-filters-container/chart-filters-container";
import { JdeScheduleProgressWorkcenterFilters } from "./filters";

export interface IJdeScheduleProgressFiltersDto
  extends IScheduleProgressFilters {
  [ProgressSummaryFilterID.WORKCENTER]: any[];
}

export const JdeScheduleProgressFilters: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();
  const scheduleUtilizationContext = useContext(ScheduleUtilizationContext);
  const {
    progressFiltersOpened,
    progressSummaryFilters,
    setProgressSummaryFilters,
  } = scheduleUtilizationContext!;

  const fetchProgressSummaryFilters = async () => {
    try {
      setLoading(true);
      const result: IJdeScheduleProgressFiltersDto =
        await userPreferencesService.getScheduleProgressFilters(
          adapterContext!.service.getAdapterService()
        );
      if (result) {
        setProgressSummaryFilters(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteProgressSummaryFilter = async (
    selectedObject: any,
    filterId: ProgressSummaryFilterID
  ) => {
    let columnId: string = "F30006_MCU";

    const newDto: IJdeScheduleProgressFiltersDto = {
      ...(progressSummaryFilters as IJdeScheduleProgressFiltersDto),
      [filterId]: (progressSummaryFilters as any)[filterId]?.filter(
        (selected: any) => selected[columnId] !== selectedObject[columnId]
      ),
    };

    try {
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        newDto
      );
      setProgressSummaryFilters(newDto);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onSelectProgressSummaryFilters = async (
    selectedObjects: any[],
    filterId: ProgressSummaryFilterID
  ) => {
    try {
      const payload: IJdeScheduleProgressFiltersDto = {
        ...(progressSummaryFilters as IJdeScheduleProgressFiltersDto),
        [filterId]: [
          ...(progressSummaryFilters as any)[filterId],
          ...selectedObjects,
        ],
      };
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        payload
      );
      setProgressSummaryFilters(payload);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgressSummaryFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWorkcenterFilters = (): ReactElement => (
    <JdeScheduleProgressWorkcenterFilters
      rows={
        (progressSummaryFilters as IJdeScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.WORKCENTER
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  return (
    <Collapse
      in={progressFiltersOpened}
      orientation={"horizontal"}
      timeout={300}
    >
      <ChartFiltersContainer>
        {renderWorkcenterFilters()}
        <RiverSpinner show={loading} />
      </ChartFiltersContainer>
    </Collapse>
  );
};
