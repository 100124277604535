import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNotification, useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { AdapterService } from "../../../../services";
import { useGridActionHelpers } from "../../../../helpers";
import { GenericAction } from "./generic-action";
import { ExcelIcon } from "../../../../icons";
import { IAdapterReport } from "@river/interfaces";
import {
  DownloadStatusType,
  RiverDownloadDialog,
} from "../../river-download-dialog";
import { Constants } from "@river/constants";

const DEFAULT_SKIP_CONFIRMATION: boolean = true;

export interface IUseReportActionProps {
  generateReport: () => Promise<IAdapterReport>;
  skipDownloadConfirmation?: boolean;
}

export const useReportAction = (props: IUseReportActionProps) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatusType>(null);
  const timerIdRef = useRef<number>();
  const [reportToDownload, setReportToDownload] =
    useState<IAdapterReport | null>(null);
  const notify = useNotification();
  const skipDownloadConfirmation: boolean =
    props.skipDownloadConfirmation ?? DEFAULT_SKIP_CONFIRMATION;

  const title: string = t("shared.action:excel_export");

  const onClick = async (): Promise<void> => {
    setDialogOpened(true);
  };

  const renderIcon = (iconProps?: any): ReactElement => (
    <GenericAction
      icon={ExcelIcon}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const downloadReport = async () => {
    try {
      setDownloadStatus({ type: "downloading" });
      await adapterService.getReportContent(
        reportToDownload!._id!,
        reportToDownload!.result_file_name!
      );
      setDownloadStatus({
        type: "complete",
        message: t("shared.download_dialog:label.complete", {
          file_name: reportToDownload?.result_file_name,
        }),
      });
    } catch (message) {
      setDownloadStatus({ type: "error" });
      stopPolling();
    }
  };

  const refreshReport = async () => {
    try {
      const reportResponse: IAdapterReport = await adapterService.getReportById(
        reportToDownload!._id!
      );

      if (reportResponse.status?.code === Constants.report_status.complete) {
        downloadReport();
        stopPolling();
      }

      if (reportResponse.status?.code === Constants.report_status.error) {
        setDownloadStatus({ type: "error" });
        stopPolling();
      }
    } catch (message) {
      setDownloadStatus({ type: "error" });
      stopPolling();
    }
  };

  useEffect(() => {
    if (reportToDownload) {
      startPolling();
    }

    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToDownload]);

  const startPolling = () => {
    timerIdRef.current = window.setInterval(() => refreshReport(), 1000);
  };

  const stopPolling = () => {
    window.clearInterval(timerIdRef.current);
  };

  const onClose = () => {
    setDialogOpened(false);
    setDownloadStatus(null);
    stopPolling();
  };

  const onDownloadClick = async (): Promise<void> => {
    try {
      setDownloadStatus({ type: "generating" });
      const report: IAdapterReport = await props.generateReport();
      setReportToDownload(report);
    } catch (message) {
      setDownloadStatus({ type: "error" });
      notify.error({ message });
    }
    setDialogOpened(true);
  };

  const renderDownloadDialog = (): ReactElement => (
    <RiverDownloadDialog
      title={title}
      open={dialogOpened}
      status={downloadStatus}
      onDownloadClick={onDownloadClick}
      skipConfirmation={skipDownloadConfirmation}
      onClose={onClose}
    />
  );

  const renderReportAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDownloadDialog()}
    </>
  );

  return {
    renderReportAction,
    reportAction: {
      icon: withSanitizedProps(renderIcon),
      renderDownloadDialog,
      title,
      onClick,
    },
  };
};

export type IUseReportAction = ReturnType<typeof useReportAction>;
