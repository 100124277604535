import React, { useContext, useEffect, useState } from "react";
import { Column } from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { IAdapterRule, IEntityObject } from "@river/interfaces";
import { ValidationRulesHeader } from "./validation-rules-header";
import { ValidationRulesSubheader } from "./validation-rules-subheader";
import { fetchHelpers, uiConstants } from "../../helpers";
import { ValidationRulesGridHeader } from "./validation-rules-grid-header";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import {
  useEntity,
  IUseEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { Constants } from "@river/constants";
import { ValidationRuleDialog } from "./validation-rule-dialog";
import { DEFAULT_RULE_TILE_COLOR } from "../rule-tile";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./validation-rules.module.scss";

export enum ValidationRulesAction {
  CREATE = "CREATE_RULE",
  UPDATE = "UPDATE_RULE",
  DELETE = "DELETE_RULE",
}

export const ValidationRules: React.FC = () => {
  usePageCleanup();

  const entityName: string = "rule";
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity: IUseEntity = useEntity({ entityName });
  const [ruleToEdit, setRuleToEdit] = useState<IAdapterRule | null>();
  const [ruleDialogOpened, setRuleDialogOpened] = useState<boolean>(false);

  const getRuleColor = (rule: IAdapterRule): string => {
    let color: string = DEFAULT_RULE_TILE_COLOR;
    const displayOptions: string = rule.display_options!;
    if (displayOptions) {
      try {
        color = JSON.parse(displayOptions).color;
      } catch {}
    }
    return color;
  };

  const openRuleDialog = (rule: IAdapterRule): void => {
    setRuleToEdit(rule);
  };

  useEffect(() => {
    if (ruleToEdit) {
      setRuleDialogOpened(true);
    }
  }, [ruleToEdit]);

  const shouldRenderColorMarker = (rule: IAdapterRule): boolean =>
    rule.rule_type === Constants.rule_type.validation_rule;

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "PlanningPlant",
      name: t("entity.rule:rule.PlanningPlant"),
      width: 150,
    },
    {
      key: "rule",
      name: t("entity.rule:rule.rule"),
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.ruleNameCell}>
                {shouldRenderColorMarker(formatterProps.row) && (
                  <div
                    className={styles.colorMarker}
                    style={{
                      background: getRuleColor(formatterProps.row),
                    }}
                  />
                )}
                <div
                  className={dataGridStyles.dataGridLink}
                  onClick={() => {
                    table.setSelectedRowIds(new Set());
                    openRuleDialog(formatterProps.row as IAdapterRule);
                  }}
                >
                  {renderCellText({ formatterProps })}
                </div>
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "entity_name", name: t("entity.rule:rule.entity_name") },
    { key: "target_period", name: t("entity.rule:rule.target_period") },
    { key: "expression", name: t("entity.rule:rule.expression") },
  ];

  const fetchRules = async (fetchProps: ITableFetchFunctionProps) => {
    let result = await adapterContext!.service
      .getAdapterService()
      .fetchRules(
        Constants.rule_type.validation_rule,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return result;
  };

  const table: IUseTable = useTable({
    entityName,
    columns,
    fetchFunction: fetchRules,
    fetchOn: true,
    dependencies: [!!site],
    fetchTriggers: [site],
    clearBeforeFetch: true,
    infiniteScrolling: true,
    useAdvancedFilters: false,
  });

  const getSelectedRule = (): IAdapterRule => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterRule;
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <ValidationRulesHeader />
        <ValidationRulesSubheader />
        <ValidationRulesGridHeader selectedRule={getSelectedRule()} />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
        <ValidationRuleDialog
          open={ruleDialogOpened}
          rule={ruleToEdit!}
          onClose={(success) => {
            setRuleToEdit(null);
            setRuleDialogOpened(false);
            if (success) table.fetch();
          }}
        />
      </TableContext.Provider>
    </>
  );
};
