import React, { ReactElement } from "react";
import { LinearProgress } from "@mui/material";
import styles from "./download-progress-bar.module.scss";
import clsx from "clsx";

interface IDownloadProgressBarProps {
  processed: number;
  total: number;
  text: string;
  className?: string;
  isError?: boolean;
  classes?: {
    container?: string;
    barRoot?: string;
    bar?: string;
    text?: string;
  };
}

export const DownloadProgressBar: React.FC<IDownloadProgressBarProps> = (
  props
): ReactElement => {
  const { processed, total } = props;
  const percent: number = Math.round((processed / total) * 100);

  return (
    <div className={props.className}>
      <div
        className={clsx([
          styles.container,
          props.classes?.container,
          { [styles.error]: props.isError },
        ])}
      >
        <LinearProgress
          variant="determinate"
          value={percent}
          classes={{
            root: clsx([styles.barRoot, props.classes?.barRoot]),
            bar: clsx([styles.bar, props.classes?.bar]),
          }}
        />
        <span className={clsx([styles.text, props.classes?.text])}>
          {props.text}
        </span>
      </div>
    </div>
  );
};
