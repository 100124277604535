import React, { useContext, useEffect } from "react";
import { RiverDialog, useTranslation } from "@river/common-ui";
import { IUseTable, RiverDateTimeInput } from "../../shared";
import { ModuleKey } from "../../sidebar-menu";
import {
  useAvailabilityExcelExportForm,
  IAvailabilityExcelExport,
} from "./use-availability-excel-export-form";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { RiverFormInstance } from "../../../hooks";
import { useReportAction } from "../../shared";
import styles from "./availability-excel-export-dialog.module.scss";
import {
  AdapterReportCreateDto,
  IAdapterReport,
  TQueryAttributeSort,
} from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { Constants } from "@river/constants";
import { AVAILABILITY_SHIFT_GRID_NUMBER_OF_COLUMNS } from "../availability";

export interface IAvailabilityExcelExportDialogProps {
  startDate: Date;
  finishDate?: Date;
  onClose: () => void;
  open: boolean;
}

export const AvailabilityExcelExportDialog: React.FC<
  IAvailabilityExcelExportDialogProps
> = (props) => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const getDateOnly = (date: Date): Date =>
    new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );

  const getStartDate = (): Date => getDateOnly(props.startDate);

  const getFinishDate = (): Date => {
    let date: Date;
    if (props.finishDate) {
      date = getDateOnly(props.finishDate);
    } else {
      date = getDateOnly(props.startDate);
      date.setDate(
        date.getDate() + AVAILABILITY_SHIFT_GRID_NUMBER_OF_COLUMNS - 1
      );
    }
    return date;
  };

  const form: RiverFormInstance = useAvailabilityExcelExportForm({
    startDate: getStartDate(),
    finishDate: getFinishDate(),
    submit: async () => {
      props.onClose();
      reportAction.onClick();
    },
  });

  const { setStandaloneFields, submit } = form;
  const standaloneFields: IAvailabilityExcelExport =
    form.standalone as IAvailabilityExcelExport;
  const { startDate, finishDate } = standaloneFields;

  useEffect(() => {
    if (props.open) {
      const newStandaloneFields: IAvailabilityExcelExport = {
        ...standaloneFields,
        startDate: getStartDate(),
        finishDate: getFinishDate(),
      };
      Object.setPrototypeOf(
        newStandaloneFields,
        Object.getPrototypeOf(standaloneFields)
      );
      setStandaloneFields(newStandaloneFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const { reportAction } = useReportAction({
    generateReport: async (): Promise<IAdapterReport> => {
      const dtoFinishDate: Date = getDateOnly(finishDate);
      dtoFinishDate.setDate(dtoFinishDate.getDate() + 1); // offset by one day

      const reportDto: AdapterReportCreateDto = {
        report_type: Constants.report_type.excel_basic_availability,
        entity_name: "availability_header",
        module: ModuleKey.AVAILABILITY,
        query: {
          query: table.lastRanQueryDto!.query,
          sort: table.sortColumns.map((sc) => ({
            attribute_name: sc.columnKey,
            sort: sc.direction.toLowerCase() as TQueryAttributeSort,
          })),
          $skip: 0,
          $limit: 0,
        },
        options: [
          { name: "start_date", value: startDate.toISOString().slice(0, 10) },
          {
            name: "finish_date",
            value: dtoFinishDate.toISOString().slice(0, 10),
          },
          /*{
            name: "avail_sort",
            value: [
              { attribute_name: "PersonNumber", sort: "asc" },
              { attribute_name: "availability_date", sort: "desc" },
            ],
          },*/
        ],
      };
      return await adapterContext!.service
        .getAdapterService()
        .generateReport(reportDto, false);
    },
  });

  return (
    <>
      <RiverDialog
        title={t("module.availability:excel_download_dialog.title")}
        open={props.open}
        onClose={props.onClose}
        onSubmit={submit}
        closeOnBackdropClick={false}
        showActionsDivider={true}
        actionButtonText={t("common.button:download")}
        classes={{
          content: styles.content,
          paper: styles.paper,
        }}
        dialogProps={{
          maxWidth: false,
        }}
        form={form}
      >
        <div className={styles.fields}>
          <RiverDateTimeInput dateOnly id="startDate" />
          <HorizontalRuleIcon className={styles.separator} />
          <RiverDateTimeInput dateOnly id="finishDate" minDate={startDate} />
        </div>
      </RiverDialog>
      {reportAction.renderDownloadDialog()}
    </>
  );
};
