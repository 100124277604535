import React, { ReactElement, useState, useEffect } from "react";
import {
  IFieldValidationErrors,
  useNotification,
  useValidation,
  IUseValidation,
  IValidationErrors,
  RiverFormContext,
} from "@river/common-ui";
import {
  IAttribute,
  IEntityDefinition,
  IEntityObject,
} from "@river/interfaces";
import { UseEntityProps, IUseEntity, useEntity } from ".";
import { Constants } from "@river/constants";
import { AttributeDataType } from "../interfaces";

const getBlankEntityDefinition = (): IEntityDefinition => {
  return {
    entity: {
      _id: "",
      adapter_type: "",
      entity_name: "",
    },
    attributes: [],
    indexes: [],
    relations: [],
  };
};

const STANDALONE_FIELDS_ENTITY_NAME = "standaloneFieldsDefinition";

export interface IFormRendererProps {
  form: RiverFormInstance;
  // other props
  [s: string]: any;
}

export type PropertyChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>
) => Promise<void>;

export type PropertySimpleChangeHandler = (value: string) => void;

interface IStandaloneFieldDef {
  fieldName: string;
  dataType: string;
}

interface IStandaloneFields<StandaloneValidator> {
  fields: StandaloneValidator;
  fieldDefs: IStandaloneFieldDef[];
  getValidatorInstance?: (obj: any) => StandaloneValidator;
}

type IArrayPropertyChangeProps = {
  arrayPropertyName: string;
  arrayElement: any;
  arrayIndex: number;
  propName: string;
  propValue: string;
};

interface IUseRiverFormProps<
  InstanceDto,
  InstanceInterface,
  StandaloneValidator,
> {
  // DTO props
  entityName?: string;
  entityDefinition?: IEntityDefinition;
  instanceToEdit?: InstanceInterface | undefined | null;
  initialDto?: InstanceDto;
  create?: (dto: InstanceDto) => Promise<InstanceInterface>;
  update?: (dto: InstanceDto) => Promise<void>;
  delete?: () => Promise<void>;
  onCreate?: (instance: InstanceInterface) => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  render?: (props: IFormRendererProps) => ReactElement;
  // standalone fields props
  standalone?: IStandaloneFields<StandaloneValidator>;
  // custom submit
  submit?: () => Promise<any>;
  // form status
  readOnly?: boolean;
  // field labels
  labels?: { [fieldId: string]: string };
}

export function useRiverForm<
  InstanceDto,
  InstanceInterface,
  StandaloneValidator,
>(
  props: IUseRiverFormProps<InstanceDto, InstanceInterface, StandaloneValidator>
) {
  const validation: IUseValidation = useValidation();
  let useEntityProps: UseEntityProps;
  if (props.entityDefinition) {
    useEntityProps = {
      entityName: props.entityName!,
      definition: props.entityDefinition,
    };
  } else if (props.entityName) {
    useEntityProps = { entityName: props.entityName };
  } else {
    useEntityProps = { entityName: "", definition: getBlankEntityDefinition() };
  }
  const entityDef: IUseEntity = useEntity(useEntityProps);

  const standaloneEntityDef: IUseEntity = useEntity({
    entityName: STANDALONE_FIELDS_ENTITY_NAME,
    definition: Object.assign(getBlankEntityDefinition(), {
      attributes: props.standalone
        ? props.standalone?.fieldDefs.map((def) => {
            return {
              _id: def.fieldName,
              attribute_name: def.fieldName,
              data_type: def.dataType,
              entity_name: STANDALONE_FIELDS_ENTITY_NAME,
              adapter_type: "",
              cardinality: "",
              is_persistent: true,
            };
          })
        : [],
    }),
  });

  const getInitialDto = (): InstanceDto => {
    if (props.initialDto) {
      const initialDto: InstanceDto = { ...props.initialDto! };
      Object.setPrototypeOf(
        initialDto,
        Object.getPrototypeOf(props.initialDto)
      );
      if (props.instanceToEdit) {
        for (let prop in initialDto) {
          // @ts-ignore
          if (typeof props.instanceToEdit[prop] !== "undefined") {
            let propVal: any = (props.instanceToEdit as any)[prop];
            const attribute: IAttribute = entityDef.attributesByName.get(prop)!;
            if (attribute) {
              if (
                attribute.data_type === AttributeDataType.DATETIME ||
                attribute.data_type === AttributeDataType.DATE
              ) {
                const newDate: Date | null =
                  propVal !== null ? new Date(propVal) : null;
                propVal =
                  newDate !== null && String(newDate) !== "Invalid Date"
                    ? newDate
                    : null;
              }
            }
            initialDto[prop] = propVal;
          }
        }
      }
      return initialDto;
    } else {
      return {} as InstanceDto;
    }
  };

  const getInitialStandaloneFields = (): StandaloneValidator => {
    let initialStandaloneFields: StandaloneValidator =
      {} as StandaloneValidator;
    if (props.standalone) {
      initialStandaloneFields = { ...props.standalone.fields };
      Object.setPrototypeOf(
        initialStandaloneFields,
        Object.getPrototypeOf(props.standalone.fields)
      );
    }
    return initialStandaloneFields;
  };

  const [dto, setDto] = useState<InstanceDto>(getInitialDto());
  const [standaloneFields, setStandaloneFields] =
    useState<StandaloneValidator | null>(getInitialStandaloneFields());
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (entityDef.attributesByName.size > 0) {
      setDto(getInitialDto());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityDef.attributesByName]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.instanceToEdit]);

  useEffect(() => {
    setDto(getInitialDto());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetForm = (): void => {
    resetValidationErrors();
    setIsProcessing(false);
    setDto(getInitialDto());
    setStandaloneFields(getInitialStandaloneFields());
  };

  const getFieldValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    entityDef: IUseEntity,
    fieldPrefix?: string
  ): any => {
    const fieldName: string = `${fieldPrefix ? fieldPrefix + "." : ""}${
      event.target.name
    }`;
    let fieldValue: any = event.target.value;
    const attribute: IAttribute = entityDef.attributesByName.get(fieldName)!;
    if (attribute) {
      if (attribute.data_type === Constants.data_type.boolean) {
        fieldValue = event.target.checked;
      } else if (
        attribute.data_type === Constants.data_type.double ||
        attribute.data_type === "number"
      ) {
        fieldValue = fieldValue === "" ? undefined : Number(fieldValue);
      } else if (
        attribute.data_type === AttributeDataType.DATETIME ||
        attribute.data_type === AttributeDataType.DATE
      ) {
        const newDate: Date | string = new Date(fieldValue);
        fieldValue =
          newDate instanceof Date && !isNaN(newDate.getDate()) ? newDate : "";
      }
    }
    return fieldValue;
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const propName = event.target.name!;
    const propValue = getFieldValue(event, entityDef);
    const newDto: InstanceDto = {
      ...dto,
      [propName]: propValue,
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newDto as unknown as object,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const getSimpleChangeHandler = (
    propName: string
  ): ((value: string) => Promise<void>) => {
    return async (propValue: string) => {
      const newDto: InstanceDto = {
        ...dto,
        [propName]: propValue,
      };
      Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
      setDto(newDto);
      const newErrors: IValidationErrors = await validation.validateProperty(
        newDto as unknown as object,
        propName,
        validationErrors
      );
      setValidationErrors(newErrors);
    };
  };

  const onLookupSelect = (
    lookupField: string,
    targetField?: string
  ): ((selectedObject: IEntityObject) => void) => {
    return (selectedObject: IEntityObject) => {
      const lookupFieldValue: any = selectedObject[lookupField];
      const newDto: InstanceDto = {
        ...dto,
        [targetField || lookupField]: lookupFieldValue,
      };
      Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
      setDto(newDto);
    };
  };

  const onStandaloneLookupSelect = (
    lookupField: string,
    targetField?: string
  ): ((selectedObject: IEntityObject) => void) => {
    return async (selectedObject: IEntityObject) => {
      const lookupFieldValue: any = selectedObject[lookupField];
      const fieldName: string = targetField || lookupField;
      const obj: StandaloneValidator = {
        ...standaloneFields!,
        [fieldName]: lookupFieldValue,
      };
      Object.setPrototypeOf(obj, Object.getPrototypeOf(standaloneFields));
      setStandaloneFields(obj);
      validateStandaloneField(fieldName, lookupFieldValue);
    };
  };

  const getArrayValidationErrors = (
    arrayPropertyName: string,
    arrayIndex: number,
    childName: string
  ): IFieldValidationErrors =>
    validationErrors?.fields[arrayPropertyName]?.childrenByIndex![arrayIndex]
      ?.fields[childName];

  const getArrayPropertyChangeHandler = (
    arrayPropertyName: string,
    arrayElement: any,
    arrayIndex: number
  ): PropertyChangeHandler => {
    return async (
      event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const propName = event.target.name!;
      const propValue = getFieldValue(event, entityDef, arrayPropertyName);
      await handleArrayPropertyChange({
        arrayPropertyName,
        arrayElement,
        arrayIndex,
        propName,
        propValue,
      });
    };
  };

  const getArrayPropertySimpleChangeHandler = (
    arrayPropertyName: string,
    arrayElement: any,
    arrayIndex: number
  ): ((propName: string) => PropertySimpleChangeHandler) => {
    return (propName: string): PropertySimpleChangeHandler => {
      return (propValue: string): void => {
        handleArrayPropertyChange({
          arrayPropertyName,
          arrayElement,
          arrayIndex,
          propName,
          propValue,
        });
      };
    };
  };

  const handleArrayPropertyChange = async (
    props: IArrayPropertyChangeProps
  ) => {
    const { arrayPropertyName, arrayElement, arrayIndex, propName, propValue } =
      props;
    const newElementState = {
      ...arrayElement,
      [propName]: propValue,
    };
    Object.setPrototypeOf(newElementState, Object.getPrototypeOf(arrayElement));

    // @ts-ignore
    const arrayField: any[] = dto[arrayPropertyName] as any[];
    arrayField.splice(arrayIndex, 1, newElementState);
    const newInstance: InstanceDto = {
      ...dto,
      [arrayPropertyName]: arrayField,
    };
    Object.setPrototypeOf(newInstance, Object.getPrototypeOf(dto));
    setDto(newInstance);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newInstance as unknown as object,
      arrayPropertyName,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateStandaloneField = async (
    fieldName: string,
    val?: any
  ): Promise<void> => {
    const valueToValidate: any =
      // @ts-ignore
      typeof val !== "undefined" ? val : standaloneFields[fieldName];

    let obj: any;
    if (typeof props.standalone?.getValidatorInstance === "function") {
      obj = props.standalone?.getValidatorInstance({
        [fieldName]: valueToValidate,
      });
    } else {
      obj = { [fieldName]: valueToValidate };
      Object.setPrototypeOf(obj, Object.getPrototypeOf(standaloneFields));
    }

    const newErrors: IValidationErrors = await validation.validateProperty(
      obj,
      fieldName,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const onStandalonePropertyChange = (opts?: {
    noValidate?: boolean;
  }): ((event: React.ChangeEvent<HTMLInputElement>) => Promise<void>) => {
    return async (
      event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const propName = event.target.name!;
      const propValue = getFieldValue(event, standaloneEntityDef);

      let newState: StandaloneValidator;
      if (typeof props.standalone?.getValidatorInstance === "function") {
        newState = props.standalone?.getValidatorInstance({
          ...standaloneFields,
          [propName]: propValue,
        });
      } else {
        newState = {
          ...standaloneFields,
          [propName]: propValue,
        } as StandaloneValidator;
        Object.setPrototypeOf(
          newState,
          Object.getPrototypeOf(standaloneFields)
        );
      }

      setStandaloneFields(newState);
      if (!opts?.noValidate) {
        validateStandaloneField(propName, propValue);
      }
    };
  };

  const mergeValidationErrors = (
    dest: IValidationErrors,
    src: IValidationErrors
  ): void => {
    Object.keys(src.fields).forEach(
      (name) => (dest.fields[name] = src.fields[name])
    );
    dest.list = dest.list.concat(src.list);
  };

  const validateForm = async (): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(dto);
    if (props.standalone) {
      let fields: StandaloneValidator = standaloneFields!;
      if (typeof props.standalone.getValidatorInstance === "function") {
        fields = props.standalone.getValidatorInstance({ ...standaloneFields });
      }
      const standaloneErrors = await validation.validateFields(fields);
      mergeValidationErrors(errors, standaloneErrors);
    }
    setValidationErrors(errors);
    return errors;
  };

  const createInstance = async (): Promise<void> => {
    if (!props.create) return;
    const errors: IValidationErrors = await validateForm();
    if (errors.list.length > 0) {
      return;
    }
    try {
      setIsProcessing(true);

      const createdInstance: InstanceInterface = await props.create(dto);
      props.onCreate?.(createdInstance);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsProcessing(false);
    }
  };

  const updateInstance = async (): Promise<void> => {
    if (props.instanceToEdit) {
      if (!props.update) return;
      const errors: IValidationErrors = await validateForm();
      if (errors.list.length > 0) {
        return;
      }
      try {
        setIsProcessing(true);
        await props.update(dto);
        props.onUpdate?.();
      } catch (message) {
        notify.error({ message });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const deleteInstance = async (): Promise<void> => {
    if (props.instanceToEdit) {
      if (!props.delete) return;
      try {
        setIsProcessing(true);
        await props.delete();
        props.onDelete && props.onDelete();
      } catch (message) {
        notify.error({ message });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const customSubmit = async (): Promise<void> => {
    if (!props.submit) return;
    if (props.standalone) {
      const errors = await validation.validateFields(standaloneFields);
      setValidationErrors(errors);
      if (errors.list.length > 0) {
        return;
      }
    }
    setIsProcessing(true);
    await props.submit();
    setIsProcessing(false);
  };

  const submit = (): Promise<void> => {
    if (props.submit) {
      return customSubmit();
    }
    if (props.instanceToEdit) {
      return updateInstance();
    } else {
      return createInstance();
    }
  };

  // Can't expose setDto directly due to unknown type error
  const dtoSetter = (obj: any) => {
    setDto(obj as InstanceDto);
  };

  // Can't expose setStandaloneFields directly due to unknown type error
  const standaloneFieldsSetter = (obj: any) => {
    setStandaloneFields(obj as StandaloneValidator);
  };

  const forceProcessingState = (loading: boolean): void =>
    setIsProcessing(loading);

  const isFieldReadOnly = (attributeName: string): boolean => {
    let isReadOnly = false;
    const attribute: IAttribute =
      entityDef.attributesByName.get(attributeName)!;
    if (attribute) {
      // if attribute is part of primary key and form is in edit mode, field is read-only
      isReadOnly = !!attribute.is_primary_key && !!props.instanceToEdit;
    }
    return isReadOnly;
  };

  const formInstance = {
    // DTO
    dto,
    setDto: dtoSetter,
    onPropertyChange,
    getSimpleChangeHandler,
    getArrayPropertyChangeHandler,
    getArrayPropertySimpleChangeHandler,
    onLookupSelect,
    create: createInstance,
    update: updateInstance,
    delete: deleteInstance,
    isCreate: !props.instanceToEdit,

    // standalone fields
    standalone: standaloneFields,
    setStandaloneFields: standaloneFieldsSetter,
    onStandalonePropertyChange,
    onStandaloneLookupSelect,
    getStandaloneValidatorInstance: props.standalone?.getValidatorInstance,
    validateStandaloneField,

    // form
    validateForm,
    validationErrors,
    setValidationErrors,
    getArrayValidationErrors,
    submit,
    resetForm,
    isProcessing,
    forceProcessingState,
    isReadOnly: !!props.readOnly,
    isFieldReadOnly,
    labels: props.labels,
    render: (): ReactElement => <></>,
  };

  formInstance.render = (): ReactElement => (
    <RiverFormContext.Provider value={{ form: formInstance }}>
      {props.render?.({ form: formInstance })}
    </RiverFormContext.Provider>
  );

  return formInstance;
}

export type RiverFormInstance = ReturnType<typeof useRiverForm>;
