import { FC, useEffect, useState, useContext } from "react";
import styles from "./availability-schedule-for-dialog.module.scss";
import {
  IRiverSimpleSelectItem,
  RiverDateInput,
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverFormSelect,
  RiverSpinner,
  RiverTextInput,
  useTranslation,
} from "@river/common-ui";
import { Box } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AdapterAvailabilityUpdateDto, IAdapterShift } from "@river/interfaces";
import { useAvailabilityScheduleForDialogForm } from "./use-availability-schedule-for-dialog-form";
import { AdapterUiContext, TableContext } from "../../../context";

interface IAvailabilityScheduleForDialogProps {
  availability: AdapterAvailabilityUpdateDto | null;
  open: boolean;
  onClose(closeProps?: { refresh?: boolean }): void;
  shifts: IAdapterShift[];
  scheduleFor: string;
}

export const AvailabilityScheduleForDialog: FC<
  IAvailabilityScheduleForDialogProps
> = (props) => {
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const [isWrongDateRange, setIsWrongDateRange] = useState<boolean>(false);
  const { t } = useTranslation();

  const form = useAvailabilityScheduleForDialogForm({
    submit: async () => {
      try {
        tableContext?.table!.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .updateAvailability(standalone);
        closeDialog({ refresh: true });
      } catch (e) {
        tableContext?.table!.forceLoadingState(false);
      }
    },
  });

  const getShift = (shiftId: string) => {
    return props.shifts.filter((shift) => shift._id === shiftId)[0];
  };

  const { resetForm, submit, setStandaloneFields, isProcessing } = form;
  const standalone: AdapterAvailabilityUpdateDto =
    form.standalone as AdapterAvailabilityUpdateDto;

  useEffect(() => {
    setStandaloneFields(
      Object.assign(new AdapterAvailabilityUpdateDto(), props.availability)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.availability]);

  const renderDialogActions = () => {
    return (
      <Box>
        <RiverDialogButton
          disabled={isProcessing}
          onClick={() => props.onClose()}
          className={styles.cancelBtn}
        >
          {t("common.button:cancel")}
        </RiverDialogButton>
        <RiverDialogActionButton
          onClick={submit}
          disabled={
            isProcessing ||
            isWrongDateRange ||
            !standalone.shift_id ||
            standalone.available_hours < 0
          }
        >
          {t("common.button:save_changes")}
        </RiverDialogActionButton>
      </Box>
    );
  };

  const mppedShifts: IRiverSimpleSelectItem[] = props.shifts.map((shift) => {
    return { value: shift._id!, text: shift.shift! };
  });
  const entries: IRiverSimpleSelectItem[] = [
    { value: "", text: `- ${t("common.label:none")} -` },
  ].concat(mppedShifts);

  const onShiftChange = (
    event: SelectChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const shiftId: string = event.target.value as string;
    if (shiftId) {
      const available_hours = getShift(shiftId).duration_hours;
      setStandaloneFields({
        ...standalone,
        shift_id: shiftId,
        available_hours,
      });
    }
  };

  const renderDateRangeFields = () => (
    <Box className={styles.dateRangeFields}>
      <RiverDateInput
        id="start_date"
        className={styles.dateField}
        error={isWrongDateRange}
        fullWidth
      />
      <RiverDateInput
        id="end_date"
        className={styles.dateField}
        fullWidth
        error={isWrongDateRange}
      />
    </Box>
  );

  const closeDialog = (closeProps?: { refresh?: boolean }) => {
    resetForm();
    props.onClose({ refresh: closeProps?.refresh });
  };

  useEffect(() => {
    if (standalone?.start_date && standalone?.end_date) {
      setIsWrongDateRange(standalone.start_date > standalone.end_date);
    }
  }, [standalone.start_date, standalone.end_date]);

  return (
    <RiverDialog
      form={form}
      titleContent={
        <Box className={styles.scheduleForContainer}>
          <Box className={styles.scheduleForIcon}>
            <EditOutlinedIcon />
          </Box>
          <p className={styles.scheduleFor}>
            <span>
              {t(
                "module.availability:availability_schedule_for_dialog.schedule_for"
              )}
              :
            </span>
            <span className={styles.scheduleForName}>{props.scheduleFor}</span>
          </p>
        </Box>
      }
      showActionsDivider={false}
      showTitleDivider={true}
      onClose={closeDialog}
      open={props.open}
      actionsContent={renderDialogActions()}
      onSubmit={submit}
    >
      <Box>
        <RiverSpinner show={isProcessing} />
        {renderDateRangeFields()}
        <RiverFormSelect
          id="shift_id"
          fullWidth
          items={entries}
          onChangeEvent={(event) => onShiftChange(event)}
        />
        <RiverTextInput
          fullWidth
          id="available_hours"
          inputProps={{
            type: "number",
            required: true,
            inputProps: { min: 0 },
          }}
        />
      </Box>
    </RiverDialog>
  );
};
