import React, { ReactElement, useContext } from "react";
import { TabProps } from "@mui/material/Tab";
import { useTranslation } from "@river/common-ui";
import {
  SupervisorScheduleContext,
  TabContext,
  TableContext,
} from "../../context";
import ProtectedAction from "../../components/protected-action";
import { useGridActions } from "../supervisor-schedule-ui-service/grid-actions";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import {
  BacklogTabId,
  IGetDefaultSupervisorScheduleOperationColumnsProps,
  IGetDefaultSupervisorScheduleWorkOrderColumnsProps,
  IOpMassUpdateDialogProps,
  IUseSupervisorScheduleCurrentTableProps,
  ScheduleAction,
  SupervisorScheduleAction,
  SupervisorScheduleTasksTabId,
  SupervisorScheduleTaskTabKeyColumnMap,
  SupervisorScheduleUiService,
  useSupervisorScheduleInstances,
  useSupervisorScheduleOperations,
  useSupervisorScheduleResources,
  useSupervisorScheduleTimecards,
  useSupervisorScheduleWorkOrders,
} from "../../services";
import {
  getWorkOrderIdFormatter,
  ReportsDropdown,
  RiverDropdownActions,
  useCompletionPercentageDialogAction,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { IWoMassUpdateDialogProps } from "../shared/shared-service.types";
import { OracleCloudWoMassUpdateDialog } from "./oracle-cloud-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useTableCellRenderers } from "../../hooks";
import { SupervisorBaselineSelector } from "../../components/supervisor-schedule/supervisor-baseline-selector";
import { getOperationIdFormatter } from "../../components/shared/formatters/operation-id-formatter";

const DEFAULT_ORACLE_CLOUD_SCHEDULE_TAB: SupervisorScheduleTasksTabId =
  SupervisorScheduleTasksTabId.WORKORDERS;

export class OracleCloudSupervisorScheduleUiService extends SupervisorScheduleUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      moveSelectedRecordsAction,
      setBreakInAction,
      resetBreakInAction,
    } = useGridActions();
    const entity_name = "workorder";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: SupervisorScheduleAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: SupervisorScheduleAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: SupervisorScheduleAction.WO_COMPLETION_PCT,
              disabled: !selectedIds,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              action: SupervisorScheduleAction.SCHEDULE,
              disabled: !selectedIds,
            },
            {
              title: setBreakInAction.title,
              startIcon: setBreakInAction.icon,
              onClick: setBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: setBreakInAction.disabled,
            },
            {
              title: resetBreakInAction.title,
              startIcon: resetBreakInAction.icon,
              onClick: resetBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: resetBreakInAction.disabled,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {moveSelectedRecordsAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      materialRequirementsAction,
      checkMaterialShortageAction,
      downloadAttachmentsAction,
    } = useGridActions();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: checkMaterialShortageAction.title,
              startIcon: checkMaterialShortageAction.icon,
              onClick: checkMaterialShortageAction.onClick,
              action: SupervisorScheduleAction.WO_MATERIAL_SHORTAGE,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: SupervisorScheduleAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {downloadAttachmentsAction.renderDialog()}
        {materialRequirementsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      opMassUpdateAction,
      assignCalendarAction,
      unassignCalendarAction,
      moveSelectedRecordsAction,
      jobPriorityScoreAction,
    } = useGridActions();
    const entity_name = "operation";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: SupervisorScheduleAction.OP_JPS,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: SupervisorScheduleAction.OP_COMPLETION_PCT,
              disabled: !selectedIds,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.SCHEDULE,
            },
            {
              title: assignCalendarAction.title,
              startIcon: assignCalendarAction.icon,
              onClick: assignCalendarAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_CALENDAR,
            },
            {
              title: unassignCalendarAction.title,
              startIcon: unassignCalendarAction.icon,
              onClick: unassignCalendarAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_CALENDAR,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {moveSelectedRecordsAction.renderDialog()}
        {assignCalendarAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const {
        renderValidationRulePopupAction,
        renderDependenciesDropdownActions,
        renderUnassignAction,
        renderCreateResourceTimecardAction,
        renderCreateInstanceTimecardAction,
      } = useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
        </>
      );
      const renderOperationActions = (): ReactElement => (
        <>
          {renderOperationEditActions()}
          {renderDependenciesDropdownActions()}
          {renderValidationRulePopupAction()}
        </>
      );
      const renderResourceActions = (): ReactElement => (
        <ProtectedAction
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          action={SupervisorScheduleAction.CREATE_RESOURCE_TIMECARD}
        >
          {renderCreateResourceTimecardAction()}
        </ProtectedAction>
      );
      const renderInstanceActions = (): ReactElement => (
        <>
          {renderUnassignAction()}
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.CREATE_INSTANCE_TIMECARD}
          >
            {renderCreateInstanceTimecardAction()}
          </ProtectedAction>
        </>
      );
      return (
        <>
          {currentTab === SupervisorScheduleTasksTabId.WORKORDERS &&
            renderWorkOrderActions()}
          {currentTab === SupervisorScheduleTasksTabId.OPERATIONS &&
            renderOperationActions()}
          {currentTab === SupervisorScheduleTasksTabId.RESOURCES &&
            renderResourceActions()}
          {currentTab === SupervisorScheduleTasksTabId.INSTANCES &&
            renderInstanceActions()}
          <ReportsDropdown />
          {currentTab !== SupervisorScheduleTasksTabId.TIMECARDS && (
            <SupervisorBaselineSelector />
          )}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.supervisor_schedule:tab.work_orders.title"),
        value: SupervisorScheduleTasksTabId.WORKORDERS,
      },
      {
        label: t("module.supervisor_schedule:tab.operations.title"),
        value: SupervisorScheduleTasksTabId.OPERATIONS,
      },
      {
        label: t("module.supervisor_schedule:tab.resources.title"),
        value: SupervisorScheduleTasksTabId.RESOURCES,
      },
      {
        label: t("module.supervisor_schedule:tab.instances.title"),
        value: SupervisorScheduleTasksTabId.INSTANCES,
      },
      {
        label: t("module.supervisor_schedule:tab.timecards.title"),
        value: SupervisorScheduleTasksTabId.TIMECARDS,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_ORACLE_CLOUD_SCHEDULE_TAB;

  useCurrentTable = (
    props: IUseSupervisorScheduleCurrentTableProps
  ): EntityTableProvider => {
    const { scheduleContext } = props;
    return () => {
      const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
      const workOrdersTable: IUseEntityTable = useSupervisorScheduleWorkOrders({
        draggable: false,
        scheduleContext,
      });
      const operationsTable: IUseEntityTable = useSupervisorScheduleOperations({
        scheduleContext,
      });
      const resourcesTable: IUseEntityTable = useSupervisorScheduleResources({
        scheduleContext,
      });
      const instancesTable: IUseEntityTable = useSupervisorScheduleInstances({
        scheduleContext,
      });
      const timecardsTable: IUseEntityTable = useSupervisorScheduleTimecards();
      if (currentTab === SupervisorScheduleTasksTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.RESOURCES) {
        return resourcesTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.INSTANCES) {
        return instancesTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.TIMECARDS) {
        return timecardsTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === SupervisorScheduleTasksTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === SupervisorScheduleTasksTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultSupervisorScheduleWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.workorder:workorder.WorkOrderNumber"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "WorkOrderDescription",
          name: t("entity.workorder:workorder.WorkOrderDescription"),
          editor: TextEditor,
        },
        {
          key: "WorkOrderTypeCode",
          name: t("entity.workorder:workorder.WorkOrderTypeCode"),
        },
        {
          key: "WorkOrderSubTypeCode",
          name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
        },
        {
          key: "AssetNumber",
          name: t("entity.workorder:workorder.AssetNumber"),
        },
        {
          key: "WorkOrderPriority",
          name: t("entity.workorder:workorder.WorkOrderPriority"),
        },
        {
          key: "WorkOrderStatusCode",
          name: t("entity.workorder:workorder.WorkOrderStatusCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.workorder:workorder.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.workorder:workorder.PlannedCompletionDate"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(
    props: IGetDefaultSupervisorScheduleOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
          formatter: getOperationIdFormatter({
            onOperationClick: props.onOperationClick,
            tableCellRenderers,
          }),
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrder.AssetNumber",
          name: t("entity.operation:operation_workorder.AssetNumber"),
        },
        {
          key: "WorkOrder.WorkOrderStatusCode",
          name: t("entity.operation:operation_workorder.WorkOrderStatusCode"),
        },
        {
          key: "WorkCenterName",
          name: t("entity.operation:operation.WorkCenterName"),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation:operation_resource.ResourceCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkCenterName",
          name: t(
            "entity.operation_resource:operation_resource.WorkCenterName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationResource.AssignedUnits",
          name: t("entity.operation_resource:operation_resource.AssignedUnits"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.LaborInstanceName",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.LaborInstanceName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
      ];
    };
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleCloudWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <></>;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.resource",
      "entity.resource_utilization",
      "entity.resource_ref",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.operation_resource",
      "entity.operation_resource_instance",
    ];
  }

  isTasksTabAssignable(): () => boolean {
    return (): boolean => {
      const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
      return currentTab === SupervisorScheduleTasksTabId.RESOURCES;
    };
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTaskTabKeyColumnMap = (): SupervisorScheduleTaskTabKeyColumnMap => {
    const woKey: string = "WorkOrderNumber";
    const opKey: string = "OperationSequenceNumber";
    const resKey: string = "WorkOrderOperationResource.ResourceSequenceNumber";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.INSTANCES]: [{ sourceField: woKey, targetField: woKey }],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.RESOURCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
      [BacklogTabId.INSTANCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
    };
  };

  getDefaultTimecardsColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "LaborInstanceName",
          name: t("entity.timecard:timecard.LaborInstanceName"),
        },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "is_confirmed",
          name: t("entity.timecard:timecard.is_confirmed"),
        },
      ];
    };
  }

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
