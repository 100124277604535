import { ReactElement, useContext } from "react";
import { IAdapterFolder } from "@river/interfaces";
import { TabProps } from "@mui/material/Tab";
import {
  BacklogAction,
  BacklogTabId,
  BacklogTabKeyColumnMap,
  BacklogUiService,
  IGetDefaultWorkOrderColumnsProps,
  IUseBacklogCurrentTableProps,
  useBacklogAssignments,
  useBacklogOperations,
  useBacklogWorkOrders,
  IWoMassUpdateDialogProps,
  IOpMassUpdateDialogProps,
  IGetDefaultBacklogOperationColumnsProps,
} from "../../services";
import { useTranslation } from "@river/common-ui";
import { BacklogContext, TabContext, TableContext } from "../../context";
import { useGridActions } from "../backlog-ui-service/grid-actions";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { JdeWoMassUpdateDialog, JdeOpMassUpdateDialog } from "./jde-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useAllowedAction } from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";
import { getOperationIdFormatter } from "../../components/shared/formatters/operation-id-formatter";

const DEFAULT_JDE_BACKLOG_TAB: BacklogTabId = BacklogTabId.WORKORDERS;

export class JdeBacklogUiService extends BacklogUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const backlogContext = useContext(BacklogContext);
    const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      removeFromScheduleAction,
    } = useGridActions();

    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: BacklogAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: BacklogAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: removeFromScheduleAction.title,
              startIcon: removeFromScheduleAction.icon,
              onClick: () => removeFromScheduleAction.onClick(),
              action: BacklogAction.SCHEDULE,
              disabled: !selectedIds || !currentSchedule,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { downloadAttachmentsAction, materialRequirementsAction } =
      useGridActions();

    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: BacklogAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      opMassUpdateAction,
      removeFromScheduleAction,
      jobPriorityScoreAction,
      renderRemoveFromScheduleAction,
    } = useGridActions();
    const backlogContext = useContext(BacklogContext);
    const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;

    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: opMassUpdateAction.title,
              startIcon: opMassUpdateAction.icon,
              onClick: opMassUpdateAction.onClick,
              action: BacklogAction.OP_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: BacklogAction.OP_JPS,
              disabled: !selectedIds,
            },
            {
              title: removeFromScheduleAction.title,
              startIcon: removeFromScheduleAction.icon,
              onClick: () => removeFromScheduleAction.onClick(),
              disabled: !selectedIds || !currentSchedule,
              action: BacklogAction.SCHEDULE,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {currentSchedule && renderRemoveFromScheduleAction()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const backlogContext = useContext(BacklogContext);
      const currentTab = tabContext?.selectedTab;
      const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
      const { renderRemoveFromScheduleAction, renderUnassignAction } =
        useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          {currentSchedule && renderRemoveFromScheduleAction()}
        </>
      );
      const renderOperationActions = (): ReactElement =>
        renderOperationEditActions();
      const renderAssignmentsActions = (): ReactElement =>
        renderUnassignAction();

      return (
        <>
          {currentTab === BacklogTabId.WORKORDERS && renderWorkOrderActions()}
          {currentTab === BacklogTabId.OPERATIONS && renderOperationActions()}
          {currentTab === BacklogTabId.ASSIGNMENTS &&
            renderAssignmentsActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.backlog:tab.work_orders.title"),
        /*icon: <DynamicFeedIcon />,*/
        value: BacklogTabId.WORKORDERS,
      },
      {
        label: t("module.backlog:tab.operations.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.OPERATIONS,
      },
      {
        label: t("module.backlog:tab.assignments.title"),
        /*icon: <LibraryBooksIcon />,*/
        value: BacklogTabId.ASSIGNMENTS,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_JDE_BACKLOG_TAB;

  useCurrentTable = (
    props: IUseBacklogCurrentTableProps
  ): EntityTableProvider => {
    const { backlogContext } = props;
    return () => {
      const currentTab = this.getCurrentTab()();
      const isActionAllowed = useAllowedAction();
      const workOrdersTable: IUseEntityTable = useBacklogWorkOrders({
        backlogContext,
        draggable: isActionAllowed(ModuleKey.BACKLOG, BacklogAction.SCHEDULE),
      });
      const operationsTable: IUseEntityTable = useBacklogOperations({
        backlogContext,
      });
      const assignmentsTable: IUseEntityTable = useBacklogAssignments({
        backlogContext,
      });
      if (currentTab === BacklogTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === BacklogTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === BacklogTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: string = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === BacklogTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === BacklogTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const tableCellRenderers = useTableCellRenderers();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "F4801_DOCO",
          name: t("entity.workorder:workorder.F4801_DOCO"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "F4801_DL01",
          name: t("entity.workorder:workorder.F4801_DL01"),
          editor: TextEditor,
        },
        {
          key: "F4801_DCTO",
          name: t("entity.workorder:workorder.F4801_DCTO"),
        },
        {
          key: "F4801_MMCU",
          name: t("entity.workorder:workorder.F4801_MMCU"),
        },
        {
          key: "F4801_NUMB",
          name: t("entity.workorder:workorder.F4801_NUMB"),
        },
        {
          key: "F4801_NUMB_desc",
          name: t("entity.workorder:workorder.F4801_NUMB_desc"),
        },
        {
          key: "F4801_HRSO",
          name: t("entity.workorder:workorder.F4801_HRSO"),
        },
        {
          key: "F4801_PRTS",
          name: t("entity.workorder:workorder.F4801_PRTS"),
          editor: TextEditor,
        },
        {
          key: "F4801_SRST",
          name: t("entity.workorder:workorder.F4801_SRST"),
        },
        {
          key: "F4801_STRT",
          name: t("entity.workorder:workorder.F4801_STRT"),
          width: 160,
        },
        {
          key: "F4801_DRQJ",
          name: t("entity.workorder:workorder.F4801_DRQJ"),
          width: 160,
        },
        {
          key: "__folder.folder",
          name: t("entity.folder:folder.folder"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(
    prop: IGetDefaultBacklogOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "F3112_DOCO",
          name: t("entity.operation:operation.F3112_DOCO"),
          width: 105,
          formatter: getOperationIdFormatter({
            onOperationClick: prop.onOperationClick,
            draggable: true,
            tableCellRenderers,
          }),
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.operation:operation.F3112_OPSQ"),
        },
        {
          key: "F3112_DSC1",
          name: t("entity.operation:operation.F3112_DSC1"),
        },
        {
          key: "F3112_MCU",
          name: t("entity.operation:operation.F3112_MCU"),
        },
        {
          key: "F3112_RUNL",
          name: t("entity.operation:operation.F3112_RUNL"),
        },
        {
          key: "F3112_STRT",
          name: t("entity.operation:operation.F3112_STRT"),
        },
        {
          key: "F3112_DRQJ",
          name: t("entity.operation:operation.F3112_DRQJ"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => [];
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => [];
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <JdeWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <JdeOpMassUpdateDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "shared.op_mass_update_dialog",
      "entity.company",
      "entity.assignment",
      "entity.user_defined_code",
      "entity.address_book",
    ];
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTabKeyColumnMap = (): BacklogTabKeyColumnMap => {
    const woWoKey: string = "F4801_DOCO";
    const opWoKey: string = "F3112_DOCO";
    const opSequenceNumber: string = "F3112_OPSQ";
    const opType: string = "F3112_OPSC";
    const opWorkCenter: string = "F3112_MCU";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woWoKey, targetField: opWoKey },
        ],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woWoKey, targetField: opWoKey },
        ],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [
          { sourceField: opWoKey, targetField: woWoKey },
        ],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: opWoKey, targetField: opWoKey },
          { sourceField: opSequenceNumber, targetField: opSequenceNumber },
          { sourceField: opType, targetField: opType },
          { sourceField: opWorkCenter, targetField: opWorkCenter },
        ],
      },
      [BacklogTabId.ASSIGNMENTS]: {
        [BacklogTabId.WORKORDERS]: [
          { sourceField: opWoKey, targetField: woWoKey },
        ],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: opWoKey, targetField: opWoKey },
          { sourceField: opSequenceNumber, targetField: opSequenceNumber },
          { sourceField: opType, targetField: opType },
          { sourceField: opWorkCenter, targetField: opWorkCenter },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "F3112_DOCO",
          name: t("entity.operation:operation.F3112_DOCO"),
          width: 105,
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.operation:operation.F3112_OPSQ"),
        },
        {
          key: "F3112_DSC1",
          name: t("entity.operation:operation.F3112_DSC1"),
          width: 210,
        },
        {
          key: "assignments.F30006_MCU",
          name: t("entity.assignment:assignment.F30006_MCU"),
        },
        {
          key: "assignments.F0101_AN8",
          name: t("entity.assignment:assignment.F0101_AN8"),
        },
        {
          key: "assignments.F0101_ALPH",
          name: t("entity.assignment:assignment.F0101_ALPH"),
        },
        {
          key: "assignments.F3112_RUNL",
          name: t("entity.assignment:assignment.F3112_RUNL"),
        },
        {
          key: "assignments.F3112_STRT",
          name: t("entity.assignment:assignment.F3112_STRT"),
        },
        {
          key: "assignments.F3112_DRQJ",
          name: t("entity.assignment:assignment.F3112_DRQJ"),
        },
      ];
    };
  }
}
