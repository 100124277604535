import React, { ReactElement, useRef, useState, useEffect } from "react";
import { Button, Popover, PopoverProps, PopoverOrigin } from "@mui/material";
import ArrowDropDownUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import styles from "./river-custom-popup.module.scss";
import clsx from "clsx";

interface IRiverCustomPopupProps {
  toggleLabel?: string;
  toggle?: React.ComponentType<any>;
  content: ReactElement;
  className?: string;
  classes?: {
    toggle?: string;
    paper?: string;
  };
  onClose?: () => void;
  onOpen?: () => void;
  popoverProps?: Partial<PopoverProps>;
}

const DEFAULT_POPOVER_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const DEFAULT_VERTICAL_TRANSFORM_OFFSET: number = -5;

const DEFAULT_POPOVER_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: DEFAULT_VERTICAL_TRANSFORM_OFFSET,
  horizontal: "right",
};

export const useRiverCustomPopup = (props: IRiverCustomPopupProps) => {
  const [opened, setOpened] = useState<boolean>(false);
  const popupAnchor = useRef<HTMLDivElement | null>(null);
  const anchorOrigin: PopoverOrigin =
    props.popoverProps?.anchorOrigin ?? DEFAULT_POPOVER_ANCHOR_ORIGIN;
  const transformOrigin: PopoverOrigin =
    props.popoverProps?.transformOrigin ?? DEFAULT_POPOVER_TRANSFORM_ORIGIN;

  const onToggleClick = (): void => openPopup();

  const openPopup = (): void => setOpened(true);
  const closePopup = (): void => setOpened(false);

  useEffect(() => {
    if (!opened) {
      props.onClose?.();
    } else {
      props.onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const CustomToggle = props.toggle!;

  const DefaultToggle = (): ReactElement => {
    const DropdownIcon = opened ? <ArrowDropDownUp /> : <ArrowDropDown />;
    return (
      <Button
        className={clsx([styles.defaultToggle, props.classes?.toggle])}
        variant={"outlined"}
        endIcon={DropdownIcon}
      >
        {props.toggleLabel}
      </Button>
    );
  };

  const renderToggle = (): ReactElement => (
    <div ref={popupAnchor} onClick={onToggleClick}>
      {props.toggle && <CustomToggle />}
      {!props.toggle && <DefaultToggle />}
    </div>
  );

  const renderPopup = (): ReactElement => {
    return (
      <Popover
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        anchorPosition={{
          top: 15,
          left: 0,
        }}
        open={opened}
        anchorEl={popupAnchor.current}
        onClose={closePopup}
        classes={{
          paper: clsx([styles.paper, props.classes?.paper]),
        }}
      >
        {props.content}
      </Popover>
    );
  };

  const render = (): ReactElement => (
    <div className={props.className}>
      {renderToggle()}
      {renderPopup()}
    </div>
  );

  return {
    render,
    closePopup,
  };
};

export type IUseRiverCustomPopup = ReturnType<typeof useRiverCustomPopup>;
