import {
  useNotification,
  useSimpleDialog,
  useTranslation,
} from "@river/common-ui";
import React, { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import {
  DeleteAction,
  FilterDropdown,
  GridActions,
  GridHeader,
  IUseTable,
} from "../../shared";
import { IAdapterRestriction } from "@river/interfaces";
import { uiConstants } from "../../../helpers";
import { RestrictionsAction } from "../restrictions";
import { ModuleKey } from "../../sidebar-menu/use-sidebar-menu-def";
import ProtectedAction from "../../protected-action";

export const RestrictionsGridHeader: React.FC = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const selectedRestriction: IAdapterRestriction = table.entities.filter(
    (entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
  )[0] as unknown as IAdapterRestriction;

  const deleteSelectedRestriction = async (): Promise<any> => {
    if (selectedRestriction) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteRestriction(selectedRestriction._id);
        tableContext?.table.fetch();
        deleteRestrictionConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteRestrictionConfirmation = useSimpleDialog({
    title: t("module.restrictions:dialog.delete_restriction"),
    message: t("module.restrictions:message.confirm_restriction_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedRestriction,
  });
  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.RESTRICTIONS}
            action={RestrictionsAction.DELETE}
          >
            <DeleteAction
              onClick={() => deleteRestrictionConfirmation.open()}
            />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteRestrictionConfirmation.render()}
    </>
  );
};
