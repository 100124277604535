import { useContext, ReactElement } from "react";
import { TabProps } from "@mui/material/Tab";
import {
  ScheduleUiService,
  IGetDefaultScheduleWorkOrderColumnsProps,
  ScheduleTasksTabId,
  useScheduleWorkOrders,
  useScheduleOperations,
  useScheduleAssignments,
  ScheduleAction,
  IUseScheduleCurrentTableProps,
  ScheduleTaskTabKeyColumnMap,
  BacklogTabId,
  IOpMassUpdateDialogProps,
  IGetDefaultScheduleOperationColumnsProps,
} from "../../services";
import { useTranslation } from "@river/common-ui";
import { ScheduleContext, TabContext, TableContext } from "../../context";
import { useGridActions } from "../schedule-ui-service/grid-actions";
import {
  TabsProvider,
  EntityTableProvider,
  ColumnsProvider,
  IUseEntityTable,
  ReactElementProvider,
} from "../ui-service.types";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useCompletionPercentageDialogAction,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { IWoMassUpdateDialogProps } from "../shared";
import { JdeOpMassUpdateDialog, JdeWoMassUpdateDialog } from "./jde-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import ProtectedAction from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";
import { getOperationIdFormatter } from "../../components/shared/formatters/operation-id-formatter";

const DEFAULT_JDE_SCHEDULE_TAB: ScheduleTasksTabId =
  ScheduleTasksTabId.WORKORDERS;

export class JdeScheduleUiService extends ScheduleUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(ScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      moveSelectedRecordsAction,
      setBreakInAction,
      resetBreakInAction,
    } = useGridActions();
    const entity_name = "workorder";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: ScheduleAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: ScheduleAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: ScheduleAction.WO_COMPLETION_PCT,
              disabled: !selectedIds,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              action: ScheduleAction.SCHEDULE,
              disabled: !selectedIds,
            },
            {
              title: setBreakInAction.title,
              startIcon: setBreakInAction.icon,
              onClick: setBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: setBreakInAction.disabled,
            },
            {
              title: resetBreakInAction.title,
              startIcon: resetBreakInAction.icon,
              onClick: resetBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: resetBreakInAction.disabled,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {moveSelectedRecordsAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { materialRequirementsAction, downloadAttachmentsAction } =
      useGridActions();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: ScheduleAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(ScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      opMassUpdateAction,
      assignCalendarAction,
      unassignCalendarAction,
      moveSelectedRecordsAction,
      jobPriorityScoreAction,
    } = useGridActions();
    const entity_name = "operation";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: opMassUpdateAction.title,
              startIcon: opMassUpdateAction.icon,
              onClick: opMassUpdateAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_EDIT,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: ScheduleAction.OP_JPS,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_COMPLETION_PCT,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.SCHEDULE,
            },
            {
              title: assignCalendarAction.title,
              startIcon: assignCalendarAction.icon,
              onClick: assignCalendarAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_CALENDAR,
            },
            {
              title: unassignCalendarAction.title,
              startIcon: unassignCalendarAction.icon,
              onClick: unassignCalendarAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_CALENDAR,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {moveSelectedRecordsAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {assignCalendarAction.renderDialog()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const {
        renderUnassignAction,
        renderValidationRulePopupAction,
        renderDependenciesDropdownActions,
      } = useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={ScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
        </>
      );
      const renderOperationActions = (): ReactElement => (
        <>
          {renderOperationEditActions()}
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={ScheduleAction.SCHEDULE}
          >
            {renderDependenciesDropdownActions()}
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={ScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
        </>
      );
      const renderAssignmentActions = (): ReactElement =>
        renderUnassignAction();

      return (
        <>
          {currentTab === ScheduleTasksTabId.WORKORDERS &&
            renderWorkOrderActions()}
          {currentTab === ScheduleTasksTabId.OPERATIONS &&
            renderOperationActions()}
          {currentTab === ScheduleTasksTabId.ASSIGNMENTS &&
            renderAssignmentActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.schedule:tab.work_orders.title"),
        value: ScheduleTasksTabId.WORKORDERS,
      },
      {
        label: t("module.schedule:tab.operations.title"),
        value: ScheduleTasksTabId.OPERATIONS,
      },
      {
        label: t("module.schedule:tab.assignments.title"),
        value: ScheduleTasksTabId.ASSIGNMENTS,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_JDE_SCHEDULE_TAB;

  useCurrentTable = (
    props: IUseScheduleCurrentTableProps
  ): EntityTableProvider => {
    const { scheduleContext } = props;
    return () => {
      const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
      const workOrdersTable: IUseEntityTable = useScheduleWorkOrders({
        draggable: false,
        scheduleContext,
      });
      const operationsTable: IUseEntityTable = useScheduleOperations({
        scheduleContext,
      });
      const assignmentsTable: IUseEntityTable = useScheduleAssignments({
        scheduleContext,
      });
      if (currentTab === ScheduleTasksTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === ScheduleTasksTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === ScheduleTasksTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === ScheduleTasksTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === ScheduleTasksTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultScheduleWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "F4801_DOCO",
          name: t("entity.workorder:workorder.F4801_DOCO"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "F4801_DL01",
          name: t("entity.workorder:workorder.F4801_DL01"),
          editor: TextEditor,
        },
        {
          key: "F4801_DCTO",
          name: t("entity.workorder:workorder.F4801_DCTO"),
        },
        {
          key: "F4801_MMCU",
          name: t("entity.workorder:workorder.F4801_MMCU"),
        },
        {
          key: "F4801_NUMB",
          name: t("entity.workorder:workorder.F4801_NUMB"),
        },
        {
          key: "F4801_NUMB_desc",
          name: t("entity.workorder:workorder.F4801_NUMB_desc"),
        },
        {
          key: "F4801_HRSO",
          name: t("entity.workorder:workorder.F4801_HRSO"),
        },
        {
          key: "F4801_PRTS",
          name: t("entity.workorder:workorder.F4801_PRTS"),
          editor: TextEditor,
        },
        {
          key: "F4801_SRST",
          name: t("entity.workorder:workorder.F4801_SRST"),
        },
        {
          key: "__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(
    props: IGetDefaultScheduleOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "F3112_DOCO",
          name: t("entity.operation:operation.F3112_DOCO"),
          width: 105,
          formatter: getOperationIdFormatter({
            onOperationClick: props.onOperationClick,
            tableCellRenderers,
          }),
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "F3112_DSC1",
          name: t("entity.operation:operation.F3112_DSC1"),
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.operation:operation.F3112_OPSQ"),
        },
        {
          key: "F3112_MCU",
          name: t("entity.operation:operation.F3112_MCU"),
        },
        {
          key: "F3112_RUNL",
          name: t("entity.operation:operation.F3112_RUNL"),
        },
        {
          key: "F3112_SETC",
          name: t("entity.operation:operation.F3112_SETC"),
        },
        {
          key: "__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => [];
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => [];
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <JdeWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <JdeOpMassUpdateDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.company",
      "entity.assignment",
      "entity.workcenter_utilization",
      "entity.user_defined_code",
      "entity.address_book",
      "entity.wo_part",
    ];
  }

  isTasksTabAssignable(): () => boolean {
    return (): boolean => {
      const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
      return currentTab === ScheduleTasksTabId.OPERATIONS;
    };
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTaskTabKeyColumnMap = (): ScheduleTaskTabKeyColumnMap => {
    const woWoKey: string = "F4801_DOCO";
    const opWoKey: string = "F3112_DOCO";
    const opSequenceNumber: string = "F3112_OPSQ";
    const opType: string = "F3112_OPSC";
    const opWorkCenter: string = "F3112_MCU";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woWoKey, targetField: opWoKey },
        ],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woWoKey, targetField: opWoKey },
        ],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [
          { sourceField: opWoKey, targetField: woWoKey },
        ],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: opWoKey, targetField: opWoKey },
          { sourceField: opSequenceNumber, targetField: opSequenceNumber },
          { sourceField: opType, targetField: opType },
          { sourceField: opWorkCenter, targetField: opWorkCenter },
        ],
      },
      [BacklogTabId.ASSIGNMENTS]: {
        [BacklogTabId.WORKORDERS]: [
          { sourceField: opWoKey, targetField: woWoKey },
        ],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: opWoKey, targetField: opWoKey },
          { sourceField: opSequenceNumber, targetField: opSequenceNumber },
          { sourceField: opType, targetField: opType },
          { sourceField: opWorkCenter, targetField: opWorkCenter },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "F3112_DOCO",
          name: t("entity.operation:operation.F3112_DOCO"),
          width: 105,
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.operation:operation.F3112_OPSQ"),
        },
        {
          key: "F3112_DSC1",
          name: t("entity.operation:operation.F3112_DSC1"),
          width: 210,
        },
        {
          key: "assignments.F30006_MCU",
          name: t("entity.assignment:assignment.F30006_MCU"),
        },
        {
          key: "assignments.F0101_AN8",
          name: t("entity.assignment:assignment.F0101_AN8"),
        },
        {
          key: "assignments.F0101_ALPH",
          name: t("entity.assignment:assignment.F0101_ALPH"),
        },
        {
          key: "assignments.F3112_RUNL",
          name: t("entity.assignment:assignment.F3112_RUNL"),
        },
        {
          key: "assignments.__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "assignments.__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
      ];
    };
  }
}
