import { FC, ReactElement, useCallback, useContext } from "react";
import {
  RiverDateInput,
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
} from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { ITimeCardDialogProps } from "../../../supervisor-timecard-ui.service";
import { useJdeSupervisorOperationTimecardForm } from "./use-jde-supervisor-operation-timecard-form";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import {
  IEntityObject,
  OracleEbsAdapterTimeCardDto,
  JdeAdapterTimeCardDto,
} from "@river/interfaces";
import { debounce } from "ts-debounce";
import { LookupType, RiverLookup } from "../../../../components/shared";
import styles from "./jde-supervisor-operation-timecard-dialog.module.scss";

export const JdeSupervisorOperationTimeCardDialog: FC<ITimeCardDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const { form } = useJdeSupervisorOperationTimecardForm({
    folderId: props.folderId,
    operation: props.operation,
    onCreate: () => {
      props.onCreate();
      closeAndRefresh();
    },
  });

  const {
    onStandalonePropertyChange,
    setStandaloneFields,
    getStandaloneValidatorInstance,
    validateStandaloneField,
    setDto,
    submit,
    resetForm,
    isProcessing,
  } = form;

  const timecardDto: OracleEbsAdapterTimeCardDto =
    form.dto as OracleEbsAdapterTimeCardDto;

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const fetchAddressByNumber = async (
    F0101_AN8Param: string
  ): Promise<void> => {
    const resource: IEntityObject = await fetchAddressByAttribute(
      "F0101_AN8",
      F0101_AN8Param
    );

    const F0101_AN8: string = (resource?.F0101_AN8 as string) || "";
    const F0101_ALPH: string = (resource?.F0101_ALPH as string) || "";
    setDto(
      Object.assign(new JdeAdapterTimeCardDto(), {
        ...timecardDto,
        F0101_AN8,
        F0101_ALPH,
      })
    );
    validateStandaloneField("F0101_AN8", F0101_AN8);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchAddressByNumber = useCallback(
    debounce((text) => {
      fetchAddressByNumber(text);
    }, 600),
    [timecardDto]
  );

  const fetchAddressByAttribute = async (
    attribute_name: string,
    attribute_value: string
  ): Promise<IEntityObject> => {
    const resources: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .fetchCraftPeople({
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return resources[0] as IEntityObject;
  };

  const renderTimeCardDateField = (): ReactElement => (
    <RiverDateInput id="timecard_date" className={styles.dateField} />
  );

  const renderTimeCardTimeField = (): ReactElement => (
    <RiverTextInput
      id="timecard_hours"
      className={styles.hoursField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderTimeCardTimeFields = (): ReactElement => (
    <div className={styles.timeFields}>
      {renderTimeCardDateField()}
      {renderTimeCardTimeField()}
    </div>
  );

  const renderAddressLookup = (): ReactElement => (
    <RiverLookup
      id="F0101_AN8"
      lookup={{ type: LookupType.PERSONS }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchAddressByNumber(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        const resource: IEntityObject = selectedObject as IEntityObject;
        const { F0101_AN8, F0101_ALPH } = resource;
        setDto(
          Object.assign(new JdeAdapterTimeCardDto(), {
            ...timecardDto,
            F0101_AN8,
            F0101_ALPH,
          })
        );
        setStandaloneFields(
          getStandaloneValidatorInstance!({
            F0101_AN8,
          })
        );
        validateStandaloneField("F0101_AN8", F0101_AN8);
      }}
      className={styles.field}
    />
  );

  const renderAddressNameField = (): ReactElement => (
    <RiverTextInput id="F0101_ALPH" fullWidth disabled={true} />
  );

  const renderContent = (): ReactElement => (
    <>
      {renderAddressLookup()}
      {renderAddressNameField()}
      {renderTimeCardTimeFields()}
    </>
  );

  return (
    <RiverDialog
      title={t("common.label:timecard")}
      open={props.open}
      onClose={close}
      actionButtonText={t("common.button:create")}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderContent()}
    </RiverDialog>
  );
};
