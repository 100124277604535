import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
} from "react";
import {
  RiverSpinner,
  RiverDialog,
  RiverDialogButton,
  RiverDialogActionButton,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { BlankIcon } from "../../../icons";
import {
  IWorkPackage,
  IWorkPackageFile,
  IWorkPackageProgressSummary,
} from "@river/interfaces";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DownloadIcon } from "../../../icons";
import { DownloadProgressBar } from "./download-progress-bar";

import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { AdapterService } from "../../../services";
import { Constants } from "@river/constants";

import styles from "./attachment-download.module.scss";
import clsx from "clsx";

enum DownloadView {
  PROGRESS = "progress",
  LOG = "log",
}

interface IAttachmentDownloadProps {
  onComplete: () => void;
  workPackage: IWorkPackage;
}

type ProgressSummary = {
  downloadPending: number;
  downloadComplete: number;
  conversionPending: number;
  conversionComplete: number;
  attached: number;
  error: number;
  processed: number;
  total: number;
  workPackageStatus: string;
};

interface ISummaryInfo {
  name: string;
  className: string;
  icon?: React.ComponentType<any>;
}
interface ISummaryItem extends ISummaryInfo {
  id: string;
}

export const AttachmentDownload: React.FC<IAttachmentDownloadProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();

  const summaryInfoMap: { [id: string]: ISummaryInfo } = {
    [Constants.work_package_file_status.download_pending]: {
      name: t("shared.attachment_download:summary.download_pending"),
      className: styles.downloadPending,
    },
    [Constants.work_package_file_status.download_complete]: {
      name: t("shared.attachment_download:summary.download_complete"),
      className: styles.downloadComplete,
    },
    [Constants.work_package_file_status.conversion_pending]: {
      name: t("shared.attachment_download:summary.conversion_pending"),
      className: styles.conversionPending,
    },
    [Constants.work_package_file_status.conversion_complete]: {
      name: t("shared.attachment_download:summary.conversion_complete"),
      className: styles.conversionComplete,
      icon: CheckIcon,
    },
    [Constants.work_package_file_status.attached]: {
      name: t("shared.attachment_download:summary.attached"),
      className: styles.attached,
      icon: CheckIcon,
    },
    [Constants.work_package_file_status.error]: {
      name: t("shared.attachment_download:summary.error"),
      className: styles.error,
      icon: WarningAmberIcon,
    },
  };
  const summaryItemsMap: { [id: string]: ISummaryItem } = Object.keys(
    summaryInfoMap
  ).reduce((itemsMap: { [id: string]: ISummaryItem }, key: string) => {
    itemsMap[key] = {
      ...summaryInfoMap[key],
      id: key,
    };
    return itemsMap;
  }, {});

  const viewCssClass: { [view in DownloadView]: string } = {
    [DownloadView.LOG]: styles.logView,
    [DownloadView.PROGRESS]: styles.progressView,
  };

  const [open, setOpen] = useState<boolean>(true);
  const [view, setView] = useState<DownloadView>(DownloadView.PROGRESS);
  const [processingFinishedWithErrors, setProcessingFinishedWithErrors] =
    useState<boolean>(false);
  const [workPackage, setWorkPackage] = useState<IWorkPackage>(
    props.workPackage
  );
  const [spinnerOn, setSpinnerOn] = useState<boolean>(false);
  const intervalRef = useRef<number>();
  const notify = useNotification();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;

  useEffect(() => {
    intervalRef.current = window.setInterval(() => refreshWorkPackage(), 1000);
    return () => {
      window.clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshWorkPackage = async () => {
    try {
      let wp = await adapterService.getWorkPackageStatus(workPackage._id!);
      if (wp.status?.code === "complete") {
        window.clearInterval(intervalRef.current);
        setTimeout(async () => {
          try {
            wp = await adapterService.getWorkPackageStatus(
              workPackage._id!,
              true // get files info
            );
            setWorkPackage(wp);
            const hasFileErrors: boolean = !!wp.files?.some(
              (file) => file.status?.code === "error"
            );
            setProcessingFinishedWithErrors(hasFileErrors);
            if (!hasFileErrors) {
              await downloadWorkPackage();
            }
          } catch (message) {
            notify.error({ message });
          }
        }, 600);
      } else {
        setWorkPackage(wp);
      }
    } catch (message) {
      notify.error({ message });
      props.onComplete();
    }
  };

  const downloadWorkPackage = async (): Promise<void> => {
    try {
      setSpinnerOn(true);
      await adapterContext!.service
        .getAdapterService()
        .downloadWorkPackage(workPackage!._id!, workPackage!.result_file_name!);
      setOpen(false);
      props.onComplete();
    } catch (message) {
      notify.error({ message });
    } finally {
      setSpinnerOn(false);
    }
  };

  const header: ReactElement = useMemo(
    (): ReactElement => {
      const title: string =
        view === DownloadView.PROGRESS
          ? t("shared.attachment_download:title")
          : t("shared.attachment_download:title.log_view");
      const processingFinished: boolean =
        workPackage.status?.code === "complete";
      return (
        <div className={styles.header}>
          <div className={styles.iconSection}>
            <DownloadIcon className={styles.icon} />
          </div>
          <div className={styles.titleSection}>
            <span className={styles.title}>{title}</span>
            {!processingFinished && (
              <span className={styles.subtitle}>
                {t("shared.attachment_download:subtitle")}
              </span>
            )}
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view, workPackage]
  );

  const getProgressSummary = (): ProgressSummary => {
    const summary: IWorkPackageProgressSummary = workPackage.progress_summary!;
    const downloadPending: number =
      summary[Constants.work_package_file_status.download_pending] || 0;
    const downloadComplete: number =
      summary[Constants.work_package_file_status.download_complete] || 0;
    const conversionPending: number =
      summary[Constants.work_package_file_status.conversion_pending] || 0;
    const conversionComplete: number =
      summary[Constants.work_package_file_status.conversion_complete] || 0;
    const attached: number =
      summary[Constants.work_package_file_status.attached] || 0;
    const error: number =
      summary[Constants.work_package_file_status.error] || 0;
    const processed: number = conversionComplete + attached + error;
    const total: number = summary.number_of_files;

    return {
      downloadPending,
      downloadComplete,
      conversionPending,
      conversionComplete,
      attached,
      error,
      processed,
      total,
      workPackageStatus: workPackage.status!.code,
    };
  };

  const renderProgressSummaryItem = (item: ISummaryItem): ReactElement => {
    const { id, name, icon, className: statusClass } = item;
    const num: number = workPackage.progress_summary![id];
    const Icon = (!!num && icon) || BlankIcon;
    return (
      <>
        <div className={clsx([styles.name, statusClass])}>{name}</div>
        <div className={styles.value}>{num}</div>
        <div /> {/* grid column placeholder */}
        <Icon className={clsx([styles.icon, statusClass])} />
      </>
    );
  };

  const renderLogSummaryItem = (item: ISummaryItem): ReactElement => {
    const { id, name, className } = item;
    const num: number = workPackage.progress_summary![id];
    return (
      <>
        <div className={clsx([styles.logSummaryItem, className])}>
          <div className={styles.name}>{name}</div>
          <div className={styles.text}>
            {t("shared.attachment_download:num_of_attachments", { num })}
          </div>
        </div>
      </>
    );
  };

  const renderSummaryItemFiles = (files: IWorkPackageFile[]): ReactElement => (
    <div className={styles.summaryItemFiles}>
      {files.map((file) => (
        <div className={styles.file}>
          <span className={styles.label}>
            {t("shared.attachment_download:log_view.file_name")}
          </span>
          <span className={styles.fileName}>{file.file_name}</span>
          {file.status_message && (
            <>
              <span className={styles.label}>
                {t("shared.attachment_download:log_view.file_message")}
              </span>
              <span className={styles.statusMessage}>
                {file.status_message}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  );
  const renderProgressSummary = (): ReactElement => {
    const statusesToRender: string[] = [
      Constants.work_package_file_status.download_pending,
      Constants.work_package_file_status.conversion_pending,
      Constants.work_package_file_status.conversion_complete,
      Constants.work_package_file_status.attached,
      Constants.work_package_file_status.error,
    ];
    return (
      <div className={styles.progressSummary}>
        {statusesToRender.map((status) =>
          renderProgressSummaryItem(summaryItemsMap[status])
        )}
      </div>
    );
  };

  const renderWorkPackageStatus = (): ReactElement => {
    const statusMap: { [workPackageStatus: string]: string } = {
      [Constants.work_package_status.pending]: t(
        "shared.attachment_download:work_package_status.pending"
      ),
      [Constants.work_package_status.merging]: t(
        "shared.attachment_download:work_package_status.merging"
      ),
    };
    const status: string = workPackage.status?.code || "";
    const label: string = statusMap[status] || "";
    return (
      <span className={clsx([styles.workPackageStatus])}>
        <span>{label}</span>
      </span>
    );
  };

  const renderProgressView = (): ReactElement => {
    const { error, processed, total } = getProgressSummary();

    return (
      <div className={styles.progressView}>
        <DownloadProgressBar
          processed={processed}
          total={total}
          text={t("shared.attachment_download:processed_of_total", {
            processed,
            total,
          })}
          className={styles.progressBar}
          isError={!!error}
        />
        {renderProgressSummary()}
        {renderWorkPackageStatus()}
      </div>
    );
  };

  const renderLogView = (): ReactElement => {
    const statusesToRender: string[] = [
      Constants.work_package_file_status.error,
      Constants.work_package_file_status.attached,
      Constants.work_package_file_status.conversion_complete,
      Constants.work_package_file_status.download_pending,
      Constants.work_package_file_status.conversion_pending,
    ];
    return (
      <div className={styles.logView}>
        {statusesToRender.map((status) => {
          const statusFiles = workPackage.files!.filter(
            (file) => file.status!.code === status
          );
          const summaryItem: ISummaryItem = summaryItemsMap[status];
          return (
            <>
              {!!statusFiles.length && (
                <div
                  className={clsx([styles.summaryGroup, summaryItem.className])}
                >
                  <Accordion className={styles.accordionRoot}>
                    <AccordionSummary
                      expandIcon={<ArrowRightIcon />}
                      className={styles.accordionSummary}
                    >
                      {renderLogSummaryItem(summaryItem)}
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordionDetails}>
                      {renderSummaryItemFiles(statusFiles)}
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const renderContent = (): ReactElement => (
    <div className={styles.content}>
      <RiverSpinner show={spinnerOn} />
      {view === DownloadView.PROGRESS ? renderProgressView() : renderLogView()}
    </div>
  );

  const onCancel = (): void => {
    setOpen(false);
    props.onComplete();
  };

  const onViewLog = (): void => {
    setView(DownloadView.LOG);
  };

  const onViewProgress = (): void => {
    setView(DownloadView.PROGRESS);
  };

  const renderViewToggle = (): ReactElement => {
    const renderViewLogButton = () => (
      <Button
        className={styles.viewToggleButton}
        onClick={onViewLog}
        variant={"text"}
      >
        {t("shared.attachment_download:button.view_log")}
      </Button>
    );
    const renderViewProgressButton = () => (
      <Button
        className={styles.viewToggleButton}
        onClick={onViewProgress}
        variant={"text"}
      >
        {t("shared.attachment_download:button.go_back")}
      </Button>
    );
    return (
      <>
        {view === DownloadView.PROGRESS
          ? renderViewLogButton()
          : renderViewProgressButton()}
      </>
    );
  };

  const onClose = () => {
    setOpen(false);
  };

  const onDownload = async (): Promise<void> => {
    await downloadWorkPackage();
  };

  return (
    <RiverDialog
      open={open}
      onClose={onClose}
      preventCloseOnEscape={true}
      titleContent={header}
      showTitleDivider={true}
      showXClose={false}
      classes={{
        paper: clsx([styles.paper, viewCssClass[view]]),
        title: styles.dialogTitle,
        content: styles.dialogContent,
      }}
      actionsContent={
        <>
          {processingFinishedWithErrors && renderViewToggle()}
          {view === DownloadView.PROGRESS && (
            <>
              <RiverDialogButton
                onClick={onCancel}
                text={t("common.button:cancel")}
              />
              <RiverDialogActionButton
                disabled={!processingFinishedWithErrors}
                onClick={onDownload}
                text={t("common.button:download")}
              />
            </>
          )}
        </>
      }
    >
      {renderContent()}
    </RiverDialog>
  );
};
