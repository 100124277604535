import React, { useContext, useState } from "react";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { CalendarsAction } from "../calendars";
import { CalendarDialog } from "../calendar-dialog";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { IAdapterShift } from "@river/interfaces";
import { PrimaryButton } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { CalendarsUiService } from "../../../services";

interface ICalendarsSubHeaderProps {
  shifts: IAdapterShift[];
}

export const CalendarsSubHeader: React.FC<ICalendarsSubHeaderProps> = (
  props
) => {
  const { t } = useTranslation();

  const [calendarDialogOpened, setCalendarDialogOpened] =
    useState<boolean>(false);
  const tableContext = useContext(TableContext);

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CalendarsUiService =
    adapterContext?.service!.getCalendarsUiService()!;

  const openDialog = (): void => {
    setCalendarDialogOpened(true);
  };
  const closeDialog = (): void => setCalendarDialogOpened(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.CALENDARS}
          action={CalendarsAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            disabled={!uiService.canManageCalendars()}
            text={t("module.calendars:button.add_calendar")}
          />
        </ProtectedAction>
      </div>
      {calendarDialogOpened && (
        <CalendarDialog
          open={calendarDialogOpened}
          calendar={null}
          shifts={props.shifts}
          onClose={(success) => {
            closeDialog();
            if (success) tableContext?.table.fetch();
          }}
        />
      )}
    </>
  );
};
