import React, { useState, useEffect, useContext, ReactElement } from "react";
import { IAdapterFolder, QueryDto } from "@river/interfaces";
import {
  userPreferencesService,
  TableUiService,
  BacklogTabId,
} from "../../../services";
import { Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  ScheduleItem,
  ScheduleItemColor,
  randomScheduleItemColors,
} from "./schedule-item";
import { CustomizeSchedulesDialog } from "./customize-schedules-dialog";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  BacklogContext,
  IAdapterUiContextState,
  IAdapterUserContext,
  AdapterUiContext,
  TableContext,
  IUserContextSite,
} from "../../../context";
import { useNotification, INotificationMessage } from "@river/common-ui";
import { fetchHelpers } from "../../../helpers";
import { useTranslation } from "@river/common-ui";
import { IUseTable, useAsyncSchedule } from "../../shared";
import styles from "./schedules-pane.module.scss";
import clsx from "clsx";

interface ISchedulesPaneProps {
  setIsLoading: (loading: boolean) => void;
}

export const SchedulesPane: React.FC<ISchedulesPaneProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();
  const tableContext = useContext(TableContext);
  const backlogContext = useContext(BacklogContext);
  const [schedules, setSchedules] = useState<IAdapterFolder[]>([]);
  const [customizeSchedulesDialogOpened, setCustomizeSchedulesDialogOpened] =
    useState<boolean>(false);
  const [selectedScheduleIds, setSelectedScheduleIds] = useState<Set<string>>(
    new Set()
  );
  const [selectedCraftIds, setSelectedCraftIds] = useState<Set<string>>(
    new Set()
  );

  const asyncSchedule = useAsyncSchedule({
    onComplete: () => fetchSchedules(),
    showTotalScheduleWOCount: true,
  });
  const { scheduleProgress, doAsyncSchedule } = asyncSchedule;

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const notify = useNotification();
  const backlogUiService = adapterContext!.service.getBacklogUiService();
  const currentTab = backlogUiService.getCurrentTab()();

  const fetchSchedules = async () => {
    try {
      const query: QueryDto = fetchHelpers.getTableQuery({
        fetchProps: {
          columnFilters: [],
          sortColumns: [],
          ids: Array.from(selectedScheduleIds),
        },
      });
      const results: IAdapterFolder[] = await adapterContext!.service
        .getAdapterService()
        .fetchFolders(query);
      setSchedules(results);
      setCollapsed(false);
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadSelectedScheduleIds = async () => {
    try {
      const scheduleIds: string[] =
        await userPreferencesService.getBacklogSchedules(
          adapterContext!.service.getAdapterService()
        );
      setSelectedScheduleIds(new Set(scheduleIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadSelectedCraftIds = async () => {
    try {
      const craftIds: string[] =
        await userPreferencesService.getUtilizationCrafts(
          adapterContext!.service.getAdapterService()
        );
      setSelectedCraftIds(new Set(craftIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    loadSelectedScheduleIds();
    loadSelectedCraftIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!site) {
      fetchSchedules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, selectedScheduleIds, backlogContext?.schedulePaneRefreshGuid]);

  const getRandomItemColor = (index: number): ScheduleItemColor =>
    randomScheduleItemColors[index % randomScheduleItemColors.length];

  const onSelectBacklogSchedules = (
    selectorScheduleIds?: Set<string>
  ): void => {
    const hasChanged = (a: Set<string>, b: Set<string>) =>
      JSON.stringify(Array.from(a)) !== JSON.stringify(Array.from(b));
    if (
      selectorScheduleIds &&
      hasChanged(selectedScheduleIds, selectorScheduleIds)
    ) {
      setSelectedScheduleIds(selectorScheduleIds);
      userPreferencesService.setBacklogSchedules(
        adapterContext!.service.getAdapterService(),
        Array.from(selectorScheduleIds)
      );
    }
  };

  const removeSchedule = (schedule: IAdapterFolder): void => {
    selectedScheduleIds.delete(schedule._id);
    setSelectedScheduleIds(new Set(selectedScheduleIds));
    userPreferencesService.setBacklogSchedules(
      adapterContext!.service.getAdapterService(),
      Array.from(selectedScheduleIds)
    );
  };

  // Invoked when scheduling individual work orders manually selected in the table, or for specific workOrderIds
  const doSyncSchedule = async (
    schedule: IAdapterFolder,
    workOrderIds?: string[]
  ): Promise<void> => {
    const selectedIds: string[] =
      workOrderIds || getSelectedRowsObjectIds(tableContext!.table);
    if (!selectedIds.length) return;

    const notification: INotificationMessage =
      selectedIds.length > 1
        ? {
            title: t(
              "module.backlog:schedule_pane.work_orders_added.notification_title"
            ),
            message: t(
              "module.backlog:schedule_pane.work_orders_added.notification_multiple_message",
              {
                work_order_num: selectedIds.length,
                folder: schedule.folder,
                workorder_count: schedule.workorder_count,
              }
            ),
          }
        : {
            title: t(
              "module.backlog:schedule_pane.work_orders_added.notification_title"
            ),
            message: t(
              "module.backlog:schedule_pane.work_orders_added.notification_single_message",
              {
                folder: schedule.folder,
                workorder_count: schedule.workorder_count,
              }
            ),
          };
    props.setIsLoading(true);
    // additional boolean parameter to remove workorders from other folders when they are dragged
    await adapterContext!.service
      .getAdapterService()
      .schedule({
        folder_id: schedule._id,
        entity_name: getEntityNameForSchedulingByTab(),
        entity_ids: selectedIds,
      })
      .then(() => {
        tableContext?.table.setSelectedRowIds(new Set());
        fetchSchedules();
        backlogContext?.refreshCurrentTable();
        notify.info(notification);
      })
      .catch((message) => {
        notify.error({ message });
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  const getEntityNameForSchedulingByTab = () => {
    switch (currentTab) {
      default:
      case BacklogTabId.WORKORDERS:
        return "workorder";
      case BacklogTabId.OPERATIONS:
        return "operation";
    }
  };

  const doSchedule = async (
    schedule: IAdapterFolder,
    workOrderIds?: string[]
  ): Promise<void> => {
    const table: IUseTable = tableContext?.table!;
    const hasSelectedObjects: boolean =
      !!workOrderIds?.length || !!table.selectedRowIds.size;
    if (hasSelectedObjects) {
      const isSelectAll: boolean =
        table.selectedRowIds.size === table.entities.length;
      if (isSelectAll) {
        doAsyncSchedule({ schedule });
      } else {
        doSyncSchedule(schedule, workOrderIds);
      }
    }
  };

  return (
    <Box
      className={clsx([
        styles.root,
        {
          [styles.collapsed]: collapsed,
        },
      ])}
    >
      <div className={styles.header}>
        <div className={styles.title}>Schedules</div>
        <div
          className={styles.selector}
          onClick={() => setCustomizeSchedulesDialogOpened(true)}
        >
          <div className={styles.selectorCaption}>Customize</div>
          <SettingsIcon classes={{ root: styles.selectorIcon }} />
        </div>
      </div>
      <div className={styles.list}>
        {schedules.map((schedule, index) => (
          <ScheduleItem
            key={index}
            schedule={schedule}
            doSchedule={doSchedule}
            onRefreshRequired={() => fetchSchedules()}
            onRemoveSchedule={() => removeSchedule(schedule)}
            color={getRandomItemColor(index)}
            selectedCraftIds={selectedCraftIds}
          />
        ))}
      </div>
      <CustomizeSchedulesDialog
        open={customizeSchedulesDialogOpened}
        onClose={() => setCustomizeSchedulesDialogOpened(false)}
        selectedScheduleIds={selectedScheduleIds}
        onChangeSelectedScheduleIds={onSelectBacklogSchedules}
        selectedCraftIds={selectedCraftIds}
        onChangeSelectedCraftIds={setSelectedCraftIds}
      />
      {scheduleProgress.renderDialog()}
    </Box>
  );
};
