import { FC, useContext } from "react";
import {
  GridHeader,
  ColumnSelectorDropdown,
  GridActions,
  GenericAction,
  FilterDropdown,
  RiverCalendarSelector,
  useAsyncResourceSync,
  DeleteAction,
  IUseTable,
} from "../../shared";
import { Box, Divider } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SyncIcon from "@mui/icons-material/Sync";
import { ExcelIcon } from "../../../icons";
import { useSimpleDialog, useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { AvailabilityAction } from "../availability";
import { useNotification } from "@river/common-ui";
import dayjs from "dayjs";
import { IEntityObject } from "@river/interfaces";
import { uiConstants } from "../../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import {
  useAvailabilityExcelExport,
  IUseAvailabilityExcelExport,
} from "../use-availability-excel-export";
import styles from "./availability-grid-header.module.scss";

interface IProps {
  startDate: Date;
  onStartDateChange: (date: Date) => void;
  setAvailabilityDialogOpened(v: boolean): void;
}

export const AvailabilityGridHeader: FC<IProps> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const { startDate, onStartDateChange, setAvailabilityDialogOpened } = props;
  // the calendar control works with "local" dates, while the availability relies on dates being shown in UTC timezone
  const calStartDate = dayjs(startDate)
    .add(startDate.getTimezoneOffset(), "minutes")
    .toDate();
  const availabilityExport: IUseAvailabilityExcelExport =
    useAvailabilityExcelExport({ startDate });

  const { t } = useTranslation();
  const notify = useNotification();

  const openAvailabilityDialog = () => setAvailabilityDialogOpened(true);

  const asyncSchedule = useAsyncResourceSync();
  const { resourceSyncProgress, doAsyncResourceSync } = asyncSchedule;

  const synchronizeResources = async () => {
    try {
      doAsyncResourceSync();
    } catch (message) {
      notify.error({ message });
    }
  };

  function dateToString(value: Date) {
    return new Intl.DateTimeFormat(navigator.language, {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(value);
  }

  function handleGoBackwardCalendar() {
    const newDate = dayjs(calStartDate)
      .subtract(7, "days")
      .subtract(calStartDate.getTimezoneOffset(), "minutes")
      .toDate();
    onStartDateChange(newDate);
  }

  function handleGoForwardCalendar() {
    let newDate = dayjs(calStartDate)
      .add(7, "days")
      .subtract(calStartDate.getTimezoneOffset(), "minutes")
      .toDate();
    onStartDateChange(newDate);
  }

  function handleOnAcceptCalendar(value: dayjs.Dayjs | null) {
    if (value) {
      onStartDateChange(
        value.subtract(calStartDate.getTimezoneOffset(), "minutes").toDate()
      );
    }
  }

  const selected: IEntityObject = table.entities.filter((entity) =>
    table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
  )[0];

  const deleteSelected = async (): Promise<any> => {
    if (selected) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteAvailabilityResource(selected._id as string);
        tableContext?.table.fetch();
        deleteResourceConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteResourceConfirmation = useSimpleDialog({
    title: t("module.availability:dialog.delete_availability_resources"),
    message: t(
      "module.availability:message.confirm_availability_resources_deletion"
    ),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelected,
  });

  return (
    <>
      <GridHeader className={styles.mainContainer}>
        <GridActions className={styles.actionsContainer}>
          <Box className={styles.root}>
            <Box className={styles.leftSection}>
              <ColumnSelectorDropdown />
              <FilterDropdown />
              <ProtectedAction
                module={ModuleKey.AVAILABILITY}
                action={AvailabilityAction.APPLY_CALENDAR}
              >
                <GenericAction
                  icon={EventAvailableIcon}
                  onClick={openAvailabilityDialog}
                  title={t(
                    "module.availability:dialog.generate_availability.title"
                  )}
                  enableOnSelection={true}
                />
              </ProtectedAction>
              <ProtectedAction
                module={ModuleKey.AVAILABILITY}
                action={AvailabilityAction.SYNC_RESOURCES}
              >
                <GenericAction
                  icon={SyncIcon}
                  onClick={synchronizeResources}
                  title={t("module.availability:label.synchronize_resources")}
                />
              </ProtectedAction>
              <ProtectedAction
                module={ModuleKey.AVAILABILITY}
                action={AvailabilityAction.DELETE_RESOURCE}
              >
                <DeleteAction
                  onClick={() => deleteResourceConfirmation.open()}
                />
              </ProtectedAction>
              <GenericAction
                icon={ExcelIcon}
                onClick={() => availabilityExport.setDialogOpened(true)}
                title={t("shared.action:excel_export")}
              />
            </Box>
            <Box className={styles.calendarContainer}>
              <span className={styles.calendarValue}>
                {`${t("module.availability:label.start_date")}: ${dateToString(
                  startDate
                )}`}
              </span>
              <Divider orientation="vertical" className={styles.divider} />
              <RiverCalendarSelector
                value={calStartDate}
                handleGoBackward={handleGoBackwardCalendar}
                handleGoForward={handleGoForwardCalendar}
                handleOnAccept={handleOnAcceptCalendar}
              />
            </Box>
          </Box>
        </GridActions>
      </GridHeader>
      {resourceSyncProgress.renderDialog()}
      {deleteResourceConfirmation.render()}
      {availabilityExport.renderDialog()}
    </>
  );
};
