import { ReactElement, useContext, useState } from "react";
import { GenericAction, gridHelpers, IUseTable } from "../..";
import { useTranslation } from "@river/common-ui";
import { SaveIcon } from "../../../../icons";

import { useGridActionHelpers } from "../../../../helpers";
import { SaveAdvancedFilterDialog } from "../../advanced-filters/save-advanced-filter-dialog";
import { TableContext } from "../../../../context";
import { ITableFilter } from "../../../../interfaces";

export const useSaveFilterAction = () => {
  const { t } = useTranslation();
  const [saveFilterDialogOpened, setSaveFilterDialogOpened] =
    useState<boolean>(false);
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const disabled: boolean = !gridHelpers.hasFiltersApplied(table);

  const { withSanitizedProps } = useGridActionHelpers();
  const title: string = t("shared.grid_header:label.save_filter");

  const filterToSave: ITableFilter = {
    name: "",
    query: table.appliedTableFilter?.query!,
    sort: table.sortColumns,
    simpleFilters: table.lastFetchColumnFilters,
  };

  const onClick = () => {
    setSaveFilterDialogOpened(true);
  };

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={SaveIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderDialog = (): ReactElement => (
    <>
      {saveFilterDialogOpened && (
        <SaveAdvancedFilterDialog
          open={saveFilterDialogOpened}
          filter={filterToSave!}
          onClose={() => setSaveFilterDialogOpened(false)}
        />
      )}
    </>
  );

  const renderSaveFilterAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderSaveFilterAction,
    saveFilterAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      disabled,
      onClick,
    },
  };
};

export type ISaveFilterAction = ReturnType<typeof useSaveFilterAction>;
