import { AdapterUiService } from "../adapter-ui.service";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import { useSearchParams } from "react-router-dom";
import { IAdapterBaseline, IEntityObject } from "@river/interfaces";
import {
  useScheduleUtilizationBacklog,
  useCraftUtilization,
  useScheduleUtilizationPersons,
  useScheduleUtilizationCrews,
  useScheduleUtilizationValidationRules,
  useScheduleUtilizationProgressSummary,
  useScheduleUtilizationCompliance,
} from "./index";
import { IAdapterUiContextState } from "../../context";

export enum ScheduleUtilizationTabId {
  BACKLOG = "backlog",
  CRAFTS = "crafts",
  PERSONS = "persons",
  CREWS = "crews",
  VALIDATION_RULES = "validation_rules",
  PROGRESS = "progress",
  COMPLIANCE = "compliance",
}

export enum ProgressSummaryFilterID {
  PMACTTYPE = "pmacttype",
  WORKCENTER = "workcenter",
  WO_TYPES = "wo_types",
  DEPARTMENTS = "departments",
  RESOURCES = "resources",
}

export interface IScheduleProgressFilters {}

export enum ComplianceSummaryFilterID {
  WO_TYPES = "wo_types",
  PMACTTYPE = "pmacttype",
}

export interface IScheduleComplianceFilters {}

export interface IScheduleUtilizationUseCurrentTableProps {
  selectedCraftIds: Set<string>;
  selectedResourceIds: Set<string>;
  selectedCrewIds: Set<string>;
  currentBaseline: IAdapterBaseline | undefined;
  progressSummaryFilters: IScheduleProgressFilters;
  complianceSummaryFilters: IScheduleComplianceFilters;
}

export interface IGetDefaultScheduleBacklogColumnsProps {
  onWorkOrderClick: (workOrder: IEntityObject) => void;
  getTaskColor: (row: IEntityObject) => string;
  draggable?: boolean;
}

export interface IGetComplianceFiltersProps {
  adapterContext?: IAdapterUiContextState | null;
  complianceFilters: IScheduleComplianceFilters;
}

export interface IGetProgressFiltersProps {
  adapterContext?: IAdapterUiContextState | null;
  progressSummaryFilters: IScheduleProgressFilters;
}

export const ScheduleUtilizationTabURLParamName = "utilization_tab";

export abstract class ScheduleUtilizationUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getCurrentTab = (): (() => ScheduleUtilizationTabId) => {
    return (): ScheduleUtilizationTabId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(ScheduleUtilizationTabURLParamName) ||
        this.getDefaultTab()) as ScheduleUtilizationTabId;
    };
  };

  useCurrentTable(
    props: IScheduleUtilizationUseCurrentTableProps
  ): EntityTableProvider {
    return () => {
      const currentTab = this.getCurrentTab()();
      const currentBaseline: IAdapterBaseline | undefined =
        props.currentBaseline;
      const scheduleBacklogTable: IUseEntityTable =
        useScheduleUtilizationBacklog({
          draggable: false,
        });
      const craftUtilizationTable: IUseEntityTable = useCraftUtilization({
        selectedCraftIds: props.selectedCraftIds,
      });
      const resourceUtilizationTable: IUseEntityTable =
        useScheduleUtilizationPersons({
          selectedPersonIds: props.selectedResourceIds,
        });
      const crewUtilizationTable: IUseEntityTable = useScheduleUtilizationCrews(
        {
          selectedCrewIds: props.selectedCrewIds,
        }
      );
      const validationRulesTable: IUseEntityTable =
        useScheduleUtilizationValidationRules();
      const progressSummaryTable: IUseEntityTable =
        useScheduleUtilizationProgressSummary({
          currentBaseline,
          progressSummaryFilters: props.progressSummaryFilters,
        });
      const complianceTable: IUseEntityTable = useScheduleUtilizationCompliance(
        {
          currentBaseline,
          complianceSummaryFilters: props.complianceSummaryFilters,
        }
      );
      if (currentTab === ScheduleUtilizationTabId.BACKLOG) {
        return scheduleBacklogTable;
      } else if (currentTab === ScheduleUtilizationTabId.CRAFTS) {
        return craftUtilizationTable;
      } else if (currentTab === ScheduleUtilizationTabId.PERSONS) {
        return resourceUtilizationTable;
      } else if (currentTab === ScheduleUtilizationTabId.CREWS) {
        return crewUtilizationTable;
      } else if (currentTab === ScheduleUtilizationTabId.VALIDATION_RULES) {
        return validationRulesTable;
      } else if (currentTab === ScheduleUtilizationTabId.PROGRESS) {
        return progressSummaryTable;
      } else if (currentTab === ScheduleUtilizationTabId.COMPLIANCE) {
        return complianceTable;
      } else {
        return scheduleBacklogTable;
      }
    };
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getTabs(): TabsProvider;
  abstract getDefaultTab(): ScheduleUtilizationTabId;
  abstract getDefaultBacklogColumns(
    props: IGetDefaultScheduleBacklogColumnsProps
  ): ColumnsProvider;
  abstract getDefaultCraftUtilizationColumns(): ColumnsProvider;
  abstract getCraftUtilizationEntityName(): string;
  abstract getCraftUtilizationKeyColumn(): string;
  abstract getCraftUtilizationStatLabelColumn(): string;
  abstract getDefaultPersonUtilizationColumns(): ColumnsProvider;
  abstract getProgressGridActionsRenderer(): () => ReactElementProvider;
  abstract getComplianceGridActionsRenderer(): () => ReactElementProvider;
  abstract renderProgressFilters(): ReactElementProvider;
  abstract renderComplianceFilters(): ReactElementProvider;
  abstract getProgressFilters(props: IGetProgressFiltersProps): QueryProvider;
  abstract getComplianceFilters(
    props: IGetComplianceFiltersProps
  ): QueryProvider;
  abstract getBlankProgressFilters(): IScheduleProgressFilters;
  abstract getBlankComplianceFilters(): IScheduleComplianceFilters;
}
