import { Column } from "react-data-grid";
import {
  AvailabilityUiService,
  IGetAvailabilityResourcesColumnsProps,
} from "../availability-ui.service";
import { GenericAvatarIcon } from "../../icons";
import availabilityStyles from "../../components/availability/availability.module.scss";
import clsx from "clsx";
import { useTableCellRenderers } from "../../hooks";
import { useTranslation } from "@river/common-ui";

export class OracleCloudAvailabilityUiService extends AvailabilityUiService {
  getAvailabilityResourcesColumns = (
    props: IGetAvailabilityResourcesColumnsProps
  ): Column<any>[] => {
    const { t } = useTranslation();
    const { renderCell, renderCellText } = useTableCellRenderers();
    return [
      {
        key: "LaborInstanceName",
        name: t(
          "entity.availability_header:availability_header.LaborInstanceName"
        ),
        width: 190,
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={clsx([availabilityStyles.personCell])}>
                  <GenericAvatarIcon />
                  <div>{renderCellText({ formatterProps })}</div>
                </div>
              ),
            })}
          </>
        ),
      },
      {
        key: "Resources.ResourceName",
        name: t("entity.availability_header:resource_ref.ResourceName"),
        width: 160,
      },
      {
        key: "crews.crew",
        name: t("entity.availability_header:availability_header.crews"),
        width: 160,
      },
      {
        key: "calendar",
        name: t("entity.availability_header:availability_header.calendar"),
        width: 120,
      },
      {
        key: "calendar_start_date",
        name: t(
          "entity.availability_header:availability_header.calendar_start_date"
        ),
        width: 140,
      },
      {
        key: "calendar_end_date",
        name: t(
          "entity.availability_header:availability_header.calendar_end_date"
        ),
        width: 140,
      },
    ];
  };

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.organization"];
  }

  getEditAvailabilityHeaderKey(): string {
    return "LaborInstanceName";
  }

  renderResourceDialog() {
    return <></>;
  }
}
