import { httpService } from "@river/common-ui";
import { Config } from "@river/config";
import {
  AdapterUserContextProp,
  IAdapterUserContext,
  IUserContextSite,
} from "../context";
import {
  AdapterAutoDependenciesActionDto,
  AdapterAvailabilityGenerationDto,
  AdapterBaselineDto,
  AdapterCalendarDto,
  AdapterCrewCraftDto,
  AdapterCrewDto,
  AdapterCrewPersonDto,
  AdapterDependencyActionDto,
  AdapterFolderDto,
  AdapterFolderJobValidationSummaryActionDto,
  AdapterPostActionDto,
  AdapterRestrictionDto,
  AdapterRoleDto,
  AdapterRoleModuleActionDto,
  AdapterRoleModuleDto,
  AdapterRuleDto,
  AdapterShiftDto,
  AdapterTimeCardDto,
  AdapterTimelineDto,
  AdapterTimelineFoldersActionDto,
  AdapterUserDto,
  AdapterUserPreferenceDto,
  AdapterUserStatusActionDto,
  AttributeDto,
  EntityDto,
  EnvLanguageDto,
  EnvLanguageStatusDto,
  EnvTranslatableDto,
  IAdapterAssignmentAction,
  IAdapterAutoScheduleAction,
  IAdapterCalendar,
  IAdapterCrew,
  IAdapterFolder,
  IAdapterFolderAction,
  IAdapterMaterialShortageAction,
  IAdapterRestriction,
  IAdapterRole,
  IAdapterRoleModule,
  IAdapterRoleModuleAction,
  IAdapterRule,
  IAdapterRuleSummary,
  IAdapterSchedulingAction,
  IAdapterShift,
  IAdapterTimeCard,
  IAdapterTimeline,
  IAdapterUser,
  IAdapterUserPreference,
  IAdapterUserStatusActionResponse,
  IAttachmentHierarchyNode,
  IAttribute,
  ICustomerInfo,
  IEntity,
  IEntityDefinition,
  IEntityObject,
  IEnvLanguage,
  IEnvTranslatable,
  IIndex,
  IKpiConfig,
  IKpiParams,
  ILicense,
  ILicensedModule,
  IModuleSummary,
  IndexDto,
  IRelation,
  ISystemCronTaskWithEnv,
  ISystemEnvCronTask,
  ITranslatable,
  IWorkPackage,
  QueryAttributeDto,
  QueryDto,
  RelationDto,
  SystemEnvCronTaskDto,
  UtilizationPeriodType,
  IAdapterLookup,
  AdapterLookupDto,
  AdapterFolderStatusDto,
  AdapterReportCreateDto,
  IAdapterReport,
  IAdapterReportConfig,
  IQuery,
  AdapterCompletedPctActionDto,
  AdapterBreakinActionDto,
  IExternalLink,
  ICustomCronTask,
  CustomCronTaskCreateDto,
  CustomCronTaskUpdateDto,
  ICronTaskAuth,
  CronTaskAuthDto,
  IAdapterAsyncAction,
  AdapterSchedulingActionAsyncDto,
  CaptchaDto,
  AdapterAvailabilityUpdateDto,
  AdapterCompletionDefaultsActionDto,
  AdapterAttachmentHierarchyActionDto,
  AdapterJobPriorityScoreActionDto,
} from "@river/interfaces";
import { createSubdomain } from "@river/util";

export abstract class AdapterService {
  // ------------
  constructor(protected readonly customerInfo: ICustomerInfo) {}

  protected userContext: IAdapterUserContext | undefined;

  setUserContext(context: IAdapterUserContext) {
    this.userContext = context;
  }

  // ------------
  getCustomerInfo(): ICustomerInfo {
    return this.customerInfo;
  }

  // ------------
  getAdapterSvcBaseUrl(): string {
    const subdomain = createSubdomain(this.customerInfo);
    return `${Config.web.REACT_APP_ADAPTER_API_PROTOCOL}${subdomain}.${Config.web.REACT_APP_ADAPTER_API_DOMAIN}`;
  }

  // ------------
  getApiBasicAuthUrl(): string {
    return `${this.getAdapterSvcBaseUrl()}/api/adapter/auth/login`;
  }

  // ------------
  getApiOAuth2Url(): string {
    return `${this.getAdapterSvcBaseUrl()}/api/adapter/auth/oauth2`;
  }

  // ------------
  getApiSaml2Url(): string {
    return `${this.getAdapterSvcBaseUrl()}/api/adapter/auth/saml2`;
  }

  // ------------
  getApiLogoutUrl(): string {
    return `${this.getAdapterSvcBaseUrl()}/api/adapter/auth/logout`;
  }

  // -------------
  async verifyCaptcha(captchaDto: CaptchaDto): Promise<any> {
    //
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/auth/captcha`,
      captchaDto
    );

    return data;
  }

  // ------------
  protected ensureQueryAttributes(
    attributes: QueryAttributeDto[],
    qry?: QueryDto
  ): QueryDto {
    const result = qry || new QueryDto();

    let { query } = result;
    result.query = query
      ? {
          $and: [...attributes, { ...query }],
        }
      : {
          $and: attributes,
        };

    // result.attributes = result.attributes || [];
    // result.attributes.push(...attributes);
    // return result;
    //const result = new QueryDto();
    // result.query = {
    //   $and: [...attributes],
    // };

    // if (qry?.query) {
    //   const { query } = qry;
    //   if (Object.keys(query).length) {
    //     result.query!.$and?.push(query);
    //   }
    // }
    return result;
  }

  // --------------
  async searchEntityData(entityName: string, query?: QueryDto): Promise<any[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/${entityName}/search`,
      query || {}
    );
    return response.data;
  }

  // -------------
  async getEntityDataById(entityName: string, id: string): Promise<any> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/${entityName}/${id}`
    );
    return response.data;
  }

  // --------------
  async createEntityData(entityName: string, data: any): Promise<any> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/${entityName}`,
      data
    );
    return response.data;
  }

  // --------------
  async updateEntityData(
    entityName: string,
    id: string,
    data: any
  ): Promise<any> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/${entityName}/${id}`,
      data
    );
    return response.data;
  }

  // --------------
  async deleteEntityData(entityName: string, id: string): Promise<any> {
    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/${entityName}/${id}`
    );
    return response.data;
  }

  // --------------
  async getEntityInfo(entityName: string): Promise<IEntityDefinition> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/definition`
    );
    return response.data;
  }

  // -------------
  async getEntityDefinitions(query?: QueryDto): Promise<IEntity[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/search`,
      query
    );
    return response.data;
  }

  // -------------
  async createEntityDefinition(entityDto: EntityDto): Promise<IEntity> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd`,
      entityDto
    );
    return response.data;
  }

  // -------------
  async updateEntityDefinition(
    entityName: string,
    entityDto: EntityDto
  ): Promise<IEntity> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}`,
      entityDto
    );
    return response.data;
  }

  // -------------
  async deleteEntityDefinition(entityName: string): Promise<IEntity> {
    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}`
    );
    return response.data;
  }

  // -------------
  async getEntityAttributeDefinitions(
    entityName: string,
    query?: QueryDto
  ): Promise<IAttribute[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/attributes/search`,
      query
    );
    return response.data;
  }

  // -------------
  async createEntityAttributeDefinition(
    entityName: string,
    attributeDto: AttributeDto
  ): Promise<IAttribute> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/attributes`,
      attributeDto
    );
    return response.data;
  }

  // -------------
  async updateEntityAttributeDefinition(
    entityName: string,
    attributeId: string,
    attributeDto: AttributeDto
  ): Promise<IAttribute> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/attributes/${attributeId}`,
      attributeDto
    );
    return response.data;
  }

  // -------------
  async deleteEntityAttributeDefinition(
    entityName: string,
    attributeId: string
  ): Promise<IAttribute> {
    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/attributes/${attributeId}`
    );
    return response.data;
  }

  // -------------
  async getEntityRelationDefinitions(
    entityName: string,
    query?: QueryDto
  ): Promise<IRelation[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/relations/search`,
      query
    );
    return response.data;
  }

  // -------------
  async createEntityRelationDefinition(
    entityName: string,
    relationDto: RelationDto
  ): Promise<IRelation> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/relations`,
      relationDto
    );
    return response.data;
  }

  // -------------
  async updateEntityRelationDefinition(
    entityName: string,
    relationId: string,
    relationDto: RelationDto
  ): Promise<IRelation> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/relations/${relationId}`,
      relationDto
    );
    return response.data;
  }

  // -------------
  async deleteEntityRelationDefinition(
    entityName: string,
    relationId: string
  ): Promise<IRelation> {
    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/relations/${relationId}`
    );
    return response.data;
  }

  // -------------
  async getEntityIndexDefinitions(
    entityName: string,
    query?: QueryDto
  ): Promise<IIndex[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/indexes/search`,
      query
    );
    return response.data;
  }

  // -------------
  async createEntityIndexDefinition(
    entityName: string,
    indexDto: IndexDto
  ): Promise<IIndex> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/indexes`,
      indexDto
    );
    return response.data;
  }

  // -------------
  async updateEntityIndexDefinition(
    entityName: string,
    indexId: string,
    indexDto: IndexDto
  ): Promise<IIndex> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/indexes/${indexId}`,
      indexDto
    );
    return response.data;
  }

  // -------------
  async deleteEntityIndexDefinition(
    entityName: string,
    indexId: string
  ): Promise<IIndex> {
    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/indexes/${indexId}`
    );
    return response.data;
  }

  // -------------
  async getDefaultEntityColumns(
    entityName: string,
    context: string = "default"
  ): Promise<string[]> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dd/${entityName}/columns/${context}`
    );
    return response.data;
  }

  // ==========================
  // Languages
  // ==========================
  async getLanguages(queryDto?: QueryDto): Promise<IEntityObject[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/languages/search`,
      queryDto
    );
    return response.data;
  }

  // --------------
  async getEnvironmentLanguages(queryDto?: QueryDto): Promise<IEntityObject[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/search`,
      queryDto
    );
    return response.data;
  }

  // --------------
  async addEnvironmentLanguage(
    languageDto: EnvLanguageDto
  ): Promise<IEnvLanguage> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages`,
      languageDto
    );
    return response.data;
  }

  // --------------
  async environmentLanguageStatus(
    language: string,
    languageStatusDto: EnvLanguageStatusDto
  ): Promise<IEnvLanguage> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/${language}`,
      languageStatusDto
    );
    return response.data;
  }

  // --------------
  async deleteEnvironmentLanguage(language: string): Promise<void> {
    await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/${language}`
    );
  }

  // --------------
  async getEnvLanguageTranslations(
    language: string,
    queryDto?: QueryDto
  ): Promise<ITranslatable[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/${language}/translations/search`,
      queryDto
    );
    return response.data;
  }

  // --------------
  async createEnvLanguageTranslation(
    language: string,
    translatableDto: EnvTranslatableDto
  ): Promise<IEnvTranslatable | null> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/${language}/translations`,
      translatableDto
    );
    return response.data;
  }

  // --------------
  async resetEnvLanguageTranslations(language: string): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/i18n/env-languages/${language}/translations/reset`
    );
  }

  // ==========================
  // User Preferences
  // ==========================
  async saveUserPreference(key: string, value: any): Promise<void> {
    const preferenceDto: AdapterUserPreferenceDto = {
      key,
      value,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/preferences`,
      preferenceDto
    );
  }

  // --------------
  async getUserPreference(key: string): Promise<any> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/preferences/${key}`
    );
    const preference = response.data as IAdapterUserPreference | null;
    return preference ? preference.value : null;
  }

  // ==========================
  // Global Preferences
  // ==========================
  async saveGlobalPreference(key: string, value: any): Promise<void> {
    const preferenceDto: AdapterUserPreferenceDto = {
      key,
      value,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/preferences/global`,
      preferenceDto
    );
  }

  // --------------
  async getGlobalPreference(key: string): Promise<any> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/preferences/global/${key}`
    );
    const preference = response.data as IAdapterUserPreference | null;
    return preference ? preference.value : null;
  }

  // ==========================
  // Sites
  // ==========================
  abstract fetchSites(query?: QueryDto): Promise<any[]>;

  // ===================
  // User Status
  // ===================
  abstract getUserStatusResponse(
    userStatusAction: AdapterUserStatusActionDto
  ): Promise<IAdapterUserStatusActionResponse>;

  // ==========================
  // Printing
  // ==========================
  async getAttachmentHierarchy(
    hierarchyAction: AdapterAttachmentHierarchyActionDto
  ): Promise<IAttachmentHierarchyNode[]> {
    //

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/attachment-hierarchy`,
      hierarchyAction
    );
    return response.data;
  }

  // ----------------
  async downloadAttachmentContent(
    attachmentHierarchyNode: IAttachmentHierarchyNode
  ): Promise<void> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/content`,
      attachmentHierarchyNode,
      { responseType: "blob" }
    );
    //console.log(`Headers: ${JSON.stringify(response.headers, null, 2)}`)
    const fileNameHeader = response.headers["content-disposition"];
    const fileName = fileNameHeader
      ? fileNameHeader.replace(/\w+; ?filename=(.*)/, "$1")
      : "test.pdf";
    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    let file = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = file;
    a.download = decodeURI(fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // ----------------
  async convertAttachmentsToPdf(
    nodes: IAttachmentHierarchyNode[]
  ): Promise<IWorkPackage> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/pdf`,
      nodes
    );

    return response.data;
  }

  // -----------------
  async getWorkPackageStatus(
    workPackageId: string,
    includeFiles: boolean = false
  ): Promise<IWorkPackage> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/pdf/${workPackageId}?files=${includeFiles}`
    );
    return response.data;
  }

  // -----------------
  async downloadWorkPackage(
    workPackageId: string,
    fileName: string
  ): Promise<void> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/pdf/${workPackageId}/content`,
      { responseType: "blob" }
    );

    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    let file = URL.createObjectURL(blob);

    let a = document.createElement("a");
    a.href = file;
    a.download = decodeURI(fileName);
    a.text = fileName;
    //a.target = '_blank';
    //a.title = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // ==========================
  // Reports
  // ==========================

  abstract generateReport(
    createReport: AdapterReportCreateDto,
    enforceSiteId?: boolean
  ): Promise<IAdapterReport>;

  async getReportById(reportId: string): Promise<IAdapterReport> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/report/${reportId}`
    );
    return response.data;
  }

  async getReportContent(reportId: string, fileName: string): Promise<void> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/report/${reportId}/content`,
      { responseType: "blob" }
    );

    let blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    let file = URL.createObjectURL(blob);

    let a = document.createElement("a");
    a.href = file;
    a.download = decodeURI(fileName);
    a.text = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /** Returns which reports are available for the given entity name / module combination. */
  async getReportConfigsForEntityName(
    entityName: string,
    moduleCode: string
  ): Promise<IAdapterReportConfig[]> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/report-config/${entityName}/${moduleCode}`
    );
    return response.data;
  }

  // ==========================
  // Roles
  // ==========================
  async fetchLicenses(): Promise<ILicense[]> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/licenses`
    );
    return response.data;
  }

  // ------------
  async fetchRoles(query?: QueryDto): Promise<IAdapterRole[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/search`,
      query || {}
    );
    return response.data;
  }

  // ------------
  async createRole(roleDto: AdapterRoleDto): Promise<IAdapterRole> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles`,
      roleDto
    );
    return response.data;
  }

  // ------------
  async updateRole(
    roleId: string,
    roleDto: AdapterRoleDto
  ): Promise<IAdapterRole> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}`,
      roleDto
    );
    return response.data;
  }

  // ------------
  async deleteRole(roleId: string): Promise<void> {
    await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}`
    );
  }

  // ------------
  async fetchAvailableModules(
    roleId: string,
    query?: QueryDto
  ): Promise<ILicensedModule[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/available_modules/search`,
      query || {}
    );
    return response.data;
  }

  // ------------
  async fetchRoleModules(
    roleId: string,
    query?: QueryDto
  ): Promise<IAdapterRoleModule[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/modules/search`,
      query || {}
    );
    return response.data;
  }

  // ------------
  async createRoleModule(
    roleId: string,
    roleModuleDto: AdapterRoleModuleDto
  ): Promise<IAdapterRoleModule> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/modules`,
      roleModuleDto
    );
    return response.data;
  }

  // ------------
  async deleteRoleModule(roleId: string, roleModuleId: string): Promise<void> {
    await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/modules/${roleModuleId}`
    );
  }

  // ------------
  async deleteRoleModules(
    roleId: string,
    roleModuleDto: AdapterRoleModuleDto
  ): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/delete_modules`,
      roleModuleDto
    );
  }

  // ------------
  async fetchRoleModuleActions(
    roleId: string,
    query?: QueryDto
  ): Promise<IAdapterRoleModuleAction[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/actions/search`,
      query || {}
    );
    return response.data;
  }

  // ------------
  async updateRoleModuleAction(
    roleId: string,
    roleModuleActionDto: AdapterRoleModuleActionDto
  ): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/roles/${roleId}/actions`,
      roleModuleActionDto
    );
  }

  // ==========================
  // Restrictions
  // ==========================
  // TODO
  async fetchRestrictions(query?: QueryDto): Promise<IAdapterRestriction[]> {
    return await this.searchEntityData("restriction", query);
  }

  // ------------
  async createRestriction(
    restrictionDto: AdapterRestrictionDto
  ): Promise<IAdapterRestriction> {
    //
    return await this.createEntityData("restriction", restrictionDto);
  }

  // ------------
  async updateRestriction(
    restrictionId: string,
    restrictionDto: AdapterRestrictionDto
  ): Promise<IAdapterRestriction> {
    //
    return await this.updateEntityData(
      "restriction",
      restrictionId,
      restrictionDto
    );
  }

  // ------------
  async deleteRestriction(restrictionId: string): Promise<void> {
    await this.deleteEntityData("restriction", restrictionId);
  }

  // ==========================
  // Users
  // ==========================
  async fetchUsers(query?: QueryDto): Promise<IAdapterUser[]> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/users/search`,
      query || {}
    );
    return response.data;
  }

  // ------------
  async createUser(userDto: AdapterUserDto): Promise<IAdapterUser> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/users`,
      userDto
    );
    return response.data;
  }

  // ------------
  async updateUser(
    userId: string,
    userDto: AdapterUserDto
  ): Promise<IAdapterUser> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/users/${userId}`,
      userDto
    );
    return response.data;
  }

  // ------------
  async deleteUser(userId: string): Promise<void> {
    await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/users/${userId}`
    );
  }

  // -------------
  async getMenuModules(): Promise<IModuleSummary[]> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/menu`
    );
    return response.data;
  }

  // ==========================
  // Folders and Timelines
  // ==========================
  abstract fetchTimelines(query?: QueryDto): Promise<IAdapterTimeline[]>;

  // --------------
  abstract createTimeline(
    timelineCreateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline>;

  // --------------
  abstract updateTimeline(
    timelineId: string,
    timelineUpdateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline>;

  // --------------
  async deleteTimeline(timelineId: string): Promise<IAdapterTimeline> {
    return await this.deleteEntityData("timeline", timelineId);
  }

  // --------------
  async generateTimelineFolders(
    timelineFoldersDto: AdapterTimelineFoldersActionDto
  ): Promise<void> {
    //
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/timeline-folders`,
      timelineFoldersDto
    );
  }

  // --------------
  abstract fetchFolders(query?: QueryDto): Promise<IAdapterFolder[]>;

  // --------------
  abstract createFolder(
    folderCreateParams: AdapterFolderDto
  ): Promise<IAdapterFolder>;

  // --------------
  abstract updateFolder(
    folderId: string,
    folderUpdateParams: AdapterFolderDto
  ): Promise<IAdapterFolder>;

  async updateFolderStatus(
    folderId: string,
    folderStatusDto: AdapterFolderStatusDto
  ): Promise<IAdapterFolder> {
    //
    const response = await httpService.put<IAdapterFolder>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/folder/${folderId}`,
      folderStatusDto
    );
    return response.data;
  }

  // --------------
  async deleteFolder(folderId: string): Promise<IAdapterFolder> {
    return await this.deleteEntityData("folder", folderId);
  }

  // --------------
  async getFolderById(folderId: string): Promise<IAdapterFolder> {
    //return this.getEntityDataById("folder", folderId);
    // DO - have to get the folder list as only then "has_uncommitted_changes" is properly populated
    const folderQuery: IQuery = {
      query: {
        $and: [
          {
            attribute_name: "_id",
            attribute_value: {
              operator: "$eq",
              value: folderId,
            },
          },
        ],
      },
    };
    const folders = await this.fetchFolders(folderQuery);
    if (!folders.length) {
      throw new Error("Unexpected error");
    }

    return folders[0];
  }

  // --------------
  async removeFromFolder(
    folderId: string,
    entityType: string,
    entityIds: string[]
  ): Promise<void> {
    const folderAction: IAdapterFolderAction = {
      folder_id: folderId,
      entity_name: entityType,
      entity_ids: entityIds,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/remove-from-folder`,
      folderAction
    );
  }

  /** Sets complete percent on a workorder or it's child object(s). */
  async setCompletePercent(
    completePercentAction: AdapterCompletedPctActionDto
  ): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/completed-pct`,
      completePercentAction
    );
  }

  /** Sets a workorder as "breakin". */
  async setBreakin(breakinAction: AdapterBreakinActionDto): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/breakin`,
      breakinAction
    );
  }

  // ==========================
  // Shifts
  // ==========================
  abstract fetchShifts(query?: QueryDto): Promise<IAdapterShift[]>;

  // ------------
  abstract createShift(shift: AdapterShiftDto): Promise<IAdapterShift>;

  // ------------
  abstract updateShift(
    shiftId: string,
    shift: AdapterShiftDto
  ): Promise<IAdapterShift>;

  // ------------
  async deleteShift(shiftId: string): Promise<IAdapterShift> {
    return this.deleteEntityData("shift", shiftId);
  }

  // ==========================
  // Crews
  // ==========================
  abstract fetchCrews(
    query?: QueryDto,
    user_id?: string
  ): Promise<IAdapterCrew[]>;

  // available (not assigned) people
  abstract fetchCrewAvailablePeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]>;

  // assigned people
  abstract fetchCrewAssignedPeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]>;

  // available crafts
  // abstract fetchCrewAvailableCrafts(
  //   crewCode: string,
  //   query?: QueryDto
  // ): Promise<any[]>;

  // assigned crafts
  // abstract fetchCrewAssignedCrafts(
  //   crewCode: string,
  //   query?: QueryDto
  // ): Promise<any[]>;

  // ------------
  abstract createCrew(crew: AdapterCrewDto): Promise<IAdapterCrew>;

  // ------------
  abstract updateCrew(
    crewId: string,
    crew: AdapterCrewDto
  ): Promise<IAdapterCrew>;

  // ------------
  async deleteCrew(crewId: string): Promise<IAdapterCrew> {
    return this.deleteEntityData("crew", crewId);
  }

  // ------------
  // assign/delete a person to/from a crew
  abstract createCrewPerson(crewPerson: AdapterCrewPersonDto): Promise<any>;

  // -------------
  async deleteCrewPerson(id: string): Promise<any> {
    return this.deleteEntityData("crew_person", id);
  }

  // -------------
  // assign/remove a craft to/from a crew
  abstract createCrewCraft(crewCraft: AdapterCrewCraftDto): Promise<any>;
  abstract deleteCrewCraft(id: string): Promise<any>;

  // ------------
  abstract updateCrewCraft(
    crewCraftId: string,
    crewCraft: AdapterCrewCraftDto
  ): Promise<any>;

  // ==========================
  // Calendars
  // ==========================
  abstract fetchCalendars(query?: QueryDto): Promise<IAdapterCalendar[]>;

  // ------------
  abstract createCalendar(
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar>;

  // ------------
  abstract updateCalendar(
    calendarId: string,
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar>;

  // ------------
  async deleteCalendar(calendarId: string): Promise<IAdapterCalendar> {
    return await this.deleteEntityData("calendar", calendarId);
  }

  // ==========================
  // External Resources
  // ==========================
  abstract fetchExternalResources(query?: QueryDto): Promise<IEntityObject[]>;

  // ------------
  async createExternalResource(
    resourceDto: IEntityObject
  ): Promise<IEntityObject> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/external_resource`,
      resourceDto
    );
    return response.data;
  }

  // ------------
  async updateExternalResource(
    resourceId: string,
    resourceDto: IEntityObject
  ): Promise<IEntityObject> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/external_resource/${resourceId}`,
      resourceDto
    );
    return response.data;
  }

  // ------------
  async deleteExternalResource(resourceId: string): Promise<IEntityObject> {
    return this.deleteEntityData("external_resource", resourceId);
  }

  // ==========================
  // Availability
  // ==========================
  abstract createAvailabilityResource(
    resource: IEntityObject
  ): Promise<IEntityObject>;

  // ----------------
  abstract updateAvailabilityResource(
    resourceId: string,
    resource: IEntityObject
  ): Promise<IEntityObject>;

  // ----------------
  abstract deleteAvailabilityResource(
    resourceId: string
  ): Promise<IEntityObject>;

  // ----------------
  abstract fetchAvailability(
    startDate: Date,
    query?: QueryDto,
    crew?: string
  ): Promise<any[]>;

  // ----------------
  abstract synchronizeResources(): Promise<IAdapterAsyncAction>;

  // ----------------
  abstract synchronizeWorkorders(): Promise<void>;

  // ------------
  async generateAvailability(
    availabilityDto: AdapterAvailabilityGenerationDto
  ): Promise<IAdapterAsyncAction> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/generate-availability`,
      availabilityDto
    );
    return response.data;
  }

  // ------------
  async updateAvailability(
    availabilityDto: AdapterAvailabilityUpdateDto
  ): Promise<void> {
    //
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/update-availability`,
      availabilityDto
    );
  }

  // ==========================
  // Workorders
  // ==========================
  abstract fetchWorkorders(query?: QueryDto): Promise<any[]>;

  // ==========================
  // Operations
  // ==========================
  abstract fetchOperations(query?: QueryDto): Promise<any[]>;

  // ==========================
  // Assignments
  // ==========================
  abstract fetchAssignments(query?: QueryDto): Promise<any[]>;

  // ==========================
  // Utilization
  // ==========================
  abstract fetchCrafts(query?: QueryDto): Promise<any[]>;

  // ------------
  abstract fetchCraftPeople(query?: QueryDto): Promise<any[]>;

  // ------------
  abstract fetchCraftUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto
  ): Promise<any[]>;

  // ------------
  abstract fetchResourceUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto
  ): Promise<any[]>;

  // ------------
  abstract fetchCrewUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto
  ): Promise<any[]>;

  // ------------
  async fetchFolderProgressSummary(
    folderId: string,
    baselineId: string | null,
    query?: QueryDto
  ): Promise<any[]> {
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
      ],
      query
    );

    // const response = await httpService.post(
    //   `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/progress_summary/search`,
    //   query || {}
    // );
    // return response.data;
    return await this.searchEntityData("progress_summary", query);
  }

  // ==========================
  // Material
  // ==========================
  abstract fetchMaterial(
    workorderNumbers: string[],
    query?: QueryDto
  ): Promise<any[]>;

  // --------------
  async checkMaterialShortage(workorderIds: string[]): Promise<void> {
    const matShortageAction: IAdapterMaterialShortageAction = {
      entity_ids: workorderIds,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/material-shortage`,
      matShortageAction
    );
  }

  // ==========================
  // Rules
  // ==========================
  abstract fetchRules(
    ruleType: string | string[],
    query?: QueryDto
  ): Promise<IAdapterRule[]>;

  // ------------
  abstract fetchFolderRules(
    folderId: string,
    query?: QueryDto
  ): Promise<IAdapterRule[]>;

  // ------------
  abstract createRule(rule: AdapterRuleDto): Promise<IAdapterRule>;

  // ------------
  abstract updateRule(
    ruleId: string,
    rule: AdapterRuleDto
  ): Promise<IAdapterRule>;

  // ------------
  deleteRule = async (ruleId: string): Promise<IAdapterRule> => {
    return await this.deleteEntityData("rule", ruleId);
  };

  // ------------
  abstract jobPriorityScore(
    jobPriorityScoreDto: AdapterJobPriorityScoreActionDto
  ): Promise<void>;

  // --------------
  // Invoke this function return workorders that satisfy the rule criteria
  abstract jobValidationFilter(
    ruleId: string,
    folderId?: string
  ): Promise<any[]>;

  // --------------
  // Invoke this function return NUMBER of workorders that satisfy the rule criteria
  abstract jobValidationFilterCount(
    ruleId: string,
    folderId?: string
  ): Promise<number>;

  // --------------
  jobValidationFolderSummary = async (
    folderDto: AdapterFolderJobValidationSummaryActionDto
  ): Promise<IAdapterRuleSummary[]> => {
    //
    const result = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-folder-summary`,
      folderDto
    );

    return result.data;
  };

  // --------------
  // Invoke this function return NUMBER of workorders that satisfy the rule criteria
  abstract jobValidationAction(
    entityName: string,
    entityIds: string[],
    ruleId: string,
    actionId: string,
    folderId?: string
  ): Promise<IAdapterAsyncAction>;

  // ==========================
  // Scheduling
  // ==========================
  async schedule(scheduleAction: IAdapterSchedulingAction): Promise<any[]> {
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/schedule`,
      scheduleAction
    );

    return data;
  }

  // --------------
  async scheduleAsync(
    asyncScheduleAction: AdapterSchedulingActionAsyncDto
  ): Promise<IAdapterAsyncAction> {
    //
    // ensure the whole query is applied
    asyncScheduleAction.query.$skip = 0;
    asyncScheduleAction.query.$limit = 0;

    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/schedule-async`,
      asyncScheduleAction
    );

    return data;
  }

  // --------------
  async autoSchedule(folderId: string): Promise<IAdapterAsyncAction> {
    //
    const autoScheduleAction: IAdapterAutoScheduleAction = {
      folder_id: folderId,
    };

    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/auto-schedule`,
      autoScheduleAction
    );

    return data;
  }

  // --------------
  // Create assignments
  // folderId   => current folder id
  // entityIds  => operation ids (selected operations)
  // person     => a person from the utilization grid { WorkCenterId: string, PersonNumber: string }
  // startDate  => date from the utilization grid
  // returns an array of ids of changed operation
  async assign(
    folderId: string,
    entityIds: string[],
    availHeaderId: string,
    startDate: Date
  ): Promise<string[]> {
    //
    const assignAction: IAdapterAssignmentAction = {
      folder_id: folderId,
      entity_ids: entityIds,
      start_date: startDate,
      availability_header_id: availHeaderId,
    };

    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/assign`,
      assignAction
    );

    return data;
  }

  // --------------
  // Delete assignments
  // assignmentId  => selected assignment
  async unassign(assignmentId: string): Promise<void> {
    await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/unassign/${assignmentId}`
    );
  }

  // ---------------------
  // "Post" workorder dates.
  // Currently only entityType="workorder" is implemented
  async post(postAction: AdapterPostActionDto): Promise<IAdapterAsyncAction> {
    //
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/post`,
      postAction
    );

    return data;
  }

  // -----------------
  // returns an array of ids of changed operations
  async createDependency(
    dependencyDto: AdapterDependencyActionDto
  ): Promise<any[]> {
    //
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/dependencies`,
      dependencyDto
    );

    return data;
  }

  // ------------
  async updateDependency(
    dependencyId: string,
    dependencyDto: AdapterDependencyActionDto
  ): Promise<any[]> {
    //
    const { data } = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/dependencies/${dependencyId}`,
      dependencyDto
    );

    return data;
  }

  // ------------
  async deleteDependency(dependencyId: string): Promise<any[]> {
    //
    const { data } = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/dependencies/${dependencyId}`
    );

    return data;
  }

  // -----------
  async autoDependencies(
    autoDependenciesDto: AdapterAutoDependenciesActionDto
  ): Promise<any[]> {
    //
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/auto-dependencies`,
      autoDependenciesDto
    );

    return data;
  }

  // -----------
  async clearDependencies(
    autoDependenciesDto: AdapterAutoDependenciesActionDto
  ): Promise<any[]> {
    //
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/clear-dependencies`,
      autoDependenciesDto
    );

    return data;
  }

  // ==========================
  // Baselines
  // ==========================
  abstract createFolderBaseline(baselineDto: AdapterBaselineDto): Promise<void>;

  // ------------
  abstract fetchFolderBaselines(
    folderId: string,
    query?: QueryDto
  ): Promise<any[]>;

  // ==========================
  // Timecards
  // ==========================
  abstract createTimeCard(
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard>;

  // ------------
  abstract updateTimeCard(
    timecardId: string,
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard>;

  // ==========================
  // Scheduling Compliance
  // ==========================
  async fetchFolderSchedulingCompliance(
    folderId: string,
    baselineId: string | null,
    query?: QueryDto
  ): Promise<any[]> {
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
      ],
      query
    );

    const result = await this.searchEntityData(
      "scheduling_compliance_summary",
      query
    );
    return result;
  }

  // ----------------
  // Cron tasks
  // ----------------
  async fetchSystemCronTasks(
    query?: QueryDto
  ): Promise<ISystemCronTaskWithEnv[]> {
    //
    const searchDto: QueryDto & { site_id: any } = {
      site_id: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      ...(query || {}),
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/system-cron-tasks/actions/search`,
      searchDto
    );

    return response.data;
  }

  // ----------------
  async updateSystemCronTask(
    taskCode: string,
    systemCronTaskDto: SystemEnvCronTaskDto
  ): Promise<ISystemEnvCronTask> {
    //
    // ensure site_id
    systemCronTaskDto.site_id = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;

    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/system-cron-tasks/${taskCode}`,
      systemCronTaskDto
    );

    return response.data;
  }

  async fetchCustomCronTasks(query?: QueryDto): Promise<ICustomCronTask[]> {
    //
    const searchDto: QueryDto & { site_id: any } = {
      site_id: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      ...(query || {}),
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/custom-cron-tasks/actions/search`,
      searchDto
    );

    return response.data;
  }

  async createCustomCronTask(
    taskCode: string,
    customCronTaskDto: CustomCronTaskCreateDto
  ): Promise<ISystemEnvCronTask> {
    //
    // ensure site_id
    const siteId = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;
    customCronTaskDto.site_id = siteId;

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/custom-cron-tasks/${taskCode}`,
      customCronTaskDto
    );

    return response.data;
  }

  async updateCustomCronTask(
    taskCode: string,
    customCronTaskDto: CustomCronTaskUpdateDto
  ): Promise<ISystemEnvCronTask> {
    //
    // ensure site_id
    const siteId = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;
    customCronTaskDto.site_id = siteId;

    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/custom-cron-tasks/${taskCode}`,
      customCronTaskDto
    );

    return response.data;
  }

  async deleteCustomCronTask(taskCode: string): Promise<ISystemEnvCronTask> {
    const siteId = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;

    const response = await httpService.delete_(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/custom-cron-tasks/${taskCode}`,
      {
        data: {
          site_id: siteId,
        },
      }
    );

    return response.data;
  }

  async getCronTaskAuthForEnvironment(): Promise<ICronTaskAuth | null> {
    const response = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/cron-task-auth`
    );

    return response.data;
  }

  async updateCronTaskAuthForEnvironment(
    cronTaskAuthDto: CronTaskAuthDto
  ): Promise<ISystemEnvCronTask> {
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/cron-tasks/cron-task-auth`,
      cronTaskAuthDto
    );

    return response.data;
  }

  // ==========================
  // Launch in ERP
  // ==========================
  abstract openRecordInErp(record: any, entityType: string): Promise<void>;

  // TEMP
  // --------------
  async getKPIData(
    kpiName: string,
    kpiParams: IKpiParams
  ): Promise<IKpiConfig> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/kpis/${kpiName}`,
      kpiParams
    );
    return response.data;
  }

  // ==========================
  // Lookups
  // ==========================
  abstract fetchLookups(lookupType: string, query?: QueryDto): Promise<any[]>;

  // ------------
  abstract createLookup(lookup: AdapterLookupDto): Promise<IAdapterLookup>;

  // ------------
  abstract updateLookup(
    lookupId: string,
    lookup: AdapterLookupDto
  ): Promise<IAdapterLookup>;

  // ------------
  deleteLookup = async (lookupId: string): Promise<IAdapterLookup> => {
    return await this.deleteEntityData("lookup", lookupId);
  };

  // --------------
  abstract getTotalReturnedBreakIns(folderId: string): Promise<any>;

  // --------------
  abstract getTotalReturnedWorkorders(folderId: string): Promise<any>;

  // --------------
  abstract getTotalScheduledHours(folderId: string): Promise<any>;

  // --------------
  abstract getTotalActualHours(folderId: string): Promise<any>;

  // --------------
  abstract getScheduledWorkOrdersCompleted(
    folderId: string,
    baselineId?: string
  ): Promise<any>;

  // Help links
  // --------------
  async getPlatformVersion(): Promise<string> {
    //
    const { data } = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/help/version`
    );
    return data?.version || "<Unknown>";
  }

  // --------------
  async getModuleHelpLinks(module: string): Promise<IExternalLink[]> {
    //
    const { data } = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/help/external-links/${module}`
    );
    return data;
  }

  // --------------
  async getHelpLinkContentUrl(
    module: string,
    externalLinkId: string
  ): Promise<string> {
    //
    const { data } = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/help/external-links/${module}/links/${externalLinkId}`
    );
    return data?.url || "";
  }

  // ---------------
  // It should be periodically called until the returned action status is in one of
  // ["success", "error", "cancelled"] (Constants.async_action_status).
  // The data grid should be completely refreshed
  async getAsyncActionStatus(id: string): Promise<IAdapterAsyncAction> {
    //
    const { data } = await httpService.get(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/async-actions/${id}`
    );

    return data;
  }

  async getMonacoEditorLib(entity_name?: string): Promise<any> {
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/dynamic-js/library`,
      {
        entity_name,
      }
    );

    return data;
  }

  // ===================
  // WO Completion data
  // ===================
  async getWorkorderCompletionDefaults(
    completionRequestAction: AdapterCompletionDefaultsActionDto
  ): Promise<any[]> {
    //
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/workorder-completion-defaults`,
      completionRequestAction
    );
    return response.data;
  }

  // --------------
  /**
   *
   * @param completionDto For Oracle EBS => OracleEbsAdapterWorkorderCompletionDto
   * @returns
   */
  async completeWorkorder(completionDto: { _id: string } & any): Promise<any> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/complete-workorder`,
      completionDto
    );
    return response.data;
  }

  // --------------
  /**
   *
   * @param uncompletionDto For Oracle EBS => OracleEbsAdapterWorkorderUncompletionDto
   * @returns
   */
  async uncompleteWorkorder(
    uncompletionDto: { _id: string } & any
  ): Promise<any> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/uncomplete-workorder`,
      uncompletionDto
    );
    return response.data;
  }
}
