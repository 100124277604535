import React, { ReactElement, useContext, useMemo } from "react";
import { RiverDateRangePicker, IRiverDateRange } from "../../..";
import { Column } from "react-data-grid";
import { TableContext } from "../../../../../context";
import { IColumnFilter } from "../../../../../interfaces";
import dayjs, { Dayjs } from "dayjs";
import styles from "./column-date-range-filter.module.scss";

interface IColumnDateRangeFilterProps {
  column: Column<any>;
}

export const ColumnDateRangeFilter: React.FC<IColumnDateRangeFilterProps> = (
  props
): ReactElement => {
  const { column } = props;
  const tableContext = useContext(TableContext);
  const currentFilters: IColumnFilter[] = tableContext?.table.columnFilters!;

  const range: IRiverDateRange | undefined = useMemo(():
    | IRiverDateRange
    | undefined => {
    let range: IRiverDateRange | undefined = undefined;
    const filters: IColumnFilter[] = currentFilters.filter(
      (filter) => filter.field === column.key
    );
    const startDateFilter: IColumnFilter = filters.find(
      (filter) => filter.operator === "$gte"
    )!;
    const endDateFilter: IColumnFilter = filters.find(
      (filter) => filter.operator === "$lt"
    )!;
    if (startDateFilter && endDateFilter) {
      range = {
        startDate: startDateFilter.value,
        endDate: endDateFilter.value,
      };
    }
    return range;
  }, [currentFilters, column]);

  const constructFilters = (
    range: IRiverDateRange
  ): { startDateFilter: IColumnFilter; endDateFilter: IColumnFilter } => {
    let { startDate, endDate } = range;

    let endDateDayjs: Dayjs = dayjs(endDate);
    endDateDayjs = endDateDayjs.add(1, "day");
    endDate = endDateDayjs.toDate();

    // Create new filters
    const newStartDateFilter: IColumnFilter = {
      field: column.key,
      operator: "$gte",
      value: startDate,
    };

    const newEndDateFilter: IColumnFilter = {
      field: column.key,
      operator: "$lt",
      value: endDate,
    };

    return {
      startDateFilter: newStartDateFilter,
      endDateFilter: newEndDateFilter,
    };
  };

  const applyRangeFilters = (range: IRiverDateRange): void => {
    // Remove existing filters for this column
    const newColumnFilters = [...currentFilters].filter(
      (filter) => filter.field !== column.key
    );

    // Add new filters for start and end dates
    const { startDateFilter, endDateFilter } = constructFilters(range);
    newColumnFilters.push(startDateFilter, endDateFilter);

    tableContext?.table.setColumnFilters(newColumnFilters);
    tableContext?.table.fetch({ newColumnFilters });
  };

  const clearRangeFilters = () => {
    const newFilters = [...currentFilters].filter(
      (filter) => filter.field !== column.key
    );
    // tableContext?.table.setColumnFilters(newFilters);
    tableContext?.table.fetch({ newColumnFilters: newFilters });
  };

  const onOpenPicker = (): void => tableContext?.table!.setResizable(false);

  const onClosePicker = (): void => tableContext?.table!.setResizable(true);

  const onClearPicker = (): void => {
    clearRangeFilters();
  };

  const onSelectRange = (range: IRiverDateRange): void => {
    applyRangeFilters(range);
  };

  return (
    <div className={styles.root}>
      <RiverDateRangePicker
        range={range}
        onOpen={onOpenPicker}
        onClose={onClosePicker}
        onSelect={onSelectRange}
        onClear={onClearPicker}
      />
    </div>
  );
};
