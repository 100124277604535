import React, { ReactElement, useContext, useState, useEffect } from "react";
import { GenericAction, IUseTable } from "../..";
import { useTranslation } from "@river/common-ui";
import TuneIcon from "@mui/icons-material/Tune";
import {
  IRiverDrawerContext,
  RiverDrawerContext,
  TableContext,
} from "../../../../context";
import { AdvancedFiltersDrawer } from "../../advanced-filters";
import { helpers, useGridActionHelpers } from "../../../../helpers";
import { Guid } from "@river/util";
import { ITableFilter } from "../../../../interfaces";
import { getBlankTableFilter } from "../../../shared";

export const ADVANCED_FILTERS_DRAWER_WIDTH = 925;

export const useAdvancedFiltersAction = () => {
  const { t } = useTranslation();
  const drawerContext: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { withSanitizedProps } = useGridActionHelpers();
  const { openDrawer, drawerOpened, drawerGUID } = drawerContext.drawer;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext!.table;
  const [baseActionGUID] = useState<string>(Guid.newGuid());
  const actionGUID: string = `${baseActionGUID}_${table.saveKey}`;
  const shouldRender: boolean = actionGUID === drawerGUID;

  const title: string = t("shared.grid_header:label.advanced_filters");

  let filterToEdit: ITableFilter;
  if (table.filterToEdit) {
    filterToEdit = table.filterToEdit;
  } else {
    if (table.appliedTableFilter) {
      const appliedFilter: ITableFilter = helpers.clone<ITableFilter>(
        table.appliedTableFilter
      );
      filterToEdit = {
        ...appliedFilter,
        simpleFilters: table.columnFilters,
      };
    } else {
      filterToEdit = {
        ...getBlankTableFilter(),
        simpleFilters: table.columnFilters,
      };
    }
  }

  useEffect(() => {
    // reopen the drawer if the table changed (when switching tabs)
    if (table.saveKey && drawerOpened) {
      openDrawer({
        width: ADVANCED_FILTERS_DRAWER_WIDTH,
        drawerGUID: actionGUID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.saveKey]);

  const onClick = () => {
    openDrawer({
      width: ADVANCED_FILTERS_DRAWER_WIDTH,
      drawerGUID: actionGUID,
    });
  };

  const renderDrawer = (): ReactElement => (
    <>{shouldRender && <AdvancedFiltersDrawer filter={filterToEdit} />}</>
  );

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={TuneIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    advancedFiltersAction: {
      icon: withSanitizedProps(renderIcon),
      renderDrawer,
      title,
      onClick,
    },
  };
};

export type IUseAdvancedFiltersAction = ReturnType<
  typeof useAdvancedFiltersAction
>;
