import React, { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  RiverDialog,
  RiverFormSelect,
  RiverSpinner,
  useNotification,
} from "@river/common-ui";
import { LookupType, RiverLookup } from "../../../../components/shared";
import {
  useOracleEbsWoMassUpdateForm,
  IOracleEbsWorkOrderMassUpdate,
  TagoutRequiredSelection,
  FirmPlannedFlagSelection,
} from "./use-oracle-ebs-wo-mass-update-form";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { IWoMassUpdateDialogProps } from "../../../shared";
import { uiConstants } from "../../../../helpers";
import { TableUiService } from "../../../table-ui.service";

export const OracleEbsWoMassUpdateDialog: React.FC<IWoMassUpdateDialogProps> = (
  props
) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useOracleEbsWoMassUpdateForm({
    submit: async () => {
      const updatedRows = await submitUseMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.workOrder),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const { submit, isProcessing, setStandaloneFields, standalone } = form;
  const massUpdateFields: IOracleEbsWorkOrderMassUpdate =
    form.standalone as IOracleEbsWorkOrderMassUpdate;

  const resetDialogState = (): void => {
    form.resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const submitUseMassUpdate = async (): Promise<any[]> => {
    const {
      PRIORITY,
      USER_DEFINED_STATUS_ID,
      PLANNER_MAINTENANCE,
      WORK_ORDER_TYPE,
      SHUTDOWN_TYPE,
      FIRM_PLANNED_FLAG,
      TAGOUT_REQUIRED,
    } = massUpdateFields;

    const updateObject: { [key: string]: any } = {
      PRIORITY,
      USER_DEFINED_STATUS_ID,
      WORK_ORDER_TYPE,
      SHUTDOWN_TYPE,
      PLANNER_MAINTENANCE,
    };

    if (TAGOUT_REQUIRED !== TagoutRequiredSelection.NO_UPDATE) {
      updateObject.TAGOUT_REQUIRED = TAGOUT_REQUIRED;
    }
    if (FIRM_PLANNED_FLAG !== FirmPlannedFlagSelection.NO_UPDATE) {
      updateObject.FIRM_PLANNED_FLAG = FIRM_PLANNED_FLAG;
    }

    const errors: any[] = [];

    const processUpdate = async (id: string) => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .updateEntityData("workorder", id, updateObject);
      } catch (err) {
        errors.push(err);
      }
    };

    if (props.workorderIds) {
      for (const id of props.workorderIds) {
        await processUpdate(id);
      }
    } else {
      const selectedRowIds: string[] = Array.from(
        tableContext?.table.selectedRowIds ?? []
      );
      for (const id of selectedRowIds) {
        await processUpdate(id);
      }
    }

    if (errors.length) {
      notify.error({
        title: t(
          "shared.wo_mass_update_dialog:notification.mass_update_failed"
        ),
        message: errors[0].message,
      });
    } else {
      const success = true;
      closeDialog(success);
    }

    return Promise.resolve([]);
  };

  const renderPriorityField = (): ReactElement => (
    <RiverLookup
      id={"priorityName"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_EBS_PRIORITY }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderMassUpdate),
          priorityName: selectedPriority.MEANING,
          PRIORITY: selectedPriority.LOOKUP_CODE,
        });
      }}
    />
  );

  const renderWorkOrderStatusField = (): ReactElement => (
    <RiverLookup
      id={"userStatusName"}
      fullWidth
      lookup={{
        type: LookupType.ORACLE_EBS_WO_STATUS,
        customFilters: [
          {
            attribute_name: "SYSTEM_STATUS",
            attribute_value: {
              operator: "$nin",
              value: [4, 5],
            },
          },
        ],
      }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderMassUpdate),
          userStatusName: selectedPriority.WORK_ORDER_STATUS,
          USER_DEFINED_STATUS_ID: selectedPriority.STATUS_ID,
        });
      }}
    />
  );

  const renderWorkOrderTypeField = (): ReactElement => (
    <RiverLookup
      id={"workOrderTypeName"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_EBS_WO_TYPE }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderMassUpdate),
          workOrderTypeName: selectedPriority.MEANING,
          WORK_ORDER_TYPE: selectedPriority.LOOKUP_CODE,
        });
      }}
    />
  );

  const renderShutdownTypeField = (): ReactElement => (
    <RiverLookup
      id={"shutdownTypeName"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_EBS_WO_SHUTDOWN_TYPE }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderMassUpdate),
          shutdownTypeName: selectedPriority.MEANING,
          SHUTDOWN_TYPE: selectedPriority.LOOKUP_CODE,
        });
      }}
    />
  );

  const renderPlannerMaintenanceField = (): ReactElement => (
    <RiverLookup
      id={"plannerMaintenanceName"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_EBS_PLANNER_MAINTENANCE }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderMassUpdate),
          plannerMaintenanceName: selectedPriority.MEANING,
          PLANNER_MAINTENANCE: selectedPriority.LOOKUP_CODE,
        });
      }}
    />
  );

  const renderTagoutRequiredField = (): ReactElement => (
    <RiverFormSelect
      id={"TAGOUT_REQUIRED"}
      fullWidth
      items={[
        {
          value: TagoutRequiredSelection.Y,
          text: t("common.button:yes"),
        },
        {
          value: TagoutRequiredSelection.N,
          text: t("common.button:no"),
        },
        {
          value: TagoutRequiredSelection.NO_UPDATE,
          text: t("common.label:do_not_update"),
        },
      ]}
    />
  );

  const renderFirmPlannedField = (): ReactElement => (
    <RiverFormSelect
      id={"FIRM_PLANNED_FLAG"}
      fullWidth
      items={[
        {
          value: FirmPlannedFlagSelection.ONE,
          text: t("common.button:yes"),
        },
        {
          value: FirmPlannedFlagSelection.TWO,
          text: t("common.button:no"),
        },
        {
          value: FirmPlannedFlagSelection.NO_UPDATE,
          text: t("common.label:do_not_update"),
        },
      ]}
    />
  );

  return (
    <RiverDialog
      title={t("shared.wo_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderPriorityField()}
      {renderWorkOrderStatusField()}
      {renderWorkOrderTypeField()}
      {renderShutdownTypeField()}
      {renderPlannerMaintenanceField()}
      {renderTagoutRequiredField()}
      {renderFirmPlannedField()}
    </RiverDialog>
  );
};
