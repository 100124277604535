import React, { ReactElement, useContext } from "react";
import { RiverDrawerContent } from "../../river-drawer-content";
import { useTranslation } from "@river/common-ui";
import { useAdvancedFilters } from "../use-advanced-filters";
import { IRiverDrawerContext, RiverDrawerContext } from "../../../../context";
import { RiverDrawerActionButton, RiverDrawerButton } from "../../../shared";
import { ITableFilter } from "../../../../interfaces";
import styles from "./advanced-filters-drawer.module.scss";

interface IAdvancedFiltersDrawerProps {
  filter?: ITableFilter | null;
}

export const AdvancedFiltersDrawer: React.FC<IAdvancedFiltersDrawerProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();

  const { drawer }: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { closeDrawer, drawerOpened } = drawer;

  const advancedFilters = useAdvancedFilters({
    filter: props.filter,
    refresh: drawerOpened,
  });

  const CloseButton = (): ReactElement => (
    <RiverDrawerButton
      onClick={() => closeDrawer()}
      text={t("common.label:close")}
      variant={"outlined"}
    />
  );

  const ApplyButton = (): ReactElement => (
    <RiverDrawerActionButton
      onClick={() => advancedFilters.apply()}
      text={t("common.button:apply")}
    />
  );

  const renderMainContent = (): ReactElement => (
    <>
      {advancedFilters.renderSimpleFilterSection()}
      {advancedFilters.renderQuerySection({
        renderSectionTitle: true,
      })}
    </>
  );

  return (
    <>
      <RiverDrawerContent
        title={t("shared.advanced_filters:dialog.title")}
        content={renderMainContent()}
        actionsContent={
          <>
            <div className={styles.actions}>
              <div className={styles.left}>{/*left aligned content*/}</div>
              <div className={styles.right}>
                <CloseButton />
                <ApplyButton />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
