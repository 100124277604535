import { ReactElement, useContext } from "react";
import jsonata from "jsonata";
import { AdapterUiService } from "../adapter-ui.service";
import {
  TabsProvider,
  EntityTableProvider,
  ColumnsProvider,
  ReactElementProvider,
  ColumnFiltersProvider,
  RiverDropdownActionsProvider,
  QueryAttributeGroupProvider,
} from "../ui-service.types";
import { IEntityObject, QueryAttributeGroupDto } from "@river/interfaces";
import {
  IWoMassUpdateDialogProps,
  IOpMassUpdateDialogProps,
  MaterialRequirementsDialog,
  IMaterialRequirementsDialogProps,
} from "../shared";
import { useSearchParams } from "react-router-dom";
import { commonModuleActions } from "../../hooks";
import { IUseTable } from "../../components/shared";
import { IBacklogContext, TabContext, TableContext } from "../../context";
import { useLocation } from "react-router";
import { IColumnFilter } from "../../interfaces";
import { BlankIcon } from "../../icons";
import { useTranslation } from "@river/common-ui";

export enum BacklogTabId {
  WORKORDERS = "workorders",
  ASSIGNMENTS = "assignments",
  OPERATIONS = "operations",
  RESOURCES = "resources",
  INSTANCES = "instances",
}

export type BacklogTableRefs = {
  [key in BacklogTabId]?: IUseTable;
};

export type BacklogInitialFiltersOverrides = {
  [key in BacklogTabId]?: IColumnFilter[] | null;
};

export type BacklogInitialQueryOverrides = {
  [key in BacklogTabId]?: QueryAttributeGroupDto | null;
};

export interface ICreateTabSelectionQueryProps {
  sourceTab: BacklogTabId;
  targetTab: BacklogTabId;
  selectedRows: IEntityObject[];
}

export type BacklogTabKeyColumnMap = {
  [sourceTab in BacklogTabId]?: {
    [targetTab in BacklogTabId]?: {
      sourceField: string;
      targetField: string;
    }[];
  };
};

enum ModuleAction {
  // General Actions
  WO_EDIT = "WO_EDIT",
  WO_JPS = "WO_JPS",
  OP_JPS = "OP_JPS",
  WO_PRINT = "WO_PRINT",
  OP_PRINT = "OP_PRINT",
  SCHEDULE = "SCHEDULE",

  // SAP-specific
  WO_USER_STATUS = "WO_USER_STATUS",
  WO_MATERIAL_SHORTAGE = "WO_MATERIAL_SHORTAGE",
  OP_EDIT = "OP_EDIT",
  OP_USER_STATUS = "OP_USER_STATUS",

  // Oracle-specific
  WO_SYNCHRONIZE = "WO_SYNCHRONIZE",
}

export type BacklogAction =
  | ModuleAction
  | commonModuleActions.GlobalFilterAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BacklogAction = {
  ...ModuleAction,
  ...commonModuleActions.GlobalFilterAction,
};

export const BacklogTabURLParamName = "tab";

export interface IGetDefaultWorkOrderColumnsProps {
  onWorkOrderClick: (workOrder: IEntityObject) => void;
  getTaskColor: (row: IEntityObject) => string;
  draggable?: boolean;
}

export interface IGetDefaultBacklogOperationColumnsProps {
  onOperationClick: (operation: IEntityObject) => void;
}

export interface IUseBacklogCurrentTableProps {
  backlogContext: IBacklogContext;
}

export abstract class BacklogUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  tableRefs: BacklogTableRefs = {};
  initialFiltersOverrides: BacklogInitialFiltersOverrides = {};
  initialQueryOverrides: BacklogInitialQueryOverrides = {};

  getBacklogRoute = (): (() => string) => {
    return (): string => {
      const location = useLocation();
      const defaultRoute: string = "/backlog";
      let route = defaultRoute;
      const defaultRouteIndex: number = location.pathname.indexOf(defaultRoute);
      if (defaultRouteIndex > 0) {
        route = location.pathname.substring(
          0,
          defaultRouteIndex + defaultRoute.length
        );
      }
      return route;
    };
  };

  getCurrentTab = (): (() => BacklogTabId) => {
    return (): BacklogTabId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(BacklogTabURLParamName) ||
        this.getDefaultTab()) as BacklogTabId;
    };
  };

  getInitialTabSimpleFilters = (tabId: BacklogTabId): ColumnFiltersProvider => {
    const initialFilterOverride: IColumnFilter[] =
      this.initialFiltersOverrides[tabId]!;
    if (initialFilterOverride) {
      return () => initialFilterOverride;
    }

    // restore column filters
    return (): IColumnFilter[] => {
      const table: IUseTable = this.tableRefs[tabId]!;
      return table ? [...table.columnFilters] : [];
    };
  };

  getInitialTabQuery = (tabId: BacklogTabId): QueryAttributeGroupProvider => {
    const initialQueryOverride: QueryAttributeGroupDto =
      this.initialQueryOverrides[tabId]!;
    if (initialQueryOverride) {
      return () => initialQueryOverride;
    }

    return (): QueryAttributeGroupDto => {
      const table: IUseTable = this.tableRefs[tabId]!;
      return table?.appliedTableFilter.query;
    };
  };

  createTabSelectionQuery = (
    props: ICreateTabSelectionQueryProps
  ): QueryAttributeGroupDto => {
    const tabKeyMap: BacklogTabKeyColumnMap = this.getTabKeyColumnMap();
    const fieldMappings: {
      sourceField: string;
      targetField: string;
    }[] = tabKeyMap[props.sourceTab]?.[props.targetTab] || [];
    const query: QueryAttributeGroupDto = { $or: [] };

    props.selectedRows.forEach((row) => {
      const rowQuery: QueryAttributeGroupDto = { $and: [] };
      fieldMappings.forEach((mapping) => {
        rowQuery.$and!.push({
          attribute_name: mapping.targetField,
          attribute_value: {
            operator: "$eq",
            value: jsonata(mapping.sourceField).evaluate(row),
          },
        });
      });
      query.$or!.push(rowQuery);
    });

    return query;
  };

  renderMaterialRequirementsDialog = (
    props: IMaterialRequirementsDialogProps
  ): ReactElement => (
    <>{props.open && <MaterialRequirementsDialog {...props} />}</>
  );

  getI18nNamespaces(): string[] {
    return [
      "module.backlog",
      "shared.schedule_selector",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.river_table_selector",
      "shared.grid_header",
      "shared.column_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.error_messages",
      "shared.lookup",
      "shared.user_status_dialog",
      "shared.wo_mass_update_dialog",
      "shared.op_mass_update_dialog",
      "shared.attachment_hierarchy",
      "shared.attachment_download",
      "shared.material_req",
      "shared.work_order_dialog",
      "shared.resource_selector",
      "shared.crafts",
      "shared.customize_schedules_dialog",
      "common.button",
      "common.label",
      "entity.folder",
      "entity.workorder",
      "entity.operation",
      "entity.availability_header",
      "entity.workcenter",
      "shared.river_data_grid",
      "shared.download_dialog",
      "shared.craft_stats",
      "shared.action",
      "shared.river_async_progress",
      "shared.async_actions",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  private getDynamicBacklogTableActionLabels = (): Record<
    BacklogTabId,
    string
  > => {
    const { t } = useTranslation();
    return {
      [BacklogTabId.WORKORDERS]: t(
        "module.backlog:action_label.show_workorders_for_selected_records"
      ),
      [BacklogTabId.ASSIGNMENTS]: t(
        "module.backlog:action_label.show_assignments_for_selected_records"
      ),
      [BacklogTabId.OPERATIONS]: t(
        "module.backlog:action_label.show_operations_for_selected_records"
      ),
      [BacklogTabId.RESOURCES]: t(
        "module.backlog:action_label.show_resources_for_selected_records"
      ),
      [BacklogTabId.INSTANCES]: t(
        "module.backlog:action_label.show_instances_for_selected_records"
      ),
    };
  };

  private getTabValues = (notIn?: BacklogTabId[]): BacklogTabId[] => {
    return this.getTabs()()
      .filter((tab) => !notIn?.includes(tab.value))
      .map((tab) => tab.value);
  };

  getDynamicFilterActionsForCurrentTab = (): RiverDropdownActionsProvider => {
    const tabContext = useContext(TabContext);
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;

    return () => {
      const sourceTab = this.getCurrentTab()();
      const tabs = this.getTabValues([sourceTab]);
      const actionLabels = this.getDynamicBacklogTableActionLabels();

      return tabs.map((targetTab) => ({
        title: actionLabels[targetTab],
        startIcon: BlankIcon,
        disabled: !selectedIds,
        onClick: () => {
          const sourceTable: IUseTable = this.tableRefs[sourceTab]!;
          const selectedRows: IEntityObject[] = sourceTable.getSelectedRows();
          const selectionQuery: QueryAttributeGroupDto =
            this.createTabSelectionQuery({
              sourceTab,
              targetTab,
              selectedRows,
            });
          this.initialFiltersOverrides[targetTab] = [];
          this.initialQueryOverrides[targetTab] = selectionQuery;
          tabContext?.setSelectedTab(targetTab);
        },
      }));
    };
  };

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract useCurrentTable(
    props: IUseBacklogCurrentTableProps
  ): EntityTableProvider;
  abstract getUserStatusResponseEntityName(): string;

  abstract getDefaultWorkOrderColumns(
    props: IGetDefaultWorkOrderColumnsProps
  ): ColumnsProvider;

  abstract getDefaultOperationColumns(
    props: IGetDefaultBacklogOperationColumnsProps
  ): ColumnsProvider;
  abstract getDefaultResourcesColumns(): ColumnsProvider;
  abstract getDefaultInstancesColumns(): ColumnsProvider;

  abstract renderWoMassUpdateDialog(
    props: IWoMassUpdateDialogProps
  ): ReactElement;

  abstract renderOpMassUpdateDialog(
    props: IOpMassUpdateDialogProps
  ): ReactElement;

  abstract renderGridActions(): ReactElementProvider;
  abstract getTabs(): TabsProvider;
  abstract getDefaultTab(): string;
  abstract getErpSpecificI18nNamespaces(): string[];
  abstract getTabKeyColumnMap(): BacklogTabKeyColumnMap;
  abstract getDefaultAssignmentColumns(): ColumnsProvider;
}
