import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import { RiverDialog, RiverSpinner, useNotification } from "@river/common-ui";
import { LookupType, RiverLookup } from "../../../../components/shared";
import {
  useSapWoMassUpdateForm,
  ISapWorkOrderMassUpdate,
} from "./use-sap-wo-mass-update-form";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { IWoMassUpdateDialogProps } from "../../../shared";
import { TableUiService } from "../../../table-ui.service";
import { uiConstants } from "../../../../helpers";

export const SapWoMassUpdateDialog: React.FC<IWoMassUpdateDialogProps> = (
  props
) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { t } = useTranslation();

  const [networkWOsSelected, setNetworkWOsSelected] = useState<boolean>(false);
  const [maintenanceWOsSelected, setMaintenanceWOsSelected] =
    useState<boolean>(false);

  const hasNetworkWOsSelected = (): boolean =>
    !!tableContext?.table
      ?.getSelectedRows()
      ?.some((row) => row["OrderCategory"] === "20");

  const hasMaintenanceWOsSelected = (): boolean =>
    !!tableContext?.table
      ?.getSelectedRows()
      ?.some(
        (row) =>
          typeof row["OrderCategory"] === "undefined" ||
          row["OrderCategory"] === "30"
      );

  useEffect(() => {
    if (props.open) {
      setNetworkWOsSelected(hasNetworkWOsSelected());
      setMaintenanceWOsSelected(hasMaintenanceWOsSelected());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
    }
  }, [props.open]);

  const form: RiverFormInstance = useSapWoMassUpdateForm({
    submit: async () => {
      const updatedRows = await submitUseMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.workOrder),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const {
    onStandaloneLookupSelect,
    setStandaloneFields,
    submit,
    isProcessing,
  } = form;
  const massUpdateFields: ISapWorkOrderMassUpdate =
    form.standalone as ISapWorkOrderMassUpdate;

  const resetDialogState = (): void => {
    form.resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const submitUseMassUpdate = async (): Promise<any[]> => {
    const updateObject: { [key: string]: any } = {};
    const omitFields: string[] = ["WBSElementExternalID"];

    Object.keys(massUpdateFields).forEach((prop) => {
      // @ts-ignore
      const val: any = massUpdateFields[prop];
      if (val !== "" && !omitFields.includes(prop)) {
        updateObject[prop] = val;
      }
    });

    const errors: any[] = [];

    const processUpdate = async (id: string) => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .updateEntityData("workorder", id, updateObject);
      } catch (err) {
        errors.push(err);
      }
    };

    if (props.workorderIds) {
      for (const id of props.workorderIds) {
        await processUpdate(id);
      }
    } else {
      const selectedRowIds: string[] = Array.from(
        tableContext?.table.selectedRowIds ?? []
      );
      for (const id of selectedRowIds) {
        await processUpdate(id);
      }
    }

    if (errors.length) {
      notify.error({
        title: t(
          "shared.wo_mass_update_dialog:notification.mass_update_failed"
        ),
        message: errors[0],
      });
    } else {
      const success = true;
      closeDialog(success);
    }

    return Promise.resolve([]);
  };

  const renderActivityTypeField = (): ReactElement => (
    <RiverLookup
      id={"Pmacttype"}
      fullWidth
      lookup={{ type: LookupType.ACTIVITY_TYPES }}
      onSelect={onStandaloneLookupSelect("Pmacttype")}
      disabled={networkWOsSelected}
    />
  );

  const renderMainWorkCenterField = (): ReactElement => (
    <RiverLookup
      id={"MnWkCtr"}
      fullWidth
      lookup={{ type: LookupType.CRAFTS }}
      onSelect={onStandaloneLookupSelect("WorkCenter", "MnWkCtr")}
      disabled={networkWOsSelected}
    />
  );

  const renderPlannerGroupField = (): ReactElement => (
    <RiverLookup
      id={"Plangroup"}
      fullWidth
      lookup={{
        type: LookupType.PLANNER_GROUPS,
      }}
      onSelect={onStandaloneLookupSelect("Plangroup")}
      disabled={networkWOsSelected}
    />
  );

  const renderRevisionField = (): ReactElement => (
    <RiverLookup
      id={"Revision"}
      fullWidth
      lookup={{ type: LookupType.REVISIONS }}
      onSelect={onStandaloneLookupSelect("Revision")}
      disabled={networkWOsSelected}
    />
  );

  const renderSystemConditionField = (): ReactElement => (
    <RiverLookup
      id={"Systcond"}
      fullWidth
      lookup={{ type: LookupType.SYSTEM_CONDITIONS }}
      onSelect={onStandaloneLookupSelect("Systcond")}
      disabled={networkWOsSelected}
    />
  );

  const renderPriorityField = (): ReactElement => (
    <RiverLookup
      id={"Priority"}
      fullWidth
      lookup={{
        type: LookupType.PRIORITY,
        entityName: networkWOsSelected ? "network_priority" : "priority",
      }}
      onSelect={onStandaloneLookupSelect("Priority")}
      disabled={maintenanceWOsSelected && networkWOsSelected}
    />
  );

  const renderWbsElementField = (): ReactElement => (
    <RiverLookup
      id={"WBSElementExternalID"}
      fullWidth
      lookup={{ type: LookupType.WBS_ELEMENTS }}
      onSelect={(selectedWbs: any) => {
        const { WBSElementInternalID, WBSElementExternalID } = selectedWbs;
        setStandaloneFields({
          ...massUpdateFields,
          WBSElementExternalID,
          WbsElem: WBSElementInternalID,
        });
      }}
    />
  );

  return (
    <RiverDialog
      title={t("shared.wo_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderActivityTypeField()}
      {renderMainWorkCenterField()}
      {renderPlannerGroupField()}
      {renderRevisionField()}
      {renderSystemConditionField()}
      {renderPriorityField()}
      {renderWbsElementField()}
    </RiverDialog>
  );
};
