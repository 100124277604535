import React, { useContext, useState } from "react";

import { PrimaryButton } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { IAdapterCrew } from "@river/interfaces";

import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import headerStyles from "../../shared/common-header/common-header.module.scss";

import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { CrewsAction, CrewsUiService } from "../../../services";
import { CrewDialog } from "../crew-dialog";

interface ICrewSubHeaderProps {
  openEditDialog: (crew: IAdapterCrew) => void;
}
export const CrewsSubHeader: React.FC<ICrewSubHeaderProps> = (props) => {
  const { t } = useTranslation();
  const [crewDialogOpened, setCrewDialogOpened] = useState<boolean>(false);
  const tableContext = useContext(TableContext);

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService =
    adapterContext?.service!.getCrewsUiService()!;

  const openDialog = (): void => {
    tableContext?.table.setSelectedRowIds(new Set());
    setCrewDialogOpened(true);
  };
  const closeDialog = (): void => setCrewDialogOpened(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction module={ModuleKey.CREWS} action={CrewsAction.CREATE}>
          <PrimaryButton
            onClick={openDialog}
            disabled={!uiService.canManageCrews()}
            text={t("module.crews:button.add_crew")}
          />
        </ProtectedAction>
      </div>
      <CrewDialog
        open={crewDialogOpened}
        onClose={(crew) => {
          closeDialog();
          if (!!crew) {
            tableContext?.table.refresh();
            props.openEditDialog(crew);
          }
        }}
      />
    </>
  );
};
