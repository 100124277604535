import React, { ReactElement, ReactNode } from "react";
import { RiverSpinner } from "@river/common-ui";
import styles from "./async-progress-spinner.module.scss";

interface IAsyncProgressSpinnerProps {
  text: string | ReactNode;
  muteSpinner?: boolean; // hide spinner to display text only (e.g. when action finished)
}
export const AsyncProgressSpinner: React.FC<IAsyncProgressSpinnerProps> = (
  props: IAsyncProgressSpinnerProps
): ReactElement => {
  const { text } = props;

  const renderText = (): ReactElement => {
    return (
      <>
        {typeof props.text === "string" ? (
          <div className={styles.text}>{text}</div>
        ) : (
          props.text
        )}
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <RiverSpinner show={!props.muteSpinner} />
      </div>
      {renderText()}
    </div>
  );
};
