import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { uiConstants } from "../../helpers";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
  ISupervisorScheduleProgressFilters,
} from "./supervisor-schedule-utilization-ui-service";
import { IAdapterBaseline, IAdapterFolder } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";

interface IUseSupervisorScheduleUtilizationProgressSummaryProps {
  currentBaseline: IAdapterBaseline | undefined;
  progressSummaryFilters: ISupervisorScheduleProgressFilters;
}

export const useSupervisorScheduleUtilizationProgressSummary = (
  props: IUseSupervisorScheduleUtilizationProgressSummaryProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const fetchProgressSummary = async (fetchProps: ITableFetchFunctionProps) =>
    await adapterContext!.service
      .getAdapterService()
      .fetchFolderProgressSummary(
        currentSchedule._id,
        props.currentBaseline?._id || null //baselineId => it should come from the current/selected baseline in SupervisorScheduleContext
      );

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "progress_summary",
      columns: [],
    };
  };

  const { entityName } = getTableParams(t);

  const table: IUseTable = useTable({
    columns: [],
    fetchFunction: fetchProgressSummary,
    dependencies: [
      !!site,
      currentTab === SupervisorScheduleUtilizationTabId.PROGRESS,
    ],
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.PROGRESS,
    fetchTriggers: [props.currentBaseline, props.progressSummaryFilters],
    rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
