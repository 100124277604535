import { useTranslation } from "@river/common-ui";
import React from "react";
import { PageHeader } from "../../page-header";

export const RestrictionsHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      showSiteSelector={false}
      pageTitle={t("module.restrictions:module.title")}
    />
  );
};
