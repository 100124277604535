import { FC, useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Routes, Route } from "react-router-dom";

import "reflect-metadata"; //necessary for class-validator/class-transformer to work properly

import { Config } from "@river/config";
import { extractSubdomain } from "@river/util";
import {
  IAccessTokenPayload,
  IAdapterUserContextProperties,
  IModuleSummary,
  ILanguage,
  ICustomerInfo,
  ICustomer,
} from "@river/interfaces";

import {
  AdapterLogin,
  LoginCallback,
  LogoutCallback,
} from "./components/adapter-login";
import {
  Logout,
  NotFound,
  NotificationProvider,
  defaultTheme,
  NotificationContainer,
  ResetPassword,
  httpService,
  authService,
  localStorageService,
  useNotification,
} from "@river/common-ui";
import RhineRiverIcon from "./icons/RhineRiverIcon.png";
import ColoradoRiverIcon from "./icons/ColoradoRiverIcon.png";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import { Main } from "./components/main";
import {
  ApplicationContext,
  AdapterUserContext,
  AdapterUiContext,
  AdapterUserContextProp,
} from "./context";

import { SitesUiService, userPreferencesService } from "./services";
import { Constants } from "@river/constants";

import { AdapterUiServiceFactory } from "./services/adapter-ui-service-factory";
import { AdapterUiService } from "./services";
import { Protected } from "./components/protected";

import i18n, { init18n, DEFAULT_LANGUAGE } from "./i18n";
import { loadDayJsLocale } from "./locales";
import { useNavigate } from "react-router";

declare global {
  interface Window {
    baseUrl: any;
  }
}

// enum AuthType {
//   BASIC = "basic",
//   OAUTH2 = "oauth2",
//   SAML2 = "saml2",
// }

const adapterTypeLogoMap: { [adapterType: string]: string } = {
  [Constants.adapter_type.sap]: RhineRiverIcon,
  [Constants.adapter_type.oracle_cloud]: ColoradoRiverIcon,
  [Constants.adapter_type.oracle_ebs]: ColoradoRiverIcon,
  [Constants.adapter_type.jde]: ColoradoRiverIcon,
};

const App: FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IAccessTokenPayload>(
    authService.getCurrentUser() || {
      user_id: "",
      display_name: "",
    }
  );
  const [userModules, setUserModules] = useState<IModuleSummary[]>();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfo | undefined>();
  const [authType, setAuthType] = useState<string>(
    Constants.authentication_type.basic
  );
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [applicationVersion, setApplicationVersion] = useState<string>("");

  const [userProperties, setUserProperties] =
    useState<IAdapterUserContextProperties>({});

  const notify = useNotification();
  const adapterUiRef = useRef<AdapterUiService | undefined>();

  const loadAvailableLanguages = async (): Promise<void> => {
    const availableLanguages: ILanguage[] = (await adapterUiRef
      .current!.getAdapterService()
      .getEnvironmentLanguages({
        query: {
          $and: [
            {
              attribute_name: "enabled",
              attribute_value: {
                operator: "$eq",
                value: true,
              },
            },
          ],
        },
      })) as unknown as ILanguage[];
    setLanguages(availableLanguages);
  };

  const loadApplicationVersion = async (): Promise<void> => {
    try {
      const result: string = await adapterUiRef
        .current!.getAdapterService()
        .getPlatformVersion();
      setApplicationVersion(result);
    } catch (message) {
      notify.error({ message });
    }
  };

  const changeFavicon = () => {
    const customer: ICustomer = customerInfo?.customer!;
    const adapterType: string = customer?.adapter_type;
    const favicon: string = adapterTypeLogoMap[adapterType];
    let link: HTMLElement | null = document.querySelector("link[rel~='icon']");
    if (link) {
      //@ts-ignore
      link.href = favicon;
    }
  };

  useEffect(() => {
    changeFavicon();
    // eslint-disable-next-line
  }, [customerInfo]);

  useEffect(() => {
    const init = async () => {
      const subdomain = extractSubdomain(
        window.location.hostname,
        Config.web.REACT_APP_ADAPTER_WEB_DOMAIN
      );

      httpService.setAuthInterceptors(
        Config.web.REACT_APP_ADAPTER_API_PROTOCOL +
          subdomain +
          "." +
          Config.web.REACT_APP_ADAPTER_API_DOMAIN +
          "/api/adapter"
      );

      adapterUiRef.current =
        await AdapterUiServiceFactory.createUiAdapter(subdomain);

      const customerInfo = adapterUiRef.current
        .getAdapterService()
        .getCustomerInfo();
      // authType will depend on the customer: "basic", "oauth2", "saml"
      setAuthType(
        customerInfo.environment.authentication ||
          Constants.authentication_type.basic
      );

      setCustomerInfo(customerInfo);

      let language: string =
        localStorageService.getLanguage() || DEFAULT_LANGUAGE;
      init18n({
        baseUrl: adapterUiRef.current
          .getAdapterService()
          .getAdapterSvcBaseUrl(),
        language,
      });
      setUserProperties(
        Object.assign({}, userProperties, {
          [AdapterUserContextProp.LANGUAGE]: language,
        })
      );
      loadDayJsLocale(navigator.language);

      await loadAvailableLanguages();

      setIsInitialized(true);
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authService.isLoggedIn() && isInitialized) {
      loadUserProperties();
      loadApplicationVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, authService.isLoggedIn(), user]);

  const loadUserProperties = async (): Promise<void> => {
    const sitesUiService: SitesUiService =
      adapterUiRef.current!.getSitesUiService()!;
    let shouldSaveProperties: boolean = false;

    const localStorageLanguage: string = localStorageService.getLanguage()!;
    let properties: IAdapterUserContextProperties =
      await userPreferencesService.getUserProperties(
        adapterUiRef.current!.getAdapterService()
      );

    if (
      !properties ||
      (localStorageLanguage &&
        localStorageLanguage !== properties[AdapterUserContextProp.LANGUAGE])
    ) {
      shouldSaveProperties = true;
    }

    try {
      if (!properties) {
        properties = await sitesUiService.getDefaultUserProperties();
      } else {
        const hasAccessToSite: boolean = await sitesUiService.hasAccessToSite(
          properties.site.site_id
        );
        if (!hasAccessToSite) {
          properties.site = await sitesUiService.getDefaultSite();
          shouldSaveProperties = true;
        }
      }
    } catch (e) {
      navigate("/login");
    }

    if (localStorageLanguage) {
      properties[AdapterUserContextProp.LANGUAGE] = localStorageLanguage;
    }

    if (shouldSaveProperties) {
      await saveUserProperties(properties);
    } else {
      setUserProperties(properties);
      const language: string = properties[AdapterUserContextProp.LANGUAGE];
      localStorageService.setLanguage(language);
      i18n.changeLanguage(language);
    }
  };

  const fetchUserModules = async (): Promise<void> =>
    await adapterUiRef
      .current!.getAdapterService()
      .getMenuModules()
      .then((modules: IModuleSummary[]) => {
        setUserModules!(modules);
      });

  const onLoggedIn = async (
    user: IAccessTokenPayload | null
  ): Promise<void> => {
    setUser(user!);
    await fetchUserModules();
  };

  const updateUserProperty = async (
    prop: AdapterUserContextProp,
    value: any
  ): Promise<void> => {
    const newProperties: IAdapterUserContextProperties = Object.assign(
      {},
      userProperties,
      {
        [prop]: value,
      }
    );
    await saveUserProperties(newProperties);
  };

  const updateUserProperties = (
    properties: Partial<AdapterUserContextProp>
  ): void => {
    const newProperties: IAdapterUserContextProperties = Object.assign(
      {},
      userProperties,
      properties
    );
    saveUserProperties(newProperties);
  };

  const saveUserProperties = async (
    properties: IAdapterUserContextProperties
  ): Promise<void> => {
    setUserProperties(properties);

    const language: string = properties[AdapterUserContextProp.LANGUAGE];
    localStorageService.setLanguage(language);
    i18n.changeLanguage(language);

    if (
      authService.isLoggedIn() &&
      properties &&
      Object.keys(properties).length
    ) {
      return await userPreferencesService.setUserProperties(
        adapterUiRef.current!.getAdapterService(),
        properties
      );
    }
  };

  return (
    <>
      {isInitialized && (
        <>
          <CssBaseline />
          <ApplicationContext.Provider
            value={{
              languages,
              reloadLanguages: loadAvailableLanguages,
              customerInfo: customerInfo,
              applicationVersion,
            }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={defaultTheme}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={navigator.language.toLowerCase()}
                >
                  <NotificationProvider>
                    <AdapterUserContext.Provider
                      value={{
                        user,
                        userProperties,
                        saveUserProperties,
                        updateUserProperty,
                        updateUserProperties,
                        userModules,
                        fetchUserModules,
                        onLoggedIn,
                      }}
                    >
                      <AdapterUserContext.Consumer>
                        {(value) => {
                          adapterUiRef
                            .current!.getAdapterService()
                            .setUserContext(value!);
                          return (
                            <AdapterUiContext.Provider
                              value={{
                                service: adapterUiRef.current!,
                              }}
                            >
                              <DndProvider backend={HTML5Backend}>
                                <Routes>
                                  <Route
                                    path="/login"
                                    element={
                                      <AdapterLogin
                                        apiUrl={`${adapterUiRef.current
                                          ?.getAdapterService()
                                          .getApiBasicAuthUrl()}`}
                                        onLoggedIn={onLoggedIn}
                                        authType={authType}
                                      />
                                    }
                                  />
                                  <Route
                                    path="/logout"
                                    element={
                                      <Logout
                                        apiUrl={`${adapterUiRef.current
                                          ?.getAdapterService()
                                          .getApiLogoutUrl()}`}
                                        redirectUrl={
                                          customerInfo?.environment
                                            ?.authentication !==
                                          Constants.authentication_type.basic
                                            ? "/logout-callback"
                                            : "/"
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/reset-password"
                                    element={<ResetPassword />}
                                  />
                                  <Route
                                    path="/login-callback"
                                    element={
                                      <LoginCallback onLoggedIn={onLoggedIn} />
                                    }
                                  />
                                  <Route
                                    path="/logout-callback"
                                    element={<LogoutCallback />}
                                  />
                                  <Route
                                    path="/not-found"
                                    element={<NotFound />}
                                  />
                                  <Route
                                    path="/*"
                                    element={
                                      <Protected>
                                        <Main />
                                      </Protected>
                                    }
                                  />
                                </Routes>
                              </DndProvider>
                            </AdapterUiContext.Provider>
                          );
                        }}
                      </AdapterUserContext.Consumer>
                      <NotificationContainer />
                    </AdapterUserContext.Provider>
                  </NotificationProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ApplicationContext.Provider>
        </>
      )}
    </>
  );
};

export default App;
