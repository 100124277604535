import { ReactElement, useState } from "react";
import { AvailabilityExcelExportDialog } from "./availability-excel-export-dialog";

interface IUseAvailabilityExcelExportProps {
  startDate: Date;
}

export const useAvailabilityExcelExport = (
  props: IUseAvailabilityExcelExportProps
) => {
  const { startDate } = props;
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const renderDialog = (): ReactElement => (
    <AvailabilityExcelExportDialog
      open={dialogOpened}
      startDate={startDate}
      onClose={() => setDialogOpened(false)}
    />
  );

  return {
    dialogOpened,
    setDialogOpened,
    renderDialog,
  };
};

export type IUseAvailabilityExcelExport = ReturnType<
  typeof useAvailabilityExcelExport
>;
