import { Column } from "react-data-grid";
import {
  AvailabilityUiService,
  IGetAvailabilityResourcesColumnsProps,
  IResourceDialogProps,
} from "../../availability-ui.service";
import { GenericAvatarIcon } from "../../../icons";
import { useTableCellRenderers } from "../../../hooks";
import { JdeAvailabilityResourceDialog } from "./jde-availability-resource-dialog/jde-availability-resource-dialog";
import { ModuleKey } from "../../../components/sidebar-menu";
import { AvailabilityAction } from "../../../components/availability/availability";
import { useAllowedAction } from "../../../components/protected-action";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../../../components/shared/river-data-grid/river-data-grid.module.scss";
import availabilityStyles from "../../../components/availability/availability.module.scss";
import clsx from "clsx";

export class JdeAvailabilityUiService extends AvailabilityUiService {
  getAvailabilityResourcesColumns = (
    props: IGetAvailabilityResourcesColumnsProps
  ): Column<any>[] => {
    const { onResourceClick } = props;
    const { t } = useTranslation();
    const { renderCell, renderCellText } = useTableCellRenderers();
    const isActionAllowed = useAllowedAction();
    return [
      {
        key: "F0101_ALPH",
        name: t("entity.availability_header:availability_header.F0101_ALPH"),
        width: 190,
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div
                  className={clsx([
                    availabilityStyles.personCell,
                    isActionAllowed(
                      ModuleKey.AVAILABILITY,
                      AvailabilityAction.UPDATE_RESOURCE
                    ) && dataGridStyles.dataGridLink,
                  ])}
                  onClick={() => {
                    if (
                      isActionAllowed(
                        ModuleKey.AVAILABILITY,
                        AvailabilityAction.UPDATE_RESOURCE
                      )
                    ) {
                      onResourceClick!(formatterProps.row);
                    }
                  }}
                >
                  <GenericAvatarIcon />
                  <div>{renderCellText({ formatterProps })}</div>
                </div>
              ),
            })}
          </>
        ),
      },
      {
        key: "F0101_AN8",
        name: t("entity.availability_header:availability_header.F0101_AN8"),
        width: 200,
      },
      {
        key: "WorkCenters.F30006_MCU",
        name: t("entity.availability_header:workcenter_ref.F30006_MCU"),
        width: 200,
      },
      {
        key: "crews.crew",
        name: t("entity.availability_header:availability_header.crews"),
        width: 200,
      },
      {
        key: "calendar",
        name: t("entity.availability_header:availability_header.calendar"),
        width: 120,
      },
      {
        key: "calendar_start_date",
        name: t(
          "entity.availability_header:availability_header.calendar_start_date"
        ),
        width: 140,
      },
      {
        key: "calendar_end_date",
        name: t(
          "entity.availability_header:availability_header.calendar_end_date"
        ),
        width: 140,
      },
    ];
  };

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.company",
      "shared.crafts",
      "entity.external_resource",
      "entity.address_book",
      "entity.workcenter",
    ];
  }
  getEditAvailabilityHeaderKey(): string {
    return "F0101_AN8";
  }

  renderResourceDialog(props: IResourceDialogProps) {
    return <JdeAvailabilityResourceDialog {...props} />;
  }
}
