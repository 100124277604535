import { ITranslationProps } from "@river/common-ui";
import React, { ReactElement, useContext, useState } from "react";
import { Restrictions } from "./restrictions";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { RestrictionsUiService } from "../../services/restrictions-ui.service";
import { withTranslation } from "react-i18next";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <Restrictions />}</>
);

export const RestrictionsModule: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: RestrictionsUiService =
    adapterContext!.service.getRestrictionsUiService();
  const namespaces: string[] = uiService.getI18nNamespaces();

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
