import { SitesUiService } from "../sites-ui.service";
import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { IUserContextSite } from "../../context";

export class JdeSitesUiService extends SitesUiService {
  protected getSiteEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "company",
      columns: [
        {
          key: "F0010_CO",
          name: t("entity.company:company.F0010_CO"),
        },
        {
          key: "F0010_NAME",
          name: t("entity.company:company.F0010_NAME"),
        },
        {
          key: "F0010_ALTC",
          name: t("entity.company:company.F0010_ALTC"),
        },
      ],
    };
  };

  getSitePropertyFromErpRecord = (siteRec: any): IUserContextSite => {
    if (!siteRec) {
      return {
        site_id: "",
        site_code: "",
        description: "",
      };
    }
    const { F0010_CO, F0010_NAME } = siteRec;
    return {
      site_id: F0010_CO,
      site_code: F0010_CO,
      description: F0010_NAME,
    };
  };

  protected getSiteRowKey = (siteRec: any): any => {
    return siteRec[this.getSiteKeyColumn()];
  };

  getSiteKeyColumn = (): string => "F0010_CO";
}
