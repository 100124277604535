import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";
import { IsNotEmpty } from "class-validator";
import { Constants } from "@river/constants";

export interface IAvailabilityExcelExport {
  startDate: Date;
  finishDate: Date;
}

class AvailabilityExcelExport {
  constructor(obj: IAvailabilityExcelExport) {
    Object.assign(this, obj);
  }

  @IsNotEmpty()
  // @ts-ignore
  startDate: Date;

  @IsNotEmpty()
  // @ts-ignore
  finishDate: Date;
}

export const useAvailabilityExcelExportForm = (props: {
  startDate: Date;
  finishDate: Date;
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const { startDate, finishDate } = props;
  return useRiverForm<Object, Object, AvailabilityExcelExport>({
    standalone: {
      fields: new AvailabilityExcelExport({ startDate, finishDate }),
      fieldDefs: [
        { fieldName: "startDate", dataType: Constants.data_type.date },
        { fieldName: "finishDate", dataType: Constants.data_type.date },
      ],
    },
    submit: props.submit,
    labels: {
      startDate: t("module.availability:excel_download_dialog.start_date"),
      finishDate: t("module.availability:excel_download_dialog.finish_date"),
    },
  });
};
