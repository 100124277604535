import React, { ReactElement, ReactNode } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./river-progress-bar.module.scss";

export interface IRiverProgressBarProps {
  text?: string | ReactNode;
  percentage?: number;
  processed?: number;
  total?: number;
}
export const RiverProgressBar: React.FC<IRiverProgressBarProps> = (
  props: IRiverProgressBarProps
): ReactElement => {
  const { text, percentage, processed, total } = props;
  const value: number = getValue();

  function getValue(): number {
    let value: number = 0;
    if (typeof percentage !== "undefined") {
      value = percentage;
    } else {
      try {
        value = (processed! / total!) * 100;
      } catch (e) {
        console.error(e);
      }
    }
    return Math.round(value);
  }

  const renderText = (): ReactElement => {
    return (
      <>
        {typeof props.text === "string" ? (
          <div className={styles.text}>{text}</div>
        ) : (
          props.text
        )}
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <LinearProgress
          className={styles.bar}
          variant="determinate"
          color="primary"
          value={value}
        />
      </div>
      {!!props.text && renderText()}
    </div>
  );
};
