import { Column } from "react-data-grid";
import { uiConstants } from "../../../helpers";
import {
  IQueryAttribute,
  IQueryAttributeGroup,
  QueryAttributeDto,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import { IColumnFilter } from "../../../interfaces";
import { AttributeGroupOperator } from "../advanced-filters";
import { IUseTable } from "./useTable";

export namespace gridHelpers {
  const UTILITY_COLUMN_KEYS = Object.values(uiConstants.utilityColumnTypes);

  export function getNonUtilityColumns(columns: Column<any>[]) {
    return columns.filter((c: any) => !UTILITY_COLUMN_KEYS.includes(c.key));
  }

  const isQueryAttribute = (
    element: QueryAttributeDto | QueryAttributeGroupDto
  ): boolean => !!element.attribute_name;

  export const hasAppliedAttribute = (
    attributeGroup: QueryAttributeGroupDto
  ): boolean => {
    if (attributeGroup) {
      const operator: string = Object.keys(attributeGroup)[0];
      if (operator) {
        const elementArray: (QueryAttributeDto | QueryAttributeGroupDto)[] =
          attributeGroup[operator]!;
        if (elementArray) {
          for (let i = 0; i < elementArray.length; i++) {
            const element: QueryAttributeDto | QueryAttributeGroupDto =
              elementArray[i];
            if (isQueryAttribute(element)) {
              return true;
            } else {
              return hasAppliedAttribute(element as QueryAttributeGroupDto);
            }
          }
        }
      }
    }
    return false;
  };

  export const hasFiltersApplied = (table: IUseTable | undefined): boolean => {
    if (!table) return false;

    const hasSimpleFiltersApplied: boolean =
      table.lastFetchColumnFilters.length > 0;

    const hasAdvancedFiltersApplied = hasAppliedAttribute(
      table.appliedTableFilter?.query!
    );

    return hasSimpleFiltersApplied || hasAdvancedFiltersApplied;
  };

  export const getSimpleFiltersQueryGroup = (
    simpleFilters: IColumnFilter[]
  ): IQueryAttributeGroup => {
    const simpleFiltersQuery: IQueryAttribute[] = simpleFilters.map(
      (simpleFilter) => ({
        attribute_name: simpleFilter.field,
        attribute_value: {
          operator: simpleFilter.operator,
          value: simpleFilter.value,
        },
      })
    );

    return {
      [AttributeGroupOperator.AND]: simpleFiltersQuery,
    };
  };

  // Merge arr2 to arr1 replacing filters with the same field name
  export const mergeColumnFilters = (
    arr1: IColumnFilter[],
    arr2: IColumnFilter[]
  ): IColumnFilter[] => {
    const merged: IColumnFilter[] = [...arr1];
    arr2.forEach((filter: IColumnFilter) => {
      const index: number = merged.findIndex(
        (el: IColumnFilter) => el.field === filter.field
      );
      if (index === -1) {
        merged.push(filter);
      } else {
        merged.splice(index, 1, filter);
      }
    });
    return merged;
  };
}
