import { ICustomerInfo } from "@river/interfaces";
import { AdapterService } from "./adapter.service";
import { ExternalResourcesUiService } from "./external-resources-ui.service";
import { ShiftsUiService } from "./shifts-ui.service";
import { CalendarsUiService } from "./calendars-ui.service";
import { BacklogUiService } from "./backlog-ui-service";
import { SchedulesUiService } from "./schedules-ui.service";
import { ScheduleUiService } from "./schedule-ui-service";
import { ScheduleUtilizationUiService } from "./schedule-utilization-ui-service";
import { SitesUiService } from "./sites-ui.service";
import { AvailabilityUiService } from "./availability-ui.service";
import { PersonUiService } from "./person-ui.service";
import { MaterialRequirementsUiService } from "./material-requirements-ui.service";
import { UserRolesUiService } from "./user-roles-ui.service";
import { UsersUiService } from "./users-ui.service";
import { TaskHelpersUiService } from "./task-helpers-ui.service";
import { CraftsUiService } from "./crafts-ui.service";
import { OperationsUiService } from "./operations-ui.service";
import { ValidationDashboardUiService } from "./validation-dashboard-ui.service";
import { ExecutionUiService } from "./execution-ui-service";
import { TimeCardUiService } from "./timecard-ui.service";
import { CrewsUiService } from "./crews-ui.service";
import { TableUiService } from "./table-ui.service";
import { RestrictionsUiService } from "./restrictions-ui.service";
import { SupervisorScheduleUiService } from "./supervisor-schedule-ui-service";
import { SupervisorSchedulesUiService } from "./supervisor-schedules-ui-service";
import { SupervisorTimeCardUiService } from "./supervisor-timecard-ui.service";
import { SupervisorScheduleUtilizationUiService } from "./supervisor-schedule-utilization-ui-service";
import { ScheduleReportUiService } from "./schedule-report-ui.service";

export abstract class AdapterUiService {
  private _service: AdapterService | null = null;

  constructor(protected readonly customerInfo: ICustomerInfo) {}

  protected abstract createAdapterService(): AdapterService;

  getAdapterService(): AdapterService {
    if (!this._service) {
      this._service = this.createAdapterService();
    }
    return this._service;
  }

  abstract getSupervisorScheduleUiService(): SupervisorScheduleUiService;

  abstract getSupervisorTimeCardUiService(): SupervisorTimeCardUiService;

  abstract getSupervisorScheduleUtilizationUiService(): SupervisorScheduleUtilizationUiService;

  abstract getSitesUiService(): SitesUiService;

  abstract getBacklogUiService(): BacklogUiService;

  abstract getSchedulesUiService(): SchedulesUiService;

  abstract getSupervisorSchedulesUiService(): SupervisorSchedulesUiService;

  abstract getScheduleUiService(): ScheduleUiService;

  abstract getScheduleUtilizationUiService(): ScheduleUtilizationUiService;

  abstract getUsersUiService(): UsersUiService;

  abstract getAvailabilityUiService(): AvailabilityUiService;

  abstract getExternalResourcesUiService(): ExternalResourcesUiService;

  abstract getMaterialRequirementsUiService(): MaterialRequirementsUiService;

  abstract getPersonUiService(): PersonUiService;

  abstract getTaskHelpersUiService(): TaskHelpersUiService;

  abstract getCraftsUiService(): CraftsUiService;

  abstract getOperationsUiService(): OperationsUiService;

  abstract getExecutionUiService(): ExecutionUiService;

  abstract getShiftsUiService(): ShiftsUiService;

  abstract getCalendarsUiService(): CalendarsUiService;

  abstract getValidationDashboardUiService(): ValidationDashboardUiService;

  abstract getScheduleReportUiService(): ScheduleReportUiService;

  abstract getTimeCardUiService(): TimeCardUiService;

  abstract getCrewsUiService(): CrewsUiService;

  abstract getTableUiService(): TableUiService;

  abstract getUserRolesUiService(): UserRolesUiService;

  abstract getRestrictionsUiService(): RestrictionsUiService;
}
