import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { IEntityObject } from "@river/interfaces";
import { useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiService,
  AdapterService,
  ExternalResourcesUiService,
} from "../../..";

import { ArrayNotEmpty, Equals, IsNotEmpty } from "class-validator";

export interface IStandaloneValidator {
  enteredAddressNumber: string;
}

export const useJdeAvailabilityResourceDialogForm = (props: {
  resource: IEntityObject | null;
  selectedAddressBook: IEntityObject | null;
  onCreate?: (data: IEntityObject) => void;
  onUpdate?: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  class StandaloneValidator {
    constructor(obj: IStandaloneValidator) {
      Object.assign(this, obj);
    }
    @IsNotEmpty()
    @Equals(props.selectedAddressBook?.F0101_AN8?.toString() || "", {
      message: (opts): string =>
        `shared.error_messages:validation.F0101_AN8?name=${opts.value}`,
    })
    // @ts-ignore
    enteredAddressNumber: string;
  }

  class JdeAvailabilityCreateResourceDialogDto {
    @ArrayNotEmpty({
      message: t("module.availability:notification.needs_at_least_one_craft", {
        craft: t("shared.crafts:label.craft"),
      }),
    })

    // @ts-ignore
    WorkCenters: IEntityObject[];

    // @ts-ignore
    number_of_units: number;

    // @ts-ignore
    is_crew_workcenter: boolean;

    // @ts-ignore
    F0101_ALPH: string;

    // Other ERP specific attributes
    [s: string]: any;
  }

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterUiService: AdapterUiService = adapterContext?.service!;
  const adapterService: AdapterService = adapterUiService.getAdapterService();
  const externalResourcesUiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;
  const craftsArrayKey: string = externalResourcesUiService.craftsArrayKey;

  return useRiverForm<
    JdeAvailabilityCreateResourceDialogDto,
    IEntityObject,
    StandaloneValidator
  >({
    initialDto: Object.assign(new JdeAvailabilityCreateResourceDialogDto(), {
      WorkCenters: props.resource?.[craftsArrayKey] ?? [],
      number_of_units: 1,
      is_crew_workcenter: false,
    }),
    standalone: {
      fields: new StandaloneValidator({
        enteredAddressNumber: props.resource?.F0101_AN8?.toString() || "",
      }),
      fieldDefs: [
        {
          fieldName: "enteredAddressNumber",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },
    entityName: "availability",
    instanceToEdit: props.resource,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (
      dto: JdeAvailabilityCreateResourceDialogDto
    ): Promise<IEntityObject> => {
      return (await adapterService.createAvailabilityResource({
        ...dto,
        F0101_AN8: props.selectedAddressBook?.F0101_AN8,
        F0101_ALPH: props.selectedAddressBook?.F0101_ALPH,
      })) as IEntityObject;
    },
    update: async (
      dto: JdeAvailabilityCreateResourceDialogDto
    ): Promise<void> => {
      await adapterService.updateAvailabilityResource(
        props.resource!._id! as string,
        {
          ...dto,
          F0101_AN8: props.selectedAddressBook?.F0101_AN8,
          F0101_ALPH: props.selectedAddressBook?.F0101_ALPH,
        }
      );
    },
    labels: {
      enteredAddressNumber: t("module.availability:label.address_number"),
      F0101_ALPH: t("module.availability:label.person_name"),
    },
  });
};
