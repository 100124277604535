import { IAdapterShift } from "@river/interfaces";
import { FC, useState, MouseEvent } from "react";
import { Box, Popover, Tooltip } from "@mui/material";
import styles from "./shift-card.module.scss";
import { useTranslation } from "@river/common-ui";
import clsx from "clsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { riverGenerateIcon } from "../../shared/river-icon-picker";
import { IRiverIconButtonProps, RiverIconButton } from "../../shared";

interface IShiftCardProps {
  shiftData: IAdapterShift;
  editFlag?: boolean;
  actions?: IRiverIconButtonProps[];
  rootClass?: string;
}

export const DEFAULT_SHIFT_CARD_COLOR = "#2770CA";
export const DEFAULT_SHIFT_CARD_ICONS_LIST: string[] = [
  "WbSunny",
  "Air",
  "Storm",
  "Thermostat",
  "AcUnit",
  "Cyclone",
  "Flood",
  "Landslide",
  "ModeNight",
  "Nightlight",
  "Thunderstorm",
  "Tornado",
  "Tsunami",
  "Water",
  "Cloud",
  "CloudQueue",
  "NightsStay",
  "SevereCold",
];
export const DEFAULT_SHIFT_CARD_ICON = DEFAULT_SHIFT_CARD_ICONS_LIST[0];

export const ShiftCard: FC<IShiftCardProps> = ({
  shiftData,
  editFlag,
  actions,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickShowPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const formatValue = (value: number) => String("0" + value).slice(-2);

  const startTimeString = `${formatValue(shiftData.start_hours)}:${formatValue(
    shiftData.start_minutes
  )}`;
  const endTimeString = `${formatValue(shiftData.end_hours)}:${formatValue(
    shiftData.end_minutes
  )}`;

  const available_hours = (shiftData as any).availableHours;

  const formattedDiffTimeString =
    Number.isFinite(available_hours ?? shiftData.duration_hours) &&
    (available_hours ?? shiftData.duration_hours).toString().split(".")[1]
      ?.length > 2
      ? (available_hours ?? shiftData.duration_hours).toFixed(2)
      : available_hours ?? shiftData.duration_hours;

  const timePlaceholderString = `${startTimeString} - ${endTimeString}`;

  const openPopover = !!anchorEl;
  const idPopover = openPopover ? "shift-card-popover" : undefined;
  const popoverBoundingClientRect = anchorEl?.getBoundingClientRect();
  const leftPopoverPosition = (popoverBoundingClientRect?.left || 0) - 56;
  const topPopoverPosition = (popoverBoundingClientRect?.top || 0) - 60;

  return (
    <Box
      className={clsx([styles.root])}
      style={{
        background: `${shiftData?.color ?? DEFAULT_SHIFT_CARD_COLOR}50`,
        borderColor: `${shiftData?.color ?? DEFAULT_SHIFT_CARD_COLOR}80`,
      }}
    >
      <Box className={styles.content}>
        <Tooltip
          title={timePlaceholderString}
          placement="top-start"
          classes={{
            popper: styles.shiftTooltip,
          }}
        >
          <Box
            className={styles.nameContainer}
            style={{ color: shiftData?.color ?? DEFAULT_SHIFT_CARD_COLOR }}
          >
            {riverGenerateIcon(shiftData?.icon ?? DEFAULT_SHIFT_CARD_ICON, {
              className: styles.shiftIcon,
            })}
            <span className={styles.shiftName}>
              {shiftData?.shift ||
                (editFlag
                  ? t("module.shifts:shift_card.name_placeholder")
                  : "")}
            </span>
          </Box>
        </Tooltip>
        <span
          className={styles.shiftTime}
        >{`${formattedDiffTimeString} Hr`}</span>
      </Box>

      {!!actions && (
        <RiverIconButton
          className={clsx([
            styles.moreVertIconButton,
            { [styles.moreVertIconButtonShow]: openPopover },
          ])}
          label=""
          icon={MoreVertIcon}
          onClick={(e) => handleClickShowPopover(e)}
        />
      )}
      <Popover
        id={idPopover}
        className={styles.popoverActions}
        open={openPopover && !!actions}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: topPopoverPosition,
          left: leftPopoverPosition,
        }}
      >
        {!!actions && (
          <Box className={styles.actionsContainer}>
            {actions.map((action, index) => (
              <RiverIconButton {...action} key={index} />
            ))}
          </Box>
        )}
      </Popover>
    </Box>
  );
};
