import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const DownloadIcon: React.FC<RiverSVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...sanitizeSvgProps(props)}
    >
      <title>{props.title}</title>
      <g clipPath="url(#clip0_199_549)">
        <path
          d="M22.4981 15.2512C21.8766 15.2512 21.3731 15.7547 21.3731 16.3762V20.3906H2.62686V16.3762C2.62686 15.7547 2.12342 15.2512 1.50186 15.2512C0.880299 15.2512 0.376862 15.7547 0.376862 16.3762V21.5156C0.376862 22.1372 0.880299 22.6406 1.50186 22.6406H22.4991C23.1206 22.6406 23.6241 22.1372 23.6241 21.5156V16.3762C23.6241 15.7547 23.1206 15.2512 22.4991 15.2512H22.4981Z"
          fill="#1A1A1A"
        />
        <path
          d="M11.7497 17.04C11.8491 17.1947 12.075 17.1956 12.1753 17.0419L16.7372 10.0809C16.8478 9.91219 16.7269 9.68813 16.5253 9.68813H13.7831C13.6434 9.68813 13.5291 9.57469 13.5291 9.43406V1.61344C13.5291 1.47281 13.4156 1.35938 13.275 1.35938H10.6472C10.5066 1.35938 10.3931 1.47281 10.3931 1.61344V9.43406C10.3931 9.57469 10.2797 9.68813 10.1391 9.68813H7.47562C7.275 9.68813 7.15312 9.91031 7.26187 10.0791L11.7497 17.04Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_199_549">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
