import {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import {
  RiverCheckbox,
  RiverDateInput,
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { Box } from "@mui/material";
import {
  AdapterFolderDto,
  IAdapterFolder,
  IEntityObject,
  IAdapterCalendar,
} from "@river/interfaces";
import { Constants } from "@river/constants";
import { RiverLookup, LookupType } from "../../shared";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import styles from "./schedule-dialog.module.scss";
import { RiverFormInstance } from "../../../hooks";
import { useScheduleForm } from "./use-schedule-form";
import { debounce } from "ts-debounce";
import { useTranslation } from "@river/common-ui";

interface ScheduleDialogProps {
  open: boolean;
  schedule: IAdapterFolder | null;
  onClose: (success: boolean) => void;
}

export const ScheduleDialog: FC<ScheduleDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotification();
  // fetched Calendar Object to check with verification
  const [selectedCalendar, setSelectedCalendar] =
    useState<IAdapterCalendar | null>(null);
  const form: RiverFormInstance = useScheduleForm({
    schedule: props.schedule,
    selectedCalendar,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });
  const scheduleDto: AdapterFolderDto = form.dto as AdapterFolderDto;
  const {
    setStandaloneFields,
    validateStandaloneField,
    getStandaloneValidatorInstance,
    validationErrors,
    onStandaloneLookupSelect,
    onStandalonePropertyChange,
    setDto,
    submit,
    isCreate,
    isProcessing,
    resetForm,
  } = form;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    if (props.open && scheduleDto.calendar_id) {
      const fetchData = async () => {
        const calendar: IAdapterCalendar = await fetchCalendar(
          scheduleDto.calendar_id as string,
          "_id"
        );
        const newStandaloneFields = getStandaloneValidatorInstance!({
          calendarName: calendar.calendar,
        });
        setStandaloneFields(newStandaloneFields);
        setSelectedCalendar(calendar);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    validateStandaloneField("calendarName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendar]);

  useEffect(() => {
    updateDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDto.start_date, scheduleDto.end_date]);

  const resetDialogState = (): void => {
    resetForm();
    setSelectedCalendar(null);
  };

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetDialogState();
    props.onClose(requiresRefresh);
  };

  const updateDuration = (): void => {
    let diff: number = 0;
    if (
      scheduleDto.start_date instanceof Date &&
      scheduleDto.end_date instanceof Date
    ) {
      const start: number = scheduleDto.start_date.getTime();
      const end: number = scheduleDto.end_date.getTime();
      if (end > start) {
        diff = (end - start) / (24 * 3600 * 1000);
      }
    }
    diff = Math.round(diff);

    const newState: AdapterFolderDto = Object.assign(new AdapterFolderDto(), {
      ...scheduleDto,
      duration: diff,
    });
    setDto(newState);
  };

  // fetch calendar object with calendar id or calendar name
  const fetchCalendar = async (
    attribute_value: string,
    attribute_name: string
  ): Promise<IAdapterCalendar> => {
    const calendars: IAdapterCalendar[] = await adapterContext!.service
      .getAdapterService()
      .fetchCalendars({
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return calendars[0] as IAdapterCalendar;
  };

  const fetchCalendarByName = async (calendarName: string): Promise<void> => {
    try {
      const calendar: IAdapterCalendar = await fetchCalendar(
        calendarName,
        "calendar"
      );
      setSelectedCalendar(calendar);
      validateStandaloneField("calendarName", calendarName);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchCalendarByName = useCallback(
    debounce((text) => {
      fetchCalendarByName(text);
    }, 600),
    [validationErrors]
  );

  const renderCalendarField = (): ReactElement => (
    <RiverLookup
      id="calendarName"
      lookup={{ type: LookupType.CALENDAR }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchCalendarByName(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        onStandaloneLookupSelect("calendar", "calendarName")(selectedObject);
        setSelectedCalendar(selectedObject as IAdapterCalendar);
      }}
    />
  );

  const renderDateRange = (): ReactElement => {
    const dateInputsDisabled: boolean =
      scheduleDto.folder_type === Constants.folder_type.schedule;
    return (
      <Box className={styles.dateRangeFields}>
        <RiverDateInput
          id="start_date"
          className={styles.dateField}
          disabled={dateInputsDisabled}
        />
        <RiverDateInput
          id="end_date"
          className={styles.dateField}
          disabled={dateInputsDisabled}
        />
        <RiverTextInput
          id="duration"
          className={styles.durationField}
          disabled={true}
        />
      </Box>
    );
  };

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.schedules:schedule_dialog.button.create_schedule")
      : t("module.schedules:schedule_dialog.button.edit_schedule");
  const getActionButtonText = (): string =>
    isCreate
      ? t("module.schedules:schedule_dialog.button.create_schedule")
      : t("common.button:save");

  return (
    <RiverDialog
      classes={{
        paper: styles.root,
        content: styles.content,
      }}
      title={getDialogTitle()}
      open={props.open}
      onClose={close}
      showTitleDivider={false}
      showActionsDivider={false}
      actionButtonText={getActionButtonText()}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      <RiverTextInput id="folder" fullWidth disabled={!isCreate} />
      {renderDateRange()}
      {renderCalendarField()}
      <RiverCheckbox id="use_avail_to_schedule_assignments" />
    </RiverDialog>
  );
};
