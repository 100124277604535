import { Column } from "react-data-grid";
import { AdapterUiService } from "./adapter-ui.service";
import { ReactElement } from "react";
import { IEntityObject } from "@river/interfaces";

export interface IResourceDialogProps {
  open: boolean;
  resource: IEntityObject | null;
  onClose(success: boolean): void;
}

export interface IGetAvailabilityResourcesColumnsProps {
  onResourceClick?: (resource: IEntityObject) => void;
}

export abstract class AvailabilityUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "module.availability",
      "shared.planning_plant_selector",
      "shared.action",
      "shared.user_menu",
      "shared.grid_header",
      "shared.column_selector",
      "shared.river_table_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.download_dialog",
      "common.label",
      "common.button",
      "shared.lookup",
      "shared.error_messages",
      "entity.availability_header",
      "entity.shift",
      "shared.river_data_grid",
      "shared.async_schedule",
      "shared.river_async_progress",
      "shared.async_actions",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getAvailabilityResourcesColumns(
    props?: IGetAvailabilityResourcesColumnsProps
  ): Column<any>[];
  abstract getErpSpecificI18nNamespaces(): string[];
  abstract getEditAvailabilityHeaderKey(): string;
  abstract renderResourceDialog(props: IResourceDialogProps): ReactElement;
}
