// noinspection ES6UnusedImports

import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Protected } from "../protected";
import { NotificationsModule } from "../notifications";
import { BacklogModule } from "../backlog";
import { SchedulesModule } from "../schedules";
import { ExecutionModule } from "../execution";
import { ScheduleModule } from "../schedule";
import { UsersModule } from "../users";
import { RolesModule } from "../roles";
import { AvailabilityModule } from "../availability";
import { DataDictModule } from "../data-dict";
import { ShiftsModule } from "../shifts";
import { CalendarsModule } from "../calendars";
import { JobPriorityRulesModule } from "../job-priority-rules";
import { ValidationRulesModule } from "../validation-rules";
import { VisualIndicatorsRulesModule } from "../visual-indicators-rules";
import { Constants } from "@river/constants";
import { ModuleKey } from "../sidebar-menu";
import { SidebarContext } from "../../context";
import { ExternalResourcesModule } from "../external-resources";
import { ValidationDashboardModule } from "../validation-dashboard";
import { LanguagesModule } from "../languages";
import { LookupsModule } from "../lookups";
import { LanguageModule } from "../language";
import { CrewsModule } from "../crews";
import { Home } from "../home";
import { CronTasksModule } from "../cron-tasks";
import { TimelinesModule } from "../timelines";
import { RestrictionsModule } from "../restrictions";
import { SupervisorSchedulesModule } from "../supervisor-schedules";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SupervisorScheduleModule } from "../supervisor-schedule";
import { SupervisorCrewsModule } from "../supervisor-crews";
import { SupervisorCrewModule } from "../supervisor-crew";
import { ScheduleReportModule } from "../schedule-report";
import { AttachmentSelectionRulesModule } from "../attachment-selection-rules";

export const Content: React.FC = () => {
  const sidebarContext = useContext(SidebarContext);
  const moduleMapReady =
    !!sidebarContext && !!sidebarContext.menuItemsByModuleKey;

  if (!moduleMapReady) {
    return <></>;
  }

  return (
    <Routes>
      {/* ------------------ Scheduler ------------------ */}
      <Route
        path={"/timelines"}
        element={
          <Protected module={ModuleKey.TIMELINES}>
            <TimelinesModule />
          </Protected>
        }
      />
      <Route
        path={"/backlog"}
        element={
          <Protected module={ModuleKey.BACKLOG}>
            <BacklogModule key={ModuleKey.BACKLOG} />
          </Protected>
        }
      />
      <Route
        path={"/backlog/:schedule_id"}
        element={
          <Protected module={ModuleKey.BACKLOG}>
            <BacklogModule key={ModuleKey.BACKLOG} />
          </Protected>
        }
      />
      <Route
        path={"/schedules"}
        element={
          <Protected module={ModuleKey.SCHEDULES}>
            <SchedulesModule />
          </Protected>
        }
      />
      <Route
        path={"/schedules/:schedule_id"}
        element={
          <Protected module={ModuleKey.SCHEDULES}>
            <ScheduleModule />
          </Protected>
        }
      />
      <Route
        path={"/execution"}
        element={
          <Protected module={ModuleKey.EXECUTION}>
            <ExecutionModule />
          </Protected>
        }
      />
      <Route
        path={"/notifications"}
        element={
          <Protected module={ModuleKey.WORK_REQUESTS}>
            <NotificationsModule />
          </Protected>
        }
      />
      <Route
        path={"/validation-dashboard"}
        element={
          <Protected module={ModuleKey.VALIDATION_DASHBOARD}>
            <ValidationDashboardModule />
          </Protected>
        }
      />
      <Route
        path={"/report"}
        element={
          <Protected module={ModuleKey.SCHEDULE_REPORT}>
            <ScheduleReportModule />
          </Protected>
        }
      />
      {/* ------------------ Resources ------------------ */}
      <Route
        path={"/external-resources"}
        element={
          <Protected module={ModuleKey.EXTERNAL_RESOURCES}>
            <ExternalResourcesModule />
          </Protected>
        }
      />
      <Route
        path={"/shifts"}
        element={
          <Protected module={ModuleKey.SHIFTS}>
            <ShiftsModule />
          </Protected>
        }
      />
      <Route
        path={"/calendars"}
        element={
          <Protected module={ModuleKey.CALENDARS}>
            <CalendarsModule />
          </Protected>
        }
      />
      <Route
        path={"/labor"}
        element={
          <Protected module={ModuleKey.AVAILABILITY}>
            <AvailabilityModule />
          </Protected>
        }
      />
      <Route
        path={"/crews"}
        element={
          <Protected module={ModuleKey.CREWS}>
            <CrewsModule />
          </Protected>
        }
      />
      {/* ------------------ Configuration ------------------ */}
      <Route
        path={"/restrictions"}
        element={
          <Protected module={ModuleKey.RESTRICTIONS}>
            <RestrictionsModule />
          </Protected>
        }
      />
      <Route
        path={"/dd"}
        element={
          <Protected module={ModuleKey.DATA_DICTIONARY}>
            <DataDictModule />
          </Protected>
        }
      />
      <Route
        path={"/job-priority-rules"}
        element={
          <Protected module={ModuleKey.JOB_PRIORITY_RULES}>
            <JobPriorityRulesModule />
          </Protected>
        }
      />
      <Route
        path={"/attachment_selection_rules"}
        element={
          <Protected module={ModuleKey.ATTACHMENT_SELECTION_RULES}>
            <AttachmentSelectionRulesModule />
          </Protected>
        }
      />
      <Route
        path={"/validation-rules"}
        element={
          <Protected module={ModuleKey.VALIDATION_RULES}>
            <ValidationRulesModule />
          </Protected>
        }
      />
      <Route
        path={"/visual-indicators-rules"}
        element={
          <Protected module={ModuleKey.VISUAL_INDICATORS_RULES}>
            <VisualIndicatorsRulesModule />
          </Protected>
        }
      />
      <Route
        path={"/languages"}
        element={
          <Protected module={ModuleKey.LANGUAGES}>
            <LanguagesModule />
          </Protected>
        }
      />
      <Route
        path={"/cron-tasks"}
        element={
          <Protected module={ModuleKey.CRON_TASKS}>
            <CronTasksModule />
          </Protected>
        }
      />
      <Route
        path={"/reason-code-lookups"}
        element={
          <Protected module={ModuleKey.REASON_CODE_LOOKUPS}>
            <LookupsModule
              moduleKey={ModuleKey.REASON_CODE_LOOKUPS}
              lookupType={Constants.lookup_type.availability_exception_reason}
            />
          </Protected>
        }
      />
      <Route
        path={"/languages/:language_id"}
        element={
          <Protected module={ModuleKey.LANGUAGES}>
            <LanguageModule />
          </Protected>
        }
      />
      {/* ------------------ Supervisor ------------------ */}
      <Route
        path={"/supervisor/crews"}
        element={
          <Protected module={ModuleKey.SUPERVISOR_CREWS}>
            <SupervisorCrewsModule />
          </Protected>
        }
      />
      <Route
        path={"/supervisor/crews/:crew"}
        element={
          <Protected module={ModuleKey.SUPERVISOR_CREWS}>
            <SupervisorCrewModule />
          </Protected>
        }
      />
      <Route
        path={"/supervisor/schedules"}
        element={
          <Protected module={ModuleKey.SUPERVISOR_SCHEDULES}>
            <SupervisorSchedulesModule />
          </Protected>
        }
      />
      <Route
        path={"/supervisor/schedules/:schedule_id"}
        element={
          <Protected module={ModuleKey.SUPERVISOR_SCHEDULES}>
            <SupervisorScheduleModule />
          </Protected>
        }
      />
      {/* ------------------ Security ------------------ */}
      <Route
        path={"/users"}
        element={
          <Protected module={ModuleKey.USERS}>
            <UsersModule />
          </Protected>
        }
      />
      <Route
        path={"/roles"}
        element={
          <Protected module={ModuleKey.ROLES}>
            <RolesModule />
          </Protected>
        }
      />
      {/* ------------------ Planner ------------------ */}
      <Route
        path={"planner/backlog"}
        element={
          <Protected module={ModuleKey.PLANNER_BACKLOG}>
            <BacklogModule key={ModuleKey.PLANNER_BACKLOG} />
          </Protected>
        }
      />
      <Route
        path={"planner/backlog/:schedule_id"}
        element={
          <Protected module={ModuleKey.PLANNER_BACKLOG}>
            <BacklogModule key={ModuleKey.PLANNER_BACKLOG} />
          </Protected>
        }
      />
      {/* ------------------ Home ------------------ */}
      <Route path={"/home"} element={<Home />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};
