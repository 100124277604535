import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../context";
import { DragEvent, useContext } from "react";
import {
  AdapterService,
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "../../services";
import { useParams } from "react-router";
import { TaskColorGetter, useEntity, useTaskColor } from "../../hooks";
import { IEntityObject } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";

interface IUseSupervisorScheduleUtilizationBacklogProps {
  draggable?: boolean;
}

export const useSupervisorScheduleUtilizationBacklog = (
  props: IUseSupervisorScheduleUtilizationBacklogProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const entityHelpers = useEntityHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const scheduleId = useParams<{ schedule_id: string }>().schedule_id;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getTaskColor: TaskColorGetter = useTaskColor();

  const fetchWorkOrders = async (fetchProps: ITableFetchFunctionProps) => {
    const workOrders = await adapterService.fetchWorkorders(
      fetchHelpers.getTableQuery({ fetchProps })
    );

    workOrders.forEach((wo) => {
      try {
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowType,
          uiConstants.rowType.workOrder
        );
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(wo, "_id")
        );
      } catch (e) {
        console.error(`Error processing WorkOrder _id: ${wo._id}`);
        console.error(e);
      }
    });
    return workOrders;
  };

  const openEntityInErp = async (record: IEntityObject, entityName: string) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, entityName);
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "workorder",
      columns: uiService.getDefaultBacklogColumns({
        onWorkOrderClick: (entity) => openEntityInErp(entity, entityName),
        getTaskColor,
        draggable: props.draggable,
      })(),
    };
  };

  const { entityName, columns } = getTableParams(t);

  const onDrag = (event: DragEvent, row: IEntityObject): void => {
    const rowId = row[uiConstants.fields.rowId] as string;
    const dragWorkOrders = table.selectedRowIds.has(rowId)
      ? Array.from(table.selectedRowIds).map((id) => {
          const wo = table.entities.find(
            (entity) => entity[uiConstants.fields.rowId] === id
          );
          if (!wo) {
            throw new Error(`Could not find WO ${id}`);
          }
          return wo;
        })
      : [row];

    const woIds = dragWorkOrders.map((wo) => wo["_id"]);
    event.dataTransfer.setData("text", JSON.stringify({ workOrderIds: woIds }));
    event.dataTransfer.dropEffect = "move";
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `supervisor.schedule.utilization.backlog`,
    columns,
    fetchFunction: fetchWorkOrders,
    dependencies: [!!site],
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.BACKLOG,
    fetchTriggers: [scheduleId, site],
    rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
    onDrag,
    useAdvancedFilters: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
