import { AdapterUiService } from "../adapter-ui.service";
import { AdapterService } from "../adapter.service";
import { JdeAdapterService } from "./jde-adapter.service";
import { JdeExternalResourcesUiService } from "./jde-external-resources-ui-service";
import { JdeSitesUiService } from "./jde-sites-ui.service";
import { JdePersonUiService } from "./jde-person-ui.service";
import { JdeAvailabilityUiService } from "./jde-availability-ui-service/jde-availability-ui.service";
import { JdeMaterialRequirementsUiService } from "./jde-material-requirements-ui.service";
import { JdeBacklogUiService } from "./jde-backlog-ui.service";
import { JdeSchedulesUiService } from "./jde-schedules-ui.service";
import { JdeScheduleUiService } from "./jde-schedule-ui.service";
import { JdeScheduleUtilizationUiService } from "./jde-schedule-utilization-ui-service";
import { JdeUsersUiService } from "./jde-users-ui.service";
import { JdeTaskHelpersUiService } from "./jde-task-helpers-ui.service";
import { JdeCraftsUiService } from "./jde-crafts-ui-service";
import { JdeOperationsUiService } from "./jde-operations-ui-service";
import { JdeExecutionUiService } from "./jde-execution-ui.service";
import { JdeShiftsUiService } from "./jde-shifts-ui.service";
import { JdeCalendarsUiService } from "./jde-calendars-ui.service";
import { JdeValidationDashboardUiService } from "./jde-validation-dashboard-ui.service";
import { JdeTimecardUiService } from "./jde-timecard-ui.service";
import { JdeCrewsUiService } from "./jde-crews-ui.service";
import { JdeTableUiService } from "./jde-table-ui.service";
import { JdeUserRolesUiService } from "./jde-user-roles-ui- service";
import { JdeRestrictionsUiService } from "./jde-restrictions-ui.service";
import { JdeSupervisorScheduleUiService } from "./jde-supervisor-schedule-ui.service";
import { JdeSupervisorScheduleUtilizationUiService } from "./jde-supervisor-schedule-utilization-ui-service";
import { JdeSupervisorSchedulesUiService } from "./jde-supervisor-schedules-ui-service";
import { JdeSupervisorTimeCardUiService } from "./jde-supervisor-timecard-ui-service";
import { JdeScheduleReportUiService } from "./jde-schedule-report-ui-service";

export class JdeAdapterUiService extends AdapterUiService {
  private supervisorScheduleUiService: JdeSupervisorScheduleUiService | null =
    null;
  private supervisorTimeCardUiService: JdeSupervisorTimeCardUiService | null =
    null;
  private supervisorScheduleUtilizationUiService: JdeSupervisorScheduleUtilizationUiService | null =
    null;
  private sitesUiService: JdeSitesUiService | null = null;
  private backlogUiService: JdeBacklogUiService | null = null;
  private schedulesUiService: JdeSchedulesUiService | null = null;
  private supervisorSchedulesUiService: JdeSupervisorSchedulesUiService | null =
    null;
  private scheduleUiService: JdeScheduleUiService | null = null;
  private scheduleUtilizationUiService: JdeScheduleUtilizationUiService | null =
    null;
  private usersUiService: JdeUsersUiService | null = null;
  private availabilityUiService: JdeAvailabilityUiService | null = null;
  private externalResourcesUiService: JdeExternalResourcesUiService | null =
    null;
  private materialRequirementsUiService: JdeMaterialRequirementsUiService | null =
    null;
  private personUiService: JdePersonUiService | null = null;
  private taskHelpersUiService: JdeTaskHelpersUiService | null = null;
  private craftsUiService: JdeCraftsUiService | null = null;
  private operationsUiService: JdeOperationsUiService | null = null;
  private executionUiService: JdeExecutionUiService | null = null;
  private shiftsUiService: JdeShiftsUiService | null = null;
  private calendarsUiService: JdeCalendarsUiService | null = null;
  private validationDashboardUiService: JdeValidationDashboardUiService | null =
    null;
  private timecardUiService: JdeTimecardUiService | null = null;
  private crewsUiService: JdeCrewsUiService | null = null;
  private tableUiService: JdeTableUiService | null = null;
  private userRolesUiService: JdeUserRolesUiService | null = null;
  private restrictionsUiService: JdeRestrictionsUiService | null = null;
  private scheduleReportUiService: JdeScheduleReportUiService | null = null;

  protected createAdapterService(): AdapterService {
    return new JdeAdapterService(this.customerInfo);
  }

  getSitesUiService(): JdeSitesUiService {
    if (!this.sitesUiService) {
      this.sitesUiService = new JdeSitesUiService(this);
    }
    return this.sitesUiService;
  }

  getSupervisorSchedulesUiService(): JdeSupervisorSchedulesUiService {
    if (!this.supervisorSchedulesUiService) {
      this.supervisorSchedulesUiService = new JdeSupervisorSchedulesUiService(
        this
      );
    }
    return this.supervisorSchedulesUiService;
  }

  getBacklogUiService(): JdeBacklogUiService {
    if (!this.backlogUiService) {
      this.backlogUiService = new JdeBacklogUiService(this);
    }
    return this.backlogUiService;
  }

  getSchedulesUiService(): JdeSchedulesUiService {
    if (!this.schedulesUiService) {
      this.schedulesUiService = new JdeSchedulesUiService(this);
    }
    return this.schedulesUiService;
  }

  getScheduleUiService(): JdeScheduleUiService {
    if (!this.scheduleUiService) {
      this.scheduleUiService = new JdeScheduleUiService(this);
    }
    return this.scheduleUiService;
  }

  getScheduleUtilizationUiService(): JdeScheduleUtilizationUiService {
    if (!this.scheduleUtilizationUiService) {
      this.scheduleUtilizationUiService = new JdeScheduleUtilizationUiService(
        this
      );
    }
    return this.scheduleUtilizationUiService;
  }

  getUsersUiService(): JdeUsersUiService {
    if (!this.usersUiService) {
      this.usersUiService = new JdeUsersUiService(this);
    }
    return this.usersUiService;
  }

  getAvailabilityUiService(): JdeAvailabilityUiService {
    if (!this.availabilityUiService) {
      this.availabilityUiService = new JdeAvailabilityUiService(this);
    }
    return this.availabilityUiService;
  }

  getExternalResourcesUiService(): JdeExternalResourcesUiService {
    if (!this.externalResourcesUiService) {
      this.externalResourcesUiService = new JdeExternalResourcesUiService(this);
    }
    return this.externalResourcesUiService;
  }

  getMaterialRequirementsUiService(): JdeMaterialRequirementsUiService {
    if (!this.materialRequirementsUiService) {
      this.materialRequirementsUiService = new JdeMaterialRequirementsUiService(
        this
      );
    }
    return this.materialRequirementsUiService;
  }

  getPersonUiService(): JdePersonUiService {
    if (!this.personUiService) {
      this.personUiService = new JdePersonUiService(this);
    }
    return this.personUiService;
  }

  getTaskHelpersUiService(): JdeTaskHelpersUiService {
    if (!this.taskHelpersUiService) {
      this.taskHelpersUiService = new JdeTaskHelpersUiService(this);
    }
    return this.taskHelpersUiService;
  }

  getCraftsUiService(): JdeCraftsUiService {
    if (!this.craftsUiService) {
      this.craftsUiService = new JdeCraftsUiService(this);
    }
    return this.craftsUiService;
  }

  getOperationsUiService(): JdeOperationsUiService {
    if (!this.operationsUiService) {
      this.operationsUiService = new JdeOperationsUiService(this);
    }
    return this.operationsUiService;
  }

  getExecutionUiService(): JdeExecutionUiService {
    if (!this.executionUiService) {
      this.executionUiService = new JdeExecutionUiService(this);
    }
    return this.executionUiService;
  }

  getShiftsUiService(): JdeShiftsUiService {
    if (!this.shiftsUiService) {
      this.shiftsUiService = new JdeShiftsUiService(this);
    }
    return this.shiftsUiService;
  }

  getCalendarsUiService(): JdeCalendarsUiService {
    if (!this.calendarsUiService) {
      this.calendarsUiService = new JdeCalendarsUiService(this);
    }
    return this.calendarsUiService;
  }

  getValidationDashboardUiService(): JdeValidationDashboardUiService {
    if (!this.validationDashboardUiService) {
      this.validationDashboardUiService = new JdeValidationDashboardUiService(
        this
      );
    }
    return this.validationDashboardUiService;
  }

  getTimeCardUiService(): JdeTimecardUiService {
    if (!this.timecardUiService) {
      this.timecardUiService = new JdeTimecardUiService(this);
    }
    return this.timecardUiService;
  }

  getCrewsUiService(): JdeCrewsUiService {
    if (!this.crewsUiService) {
      this.crewsUiService = new JdeCrewsUiService(this);
    }
    return this.crewsUiService;
  }

  getTableUiService(): JdeTableUiService {
    if (!this.tableUiService) {
      this.tableUiService = new JdeTableUiService(this);
    }
    return this.tableUiService;
  }

  getUserRolesUiService(): JdeUserRolesUiService {
    if (!this.userRolesUiService) {
      this.userRolesUiService = new JdeUserRolesUiService(this);
    }
    return this.userRolesUiService;
  }

  getRestrictionsUiService(): JdeRestrictionsUiService {
    if (!this.restrictionsUiService) {
      this.restrictionsUiService = new JdeRestrictionsUiService(this);
    }
    return this.restrictionsUiService;
  }

  getSupervisorScheduleUiService(): JdeSupervisorScheduleUiService {
    if (!this.supervisorScheduleUiService) {
      this.supervisorScheduleUiService = new JdeSupervisorScheduleUiService(
        this
      );
    }
    return this.supervisorScheduleUiService;
  }

  getSupervisorScheduleUtilizationUiService(): JdeSupervisorScheduleUtilizationUiService {
    if (!this.supervisorScheduleUtilizationUiService) {
      this.supervisorScheduleUtilizationUiService =
        new JdeSupervisorScheduleUtilizationUiService(this);
    }
    return this.supervisorScheduleUtilizationUiService;
  }

  getSupervisorTimeCardUiService(): JdeSupervisorTimeCardUiService {
    if (!this.supervisorTimeCardUiService) {
      this.supervisorTimeCardUiService = new JdeSupervisorTimeCardUiService(
        this
      );
    }
    return this.supervisorTimeCardUiService;
  }

  getScheduleReportUiService(): JdeScheduleReportUiService {
    if (!this.scheduleReportUiService) {
      this.scheduleReportUiService = new JdeScheduleReportUiService(this);
    }
    return this.scheduleReportUiService;
  }
}
