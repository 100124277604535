import styles from "./saved-filters-dialog.module.scss";
import { RiverDialog, useNotification } from "@river/common-ui";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITableFilter, ITablePreferences } from "../../../interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { IUseTable } from "../river-data-grid";
import { userPreferencesService } from "../../../services";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { commonModuleActions, useCurrentModule } from "../../../hooks";
import { helpers } from "../../../helpers";
import {
  IUseDeleteFilterConfirmation,
  TableFilterScope,
  useDeleteFilterConfirmation,
} from "../advanced-filters";
import clsx from "clsx";

import { SaveAdvancedFilterDialog } from "../advanced-filters/save-advanced-filter-dialog";

interface ISavedFiltersDialogProps {
  open: boolean;
  onClose?: () => void;
  onNewFilter?: () => void;
  onShowFilter?: (filter: ITableFilter) => void;
  onFilterApplied?: (filter: ITableFilter) => void;
}

export const SavedFiltersDialog: React.FC<ISavedFiltersDialogProps> = (
  props
) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const currentModule = useCurrentModule();
  const deleteFilterConfirmation: IUseDeleteFilterConfirmation =
    useDeleteFilterConfirmation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);

  const { open } = props;
  const table: IUseTable = tableContext?.table!;
  const { GlobalFilterAction } = commonModuleActions;

  const [filterToEdit, setFilterToEdit] = useState<ITableFilter | null>(null);

  const localFilters: ITableFilter[] | undefined = table.tableFilters;
  const [globalFilters, setGlobalFilters] = useState<
    ITableFilter[] | undefined
  >();

  const getFilterScope = (filter: ITableFilter): TableFilterScope => {
    if (!!globalFilters?.find((f) => f.name === filter.name)) {
      return TableFilterScope.GLOBAL;
    }

    return TableFilterScope.LOCAL;
  };

  const canUpdateFilter = (filter: ITableFilter): boolean => {
    if (getFilterScope(filter) === TableFilterScope.GLOBAL) {
      const canUpdateGlobalFilter: boolean = currentModule.isActionAllowed(
        GlobalFilterAction.UPDATE_GLOBAL_FILTER
      );
      return canUpdateGlobalFilter;
    }

    return true;
  };

  const canDeleteFilter = (filter: ITableFilter): boolean => {
    if (getFilterScope(filter) === TableFilterScope.GLOBAL) {
      const canUpdateGlobalFilter: boolean = currentModule.isActionAllowed(
        GlobalFilterAction.DELETE_GLOBAL_FILTER
      );
      return canUpdateGlobalFilter;
    }

    return true;
  };

  const isDefault = (filter: ITableFilter): boolean =>
    filter.name === table.defaultFilterName;

  const loadGlobalFilters = async (): Promise<any> => {
    try {
      const globalPreferences: ITablePreferences =
        await userPreferencesService.getTablePreferences({
          adapter: adapterContext!.service.getAdapterService(),
          tableSaveKey: table.dbSaveKey,
          isGlobal: true,
        });
      setGlobalFilters(globalPreferences?.filters || []);
    } catch (message) {
      notify.error({ message });
    }
  };

  const onClose = (): void => {
    props.onClose?.();
    setGlobalFilters(undefined);
  };

  const renderNoFiltersMessage = (): ReactElement => (
    <div className={styles.noSavedFiltersMessage}>
      {t("shared.saved_filters_dialog:no_saved_filters")}
    </div>
  );

  const applyFilter = async (filter: ITableFilter): Promise<void> => {
    try {
      await table.fetch({
        newQuery: filter.query,
        newColumnFilters: filter.simpleFilters,
      });
      const filterClone: ITableFilter = helpers.clone<ITableFilter>(filter);
      props.onFilterApplied?.(filterClone);
      table.setSortColumns(filter.sort ?? []);
    } finally {
      onClose();
    }
  };

  const onDelete = async (filter: ITableFilter): Promise<void> => {
    const scope: TableFilterScope = getFilterScope(filter);

    if (scope === TableFilterScope.LOCAL) {
      await table.loadTablePreferences();
    } else {
      await loadGlobalFilters();
    }
  };

  const renderFilter = (filter: ITableFilter, index: number): ReactElement => (
    <div className={styles.filter} key={index}>
      <div className={styles.filterNameContainer}>
        <span className={styles.filterName} onClick={() => applyFilter(filter)}>
          {filter.name}
        </span>
        {isDefault(filter) && (
          <CheckIcon
            className={clsx([styles.filterActionIcon, styles.isDefault])}
          />
        )}
      </div>
      {canUpdateFilter(filter) && (
        <EditIcon
          onClick={() => {
            setFilterToEdit(filter);
          }}
          className={styles.filterActionIcon}
          titleAccess={t("shared.saved_filters_dialog:label.edit_filter")}
        />
      )}
      <TuneOutlinedIcon
        onClick={() => {
          onClose();
          const filterClone: ITableFilter = helpers.clone<ITableFilter>(filter);
          props.onShowFilter?.(filterClone);
        }}
        className={styles.filterActionIcon}
        titleAccess={t(
          "shared.saved_filters_dialog:label.edit_filter_criteria"
        )}
      />
      {canDeleteFilter(filter) && (
        <DeleteIcon
          onClick={() =>
            deleteFilterConfirmation.open({
              filter,
              scope: getFilterScope(filter),
              onDelete: () => onDelete(filter),
            })
          }
          className={styles.filterActionIcon}
          titleAccess={t("shared.saved_filters_dialog:label.delete_filter")}
        />
      )}
    </div>
  );

  const renderFilters = (): ReactElement => {
    const filters: ITableFilter[] | undefined = [
      ...(localFilters || []),
      ...(globalFilters || []),
    ];

    return (
      <div className={styles.filters}>
        {!!filters && filters.length === 0 ? renderNoFiltersMessage() : null}

        {filters.map((group, index) => renderFilter(group, index))}
      </div>
    );
  };

  useEffect(() => {
    if (open) {
      loadGlobalFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <RiverDialog
      title={t("shared.saved_filters_dialog:dialog_title")}
      open={open}
      onClose={onClose}
      actionButtonText={t("common.button:new")}
      onSubmit={() => {
        onClose();
        props.onNewFilter?.();
      }}
      classes={{
        content: styles.content,
      }}
    >
      {renderFilters()}
      {deleteFilterConfirmation.render()}
      {filterToEdit && (
        <SaveAdvancedFilterDialog
          open={!!filterToEdit}
          filter={filterToEdit}
          scope={getFilterScope(filterToEdit)}
          onSave={async () => {
            await loadGlobalFilters();
          }}
          onClose={() => {
            setFilterToEdit(null);
          }}
        />
      )}
    </RiverDialog>
  );
};
