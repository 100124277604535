import {
  AdapterBaselineDto,
  AdapterCalendarDto,
  AdapterCrewCraftDto,
  AdapterCrewDto,
  AdapterCrewPersonDto,
  AdapterFolderDto,
  AdapterJobPriorityScoreActionDto,
  AdapterLookupDto,
  AdapterReportCreateDto,
  AdapterRuleDto,
  AdapterShiftDto,
  AdapterTimeCardDto,
  AdapterTimelineDto,
  AdapterUserStatusActionDto,
  AdapterUserStatusEditActionDto,
  IAdapterAsyncAction,
  IAdapterCalendar,
  IAdapterCrew,
  IAdapterFolder,
  IAdapterJobPriorityScoreAction,
  IAdapterJobValidationAction,
  IAdapterLookup,
  IAdapterReport,
  IAdapterRule,
  IAdapterShift,
  IAdapterTimeCard,
  IAdapterTimeline,
  IAdapterUserStatusActionResponse,
  IEntityObject,
  QueryAttributeDto,
  QueryDto,
  UtilizationPeriodType,
} from "@river/interfaces";
import { httpService } from "@river/common-ui";
import { Constants } from "@river/constants";

import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { AdapterService } from "../adapter.service";

export class SapAdapterService extends AdapterService {
  //
  // --------------
  protected ensurePlanningPlantQueryAttribute(
    query?: QueryDto,
    useStarPlant: boolean = true,
    planningPlantAttrName: string = "PlanningPlant"
  ): QueryDto {
    //
    const siteId =
      (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      )?.site_id || "";

    query =
      siteId !== "*" || useStarPlant
        ? this.ensureQueryAttributes(
            [
              {
                attribute_name: planningPlantAttrName,
                attribute_value: {
                  operator: "$eq",
                  value:
                    (
                      this.userContext!.userProperties[
                        AdapterUserContextProp.SITE
                      ] as IUserContextSite
                    )?.site_id || "",
                },
              },
            ],
            query
          )
        : query ?? new QueryDto();

    return query;
  }

  // --------------
  async fetchSites(query?: QueryDto | undefined): Promise<any[]> {
    return this.searchEntityData("planning_plant", query);
  }

  // --------------
  async fetchTimelines(
    query?: QueryDto | undefined
  ): Promise<IAdapterTimeline[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("timeline", query);
  }

  // --------------
  async createTimeline(
    timelineCreateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline> {
    //
    const timelineDto: AdapterTimelineDto = {
      ...timelineCreateParams,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post<IAdapterTimeline>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timeline`,
      timelineDto
    );
    return response.data;
  }

  // --------------
  async updateTimeline(
    timelineId: string,
    timelineUpdateParams: AdapterTimelineDto
  ): Promise<IAdapterTimeline> {
    const timelineDto: AdapterTimelineDto = {
      ...timelineUpdateParams,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put<IAdapterTimeline>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timeline/${timelineId}`,
      timelineDto
    );
    return response.data;
  }

  // --------------
  async fetchFolders(query?: QueryDto | undefined): Promise<IAdapterFolder[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    if (!query.sort?.length) {
      query.sort = [
        {
          attribute_name: "start_date",
          sort: "asc",
        },
      ];
    }
    return await this.searchEntityData("folder", query);
  }

  // --------------
  async createFolder(
    folderCreateParams: AdapterFolderDto
  ): Promise<IAdapterFolder> {
    const folderDto: AdapterFolderDto = {
      ...folderCreateParams,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post<IAdapterFolder>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/folder`,
      folderDto
    );
    return response.data;
  }

  // --------------
  async updateFolder(
    folderId: string,
    folderUpdateParams: AdapterFolderDto
  ): Promise<IAdapterFolder> {
    const folderDto: AdapterFolderDto = {
      ...folderUpdateParams,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put<IAdapterFolder>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/folder/${folderId}`,
      folderDto
    );
    return response.data;
  }

  // --------------
  async fetchShifts(query?: QueryDto | undefined): Promise<IAdapterShift[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("shift", query);
  }

  // --------------
  async createShift(shift: AdapterShiftDto): Promise<IAdapterShift> {
    const shiftDto: AdapterShiftDto = {
      ...shift,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/shift`,
      shiftDto
    );
    return response.data;
  }

  // --------------
  async updateShift(
    shiftId: string,
    shift: AdapterShiftDto
  ): Promise<IAdapterShift> {
    const shiftDto: AdapterShiftDto = {
      ...shift,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/shift/${shiftId}`,
      shiftDto
    );
    return response.data;
  }

  // ==========================
  // Crews
  // ==========================
  async fetchCrews(
    query?: QueryDto | undefined,
    user_id?: string
  ): Promise<IAdapterCrew[]> {
    if (user_id) {
      query = this.ensureQueryAttributes(
        [
          {
            attribute_name: "supervisors",
            attribute_value: {
              operator: "$elemMatch",
              value: { $eq: user_id },
            },
          },
        ],
        query
      );
    }
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("crew", query);
  }

  // -------------------
  // available (not assigned) people
  async fetchCrewAvailablePeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]> {
    //
    const planningPlant = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;

    const planningPlantFilter: QueryAttributeDto[] =
      planningPlant != "*"
        ? [
            {
              attribute_name: "WorkCenters.PlanningPlant",
              attribute_value: {
                operator: "$eq",
                value: planningPlant,
              },
            },
          ]
        : [];

    query = this.ensureQueryAttributes(
      [
        // {
        //   attribute_name: "WorkCenters.PlanningPlant",
        //   attribute_value: {
        //     operator: "$eq",
        //     value: planningPlant,
        //   },
        // },
        ...planningPlantFilter,
        {
          attribute_name: "crews",
          attribute_value: {
            operator: "$not",
            value: {
              $elemMatch: { crew: crewCode, PlanningPlant: planningPlant },
            },
          },
        },
        {
          attribute_name: "is_crew_workcenter",
          attribute_value: {
            operator: "$ne",
            value: true,
          },
        },
      ],
      query
    );

    return await this.searchEntityData("availability_header", query);
  }

  // -------------------
  // assigned people
  async fetchCrewAssignedPeople(
    crewCode: string,
    query?: QueryDto
  ): Promise<any[]> {
    //
    const planningPlant = (
      this.userContext!.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id;

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "PlanningPlant",
          attribute_value: {
            operator: "$eq",
            value: planningPlant,
          },
        },
        {
          attribute_name: "crew",
          attribute_value: {
            operator: "$eq",
            value: crewCode,
          },
        },
      ],
      query
    );

    return await this.searchEntityData("crew_person", query);
  }

  // --------------
  async createCrew(crew: AdapterCrewDto): Promise<IAdapterCrew> {
    const crewDto: AdapterCrewDto = {
      ...crew,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew`,
      crewDto
    );
    return response.data;
  }

  // --------------
  async updateCrew(
    crewId: string,
    crew: AdapterCrewDto
  ): Promise<IAdapterCrew> {
    const crewDto: AdapterCrewDto = {
      ...crew,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew/${crewId}`,
      crewDto
    );
    return response.data;
  }

  // -----------------
  // Create a person
  async createCrewPerson(crewPerson: AdapterCrewPersonDto): Promise<any> {
    //
    const crewPersonDto = {
      ...crewPerson,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.createEntityData("crew_person", crewPersonDto);
  }

  // -------------
  // assign a craft to a crew
  async createCrewCraft(crewCraft: AdapterCrewCraftDto): Promise<any> {
    //
    const crewCraftDto = {
      ...crewCraft,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.createEntityData("crew_workcenter", crewCraftDto);
  }

  // ------------
  async updateCrewCraft(
    crewCraftId: string,
    crewCraft: AdapterCrewCraftDto
  ): Promise<any> {
    //
    const crewCraftDto = {
      ...crewCraft,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    return await this.updateEntityData(
      "crew_workcenter",
      crewCraftId,
      crewCraftDto
    );
  }

  // -----------
  async deleteCrewCraft(id: string): Promise<any> {
    //
    return this.deleteEntityData("crew_workcenter", id);
  }

  // --------------
  async fetchCalendars(
    query?: QueryDto | undefined
  ): Promise<IAdapterCalendar[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("calendar", query);
  }

  // --------------
  async createCalendar(
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar> {
    const calendarDto: AdapterCalendarDto = {
      ...calendar,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/calendar`,
      calendarDto
    );
    return response.data;
  }

  // --------------
  async updateCalendar(
    calendarId: string,
    calendar: AdapterCalendarDto
  ): Promise<IAdapterCalendar> {
    const calendarDto: AdapterCalendarDto = {
      ...calendar,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/calendar/${calendarId}`,
      calendarDto
    );
    return response.data;
  }

  // --------------
  async fetchExternalResources(
    query?: QueryDto | undefined
  ): Promise<IEntityObject[]> {
    query = this.ensurePlanningPlantQueryAttribute(
      query,
      true,
      "WorkCenters.PlanningPlant"
    );
    return await this.searchEntityData("external_resource", query);
  }

  // --------------
  async createAvailabilityResource(
    resource: IEntityObject
  ): Promise<IEntityObject> {
    throw new Error("Not supported");
  }

  // --------------
  async updateAvailabilityResource(
    resourceId: string,
    resource: IEntityObject
  ): Promise<IEntityObject> {
    throw new Error("Not supported");
  }

  // -------------
  async deleteAvailabilityResource(resourceId: string): Promise<IEntityObject> {
    throw new Error("Not supported");
  }

  // --------------
  async fetchAvailability(
    startDate: Date,
    query?: QueryDto | undefined,
    crew?: string
  ): Promise<any[]> {
    //
    const queryAttributes: QueryAttributeDto[] = [
      {
        attribute_name: "start_date",
        attribute_value: {
          operator: "$eq",
          value: startDate.toISOString().substr(0, 10),
        },
      },
    ];

    if (crew) {
      queryAttributes.push({
        attribute_name: "crews",
        attribute_value: {
          operator: "$elemMatch",
          value: { crew: { $eq: crew } },
        },
      });
    }

    query = this.ensureQueryAttributes(queryAttributes, query);

    query = this.ensurePlanningPlantQueryAttribute(
      query,
      false,
      "WorkCenters.PlanningPlant"
    );
    return await this.searchEntityData("availability_header", query);
  }

  // ----------------
  async synchronizeResources(): Promise<IAdapterAsyncAction> {
    const query = this.ensurePlanningPlantQueryAttribute();
    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/sync-resources`,
      query
    );

    return data;
  }

  // ----------------
  async synchronizeWorkorders(): Promise<void> {
    //
    const query = this.ensurePlanningPlantQueryAttribute(new QueryDto());
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/sync-workorders`,
      query
    );
  }

  // --------------
  async fetchWorkorders(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query, false);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workorder/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchOperations(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query, false);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/operation/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchAssignments(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query, false);

    const unwind = !query.$unwind
      ? []
      : Array.isArray(query.$unwind)
        ? query.$unwind
        : [query.$unwind];
    unwind.push("to_Assignment");

    query.$unwind = unwind;
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/operation/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchCrafts(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(
      query,
      false,
      "PlanningPlant"
    );
    return await this.searchEntityData("workcenter", query);
  }

  // --------------
  async fetchCraftPeople(query?: QueryDto | undefined): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(
      query,
      false,
      "WorkCenters.PlanningPlant"
    );
    return await this.searchEntityData("availability_header", query);
  }

  // --------------
  async fetchCraftUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "WorkCenterId" values:
    // {
    //   attributes: [{
    //     attribute_name: "WorkCenterId",
    //     values: [{
    //       operator: '$in',
    //       value: ["10000118", "10004076"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workcenter_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  async fetchResourceUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "PersonNumber" values:
    // {
    //   attributes: [{
    //     attribute_name: "PersonNumber",
    //     values: [{
    //       operator: '$in',
    //       value: ["00001811", "00001911"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/person_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  async fetchMaterial(
    workorderNumbers: string[],
    query?: QueryDto | undefined
  ): Promise<any[]> {
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "Orderid",
          attribute_value: {
            operator: "$in",
            value: workorderNumbers,
          },
        },
      ],
      query
    );

    //const response = await httpService.post<IEntityObject[]>(`${this.getAdapterSvcBaseUrl()}/api/adapter/entities/workorder/search`, query);
    const response = await httpService.post<IEntityObject[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/wo_component/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchCrewUtilization(
    folderId: string,
    baselineId: string | null,
    startDate: Date,
    periodType: UtilizationPeriodType,
    periods: number,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    // "query" parameter should contain an array of "crew" values:
    // {
    //   attributes: [{
    //     attribute_name: "crew",
    //     values: [{
    //       operator: '$in',
    //       value: ["Crew1", "Crew2"]
    //     }]
    //   }]
    // }

    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
        {
          attribute_name: "baseline_id",
          attribute_value: {
            operator: "$eq",
            value: baselineId,
          },
        },
        {
          attribute_name: "start_date",
          attribute_value: {
            operator: "$eq",
            value: startDate,
          },
        },
        {
          attribute_name: "period_type",
          attribute_value: {
            operator: "$eq",
            value: periodType,
          },
        },
        {
          attribute_name: "periods",
          attribute_value: {
            operator: "$eq",
            value: periods,
          },
        },
      ],
      query
    );

    // intentionally commented out
    //query = this.ensureOrganizationIdAttribute(query);

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/crew_utilization/search`,
      query || {}
    );
    return response.data;
  }

  // --------------
  async fetchRules(
    ruleType: string | string[],
    query?: QueryDto | undefined
  ): Promise<IAdapterRule[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "rule_type",
          attribute_value: {
            operator: "$in",
            value: Array.isArray(ruleType) ? ruleType : [ruleType],
          },
        },
      ],
      query
    );

    query = this.ensurePlanningPlantQueryAttribute(query, false);
    return await this.searchEntityData("rule", query);
  }

  // --------------
  async fetchFolderRules(
    folderId: string,
    query?: QueryDto | undefined
  ): Promise<IAdapterRule[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "rule_type",
          attribute_value: {
            operator: "$eq",
            value: Constants.rule_type.validation_rule,
          },
        },
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
      ],
      query
    );

    query = this.ensurePlanningPlantQueryAttribute(query, true);
    return await this.searchEntityData("rule", query);
  }

  // --------------
  async createRule(rule: AdapterRuleDto): Promise<IAdapterRule> {
    const ruleDto: AdapterRuleDto = {
      ...rule,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/rule`,
      ruleDto
    );
    return response.data;
  }

  // --------------
  async updateRule(
    ruleId: string,
    rule: AdapterRuleDto
  ): Promise<IAdapterRule> {
    const ruleDto: AdapterRuleDto = {
      ...rule,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/rule/${ruleId}`,
      ruleDto
    );
    return response.data;
  }

  // --------------
  async jobPriorityScore(
    jobPriorityScoreDto: AdapterJobPriorityScoreActionDto
  ): Promise<void> {
    const jobPriorityScoreAction: IAdapterJobPriorityScoreAction = {
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: jobPriorityScoreDto.entity_name,
      entity_ids: jobPriorityScoreDto.entity_ids,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-priority-score`,
      jobPriorityScoreAction
    );
  }

  // --------------
  async jobValidationFilter(
    ruleId: string,
    folderId?: string | undefined
  ): Promise<any[]> {
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: "workorder",
      rule_id: ruleId,
      folder_id: folderId,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-filter`,
      jobPriorityScoreAction
    );
    return response.data;
  }

  // --------------
  async jobValidationFilterCount(
    ruleId: string,
    folderId?: string | undefined
  ): Promise<number> {
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: "workorder",
      rule_id: ruleId,
      folder_id: folderId,
    };

    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-filter-count`,
      jobPriorityScoreAction
    );
    return response.data;
  }

  // --------------
  async jobValidationAction(
    entityName: string,
    entityIds: string[],
    ruleId: string,
    actionId: string,
    folderId?: string | undefined
  ): Promise<IAdapterAsyncAction> {
    //
    const jobPriorityScoreAction: IAdapterJobValidationAction = {
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
      entity_name: entityName,
      entity_ids: entityIds,
      rule_id: ruleId,
      action_id: actionId,
      folder_id: folderId,
    };

    const { data } = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/job-validation-action`,
      jobPriorityScoreAction
    );
    return data;
  }

  // --------------
  async createFolderBaseline(baselineDto: AdapterBaselineDto): Promise<void> {
    const payload: AdapterBaselineDto = {
      ...baselineDto,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };

    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/baseline`,
      payload
    );
  }

  // --------------
  async fetchFolderBaselines(
    folderId: string,
    query?: QueryDto | undefined
  ): Promise<any[]> {
    //
    query = query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "folder_id",
          attribute_value: {
            operator: "$eq",
            value: folderId,
          },
        },
      ],
      query
    );

    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("baseline", query);
  }

  // -------------
  async createTimeCard(
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard> {
    const timecardDto: AdapterTimeCardDto = {
      ...timecard,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timecard`,
      timecardDto
    );
    return response.data;
  }

  // ------------
  async updateTimeCard(
    timecardId: string,
    timecard: AdapterTimeCardDto
  ): Promise<IAdapterTimeCard> {
    const timecardDto: AdapterTimeCardDto = {
      ...timecard,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/timecard/${timecardId}`,
      timecardDto
    );
    return response.data;
  }

  // --------------
  async openRecordInErp(record: any, entityType: string): Promise<void> {
    // helper function to determine the clinet OS type
    // const isWindows = (): boolean => {
    //   return window.navigator?.userAgent?.includes("Windows");
    // };

    // determine the client operatin system - if Windows - prepare the shortcut file for Windows SAP GUI,
    // otherwise prepare a shortcut file for Java SAP GUI.

    // TODO - the example below is for SAP GUI for Java
    // TODO - Also need to support SAP GUI for Web GUI
    const sapWebGuiUrlProperty = (
      this.customerInfo.environment.options || []
    ).find((p) => p.name === "sap-webgui-url");

    // const sapSystemProperty = (
    //   this.customerInfo.environment.options || []
    // ).find((p) => p.name === "sap-system");

    const sapClientProperty = (
      this.customerInfo.environment.options || []
    ).find((p) => p.name === "sap-client");

    /*
    const fileName = `sap_workorder_${record.Orderid}.sap`;
    //let blob = new Blob(['response.data'], { type: response.headers['content-type'] });
    let shortcutContent: BlobPart[] = [];

    if (isWindows()) {
      // Shortcut Content
      // [System]
      // Name=ID8
      // Description=ID8 [sap01.oneriversoftware.com]
      // Client=800
      // [User]
      // Name=DEJAN
      // Language=EN
      // [Function]
      // Title=Change Repair Order 822127: Central Header
      // Command=*IW32 CAUFVD-AUFNR=822127
      // [Configuration]
      // WorkDir=C:\Users\Administrator\Documents\SAP\SAP GUI
      // [Options]
      // Reuse=1

      if (sapSystemProperty?.value) {
        //
        shortcutContent.push(`[System]`, `Name=${sapSystemProperty.value}`);

        if (sapClientProperty?.value) {
          shortcutContent.push(`Client=${sapClientProperty.value}`);
          shortcutContent.push(
            `[Function]`,
            `Title=Change Repair Order: ${record.Orderid}`,
            `Command=*IW32 CAUFVD-AUFNR=${record.Orderid}`
          );
        }
      }
    } else {
      //
      if ((sapUrlProperty?.value as string)?.startsWith("http")) {
        // open web
        let workorderUrl = `${sapUrlProperty}/sap/bc/gui/sap/its/webgui?~transaction=*iw32%20CAUFVD-AUFNR=${record.Orderid}`;
        if (sapClientProperty?.value) {
          workorderUrl = `${workorderUrl}&sap-client=${sapClientProperty.value}`;
        }
        window.open(workorderUrl);
      } else {
        shortcutContent = [
          `conn=/H/${sapUrlProperty?.value}/S/3200&expert=true&tran=*IW32 CAUFVD-AUFNR=${record.Orderid}`,
        ];
      }
      // blob = new Blob([
      //   //`conn=/H/172.31.37.226/S/3200&expert=true&tran=*IW32 CAUFVD-AUFNR=${record.Orderid}`,
      //   `conn=/H/${sapUrlProperty?.value}/S/3200&expert=true&tran=*IW32 CAUFVD-AUFNR=${record.Orderid}`,
      // ]);
    }

    if (shortcutContent.length) {
      const blob = new Blob(shortcutContent);
      let file = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = file;
      a.download = decodeURI(fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    */

    switch (entityType) {
      //
      case "workorder":
        const tCode = record.OrderCategory === "20" ? "cn22" : "iw32";

        let workorderUrl = `${sapWebGuiUrlProperty?.value}/sap/bc/gui/sap/its/webgui?~transaction=*${tCode}%20CAUFVD-AUFNR=${record.Orderid}`;
        if (sapClientProperty?.value) {
          workorderUrl = `${workorderUrl}&sap-client=${sapClientProperty.value}`;
        }
        window.open(workorderUrl);

        break;

      case "notification":
        let notificationUrl = `${sapWebGuiUrlProperty?.value}/sap/bc/gui/sap/its/webgui?~transaction=*iw22%20RIWO00-QMNUM=${record.NotifNo}`;
        if (sapClientProperty?.value) {
          notificationUrl = `${notificationUrl}&sap-client=${sapClientProperty.value}`;
        }
        window.open(notificationUrl);

        break;
    }
  }

  // ===================
  // User Status
  // ===================
  async getUserStatusResponse(
    userStatusAction: AdapterUserStatusActionDto
  ): Promise<IAdapterUserStatusActionResponse> {
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/user-status`,
      userStatusAction
    );
    return response.data;
  }

  // ------------
  async updateUserStatus(
    userStatusEditAction: AdapterUserStatusEditActionDto
  ): Promise<void> {
    await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/scheduling/user-status-edit`,
      userStatusEditAction
    );
  }

  // ==================
  // Lookups
  // ==================
  async fetchPlannerGroups(query?: QueryDto): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("planner_group", query);
  }

  // ------------
  async fetchRevisions(query?: QueryDto): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    return await this.searchEntityData("revision", query);
  }

  // ------------
  async fetchPmActivityTypes(query?: QueryDto): Promise<any[]> {
    return await this.searchEntityData("pm_activity_type", query);
  }

  // ------------
  async fetchSystemConditions(query?: QueryDto): Promise<any[]> {
    return await this.searchEntityData("system_condition", query);
  }

  // --------------
  async fetchEquipment(query?: QueryDto): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/equipment/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchWbsElements(query?: QueryDto): Promise<any[]> {
    //
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/wbs/search`,
      query
    );
    return response.data;
  }

  // --------------
  async fetchNotifications(query?: QueryDto): Promise<any[]> {
    query = this.ensurePlanningPlantQueryAttribute(query);

    const response = await httpService.post<any[]>(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification/search`,
      query
    );
    return response.data;
  }

  // -------------
  async createNotification(notification: IEntityObject): Promise<any> {
    /*
    Notification fields:

    "NotificationType": string => default value to "M1",
    "Equipment": string => lookup of "Equipment" attribute from "equipment" entity
    "Description": string => (max 40 characters)

    */

    const notificationDto: IEntityObject = {
      ...notification,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification`,
      notificationDto
    );
    return response.data;
  }

  // -------------
  async updateNotification(notification: IEntityObject): Promise<any> {
    const notificationDto: IEntityObject = {
      ...notification,
      PlanningPlant: (
        this.userContext!.userProperties[
          AdapterUserContextProp.SITE
        ] as IUserContextSite
      ).site_id,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/notification`,
      notificationDto
    );
    return response.data;
  }

  // --------------
  async fetchLookups(
    lookupType: string,
    query?: QueryDto | undefined
  ): Promise<IAdapterLookup[]> {
    //
    query = this.ensureQueryAttributes(
      [
        {
          attribute_name: "lookup_type",
          attribute_value: {
            operator: "$eq",
            value: lookupType,
          },
        },
      ],
      query
    );
    return await this.searchEntityData("lookup", query);
  }

  // --------------
  async createLookup(lookup: AdapterLookupDto): Promise<IAdapterLookup> {
    const lookupDto: AdapterLookupDto = {
      ...lookup,
    };
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/lookup`,
      lookupDto
    );
    return response.data;
  }

  // --------------
  async updateLookup(
    lookupId: string,
    lookup: AdapterLookupDto
  ): Promise<IAdapterLookup> {
    const lookupDto: AdapterLookupDto = {
      ...lookup,
    };
    const response = await httpService.put(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/entities/lookup/${lookupId}`,
      lookupDto
    );
    return response.data;
  }

  // --------------
  async getTotalReturnedBreakIns(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData("total_break_ins", query ?? {});
  }

  // --------------
  async getTotalReturnedWorkorders(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData(
      "total_returned_workorders",
      query ?? {}
    );
  }

  // --------------
  async getTotalScheduledHours(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);

    return await this.searchEntityData("total_scheduled_hours", query ?? {});
  }

  // --------------
  async getTotalActualHours(folderId: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
    ]);
    return await this.searchEntityData("total_actual_hours", query ?? {});
  }

  // --------------
  async getScheduledWorkOrdersCompleted(folderId: string, baselineId?: string) {
    let query = this.ensureQueryAttributes([
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: folderId,
        },
      },
      {
        attribute_name: "baseline_id",
        attribute_value: {
          operator: "$eq",
          value: baselineId ?? null,
        },
      },
    ]);

    return await this.searchEntityData(
      "scheduled_work_orders_completed",
      query
    );
  }

  async generateReport(
    payload: AdapterReportCreateDto,
    enforceSiteId: boolean = true
  ): Promise<IAdapterReport> {
    if (enforceSiteId) {
      payload.query = this.ensurePlanningPlantQueryAttribute(
        payload.query,
        false
      );
    }
    const response = await httpService.post(
      `${this.getAdapterSvcBaseUrl()}/api/adapter/attachments/report`,
      payload
    );
    return response.data;
  }
}
