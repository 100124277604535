import { useTranslation } from "@river/common-ui";
import {
  QueryProvider,
  ScheduleReportUiService,
  ReactElementProvider,
  ICraftSelectorProps,
  IGetProgressFiltersProps,
  ProgressSummaryFilterID,
} from "..";
import { QueryAttributeGroupDto } from "@river/interfaces";
import { ReactElement, useContext } from "react";
import { ScheduleReportContext } from "../../context";
import { GenericAction } from "../../components/shared";
import TuneIcon from "@mui/icons-material/Tune";
import { JdeCraftSelector } from "./jde-shared/jde-craft-selector";
import {
  IJdeScheduleReportProgressFiltersDto,
  JdeScheduleReportProgressFilters,
} from "./jde-schedule-report-utilization-ui-service/jde-schedule-progress-filters";

export class JdeScheduleReportUiService extends ScheduleReportUiService {
  getErpSpecificI18nNamespaces() {
    return [
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_part",
      "entity.pm_activity_type",
      "entity.planner_group",
      "entity.priority",
      "entity.revision",
      "entity.system_condition",
      "entity.wbs",
      "entity.workcenter_utilization",
      "entity.workcenter",
    ];
  }

  getProgressFilters(props: IGetProgressFiltersProps): QueryProvider {
    return async () => {
      let workcenterFilters: any[] =
        (props.progressSummaryFilters as IJdeScheduleReportProgressFiltersDto)[
          ProgressSummaryFilterID.WORKCENTER
        ] || [];

      const createWorkcenterQuery = (
        workcenterFilter: any
      ): QueryAttributeGroupDto => {
        return {
          $and: [
            {
              attribute_name: "F30006_MCU",
              attribute_value: {
                operator: "$eq",
                value: workcenterFilter.F30006_MCU as string,
              },
            },
            {
              attribute_name: "F30006_MMCU",
              attribute_value: {
                operator: "$eq",
                value: workcenterFilter.F30006_MMCU as string,
              },
            },
          ],
        };
      };

      const workcenterQuery: QueryAttributeGroupDto = {
        $or: workcenterFilters.map((workcenterFilter) =>
          createWorkcenterQuery(workcenterFilter)
        ),
      };
      return {
        query: workcenterQuery,
      };
    };
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <JdeScheduleReportProgressFilters />;
  }

  getProgressHeaderActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const scheduleReportContext = useContext(ScheduleReportContext);
      const { progressFiltersOpened, setProgressFiltersOpened } =
        scheduleReportContext!;
      return () => (
        <GenericAction
          icon={TuneIcon}
          title={t("module.schedule_report:.progress_filters_toggle")}
          onClick={() => {
            setProgressFiltersOpened(!progressFiltersOpened);
          }}
        />
      );
    };
  }

  getCraftLabelPropertyValue(props: any) {
    return props.F30006_MCU;
  }

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <JdeCraftSelector {...props} />
  );
}
