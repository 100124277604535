import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { useContext } from "react";
import { IsPersonKeyExists } from "../../../supervisor-schedule-ui-service";
import { IUseTimeCardFormProps } from "../../../supervisor-timecard-ui.service";
import {
  IAdapterTimeCard,
  IEntityObject,
  JdeAdapterTimeCardDto,
} from "@river/interfaces";
import { IsNotEmpty } from "class-validator";

interface IStandaloneValidator {
  F0101_AN8: string;
  F0101_ALPH: string;
}

export const useJdeSupervisorOperationTimecardForm = (
  props: IUseTimeCardFormProps
) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  class StandaloneValidator {
    constructor(obj: StandaloneValidator) {
      Object.assign(this, obj);
    }

    @IsNotEmpty()
    @IsPersonKeyExists({ context: { adapterContext } })
    // @ts-ignore
    F0101_AN8: string;
  }

  const form: RiverFormInstance = useRiverForm<
    JdeAdapterTimeCardDto,
    IAdapterTimeCard,
    Object
  >({
    initialDto: Object.assign(new JdeAdapterTimeCardDto(), {
      folder_id: props.folderId,
      workorder_id: (props.operation?.workorder as IEntityObject)._id,
      operation_id: props.operation?._id,
      F0101_AN8: props.initialValues?.F0101_AN8 || "",
      F0101_ALPH: props.initialValues?.F0101_ALPH || "",
      timecard_date: props.initialValues?.timecard_date || new Date(),
      timecard_hours: props.initialValues?.timecard_hours || 1,
    }),
    standalone: {
      fields: new StandaloneValidator({
        F0101_AN8: "",
      }),
      fieldDefs: [
        {
          fieldName: "F0101_AN8",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },

    entityName: "timecard",
    instanceToEdit: props.timecard,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: JdeAdapterTimeCardDto): Promise<IAdapterTimeCard> => {
      return await adapterContext!.service
        .getAdapterService()
        .createTimeCard(dto);
    },
    update: async (dto: JdeAdapterTimeCardDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateTimeCard(props.timecard!._id!, dto);
    },
    labels: {
      F0101_AN8: t("entity.timecard:timecard.F0101_AN8"),
      F0101_ALPH: t("entity.timecard:timecard.F0101_ALPH"),
      timecard_date: t("entity.timecard:timecard.timecard_date"),
      timecard_hours: t("entity.timecard:timecard.timecard_hours"),
    },
  });

  return {
    form,
  };
};

export type IUseJdeSupervisorOperationTimecardForm = ReturnType<
  typeof useJdeSupervisorOperationTimecardForm
>;
