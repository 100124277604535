import { useContext } from "react";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../../context";
import { CalendarsUiService } from "../../services";

export class SapCalendarsUiService extends CalendarsUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.planning_plant"];
  }

  canManageCalendars = (): boolean => {
    const site: IUserContextSite =
      useContext(AdapterUserContext)?.userProperties[
        AdapterUserContextProp.SITE
      ];

    return site?.site_id !== "*";
  };
}
