import React, { ReactElement, useContext } from "react";
import { GenericAction, IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  TableContext,
} from "../../../context";
import { useNotification } from "@river/common-ui";
import { uiConstants, useGridActionHelpers } from "../../../helpers";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TableUiService } from "../../table-ui.service";

export const useDeleteDependenciesAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const scheduleContext = useContext(SupervisorScheduleContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t(
    "module.supervisor_schedule:action_label.delete_dependencies"
  );

  const onClick = async (): Promise<void> => {
    const table: IUseTable = tableContext?.table!;
    const selectedOperationIds: string[] = Array.from(table.selectedRowIds);
    table.forceLoadingState(true);

    const errors: any[] = [];
    try {
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .clearDependencies({
          folder_id: scheduleContext?.currentSchedule?._id,
          operation_ids: selectedOperationIds,
        });
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
      });
    } catch (error) {
      errors.push(error);
    }

    table.forceLoadingState(false);

    if (errors.length) {
      notify.error({
        title: t(
          "module.supervisor_schedule:notification.dependency_deletion_failed"
        ),
        message: errors[0],
      });
    } else {
      notify.success(
        t("module.supervisor_schedule:notification.dependency_deletion_success")
      );
    }
  };

  const renderDeleteDependenciesAction = (props?: any): ReactElement => (
    <GenericAction
      icon={DeleteOutlineIcon}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderDeleteDependenciesAction,
    deleteDependenciesAction: {
      icon: withSanitizedProps(renderDeleteDependenciesAction),
      title,
      onClick,
    },
  };
};

export type IUseDeleteDependenciesAction = ReturnType<
  typeof useDeleteDependenciesAction
>;
