import { AdapterUiService } from "../adapter-ui.service";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import { useSearchParams } from "react-router-dom";
import { IAdapterBaseline, IEntityObject } from "@river/interfaces";
import {
  useSupervisorScheduleUtilizationBacklog,
  useSupervisorCraftUtilization,
  useSupervisorScheduleUtilizationPersons,
  useSupervisorScheduleUtilizationCrews,
  useSupervisorScheduleUtilizationValidationRules,
  useSupervisorScheduleUtilizationProgressSummary,
  useSupervisorScheduleUtilizationCompliance,
  useSupervisorScheduleUtilizationKpis,
} from "./index";
import { IAdapterUiContextState } from "../../context";

export enum SupervisorScheduleUtilizationTabId {
  METRICS = "metrics",
  BACKLOG = "backlog",
  CRAFTS = "crafts",
  PERSONS = "persons",
  CREWS = "crews",
  VALIDATION_RULES = "validation_rules",
  PROGRESS = "progress",
  COMPLIANCE = "compliance",
}

export interface ISupervisorScheduleUtilizationUseCurrentTableProps {
  selectedCraftIds: Set<string>;
  selectedResourceIds: Set<string>;
  selectedCrewIds: Set<string>;
  currentBaseline: IAdapterBaseline | undefined;
  progressSummaryFilters: ISupervisorScheduleProgressFilters;
  complianceSummaryFilters: ISupervisorScheduleComplianceFilters;
}

export interface IGetDefaultSupervisorScheduleBacklogColumnsProps {
  onWorkOrderClick: (workOrder: IEntityObject) => void;
  getTaskColor: (row: IEntityObject) => string;
  draggable?: boolean;
}

export enum SupervisorProgressSummaryFilterID {
  PMACTTYPE = "pmacttype",
  WORKCENTER = "workcenter",
  WO_TYPES = "wo_types",
  DEPARTMENTS = "departments",
  RESOURCES = "resources",
}

export interface ISupervisorScheduleProgressFilters {}

export enum SupervisorComplianceSummaryFilterID {
  WO_TYPES = "wo_types",
  PMACTTYPE = "pmacttype",
}

export interface ISupervisorScheduleComplianceFilters {}

export interface IGetSupervisorComplianceFiltersProps {
  adapterContext?: IAdapterUiContextState | null;
  complianceFilters: ISupervisorScheduleComplianceFilters;
}

export interface IGetSupervisorProgressFiltersProps {
  adapterContext?: IAdapterUiContextState | null;
  progressSummaryFilters: ISupervisorScheduleProgressFilters;
}

export const SupervisorScheduleUtilizationTabURLParamName = "utilization_tab";

export abstract class SupervisorScheduleUtilizationUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getCurrentTab = (): (() => SupervisorScheduleUtilizationTabId) => {
    return (): SupervisorScheduleUtilizationTabId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(SupervisorScheduleUtilizationTabURLParamName) ||
        this.getDefaultTab()) as SupervisorScheduleUtilizationTabId;
    };
  };

  useCurrentTable(
    props: ISupervisorScheduleUtilizationUseCurrentTableProps
  ): EntityTableProvider {
    return () => {
      const currentTab = this.getCurrentTab()();
      const currentBaseline: IAdapterBaseline | undefined =
        props.currentBaseline;
      const scheduleUtilizationKpisTable: IUseEntityTable =
        useSupervisorScheduleUtilizationKpis();
      const scheduleBacklogTable: IUseEntityTable =
        useSupervisorScheduleUtilizationBacklog({
          draggable: false,
        });
      const craftUtilizationTable: IUseEntityTable =
        useSupervisorCraftUtilization({
          selectedCraftIds: props.selectedCraftIds,
        });
      const resourceUtilizationTable: IUseEntityTable =
        useSupervisorScheduleUtilizationPersons({
          selectedPersonIds: props.selectedResourceIds,
        });
      const crewUtilizationTable: IUseEntityTable =
        useSupervisorScheduleUtilizationCrews({
          selectedCrewIds: props.selectedCrewIds,
        });
      const validationRulesTable: IUseEntityTable =
        useSupervisorScheduleUtilizationValidationRules();
      const progressSummaryTable: IUseEntityTable =
        useSupervisorScheduleUtilizationProgressSummary({
          currentBaseline,
          progressSummaryFilters: props.progressSummaryFilters,
        });
      const complianceTable: IUseEntityTable =
        useSupervisorScheduleUtilizationCompliance({
          currentBaseline,
          complianceFilters: props.complianceSummaryFilters,
        });
      if (currentTab === SupervisorScheduleUtilizationTabId.METRICS) {
        return scheduleUtilizationKpisTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.BACKLOG) {
        return scheduleBacklogTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.CRAFTS) {
        return craftUtilizationTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.PERSONS) {
        return resourceUtilizationTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.CREWS) {
        return crewUtilizationTable;
      } else if (
        currentTab === SupervisorScheduleUtilizationTabId.VALIDATION_RULES
      ) {
        return validationRulesTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.PROGRESS) {
        return progressSummaryTable;
      } else if (currentTab === SupervisorScheduleUtilizationTabId.COMPLIANCE) {
        return complianceTable;
      } else {
        return scheduleBacklogTable;
      }
    };
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getTabs(): TabsProvider;
  abstract getDefaultTab(): SupervisorScheduleUtilizationTabId;
  abstract getDefaultBacklogColumns(
    props: IGetDefaultSupervisorScheduleBacklogColumnsProps
  ): ColumnsProvider;
  abstract getDefaultCraftUtilizationColumns(): ColumnsProvider;
  abstract getCraftUtilizationEntityName(): string;
  abstract getCraftUtilizationKeyColumn(): string;
  abstract getCraftUtilizationStatLabelColumn(): string;
  abstract getDefaultPersonUtilizationColumns(): ColumnsProvider;
  abstract getProgressGridActionsRenderer(): () => ReactElementProvider;
  abstract getComplianceGridActionsRenderer(): () => ReactElementProvider;
  abstract renderProgressFilters(): ReactElementProvider;
  abstract renderComplianceFilters(): ReactElementProvider;
  abstract getProgressFilters(
    props: IGetSupervisorProgressFiltersProps
  ): QueryProvider;
  abstract getComplianceFilters(
    props: IGetSupervisorComplianceFiltersProps
  ): QueryProvider;
  abstract getBlankProgressFilters(): ISupervisorScheduleProgressFilters;
  abstract getBlankComplianceFilters(): ISupervisorScheduleComplianceFilters;
}
